import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Languages from "../../../constants/Languages";
import Select from 'react-select';
import Constants from "../../../constants/Constants";

class PaperForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        paper: {
            id: null,
            enabled: true,
            has_cover: 0,
            has_text: 0,
            has_jacket: 0,
            has_banderole: 0,
            has_cloth: 0,
            has_cardboard: 0,
            has_endpapers: 0,
            has_bookmark: 0,
            paper_name_id: '',
            paper_color_id: '',
            paper_size_id: '',
            paper_density_id: '',
            paper_type_id: '',
            volume_ratio: '',
            volume_ratio_alt: 0,
            coef1_sewed: 0,
            coef2_sewed: 0,
            coef3_sewed: 0,
            coef1_stitched: 0,
            coef2_stitched: 0,
            coef3_stitched: 0,
            price_per_ton: '',
            price_per_ton_base: '',
            price_per_sheet: '',
            price_per_sheet_base: '',
            overcharge: '',
            thickness: 0,
            thickness_additional: 0,
            suppliers_s: [],
            vat: 20
        },
        names: [],
        colors: [],
        sizes: [],
        densities: [],
        types: [],
        products: [],
        forProducts: [],
        usedProductsGroups: [],
        suppliers: []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id !== undefined) {
            this.load(id);
        } else {
            this.loadForm();
        }
    }

    setMainSelects(response) {
        let {products} = response;
        let {names} = response;
        let {colors} = response;
        let {sizes} = response;
        let {types} = response;
        let {densities} = response;
        let {suppliers} = response;
        let forProducts = [];
        products.forEach(product => {
            forProducts.push({id: product.id, value: 'special', isSelected: false})
        });
        this.setState({products: products, names: names, colors: colors, sizes: sizes, types: types, densities: densities,forProducts: forProducts, suppliers: suppliers});
    }

    loadForm() {
        this.setState({isLoading: true}, () => {
            NetworkClient.get('papers/create')
                .then(response => {
                    this.setMainSelects(response);
                })
                .finally(()=>{
                    this.setState({isLoading: false});
                })
        })
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    initForProductsForEdit() {
        let {forProducts} = this.state;

        for (let i = 0; i < forProducts.length; i++)
        {
            if(this.state.usedProductsGroups.find(upg => upg.product_id === forProducts[i].id))
            {
                forProducts[i].isSelected = true;
            }
        }

        this.setState({forProducts: forProducts});
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`papers/${id}`)
                .then(response => {
                    this.setMainSelects(response);
                    let {paper} = response;
                    let {usedProductsGroups} = response;
                    this.setState({paper: paper, usedProductsGroups: usedProductsGroups},()=>{
                        this.initForProductsForEdit();
                    });
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        const {paper} = this.state;
        let {forProducts} = this.state;
        let products = forProducts.filter(p => p.isSelected === true).map(p => { return {id: p.id, group: p.value} } );
        console.log(paper);
        if(paper.has_banderole === 0
            && paper.has_cardboard === 0
            && paper.has_cloth === 0
            && paper.has_cover === 0
            && paper.has_endpapers === 0
            && paper.has_jacket === 0
            && paper.has_text === 0
            && paper.has_bookmark === 0)
        {
            const {t} = this.props;
            Swal.fire('Error',t('please-select-at-least-one-element'),'error');
            return;
        }

        if(products.length === 0)
        {
            const {t} = this.props;
            Swal.fire('Error',t('please-select-at-least-one-product'),'error');
            return;
        }

        this.setState({isLoading: true});
        let url = 'papers';
        if (paper.id !== null) {
            url += '/' + paper.id;
        }

        let requestPayload = {paper: paper, productGroups: products};

        NetworkClient.post(url, requestPayload)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        if(this.isCardboard())
                        {
                            this.props.history.push('/cardboards');
                        }
                        else
                        {
                            this.props.history.push('/papers/full');
                        }

                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    hasPrint(t) {
        if(this.isCardboard())
        {
            let {paper} = this.state;
            if(paper.has_cardboard !== 1)
            {
                paper.has_cardboard = 1;
                //TODO: Change cardboard logic complete?
                paper.paper_name_id = 77;
                this.setState({paper: paper});
            }
            return;
        }

        let has = ['has_cover','has_text','has_jacket','has_banderole','has_cloth','has_cardboard','has_endpapers','has_bookmark'];

        return has.map(h => <label>
            <input type={"checkbox"} onChange={e => {
                let {paper} = this.state;
                paper[h] = e.target.checked === true ? 1 : 0;
                this.setState({paper: paper});
            }} checked={this.state.paper[h]} /> &nbsp;
            {t(h)} &nbsp;
        </label>)
    }

    handleNormalSpecialChange(e, p, value) {
        let {forProducts} = this.state;
        let index = forProducts.findIndex(fp => fp.id === p.id);
        forProducts[index].value = value;
        this.setState({forProducts: forProducts});
    }

    printForProducts(t) {
        return this.state.products.filter(p => this.isCardboard() ? p.id === 8 : true).map(p => <div className={"row"}>
            <div className="col-6">
                <label>
                    <input
                        checked={this.state.forProducts.findIndex(fp => fp.id === p.id && fp.isSelected === true) !== -1}
                        onChange={e => {
                            let {forProducts} = this.state;
                            let index = forProducts.findIndex(fp => fp.id === p.id);
                            forProducts[index].isSelected = e.target.checked;
                            this.setState({forProducts: forProducts});
                        }}
                        type={"checkbox"} /> &nbsp; {p.name}
                </label>
            </div>
            {this.state.paper.id === null &&
            <div className="col-6">
                <input
                    name={p.name}
                    value={"normal"}
                    checked={this.state.forProducts.find(fp => fp.id === p.id && fp.value === 'normal')}
                    type="radio"
                    onChange={e => { this.handleNormalSpecialChange(e, p,"normal"); }}
                />
                {t('normal')} | {t('special')}
                <input
                    name={p.name}
                    value={"special"}
                    checked={this.state.forProducts.find(fp => fp.id === p.id && fp.value === 'special')}
                    type="radio"
                    onChange={e => { this.handleNormalSpecialChange(e, p,"special"); }}
                />
            </div>
            }
        </div>)
    }

    handleChangeTextInput(e, field) {
        let {paper} = this.state;
        paper[field] = e.target.value;
        this.setState({paper: paper});
    }

    isCardboard()
    {
        return this.props.location.pathname.includes('/cardboards') ? true : false;
    }

    printPaperFields(t)
    {
        return (
            <div>
            <div className={"row"}>
                <div className={`col-12 mb-1 ${this.isCardboard() ? 'd-none' : ''}`}>
                    <label>{t("name")}</label>
                    <Select
                        value={this.state.names.find(c => c.id === this.state.paper.paper_name_id)}
                        onChange={(selected)=>{
                            let {paper} = this.state;
                            paper.paper_name_id = selected.id;
                            this.setState({paper: paper});
                        }}
                        options={this.state.names}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className={"col-12 mb-1"}>
                    <label>{t('color')}</label>
                    <Select
                        value={this.state.colors.find(c => c.id === this.state.paper.paper_color_id)}
                        onChange={(selected)=>{
                            let {paper} = this.state;
                            paper.paper_color_id = selected.id;
                            this.setState({paper: paper});
                        }}
                        options={this.state.colors}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className={"col-6 mb-1"}>
                    <label>{t('format')}</label>
                    <Select
                        value={this.state.sizes.find(c => c.id === this.state.paper.paper_size_id)}
                        onChange={(selected)=>{
                            let {paper} = this.state;
                            paper.paper_size_id = selected.id;
                            this.setState({paper: paper});
                        }}
                        options={this.state.sizes}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-6 mb-1">
                    <label>{t('type')}</label>
                    <Select
                        value={this.state.types.find(c => c.id === this.state.paper.paper_type_id)}
                        onChange={(selected)=>{
                            let {paper} = this.state;
                            paper.paper_type_id = selected.id;
                            this.setState({paper: paper});
                        }}
                        options={this.state.types}
                        getOptionLabel={(option)=> option.type}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-4 mb-1">
                    <label>{t('density')}</label>
                    <Select
                        value={this.state.densities.find(c => c.id === this.state.paper.paper_density_id)}
                        onChange={(selected)=>{
                            let {paper} = this.state;
                            paper.paper_density_id = selected.id;
                            this.setState({paper: paper});
                        }}
                        options={this.state.densities}
                        getOptionLabel={(option)=> option.density}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-2 mb-1">
                    <label>{t('volume_ratio')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.volume_ratio}
                        onChange={(e)=>{ this.handleChangeTextInput(e, 'volume_ratio')}}
                    />
                </div>
                <div className="col-2 mb-1 d-none">
                    <label>{t('volumeRatioAlt')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.volume_ratio_alt}
                        onChange={(e)=>{ this.handleChangeTextInput(e, 'volume_ratio_alt')}}
                    />
                </div>
                <div className={"col-3 mb-1 " + (this.isCardboard() ? '' : 'd-none')}>
                    <label>{t('thickness')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.thickness}
                        onChange={(e)=>{ this.handleChangeTextInput(e, 'thickness')}}
                    />
                </div>
                <div className={"col-3 mb-1 " + (this.isCardboard() ? '' : 'd-none')}>
                    <label>{t('thickness_additional')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.thickness_additional}
                        onChange={(e)=>{ this.handleChangeTextInput(e, 'thickness_additional')}}
                    />
                </div>

                <div className="col-2 mb-1 d-none">
                    <label>{t('coef1_sewed')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.coef1_sewed}
                        onChange={(e)=>{ this.handleChangeTextInput(e, 'coef1_sewed')}}
                    />
                </div>

                <div className="col-2 mb-1 d-none">
                    <label>{t('coef2_sewed')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.coef2_sewed}
                        onChange={(e)=>{ this.handleChangeTextInput(e, 'coef2_sewed')}}
                    />
                </div>

                <div className="col-2 mb-1 d-none">
                    <label>{t('coef3_sewed')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.coef3_sewed}
                        onChange={(e)=>{ this.handleChangeTextInput(e, 'coef3_sewed')}}
                    />
                </div>

                <div className="col-2 mb-1 d-none">
                    <label>{t('coef1_stitched')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.coef1_stitched}
                        onChange={(e)=>{ this.handleChangeTextInput(e, 'coef1_stitched')}}
                    />
                </div>

                <div className="col-2 mb-1 d-none">
                    <label>{t('coef2_stitched')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.coef2_stitched}
                        onChange={(e)=>{ this.handleChangeTextInput(e, 'coef2_stitched')}}
                    />
                </div>

                <div className="col-2 mb-1 d-none">
                    <label>{t('coef3_stitched')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.coef3_stitched}
                        onChange={(e)=>{ this.handleChangeTextInput(e, 'coef3_stitched')}}
                    />
                </div>
            </div>
            <div className={"row"}>
                <div className="col-4 mb-1">
                    <label>{t('pricePerTon')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.price_per_ton_base}
                        onChange={(e)=>{
                            let selectedSize = this.state.paper.paper_size_id;
                            let size = this.state.sizes.find(s=>s.id === selectedSize);
                            let selectedDensity = this.state.paper.paper_density_id;
                            let density = this.state.densities.find(d => d.id === selectedDensity);
                            let vat = 20;

                            if(size && density)
                            {
                                let sheetFormula = (size.height / 100) * (size.length / 100) * (density.density / 1000) / 1000;
                                let price = parseFloat(e.target.value) * (1 + parseFloat(vat)/100)*(1 + parseFloat(this.state.paper.overcharge)/100);
                                let {paper} = this.state;
                                paper.price_per_ton = (price).toFixed(2);
                                paper.price_per_sheet = (sheetFormula*price).toFixed(6);
                                paper.price_per_sheet_base = (sheetFormula * parseFloat(e.target.value)).toFixed(6);
                                this.setState({paper: paper})
                            }

                            this.handleChangeTextInput(e, 'price_per_ton_base');
                        }}
                        />
                    </div>

                    <div className="col-4 mb-1">
                        <label>{t('price_per_sheet')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.price_per_sheet_base}
                        onChange={(e)=>{
                            let vat = 20;
                            let price = parseFloat(e.target.value) * (1 + vat / 100) * (1 + this.state.paper.overcharge / 100);
                            let {paper} = this.state;
                            paper.price_per_sheet = price.toFixed(6);
                            paper.price_per_ton = '';
                            paper.price_per_ton_base = '';
                            this.setState({paper: paper});
                            this.handleChangeTextInput(e, 'price_per_sheet_base')
                        }}
                        />
                    </div>

                    <div className="col-4 mb-1">
                        <label>{t('overcharge')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.overcharge}
                        onChange={(e)=>{
                            let priceTon = this.state.paper.price_per_ton;
                            let priceTonBase = this.state.paper.price_per_ton_base;
                            let vat = 20;
                            let priceSheetBase = this.state.price_per_sheet_base;
                            let thisValue = parseFloat(e.target.value);
                            let {paper} = this.state;

                            if (priceTon === 0 || priceTon === '') {
                                let price = priceSheetBase * (1 + vat / 100) * (1 + thisValue / 100);
                                paper.price_per_sheet = price.toFixed(6);
                            } else {
                                let sheetFormula = this.getSheetFormula();
                                if(sheetFormula)
                                {
                                    let price = priceTonBase * (1 + vat / 100) * (1 + thisValue / 100);
                                    paper.price_per_ton = price.toFixed(2);
                                    paper.price_per_sheet = (sheetFormula * price).toFixed(6);
                                }

                            }
                            this.setState({paper: paper});
                            this.handleChangeTextInput(e, 'overcharge')
                        }}
                        />
                    </div>

                    <div className="col-4 mb-1">
                        <label>{t('price_per_ton_with_overcharge')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.price_per_ton}
                        onChange={(e)=>{
                            let sheetFormula = this.getSheetFormula();
                            let vat = 20;
                            if(sheetFormula)
                            {
                                let price = parseFloat(e.target.value) / (1 + vat / 100) / (1 + this.state.paper.overcharge / 100);
                                let {paper} = this.state;
                                paper.price_per_ton_base = price.toFixed(2);
                                paper.price_per_sheet = (sheetFormula * (parseFloat(e.target.value))).toFixed(6);
                                paper.price_per_sheet_base = (sheetFormula * price).toFixed(6);
                            }
                            this.handleChangeTextInput(e, 'price_per_ton')
                        }}
                        />
                    </div>

                    <div className="col-4 mb-1">
                        <label>{t('price_per_sheet_with_overcharge')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={this.state.paper.price_per_sheet}
                        onChange={(e)=>{
                            let vat = 20;
                            let price = parseFloat(e.target.value) / (1 + vat/100) / (1 + this.state.paper.overcharge/100);
                            let {paper} = this.state;
                            paper.price_per_sheet_base = price.toFixed(6);
                            paper.price_per_ton = '';
                            paper.price_per_ton_base = '';
                            this.setState({paper: paper});
                            this.handleChangeTextInput(e, 'price_per_sheet')
                        }}
                        />
                    </div>

                    <div className="col-4 mb-1">
                        <label>{t('vat')}</label>
                    <input
                        type="text" className={"form-control"}
                        value={Constants.VAT}
                        readOnly={true}
                        onChange={(e)=>{ this.handleChangeTextInput(e, 'vat')}}
                        />
                    </div>
                </div>
            </div>
        )
    }

    getSheetFormula() {
        let selectedSize = this.state.paper.paper_size_id;
        let size = this.state.sizes.find(s=>s.id === selectedSize);
        let selectedDensity = this.state.paper.paper_density_id;
        let density = this.state.densities.find(d => d.id === selectedDensity);
        let vat = 20;

        if(size && density) {
            return (size.height / 100) * (size.length / 100) * (density.density / 1000) / 1000;
        }

        return false;
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{this.isCardboard() ? t('cardboard') : t('paper')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"row"}>
                            <div className={"col-md-8"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className={"row"}>
                                            <div className={"col-6"}>
                                                <input type="checkbox" checked={this.state.paper.enabled === true}
                                                    onChange={e => {
                                                        let {paper} = this.state;
                                                        paper.enabled = e.target.checked;
                                                        this.setState({paper:paper});
                                                    }}
                                                /> {t('enabled')}
                                            </div>
                                            <div className={"col-6"}>
                                                {this.hasPrint(t)}
                                            </div>
                                        </div>
                                        {this.printPaperFields(t)}

                                        <div className="row">
                                            <div className="col-12">
                                                <label>{t('supplier')}</label>
                                                <Select
                                                    isMulti={true}
                                                    value={this.state.paper.suppliers}
                                                    onChange={(selected)=>{
                                                        let {paper} = this.state;
                                                        paper.suppliers = selected;
                                                        this.setState({paper: paper});
                                                    }}
                                                    options={this.state.suppliers}
                                                    getOptionLabel={(option)=> option.name}
                                                    getOptionValue={(option) => option.id}
                                                />
                                            </div>
                                        </div>

                                        <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                            {this.state.paper.id === null ? 'Create' : 'Update'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-4"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        {this.printForProducts(t)}
                                    </div>
                                </div>
                                <div className={"card card-primary " + (this.state.usedProductsGroups.length > 0  ? '' : 'd-none')}>
                                    <div className="card-body">
                                        {this.state.usedProductsGroups.map(p => <div>
                                            <b>
                                                {this.state.products.find(prod => prod.id === p.product_id).name}
                                            </b>
                                            <div>
                                                {p.components.map(c => <div>
                                                    {c.component} : {c.group}
                                                </div>)}
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PaperForm);
