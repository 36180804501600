import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../../../api/NetworkClient';
import Loader from '../../../../../partial/Loader';
import Languages from "../../../../../constants/Languages";
import SimpleFieldsGenerator from '../../../../../helpers/SimpleFieldsGenerator';
import Select from "react-select/";

class OverchargesForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        overcharge: {
            id: null,
            weight_from: '',
            weight_to: '',
            paper_type_id: '',
            group_id: '',
            overcharge: '',
            k1: '',
            k2: '',
            k3: '',
            k4: '',
            k5: '',
            enabled: 0,
        },
        groups: [],
        papertypes: [],
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        

        if(id !== undefined)
        {
            this.load(id);
        }
        else 
        {
            this.setState({isLoading:true});
            NetworkClient.get('transport/fr/overcharges-get-groups-papertypes')
                .then(response => {
                    let groups = response.groups;
                    let papertypes = response.papertypes;
                    this.setState({groups, papertypes});
                }).finally(c=> {
                    this.setState({isLoading:false});
            });
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`transport/fr/overcharges/${id}`)
                .then(response => {
                    let papertypes = response.data.papertypes;
                    const groups = response.data.groups;
                    const overcharge = response.overcharge;
                    this.setState({overcharge, groups, papertypes });
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {overcharge} = this.state;
        let url = 'transport/fr/overcharges';
        if(overcharge.id !== null)
        {
            url += '/' + overcharge.id;
        }

        NetworkClient.post(url, overcharge)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c=>{
                        this.props.history.push('/transport/france/overcharges');
                    })
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
        const {groups} = this.state;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                         
                                <h1>{t('edit')} - FR {t('transport')} {t('overcharges')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                   
                                    <div className={"form-group"}>
                                    <label>{t('group')}</label>
                                    <Select
                                        value={groups && groups.find(group => group.id === this.state.overcharge.group_id)}
                                        options={groups}
                                        getOptionLabel={(option)=> option.name}
                                        getOptionValue={(option) => option.id}
                                        onChange={(selected) => {
                                            let {overcharge} = this.state;
                                            overcharge.group_id = selected.id;
                                            this.setState({overcharge : overcharge})
                                        }}
                                    />
                                    </div>
                                    <div className={"form-group"}>
                                    <label>{t('paper')} {t('types')}</label>
                                    <Select
                                        value={this.state.papertypes.find(pap => pap.id === this.state.overcharge.paper_type_id)}
                                        options={this.state.papertypes}
                                        getOptionLabel={(option)=> option.type}
                                        getOptionValue={(option) => option.id}
                                        onChange={(selected) => {
                                            let {overcharge} = this.state;
                                            overcharge.paper_type_id = selected.id;
                                            this.setState({overcharge : overcharge})
                                        }}
                                    />
                                    </div>
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'weight_from', type: 'number'}], "overcharge")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'weight_to', type: 'number'}], "overcharge")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'overcharge', type: 'number'}], "overcharge")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'k1', type: 'number'}], "overcharge")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'k2', type: 'number'}], "overcharge")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'k3', type: 'number'}], "overcharge")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'k4', type: 'number'}], "overcharge")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'k5', type: 'number'}], "overcharge")()}
                                    <div className={"form-group"}>
                                    <label>{t('enabled')}
                                        <input type="checkbox" className="ml-3" checked={this.state.overcharge.enabled === 1}
                                            onChange={e => {
                                                let {overcharge} = this.state;
                                                overcharge.enabled = e.target.checked === true ? 1 : 0;
                                                this.setState({overcharge})
                                            }}
                                        />
                                        </label>
                                    </div>
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.overcharge.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(OverchargesForm);
