import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;

    return (
      <div>
        <div class="content-header">
            {t("test")}
        </div>
      </div>
    )
  }
}

export default withTranslation()(Dashboard);
