import Swal from 'sweetalert2';

const swalFireAskBeforeAction = function(t){
    return Swal.fire({
        title: t('are-you-sure'),
        text: t('you-wont-be-able-to-revert-this'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('yes-do-it')
    });
}

export default swalFireAskBeforeAction;
