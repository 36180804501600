import React, { Component } from 'react'
import Cookies from 'js-cookie';
import Languages from '../constants/Languages';

export default class LangNav extends Component {
  constructor(props)
  {
    super(props);
  }

  render() {
    const {t, i18n} = this.props;
    const languages = Languages;

    return (
      <div>
        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          {languages.map(l =><a onClick={()=>{
            this.props.i18n.changeLanguage(l);
            Cookies.set('lang', l);
          }} className="dropdown-item">
            <img alt={""} style={{width:21}} className={"img-fluid"} src={`/dist/img/flags/${l}.jpg`} /> {t(l)}
          </a> )}
        </div>
      </div>
    )
  }
}
