import React from 'react';

/*
* Fields format should be {name: '', type: '', col: 12, label_title: 'OPTIONAL'}
* Name is name from state
* Type is number, text, date
* Col is number, bootstrap column number
* Object name might be state main object (paper, density, order, etc.)
* */

let SimpleFieldsWithColumnsGenerator = function printFromFields(t, fields, objectName) {
    return fields.map((field, i) =>  <div className={`form-group col-${field.col}`}>
        <label>{field.label_title !== undefined ? t(field.label_title) : t(field.name)}</label>
        <input
            key={i}
            type={field.type} className={"form-control"}
            placeholder={field.label_title !== undefined ? t(field.label_title) : t(field.name)} value={this.state[objectName][field.name]}
            onChange={(e) => {
                let tmp = this.state[objectName];
                tmp[field.name] = e.target.value;
                let curState = this.state;
                curState[objectName] = tmp;
                this.setState(curState);
            }}
        />
    </div>)
}

export default SimpleFieldsWithColumnsGenerator;
