import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import Languages from "../../constants/Languages";
import Select from 'react-select';
import TooltipLabel from "../../helpers/TooltipLabel";

class CurrenciesForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        currency: {
            id: null,
            type: '',
            value: '',
            is_main: 0
        },
        currencies: []
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id !== undefined) {
            this.load(id);
        }
        else {
            this.loadCurrencies();
        }
    }

    loadCurrencies() {
        NetworkClient.get('currencies-list-json')
            .then(response => {
                const {currencies} = response;
                this.setState({currencies});
            })
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`currencies/${id}`)
                .then(response => {
                    const {currency} = response;
                    const {currencies} = response;
                    this.setState({currency, currencies});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {currency} = this.state;
        let url = 'currencies';
        if (currency.id !== null) {
            url += '/' + currency.id;
        }

        NetworkClient.post(url, currency)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        this.props.history.push('/currencies');
                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    printFormFields(t) {
        let fields= [{name: 'value', type: 'number'}];

        return fields.map((field, i) =>  <div className="form-group">
            <label>{t(field.name)}</label>
            <input
                disabled={this.state.currency.is_main===1}
                key={i}
                type={field.type} className={"form-control"}
                placeholder={t(field.name)} value={this.state.currency[field.name]}
                onChange={(e) => {
                    let {currency} = this.state;
                    currency[field.name] = e.target.value;
                    this.setState({currency});
                }}
            />
        </div>)
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('currencies')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <label>{t('type')}</label>
                                    <Select
                                        value={this.state.currencies.find(c => c.value.toLowerCase() === this.state.currency.type)}
                                        onChange={(selected)=>{
                                            let {currency} = this.state;
                                            currency.type = selected.value.toLowerCase();
                                            this.setState({currency});
                                        }}
                                        options={this.state.currencies}
                                        getOptionLabel={(option)=> option.name}
                                        getOptionValue={(option) => option.value}
                                    />
                                    <div style={{marginTop: 10}}>
                                        <label className={"d-block"}>
                                            {t('is_main_currency')} <TooltipLabel label={t('main_currency_explained')} />
                                        </label>

                                        <input type={"checkbox"}
                                               onChange={()=>{
                                                   let {currency} = this.state;
                                                   currency.is_main = currency.is_main === 1 ? 0 : 1;
                                                   if(currency.is_main === 1)
                                                   {
                                                       currency.value = 1;
                                                   }
                                                   this.setState({currency});
                                               }}
                                               checked={this.state.currency.is_main === 1} />
                                    </div>

                                    {this.printFormFields(t)}

                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.currency.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CurrenciesForm);
