import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';

class EmbossingFormPartial extends Component {
    state = {
        isLoading: false,
        embossing: {
            is_enabled: false,
            number_of_stamps: 1,
            sizes: [
                {
                    length: 100,
                    height: 100,
                    lengthInches: 3.94,
                    heightInches: 3.94
                }
            ]
        },
        is_inited: false
    }

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(props) {
        if(!this.state.is_inited && props.initial_state !== null && props.initial_state !== undefined)
        {
            let embossing = props.initial_state;
            this.setState({embossing: props.initial_state})
        }
    }

    render() {
        return (<> <div
                className="row pb-3"
                style={{borderBottom: '1px solid silver'}}>
                <div className="col-6">
                    <label>
                        <input
                            type="checkbox"
                            checked={this.state.embossing.is_enabled}
                            onChange={e => {
                                let {embossing} = this.state;
                                embossing.is_enabled = e.target.checked;
                                this.setState({embossing},()=>{
                                    this.props.applyChanges(this.state.embossing);
                                })
                            }}
                        /> &nbsp;
                        {this.props.t('embossing')}
                    </label>
                    <div className={(this.state.embossing.is_enabled ? ' ' : 'd-none')}>
                        {this.props.t('all_sizes_in_mm')} {this.props.measure}
                    </div>
                </div>
                <div className={"col-6 " + (this.state.embossing.is_enabled ? ' ' : 'd-none') } >
                    <label>{this.props.t('number_of_stamps')}</label>
                    <select onChange={e => {
                        let embossing = this.state.embossing;
                        let sizes = embossing.sizes;
                        let value = e.target.value;
                        if(sizes.length < value)
                        {
                            while(sizes.length < value)
                            {
                                sizes.push({
                                    length: 100,
                                    height: 100,
                                    lengthInches: 3.94,
                                    heightInches: 3.94
                                })
                            }
                        }

                        if(sizes.length > value)
                        {
                            while(sizes.length > value)
                            {
                                sizes.splice(sizes.length - 1,1);
                            }
                        }

                        embossing.sizes = sizes;
                        embossing.number_of_stamps = value;
                        this.setState({embossing},()=>{
                            this.props.applyChanges(this.state.embossing);
                        })
                    }} className={'form-control'}>
                        <option selected={this.state.embossing.number_of_stamps == 1} value="1">1</option>
                        <option selected={this.state.embossing.number_of_stamps == 2} value="2">2</option>
                        <option selected={this.state.embossing.number_of_stamps == 3} value="3">3</option>
                        <option selected={this.state.embossing.number_of_stamps == 4} value="4">4</option>
                    </select>
                </div>
            </div>
                <div className={"row mt-3 " + (this.state.embossing.is_enabled ? ' ' : 'd-none')}>
                    {this.state.embossing.sizes.map( (size, index) => {
                        return (<>
                            <div className="col-6">
                                <label>{this.props.t('length')}</label>
                                <input
                                    type="number"
                                    min={3.94}
                                    value={this.state.embossing.sizes[index].lengthInches}
                                    onChange={e => {
                                        let {embossing} = this.state;
                                        let inches = e.target.value;
                                        embossing.sizes[index].length = Math.ceil(inches * 25.4);
                                        embossing.sizes[index].lengthInches = inches;
                                        this.setState({embossing},()=>{
                                            this.props.applyChanges(this.state.embossing);
                                        })
                                    }}
                                    className={"form-control" + (this.props.measure === 'inches' ? '' : ' d-none')}
                                />
                                <input
                                    value={this.state.embossing.sizes[index].length}
                                    type="number"
                                    min={100}
                                    className={"form-control" + (this.props.measure === 'mm' ? '' : ' d-none')}
                                    onChange={e => {
                                        let {embossing} = this.state;
                                        embossing.sizes[index].length = e.target.value;
                                        let inches = parseFloat(e.target.value * 0.0393700787).toFixed(2);
                                        embossing.sizes[index].lengthInches = inches;
                                        this.setState({embossing},()=> {
                                            this.props.applyChanges(this.state.embossing);
                                        })
                                    }}
                                />
                                {this.state.embossing.sizes[index].length < 100 && <div className={'text-red'}>
                                    {this.props.t('minimal_size')}  {this.props.measure === 'mm' ? '100mm':'3.94 inches'}
                                </div>}
                            </div>
                            <div className="col-6">
                                <label>{this.props.t('height')}</label>
                                <input
                                    min={3.94}
                                    value={this.state.embossing.sizes[index].heightInches}
                                    onChange={e => {
                                        let {embossing} = this.state;
                                        let inches = e.target.value;
                                        embossing.sizes[index].height = Math.ceil(inches * 25.4);
                                        embossing.sizes[index].heightInches = inches;
                                        this.setState({embossing},()=>{
                                            this.props.applyChanges(this.state.embossing);
                                        })
                                    }}
                                    type="number"
                                    className={"form-control" + (this.props.measure === 'inches' ? '' : ' d-none')}
                                />
                                <input
                                    value={this.state.embossing.sizes[index].height}
                                    type="number"
                                    min={100}
                                    onChange={e => {
                                        let {embossing} = this.state;
                                        embossing.sizes[index].height = e.target.value;
                                        let inches = parseFloat(e.target.value * 0.0393700787).toFixed(2);
                                        embossing.sizes[index].heightInches = inches;
                                        this.setState({embossing},()=>{
                                            this.props.applyChanges(this.state.embossing);
                                        })
                                    }}
                                    className={"form-control" + (this.props.measure === 'mm' ? '' : ' d-none')}
                                />
                                {this.state.embossing.sizes[index].height < 100 && <div className={'text-red'}>
                                    {this.props.t('minimal_size')}  {this.props.measure === 'mm' ? '100mm':'3.94 inches'}
                                </div>}
                            </div>
                        </>)
                    })}
                </div>
            </>
        );
    }
}

export default withTranslation()(EmbossingFormPartial);
