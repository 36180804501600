import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Languages from '../../../constants/Languages';
import SimpleFieldsGenerator from '../../../helpers/SimpleFieldsGenerator';
import Select from 'react-select';

class AllCountriesForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        country: {
            id: null,
            iso3: '',
            currency_id: ''
        },
        currencies: []
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id !== undefined) {
            this.load(id);
        }
        else
        {
            this.setState({isLoading: true});
            NetworkClient.get('currencies')
                .then(response => {
                    let {currencies} = response;
                    this.setState({currencies: currencies, isLoading: false});
                })
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`all-countries/${id}`)
                .then(response => {
                    const country = response.country;
                    const currencies = response.currencies;
                    this.setState({country:country, currencies:currencies});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {country} = this.state;
        let url = 'all-countries';
        if (country.id !== null) {
            url += '/' + country.id;
        }

        NetworkClient.post(url, country)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        this.props.history.push('/all-countries');
                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
        let fieldsLang = this.state.languages.map((l) => {return {name: `${l}_name`, type:'text'}});
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('country')} {this.state.country.id === null ? t('createNew') : t('edit')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {SimpleFieldsGenerator.bind(this,t,fieldsLang,"country")()}
                                    <hr/>
                                    {SimpleFieldsGenerator.bind(this,t,[{name: 'iso3', type: 'text',helper_label: 'iso3_explained'}],"country")()}
                                    <label>{t('currency')}</label>
                                    <Select
                                        value={this.state.currencies.find(c => c.id === this.state.country.currency_id)}
                                        onChange={(selected)=>{
                                            let {country} = this.state;
                                            country.currency_id = selected.id;
                                            this.setState({country});
                                        }}
                                        options={this.state.currencies}
                                        getOptionLabel={(option)=> option.type}
                                        getOptionValue={(option) => option.id}
                                    />
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.country.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(AllCountriesForm);
