const de = {
    "regular_mitnica": "Такса митница (лв)",
    "regular_kg": "Тегло на палет (400)[X](кг)",
    "regular_first_kg_price": "Цена за първия палет до (400)[X]кг. (лв)",
    "regular_after_kg": "Цена за всеки следващ палет (400)[X]кг. (лв)",
    "regular_fix": "Фиксиран разход - сухопътен транспорт (лв)",
    "regular_price_kg_walk": "Цена за килограм - сухопътен транспорт (лв)",
    "regular_minimal_value_land": "Минимална цена (кг * цена за кг) - сухопътен транспорт (лв)",
    "express_first_kg_price": "Цена до (100)[X]кг тегло (лв)",
    "express_after_kg": "Цена за следващите (100)[Y] килограми (лв)",
    "express_kg": "Тегло X (кг)",
    "express_kg_each": "Тегло Y (кг)",

    "c_pro": "",
    "owner": "Inhaber",
    "show_mine" : "Nur meine Bestellungen anzeigen",

    "maculatures-cover-print" : "Макулатури корица",
    "uv_lak": "УВ Лак",
    "selektiven_lak": "Селективен лак",
    "maculatures-knigovezane": "Макулатури книговезки",
    "lepena_bez_kapaci" : "Лепена без капаци",
    "lepena_s_kapaci" : "Лепена с капаци",
    "lepena_pur_s_kapaci" : "PUR с капаци",
    "lepena_pur_bez_kapaci" : "PUR без капаци",
    "shita_lepena_s_kapaci" : "Шита-лепена с капаци",
    "shita_lepena_bez_kapaci" : "Шита-лепена без капаци",
    "vshm_bez_kapaci" : "ВШМ без капаци",
    "vshm_s_kapaci" : "ВШМ с капаци",
    "tvurda_podvurziq_standart" : "Твърда стандартна",
    "tvurda_podvurziq_s_forzaci_ot_tqloto" : "Твърда с форзаци от тялото",
    "maculatures-print-body" : "Макулатури печат тяло",
    "tirage_from" : "Тираж от",
    "tirage_to" : "Тираж до",
    "print_one" : "1+0",
    "print_two" : "2+0",
    "print_three" : "3+0",
    "print_four" : "4+0",
    "pantone" : "Пантон",
    "nadpechaten_lak" : "Надпеч. лак 1+0",
    "laminate"  : "ламинат",
    "punching" : "Щанцоване",

    "paper_format_height" : "Формат х-я Височина",
    "paper_format_length" : "Формат х-я Дължина",
    "paper_max_format_height": "Мax Формат за печат Височина",
    "paper_max_format_length": "Мax Формат за печат Дължина",
    "paper_max_format_cut_height" : "Max обрязан формат Височина",
    "paper_max_format_cut_length" : "Max обрязан формат Дължина",
    "count_pages_in_print_list" : "Брой стр в печатен лист",
    "iso3_explained" : "Country Code 3 Symbols",
    "density_text_lb" : "Dichtetext lb",
    "density_cover_lb" : "Dichteabdeckung lb",
    "default_locale": "Default locale",
    "has_cover" : "Beschichtung",
    "has_text" : "Text",
    "has_jacket" : "Schutzumschlag",
    "has_banderole" : "Bauchbinde",
    "has_cloth" : "Papier über Pappkante umschlagen",
    "has_endpapers" : "Vorsätze",
    'all_countries': "Länder",
    'pricePerTon': 'Preis pro Tonne',
    "countries_delivery": "System Länder",
    "complement":"Ergänzen",
    "cur_val":"Zwischensumme",
    "permissions":"Berechtigungen",
    "list":"Liste",
    "id":"ID",
    "name":"Name",
    "display_name":"Anzeigename",
    "description":"Beschreibung",
    "create_new":"Erstellen",
    "view":"Aussicht",
    "delete":"Löschen",
    "submit":"Anwenden",
    "edit":"Bearbeiten",
    "permission":"Berechtigung",
    "updated":"Aktualisieren",
    "added":"hinzugefügt",
    "deleted":"gelöscht",
    "all_permissions":"Alle Berechtigungen",
    "users":"Benutzer",
    "all_users":"Alle Benutzer",
    "roles":"Rollen",
    "role":"Rolle",
    "add":"Hunzufügen",
    "all_roles":"Alle Rollen",
    "assign_permissions":"Berechtigungen zuordnen",
    "assign_roles":"Rolle zuordnen",
    "create_user":"Benutzerkonto erstellen",
    "assign_websites":"Internetseite zuordnen",
    "websites":"Internetseiten",
    "domain":"Domain",
    "currency":"Währung",
    "email":"E-mail",
    "phone":"Telefon",
    "team":"Team",
    "address_info":"Anschriftangaben",
    "orders_not_final":"Liste unvollendeter Bestellungen",
    "default_category":"Kategorie laut Vorgabe",
    "is_special":"ist speziell",
    "special_category":"Sonderkategorie",
    "website":"Website",
    "has_departments":"hat Bundesländer",
    "weight":"Gewicht",
    "price":"Preis",
    "type":"Typ",
    "step":"Schritt",
    "update":"Aktualisieren",
    "update_to_current_rate":"Auf aktuelle Rate aktualisieren",
    "value":"Wert",
    "current_value":"Aktueller Wert",
    "current_rate":"Aktuelle Rate",
    "min_price":"Minimaller Preis",
    "max_price":"Maximaller Preis",
    "fixed_price":"Fixpreis",
    "per":"pro",
    "for":"für",
    "from":"von",
    "to":"bis",
    "surcharge":"Zuschlag",
    "economy":"Standard",
    "express":"Express",
    "courier":"Kurier",
    "delivery":"Lieferung",
    "color":"Farbe",
    "operations":"Operationen",
    "orders_list":"Angebotsliste",
    "status":"Status",
    "state":"Zustand",
    "select_state":"Zustand auswählen",
    "link":"Verbinden",
    "linked_orders":"Angebote verbinden",
    "finish":"Beenden",
    "back":"Zurück",
    "contacts":"Kontakt",
    "cancel":"Abbrechen",
    "error":"Fehler",
    "preview":"Vorschau",
    "calculate":"Berechnen",
    "custom_size":"benutzerdefiniertes Format",
    "standard_size":"Standardformat",
    "final_size":"Endformat",
    "length":"Breite",
    "height":"Höhe",
    "company_name":"Unternehmen",
    "first_name":"Vorname",
    "last_name":"Nachname",
    "products":"Produkte",
    "properties":"Eigenschaften",
    "deliveries":"Lieferungen",
    "papers":"Papiersorten",
    "sizes":"Formate",
    "densities":"Grammaturen",
    "suppliers":"Lieferanten",
    "colors":"Farbtöne",
    "names":"Namen",
    "types":"Typen",
    "maculatures":"Makulaturen",
    "transport":"Transport",
    "districts":"Gebiete",
    "overcharges":"zusätzliche Gebühren",
    "prices":"Preise",
    "settings":"Einstellungen",
    "taxes":"Gebühren",
    "tax_pulsio":"Gebühr Pulsio",
    "tax_security":"Gebühr Sicherheit",
    "tax_fuel":"Gebühr Treibstoff",
    "calculator_helper":"Kalkulator",
    "currencies":"Währungen",
    "orders":"Bestellungen",
    "current_states":"aktuelle Zustände",
    "states":"Zustände",
    "clients":"Kunden",
    "categories":"Kategorien",
    "contact_types":"Kontakttypen",
    "navigation":"Navigation",
    "create":"Erstellen",
    "total_papers":"alle Papiersorten",
    "total_clients":"alle Kunden",
    "total_products":"alle Druckprodukte",
    "total_orders":"alle Aufträge",
    "enabled":"aktiviert",
    "enable":"aktivieren",
    "question_delete":"Möchten Sie das wirklich löschen?",
    "sign_in":"Einloggen",
    "remember_me":"Speichern",
    "login_msg":"Neue Sitzung starten",
    "password":"Passwort",
    "key":"Schlüssel",
    "info":"Info",
    "category":"Kategorie",
    "mobile_phone":"Mobiltelefon",
    "business":"Geschäftstätigkeit",
    "bulstat":"Steuer-Nr.",
    "address":"Anschrift",
    "invoice":"Rechnung",
    "invoice_address":"Anschrift Rechnung",
    "delivery_address":"Lieferadresse",
    "number":"Nummer",
    "street_name":"Straße",
    "city":"Ort",
    "post_code":"Postleitzahl",
    "add_contact":"Kontakt hinzufügen",
    "title":"Titel",
    "waste":"Abfall",
    "percent":"Prozentsatz",
    "new_order":"Neues Angebot",
    "select_product_in_orders":"Wählen Sie ein Produkt für das neue Angebot",
    "select_type_in_orders":"Wählen Sie der Typ Ihrer Bestellung",
    "select_website_in_orders":"Wählen Sie eine Website für das neue Angebot aus",
    "comment":"Kommentar",
    "client":"Kunde",
    "product":"Produkt",
    "company":"Unternehmen",
    "finished_size_length":"Endformat Breite",
    "finished_size_height":"Endformat Höhe",
    "copies":"Exemplare",
    "bindings":"Bindungstypen",
    "order":"Angebot",
    "foil":"Folie",
    "banderole":"Bauchbinde",
    "jacket":"Schutzumschlag",
    "wrapped":"gepackt",
    "total_price":"Gesamtbetrag",
    "overcharge":"Preisaufschlag",
    "discount":"Ermäßigung",
    "final_price":"Endpreis",
    "budget":"Budget",
    "base_overcharges":"Standardpreisaufschläge",
    "additional_overcharges":"Zusatzpreisaufschläge",
    "property":"Option",
    "percentage":"Prozentsatz",
    "remove":"Entfernen",
    "paper":"Papier",
    "density":"Dichte",
    "text":"Text",
    "text2":"Text",
    "price_per_sheet":"Preis pro Seite",
    "volume_ratio":"Volumenverhältnis",
    "volume_ratio_alt":"Volume Ratio (alt)",
    "coef1_sewed":"Coef 1 (fadengeheftet)",
    "coef2_sewed":"Coef 2 (fadengeheftet)",
    "coef3_sewed":"Coef 3 (fadengeheftet)",
    "coef1_stitched":"Coef 1 (drahtklammergeheftet)",
    "coef2_stitched":"Coef 2 (drahtklammergeheftet)",
    "coef3_stitched":"Coef 3 (drahtklammergeheftet)",
    "cover":"Beschichtung",
    "size":"Größe",
    "price_per_ton_with_overcharge":"Preis pro Tonne(einschl. MwSt.)",
    "price_per_sheet_with_overcharge":"Preis pro Seite(einschl. MwSt.)",
    "vat":"MwSt.",
    "book":"Buch",
    "paper_group":"Papierkategorien",
    "special":"Sonderpapiere",
    "no_group":"Ohne Gruppe",
    "normal":"Standardpapiere",
    "profile":"Benutzerkonto",
    "sign_out":"Ausloggen",
    "folding":"Falzart",
    "binding":"Bindungstyp",
    "print":"Farbigkeit",
    "finishing":"Veredelung",
    "insert":"Einschubblätter",
    "days":"Tage",
    "new_password":"Neues Passwort",
    "repeat_password":"Neues Passwort wiederholen",
    "group":"Gruppe",
    "factor":"Faktor",
    "action_done":"Operation abgeschlossen",
    "success":"Erfolg",
    "price_per_ton":"Preis pro Tonne",
    "all":"Alle",
    "wrong_id":"Falsche ID",
    "formats":"Formate",
    "format":"Format",
    "thickness":"Dicke/Buchbinderpappe",
    "france":"Frankreich",
    "united_kingdom":"Großbritannien",
    "bulgaria":"Bulgarien",
    "belgium":"Belgien",
    "switzerland":"Schweiz",
    "belgium_trans_info":"Der belgische Verkehr entspricht dem 59 französischen Departement.",
    "mr_ms":"",
    "mr":"Herr",
    "mrs":"Frau",
    "country":"Land",
    "countries":"Länder",
    "default_currency":"Standardwährung",
    "groups":"Gruppen",
    "code":"Kode",
    "close":"Abschließen",
    "delivery_days":"Liefertage",
    "delivery_point":"Lieferort",
    "shipments":"Sendungen",
    "statuses":"Zustände",
    "priorities":"Prioritäten",
    "tir_groups":"Tir Gruppen",
    "tir_group":"Tir Gruppe",
    "printings":"Druckerzeugnisse",
    "priority":"Priorität",
    "remarks":"Anmerkungen",
    "remarks_trader":"Anmerkungen Vertrieb",
    "remarks_logistic":"Anmerkungen Logistik",
    "zip_code":"Postleitzahl",
    "delivery_from_print":"Lieferung ab Fertigungstag",
    "delivery_loading":"Expedition delivery day",
    "delivery_day":"Expedition Liefertag",
    "copies_total":"Exemplare",
    "copies_sending":"Gesendete Exemplare",
    "copies_left":"Übrige Exemplare",
    "kg":"kg",
    "schedule":"Zeitplan",
    "schedules":"Zeitpläne",
    "calendar":"Kalendar",
    "search":"Suchen",
    "year":"Jahr",
    "week":"Woche",
    "already_exists":"existiert bereits",
    "has_items":"hat Items",
    "created_by":"Erstellt von",
    "assign_delivery":"Lieferung zuordnen",
    "must_be_less_than":"muss kleiner sein als",
    "must_be_greater_than":"muss größer sein als",
    "trader":"Vertrieb",
    "package_number":"Paketnummer",
    "not_set":"Noch nicht festgelegt",
    "active":"Aktiv",
    "no_orders_assigned_yet":"Noch keine Aufträge zugeordnet",
    "other":"Andere",
    "action":"Aktion",
    "actions":"Aktionen",
    "need_update":"Sie müssen aktualisieren!",
    "total":"Total",
    "remaining":"Verbleibend",
    "to_clients_address":"an Kundenadresse auf dem Territorium von Bulgarien",
    "our_warehouse":"FRANCO unseres Lagerhaus",
    "point":"Ort",
    "points":"Orte",
    "clients_delivery_addresses":"Lieferadresse des Kunden",
    "street":"Straße",
    "address_remarks":"Bemerkungen Adresse",
    "enter_address_manually":"Adresse manuell eingeben",
    "locked":"Verschlossen",
    "unlocked":"Entschloßen",
    "inactive":"Inaktiv",
    "shipped":"Versand erfolgt",
    "not_shipped":"Nicht versendet",
    "assigned":"Zugewiesen",
    "selected":"Ausgewählt",
    "pallet":"Palette",
    "paper_factor":"Papierfaktor",
    "additional_costs":"Zusatzkosten",
    "reset":"Zurücksetzen",
    "payer":"Zahler",
    "assign_for_shipment":"Zum Versand zuordnen",
    "yes":"Ja",
    "no":"Nein",
    "boxes":"Kartons",
    "mark_as_shipped":"Als geliefert markieren",
    "mark_all_as_shipped":"Alle als geliefert markieren",
    "mark_as_not_shipped":"Als nicht geliefert markieren",
    "mark_all_as_not_shipped":"Alle als nicht geliefert markieren",
    "send_to_transporter":"An Spedition senden",
    "transporter_has_locked_the_delivery":"Die Spedition hat die Lieferung gesperrt",
    "mark_as_assigned_to_tir_group":"Als Tir Gruppe zugewiesen markieren",
    "mark_as_is_shipping":"Als versandbereit markieren",
    "mark_as_not_shipping":"Als nicht versandbereit markieren",
    "mark_all_as_assigned_to_tir_group":"Alle als Tir-Gruppen zugewiesen markieren",
    "mark_all_as_not_assigned_to_tir_group":"Alle als NICHT einer Tir-Gruppe zugewiesen markieren",
    "dates":"Datums",
    "sending":"Senden",
    "transporter":"Transporter",
    "see_on_map":"Siehe auf der Mappe",
    "delivery_express_day":"Express-Lieferfrist",
    "date":"Datum",
    "additional_options":"Zusatzoptionen",
    "barcode":"Barcode",
    "isbn":"ISBN",
    "generate":"Generieren",
    "tickets":"Tickets",
    "ticket":"Ticket",
    "all_tickets":"Alle Tickets",
    "ex":"Ex.",
    "cnt":"Kartons",
    "documents":"Dokumenten",
    "bdl":"Lieferschein",
    "cmr":"CMR",
    "all_bd_ls":"Alle Lieferscheine",
    "all_cm_rs":"Alle CMRs",
    "only_marked":"Nur markiert",
    "cloth":"Papier über Pappkante umschlagen",
    "cardboard":"Buchbinderpappe",
    "endpapers":"Vorsätze",
    "editor":"Verlag",
    "consignment_number":"Consignment number",
    "number_of_copies_per_carton":"Anzahl Exemplare in einem Kartonschlachtel",
    "number_of_copies":"Anzahl Exemplare",
    "title_label":"Titel",
    "label_ex":"Ex.",
    "nothing_selected":"Nichts ausgewählt!",
    "copies_in_a_box":"Exemplare in einem Kartonschlachtel",
    "count_boxes":"Anzahl Schachteln",
    "warehouse":"Lager",
    "damaged_papers":"Damaged Papers",
    "component":"Komponente",
    "component_is_required":"Komponente ist erforderlich",
    "group_is_required_for_book":"Gruppe ist erforderlich für das Buch",
    "thickness_additional":"Dicke/Schaumstoff",
    "difference":"Unterschied",
    "mark":"Check",
    "un_mark":"Uncheck",
    "no_boxes_assigned":"Es sind keine Boxen zugeordnet",
    "shipments_order":"Versandauftrag",
    "all_shipment_order":"Alle Sendungsaufträge",
    "select_from_addresses":"Wählen Sie aus den folgenden Adressen",
    "enter_other_address":"Verschiedene Lieferadresse eingeben",
    "must_not_be_deleted":"Item darf nicht gelöscht werden!",
    "mm":"mm",
    "is_shipped":"Versendet",
    "system":"System",
    "width":"Breite",
    "":"B",
    "":"H",
    "":"W",
    "created_at":"Erstellt am",
    "optional":"optional",
    "production_number":"Produktionsnummer",
    "distributions":"Verteilungen",
    "distribution":"Verteilung",
    "contact_person":"Kontaktperson",
    "client_has_orders":"Der Kunde hat Aufträge!",
    "suspended_by":"Aufgehoben von",
    "suspended_at":"Aufgehoben am",
    "suspend":"Aufheben",
    "suspended":"Aufgehoben",
    "deleted_by":"Gelöscht von",
    "deleted_at":"Gelöscht am",
    "organization":"Organisation",
    "levs":"leva",
    "export_tn_ts":"Export TNTs",
    "bdl_date_label":"Dokument erstellt am",
    "bdl_proof_of_delivery":"Zustellungsnachweiß",
    "bdl_title":"Titel",
    "bdl_number_of_copies_per_carton":"Anzahl Exemplare pro Kartonschlachtel",
    "bdl_number_of_cartons":"Anzahl der Kartonschachteln",
    "bdl_total_quantity":"Gesamtmenge",
    "bdl_number_of_pallets":"Anzahl der Palleten",
    "bdl_total":"Total",
    "bdl_consignment_number":"Consignment number",
    "bdl_delivered_to":"Geliefert zu",
    "bdl_on_behalf_of":"On behalf of",
    "bdl_date":"Datum:",
    "bdl_signature":"Unterzeichnung & stamp:",
    "assign_other_contact_in_documents":"Assign other contact in Documents",
    "production":"Produktion",
    "productivity":"Produktivität",
    "circulation":"Verkehr",
    "term":"Frist",
    "machine":"Maschine",
    "book_cover":"Buchdeckel",
    "amount":"Menge",
    "schedule_status":"Zustand",
    "schedule_client":"Kunde",
    "schedule_title":"Titel",
    "schedule_circulation":"Verkehr",
    "schedule_format":"Format",
    "schedule_remarks":"Hinweise",
    "schedule_color":"Farbe",
    "schedule_term":"Frist",
    "schedule_machine":"Maschine",
    "schedule_book_cover":"Buchdeckel",
    "schedule_amount":"Menge",
    "schedule_body":"Körper",
    "schedule_cover":"Umschlag",
    "schedule_cover_extras":"Umschlagextras",
    "schedule_cloth":"Leinen",
    "schedule_endpapers":"Vorsätze",
    "filter":"Filter",
    "preparations":"Vorbereitungen",
    "schedule_preparations":"Zeitplan Vorbereitungen",
    "schedule_technologist":"Technologe",
    "due_date":"Zahalungsfäligkeit",
    "schedule_fitter":"Zeitplan Monteur",
    "schedule_prepress":"Teitplan Prepress",
    "copy":"Kopie",
    "all_technologists":"Alle Technologen",
    "all_fitters":"Alle Monteure",
    "all_prepressers":"Alle Prepressspezialisten",
    "schedule_production_number":"Produktionsnummer",
    "show_only_mine":"Nur meine anzeigen",
    "schedule_employees":"Mitarbeiter",
    "schedule_type":"Typ",
    "schedule_state":"Zustand",
    "schedule_bodies":"Körper",
    "schedule_sheets":"Bögen",
    "create_and_ship":"Erstellen und senden",
    "update_and_ship":"Aktualisieren und senden",
    "filter_by_trader":"Filtern nach Vertriebaußendienst-MA",
    "no_trader_selected":"Keinen Vertriebaußendienst-MA wurde ausgewählt",
    "filter_by_printing":"Filtern nach Printart",
    "filter_by_tir_group":"Filtern nach Tir Gruppe",
    "filter_by_delivery_from_printing_day":"Filtern nach Lieferung vom Fertigungsdatum",
    "filter_by_delivery_arrival_day":"Filtern nach Liefertag",
    "no_printing_selected":"Kein Druck ausgewählt",
    "no_tir_group_selected":"Keine Tir Gruppe ausgewählt",
    "no_delivery_from_printing_day_selected":"Keine Lieferung ab Drucktag ausgewählt",
    "no_delivery_arrival_day_selected":"Kein Liefertermin ausgewählt",
    "no_delivery_from_expedition_selected":"Keine Lieferung ab dem Expeditionstag ausgewählt",
    "filter_by_delivery_from_expedition_day":"Filtern nach Lieferung ab Expeditionstag",
    "filter_by_id":"Filtern nach ID",
    "filter_by_job":"Filtern nach Job",
    "filter_by_country":"Filtern nach Land",
    "no_country_selected":"Kein Land ausgewählt",
    "id_invalid":"ID ist ungültig",
    "job_invalid":"JOB ist ungültig",
    "filter_by_package_number":"Filtern nach Paketnummer",
    "filter_by_isbn":"Filtern nach ISBN",
    "filter_by_order_name":"Filtern nach Auftragsname",
    "filter_by_trader_name":"Filtern nach Händlernamen",
    "all_packages":"Alle Pakete",
    "all_books":"Alle Bücher",
    "dropped":"Zugestellt",
    "books_dropped":"Zugestellt",
    "books_title":"Titel",
    "books_package_number":"Paketnummer",
    "books_order_id":"Auftrag ID",
    "books_address":"Adresse",
    "books_delivery_date":"Liefertag",
    "books_boxes":"Kartons",
    "books_printing":"Druck",
    "books_client":"Kunde",
    "books_trader":"Händler",
    "books_copies_ordered":"Bestellte Exemplare",
    "books_copies_expedited":"Kopien gefördert",
    "books_difference":"Unterschied",
    "books_weight_ordered":"Gewicht(kg) bestellt",
    "books_weight_per_unit":"Gewicht(kg) pro Item",
    "points_all_point":"Sendungen für den Transport",
    "tir_group":"Tir Gruppe",
    "client":"Kunde",
    "trader":"Händler",
    "shipment_n":"Sendung №",
    "boxes":"Kartons",
    "zip_code":"Postleitzahl",
    "address":"Adresse",
    "priority":"Priorität",
    "delivery_date":"Liefertag",
    "remarks_trader":"Bemerkungen Händler",
    "from_printing_date":"Datum ab Druck",
    "loading_date":"Datum wird geladen",
    "weight_ordered":"Bestellte kg",
    "total_copies_ordered":"Gesamtmenge der bestellten Exemplare",
    "total_weight_ordered":"Gesamtgewicht kg",
    "total_copies_expedited":"Gesamtzahl der zugeschickten Exemplare",
    "price_kg":"Preis kg",
    "phone":"Telefon",
    "email":"Email",
    "country":"Land",
    "remarks_logistic":"Bemerkungen Logistik",
    "tickets_address":"Adresse",
    "tickets_editor":"Kunde",
    "tickets_consignment_number":"Ref",
    "tickets_order_number":"Auftrag ID",
    "tickets_title_label":"Titel",
    "tickets_number_of_copies_per_carton":"Exemplare pro Schachtel",
    "tickets_number_of_copies":"Kopien",
    "tickets_label_ex":"",
    "return_shipment":"Die Sendung zurückschicken",
    "update_remarks":"Anmerkungen aktualisieren",
    "financial_ctrl":"Finanzkontrole",
    "fin_company_name":"Firmenname",
    "fin_client_name":"Kundenname",
    "fin_client_phone":"Kundentelefon",
    "fin_client_email":"Kunden-Email",
    "fin_trader_name":"Händlername",
    "fin_order_price":"Bestellpreis",
    "fin_financial_ctrl_state":"Zustand Finanzkontrole",
    "fin_financial_ctrl_remarks":"Anmerkungen Finanzkontrole",
    "fin_super_am_state":"Super AM Zustand",
    "fin_super_am_remarks":"Super AM Bemerkungen",
    "fin_updated_by":"Liste aktualisiert von",
    "fin_title":"Titel",
    "restore_client":"Möchten Sie diesen Kunden wirklich wiederherstellen?",
    "client_exists":"Es ist bereits ein Kunde mit derselben E-Mail-Adresse vorhanden.",
    "restore":"Wiederherstellen",
    "all_reports":"Alle Berichte",
    "sorted_by_id":"Sortiert nach ID",
    "sorted_by_country":"Nach Land sortiert",
    "sorted_by_post_code":"Nach Postleitzahl sortiert",
    "export_documents":"Dokumente exportieren",
    "sorted_by_tir_group":"Nach TIR Gruppe sortiert",
    "sorted_by_city":"Sortiert nach Stadt",
    "sorted_by_organization":"Nach Organisationsname sortiert",
    "sorted_by_client":"Nach Kundenname sortiert",
    "sorted_by_package_number":"Nach Pektnummer sortiert",
    "reports_all_point":"Sendungen für den Transport",
    "reports_tir_group":"TIR Gruppe",
    "reports_delivery_date":"Liefertag",
    "reports_country":"Land",
    "reports_post_code":"Postleitzahl",
    "reports_city":"Stadt",
    "reports_address":"Adresse",
    "reports_number":"Nummer",
    "reports_organization":"Organisation",
    "reports_client":"Kunde",
    "reports_phone":"Telefon",
    "reports_email":"Email",
    "reports_phone_optional":"Telefon (freiwillig)",
    "reports_email_optional":"Email (freiwillig)",
    "reports_order_name":"Titel",
    "reports_package_number":"Paketnummer",
    "reports_prints":"Exemplare",
    "reports_boxes":"Anzahl Kartonschlachteln",
    "reports_weight":"Gewicht (kg)",
    "mark_as_arrived":"Als angekommen markieren",
    "mark_as_departed":"Als abgereist markieren",
    "transport_ctrl":"Transportkontrole",
    "not_expedited_yet":"Noch nicht zugeschickt",
    "is_shipping":"Versendet",
    "not_assigned":"Nicht zugewiesen",
    "sending_copies":"Exemplare versendet",
    "order_id":"Bestellung ID",
    "date_today":"Datum heute",
    "date_marked_from_trader":"Vom Händler markiertes Datum",
    "date_marked_from_warehouse":"Vom Lager markiertes Datum",
    "filter_by_client":"Filtern nach Kunde",
    "auto_rescheduled_for_week":"Neuplanung für die Woche",
    "rescheduled_prints":"Neuplanung Druckaufträge",
    "rescheduled_weight":"Neuplanung Gewicht",
    "quick_shipments_switch":"Schneller Versandswechsel",
    "quick_prices_switch":"Schneller Preiswechsel",
    "sending_boxes":"Versandkartons",
    "deny_reschedule":"Neuplanung verweigern",
    "proceed_reschedule":"Neuplanung fortfahren",
    "proceed_reschedule_and_ship":"Neuplanung fortfahren und schicken",
    "reschedule":"Neuplanung",
    "reschedule_history":"Neuplanung Verlauf",
    "denied":"Verweigert",
    "rescheduled":"Neugeplant",
    "sent":"Gesendet",
    "copies_rescheduled":"Exemplare neugeplant",
    "weight_rescheduled":"Gewicht neugeplant",
    "unresolved":"Pending",
    "copy_to_clipboard":"In die Zwischenablage kopieren",
    "no_boxes":"Keine Kartons erfasst",
    "agreement_day":"Vereinbarungstag",
    "filter_by_delivery_agreement_day":"Filtern nach Tag der Liefervereinbarung",
    "reports_delivery_date_trader":"Liefertermin/Händler",
    "reports_delivery_date_agreement":"Liefertermin/Vereinbarung",
    "reports_delivery_date_final":"Liefertermin/Endgültig",
    "denied_prints":"Verweigerte Exemplare",
    "type_of_print":"Drucktyp",
    "message_boxes":"Die Bestellung ist nicht als versandt markiert",
    "language":"Sprache",
    "reception_company":"Empfangsunternehmen (im Etikett enthalten)",
    "display_the_transport_price_in_the_offer":"Transportpreis im Angebot anzeigen",
    "global_search":"Globale Suche",
    "client_val_email":"Email ist bereits bei einem anderen Kunden registriert",
    "client_val_name":"Ein Kunde mit gleichem Vor- und Nachnamen ist bereits registriert",
    "client_val_last":"Ein Kunde mit gleichem Vor- und Nachnamen ist bereits registriert",
    "client_val_org_name":"Eine Organisation mit gleichem Namen ist bereits registriert",
    "client_val_org_bulstat":"Eine Organisation mit derselben Steuernummer ist bereits registriert",
    "sales_agent":"Verkaufsaußendienstagent",
    "disapproved":"Abgelehnt",
    "approved":"Genehmigt",
    "pending":"Pending",
    "organizations":"Organisationen",
    "show_only_disapproved":"Nur abgelehnte anzeigen",
    "history":"Verlauf",
    "user":"Benutzer",
    "log":"Log",
    "updated_at":"Aktualisiert am",
    "system_generated":"System generiert",
    "system_updated":"System aktualisiert",
    "client_email_registered":"Die Email-Adresse ist bereits in der Datenbank registriert",
    "client_fln_ames_registered":"Die Vor- und Nachname sind bereits in der Datenbank registriert",
    "techno_card":"Tech. Karte",
    "techno_card_upload":"Hochladen",
    "techno_card_filename":"Dateiname",
    "techno_card_date":"Datum",
    "techno_card_status":"Zustand",
    "activate":"Aktivieren",
    "techno_card_comment":"Kommentar",
    "download":"Herunterladen",
    "fin_sam_override":"Super AM überspringt die Entscheidung der Finanzkontrolle",
    "max_insurance":"Max. Versicherung ",
    "new_max_insurance":"Max. Versicherung ",
    "order_is_in_production":"Der Auftrag ist in Produktion und kann nicht modifiziert werden",
    "showing_results_after":"Ergebnisse danach anzeigen",
    "generated_client_financial_excel_file":"Generierte Excel-Datei zur Kundenfinanzkontrolle",
    "generated_excel_file_for_schedule":"Generierte Finanzkontroll-Excel-Datei für den Transportplan",
    "all_shipments":"Alle Sendungen",
    "all_prices":"Alle Preise",
    "printing":"Wird gedruckt",
    "in_production":"In Produktion",
    "sent_to_fin_ctrl":"An Finanzkontrolle gesendet",
    "statistics":"Statistiken",
    "total_shipments":"Total Sendungen",
    "sending_shipments":"Sendungen verschicken",
    "rescheduled_shipments":"Neugeplante Sendungen",
    "locale":"lokal",
    "hunter":"Händler",
    "quotes":"Preisstellungen",
    "quote":"Einen Preis stellen",
    "offer":"Angebot",
    "offers":"Angebote",
    "order_now":"Jetzt bestellen",
    "copies_difference":"Exemplare Differenz",
    "tirage":"Exemplare",
    "redistribute":"Umverteilen",
    "per_packs":"Pro Pakete",
    "packaging":"VERPACKUNG: Schweißfolienverpackung, in Kartons auf Paletten",
    "pdf_issue294":"Berücksichtigung der Faserlaufrichtung kann nicht garantiert werden.",
    "print_run_from":"Auflage ab",
    "print_run_to":"Auflage bis",

    "printRunFrom":"Auflage ab",
    "printRunTo":"Auflage bis",

    "overcharge_52":"0+0 [%]",
    "discount_52":"Ermäßigung: 0+0",
    "overcharge_18":"1+0 [%]",
    "discount_18":"Ermäßigung: 1+0",
    "overcharge_15":"1+1 [%]",
    "discount_15":"Ermäßigung: 1+1",
    "overcharge_50":"2+0 [%]",
    "discount_50":"Ermäßigung: 2+0",
    "overcharge_16":"2+2 [%]",
    "discount_16":"Ermäßigung: 2+2",
    "overcharge_19":"4+0 [%]",
    "discount_19":"Ermäßigung: 4+0",
    "overcharge_51":"4+1 [%]",
    "discount_51":"Ermäßigung: 4+1",
    "overcharge_17":"4+4 [%]",
    "discount_17":"Ermäßigung: 4+4 [%]",


    "pdf_issue296":"Achtung! Bei dieser Falzgröße, läuft das Umschlagspapier nicht mit der Maserung.",
    "pdf_issue33":"Maschinenlack",
    "notifications":"Benachrichtigungen",
    "notification":"Benachrichtigung",
    "is_active":"Ist aktiv",
    "off":"Aus",
    "on":"Ein",
    "business_hours":"Geschäftszeiten",
    "pallets_requirements":"Anforderungen an den Patellen",
    "cartons_requirements":"Anforderungen an den Kartons",
    "more_information":"Mehr Information",
    "none":"Kein(e)",
    "neutral_delivery":"Neutralversand",
    "preparers":"Prepress",
    "preparer":"Prepress",
    "duplicated_property":"Duplizierte Eigenschaft",
    "is_default":"Ist vordefiniert",
    "is_final":"Ist endgültig",
    "spine":"Buchrücken",
    "real_spine":"Echter Buchrücken",
    "sample":"Muster",
    "samples":"Muster",
    "bleed_inside":"Innen-",
    "bleed_outside":"Außer-",
    "bleed_head":"am Kopf",
    "bleed_legs":"am Fuß",
    "bleeds":"Bescshnitt + мм",
    "technologist":"Technologe",
    "has_sample":"Hat Muster",
    "address_delivery1":"Lieferadresse 1",
    "address_delivery2":"Lieferadresse 2",
    "address_delivery3":"Lieferadresse 3",
    "contacts1":"Kontakt 1",
    "contacts2":"Kontakt 2",
    "contacts3":"Kontakt 3",
    "contacts4":"Kontakt 4",
    "contacts5":"Kontakt 5",
    "foil":"Folie",
    "number_of_stamps":"Anzahl Prägungen",
    "all_sizes_in_mm":"Maße",
    "embossing":"Blindprägung",
    "embossing_foil":"Heißfolienprägung",
    "load":"Last",
    "holidays":"Urlaube",
    "short_name":"Kurzname",
    "working_days":"Arbeitstage",
    "capacity":"Kapazität",
    "period":"Frist",
    "load_lepena":"Kelebindung",
    "load_shita":"Klammerheftung",
    "load_tvarda":"Hard-",
    "load_s_kapaci":"Mit Klappen",
    "load_vshm":"Fadenheftung",
    "load_drugi":"Andere Bestellarten",
    "free_capacity1":"Freie Kapazität %",
    "free_capacity2":"Freie Kapazität St.",
    "total40for_period":"Insgesamt 4/0 Vorbereitungen für den Zeitraum",
    "total40s_for_period":"Insgesamt 4/0 Bögen für den Zeitraum",
    "total11for_period":"Insgesamt 1/1 Vorbereitungen für den Zeitraum",
    "total11s_for_period":"Insgesamt 1/1 Bögen für den Zeitraum",
    "download_excel":"Excel-Datei herunterladen",
    "download_detailed_excel":"Ausführliche Excel-Datei herunterladen",
    "dencity":"Grammatur",
    "count_pages":"Seiten",
    "supplier":"Lieferant",
    "am":"AM",
    "paper_body":"Papiersorte Inhalt",
    "paper_cover":"Papiersorte Umschlag",
    "calculated_transport_price":"Berechneter Tansportpreis",
    "calculated_transport_copies":"Berechnete Exemplarenzahl für den Transport",
    "calculated_transport_weight":"Berechnetes Gewicht für den Transport",
    "books_price_be":" Tranasportpreis BE",
    "recipient_email":"Email Empfänger",
    "recipient_phone":"Empfängertelefon",
    "recipient":"Empfänger",
    "paper_type":"Papiersorte (Text Inhalt)",
    "missing_paper":" ist nicht mehr verfügbar - um dieses Angebot anzuzeigen wird es durch Papier ersetzt",
    "disabled":"Deaktiviert",
    "disabled":"Inaktiv",
    "enabled":"Aktiv",
    "all":"Alle",
    "paper_validation":"Einem Papier mit diesem Namen wurde bereits ein Status zugewiesen - Standard/Spezial für das Produkt",
    "pantone_number":"Pantonfarbe",
    "pantone_numbers":"Pantonfarben",
    "pantone_type":"Pantonetyp",
    "pdf_paper_client":"Papier des Kunden ({PAPER_NAME} - {PAPER_DENSITY} g - {BIG_SHEETS} Bögen {PAPER_SIZE})",
    "pdf_paper_client_special":"Sonderpapier ({PAPER_NAME} - {PAPER_DENSITY} g - {BIG_SHEETS} Bögen {PAPER_SIZE})",
    "pdf_paper_client_special2":"Sonderpapier ({PAPER_NAME} - {PAPER_DENSITY} g)",
    "paper_client":"Papier des Kunden",
    "special_client":"Sonderpapier",
    "users_excel_id":"ID",
    "users_excel_first_name":"Vorname",
    "users_excel_last_name":"Nachname",
    "users_excel_email":"Email",
    "users_excel_phone":"Telefon",
    "users_excel_role":"Rolle",
    "users_excel_websites":"Internetseite(n)",
    "price_per_sheet2":"Preis pro Bogen",
    "fin_ctrl_currency":"Währung",
    "fin_ctrl_approval":"Genehmigung",
    "fin_ctrl_payment_term":"Zahlungsbedingungen",
    "fin_intra_community_vat":"Innergemeinschaftliche Steuer-Nr.",
    "field_required":"Dieses Feld ist erforderlich",
    "format_error":"Das Format entspricht nicht den technologischen Grenzwerten (min {MIN_MM} mm / max {MAX_MM} mm). Bitte ändern Sie es oder kontaktieren Sie uns für ein maßgeschneidertes Angebot.",
    "format_error1":"Das Format entspricht nicht den technologischen Grenzwerten (",
    "format_error2":").",
    "format_error3":"Bitte ändern Sie es oder kontaktieren Sie uns für ein maßgeschneidertes Angebot.",
    "spine_error":"Sie haben die für dieses Produkt zulässige minimale oder maximale Rückenbreite erreicht(min {MIN_MM} mm / max {MAX_MM} mm). Bitte kontaktieren Sie uns für ein maßgeschneidertes Angebot.",
    "spine_error1":"Sie haben die für dieses Produkt zulässige minimale oder maximale Rückenbreite erreicht (",
    "spine_error2":"mm).",
    "spine_error3":"Bitte kontaktieren Sie uns für ein maßgeschneidertes Angebot.",
    "due_date_validation":"Bitte füllen Sie das Feld - Fälligkeitsdatum",
    "initial_dead_line":"Anfangsfrist",
    "books_format":"Format",
    "pantone_serial":"Pantone-Nr.",
    "pantone_amount":"Pantone-Menge",
    "prep_remarks":"Hinweis Zustand",
    "prep_remarks_probe":"Hinweis Proof",
    "documentation":"Dokumentation",
    "remarks_accounting":"Hinweise Buchhaltung",
    "billing_ntities":"Rechnungsstellen",
    "volume_without":"Volume m3 (ohne Kartons)",
    "ratio":"Ratio",
    "at_name":"auf",
    "tirage_label_ex":"Exemplare",
    "shipment_price":"Lieferort",
    "failed":"Benutzer-Authentifizierung fehlgeschlagen.",
    "throttle":"Zu viele fehlgeschlagene Anmeldeversuche. Bitte versuchen Sie es nach :seconds Sekunden erneut.",
    "default":"Standard",
    "data_error":"Datenfehler",
    "calc_error":"Berechnungsfehler",
    "db_error":"Datenbankfehler",
    "paper_color_density_mismatch_text":"Die Kombination Papier/Farbe/Dichte für dieses Angebot (Text) \ wurde nicht in unserer Datenbank gefunden. Bitte wählen Sie eine andere!",
    "paper_color_density_mismatch_cover":"Die Kombination Papier/Farbe/Dichte für dieses Angebot (Umschlag)\ wurde nicht in unserer Datenbank gefunden. Bitte wählen Sie eine andere!",
    "paper_color_density_mismatch_insert":"Die Kombination Papier/Farbe/Dichte für dieses Angebot(Inhalt)\ wurde nicht in unserer Datenbank gefunden. Bitte wählen Sie eine andere!",
    "zero_copies":"Die Anzahl der Exemplare muss größer als Null sein.",
    "no_hat_db_record":"Datenbankeintrag für Kapitelbände wurde nicht gefunden.",
    "no_lace_db_record":"Datenbankeintrag für Lesebändchen wurde nicht gefunden.",
    "text_pages_change":"Textseitenänderung: :VON :BIS",
    "insert_pages_change":"Einschubblätterseitenänderung: :VON :BIS",
    "cover_pages_change":"Umschlagsseitenänderung: :VON :BIS",
    "titles":"Herr",
    "titles":"Frau",
    "organization_type":"Rechtsform",
    "trademark":"Phantasiebezeichnung",
    "address_correspondence":"Anschrift Korrespondenz",
    "code_ebp":"Code EBP",
    "code_ajour":"Code AJOUR",
    "civilite":"Andere",
    "prenom":"Rechnungsempfänger Vorname",
    "nomenvoi":"Rechnungsempfänger Nachname",
    "email_organization":"Rechnungempfänger Email",
    "email_organization_copy":"Rechnungskopie Email",
    "siege_social":"Anschrift Rechnung",
    "create":"Neu estellen",
    "edit":"Bearbeiten",
    "copy":"Kopieren",
    "delete":"Entfernen",
    "submit":"Senden",
    "email":"Email",
    "order":"Bestellung",
    "overcharges_main":"Preisaufschläge",
    "overcharges_list":"Liste",
    "overcharges_create":"Erstellen",
    "title":"Titel",
    "client_email":"Ein Konto mit dieser E-Mail-Adresse ist bereits vorhanden.",
    "new_client":"Ihre Registrierung konnte nicht durchgeführt werden. Bestellvorgang abgeschlossen.",
    "client_id":"Konto nicht gefunden. Bestellvorgang abgeschlossen.",
    "order_save":"Das Angebot kann nicht gespeichert werden.",
    "headings_create":"Neues Angebot",
    "headings_edit":"Angebotsdaten bearbeiten",
    "filter_id":"ID",
    "filter_client":"Kunde / Organisation / Kontakt",
    "filter_status":"Zustand",
    "filter_name":"Name",
    "filter_product_type":"Produkt",
    "filter_apply":"Anwenden",
    "filter_clear":"Löschen",
    "filter_owner":"Inhaber",
    "filter_show_mine":"Nur meine Bestellungen anzeigen",
    "filter_allproducts":"Alle Produkte",
    "filter_allusers":"Alle",
    "filter_from":"Zeitraum von",
    "filter_to":"Zeitraum bis",
    "form_pricing":"Preisangabe",
    "form_price_final":"Endpreis",
    "form_price":"Preis",
    "form_print_run":"Auflage",
    "form_info":"Angaben zum Angebot",
    "form_cover":"Umschlag",
    "form_text":"Inhalt",
    "form_insert":"Einschubblätter",
    "form_properties":"Produktmerkmale",
    "form_order_name":"Ihr Projektname(Ref.)",
    "form_budget":"Budget",
    "form_overcharge_default":"Standardmäßiger Preisaufschlag",
    "form_overcharge_total":"Gesamtpreisaufschlag",
    "form_overcharge_change":"Presiaufschlag ändern",
    "form_discount":"Ermäßigung",
    "form_category":"Kategorie auswählen",
    "form_client_new":"Neukunde",
    "form_client_select":"Kundenkonto auswählen",
    "form_client_name":"Kundenname",
    "form_client_email":"Kunden-Email-Adresse",
    "form_client_phone":"Kunden-Telefonnumer",
    "form_client_company":"Kundenunternehmen",
    "form_pages":"Seitenzahl",
    "form_print":"Farbigkeit",
    "form_finishing":"Veredelung",
    "form_paper":"Papiersorte",
    "form_color":"Farbton",
    "form_density":"Grammatur",
    "form_weight":"Gewicht",
    "form_height":"Höhe",
    "form_length":"Breite",
    "form_size":"Endformat",
    "form_folding":"Falzart",
    "form_binding":"Bindungstyp",
    "form_copies":"Anzahl Exemplare",
    "form_address":"Lieferadresse",
    "form_delivery":"Zusätzliche Lieferadresse",
    "form_comment":"Kommentare",
    "form_add_cover":"Umschlag",
    "form_add_insert":"Einschubblätter hinzufügen",
    "form_add_jacket":"Schutzumschlag",
    "form_add_banderole":"Bauchbinde",
    "form_history_id":"ID",
    "form_history_section_title":"Angebotsverlauf",
    "form_history_ids":"ID / Angebot ID",
    "form_history_action":"Aktion",
    "form_history_user_names":"Benutzer",
    "form_history_date":"Datum",
    "form_history_additional_data":"Zusätzliche Informationen",
    "form_banderole":"Bauchbinde",
    "form_jacket":"Schutzumschlag",
    "form_additional_languages":"Zusatzsprache",
    "form_main_language_copies":"Hauptsprache:",
    "form_add_language_copies":"Anzahl der Exemplare Zusatzsprache",
    "form_add_language_mutation_count":"Mutation schwarzer Druckplatten",
    "form_cloth":"Überzugsstoff",
    "form_cardboard":"Buchbinderpappe",
    "form_endpapers":"Vor- und Nachsatz",
    "form_add_endpapers":"Vor- und Nachsatz",
    "form_cloth_type":"Typ des Überzugsstoffes",
    "form_cloth_type_paper":"Überzugsstoffstyp Papier",
    "form_cloth_type_mixed":"Überzugsstoffstyp Papier und Leinen",
    "form_cloth_type_cloth":"Überzugsstoffstyp Leinen",
    "form_cloth_type_without_clothing":"Ohne Überzugsstoff",
    "form_cloth_width":"Überzugsstoff Breite",
    "form_cloth_price":"Überzugsstoffspreis (m2)",
    "form_cloth_visible_part":"Überzugsstoff sichtbarer Teil",
    "form_cloth_model":"Überzugsstoffsname/-modell",
    "form_cloth_supplier":"Lieferant",
    "form_cloth_min_length":"Minimale Länge(m)",
    "form_cardboard_paper_name_suffix":"(Buchbinderpappe Dicke mm. / Schaumstoffdicke mm.)",
    "form_bookmark_insert":"im Produkt hinzugefügt",
    "form_bookmark_no_insert":"ohne hinzufügen",
    "form_bookmarks":"Lesemarken",
    "form_spine_type":"Buchrückentyp",
    "form_spine_type_flat":"Gerade",
    "form_spine_type_round":"Rund",
    "form_cloth_int_select":"Leinenkataloge",
    "form_cloth_int_vendor":"Lieferant",
    "form_cloth_int_type":"Typ",
    "form_cloth_int_price":"Preis per m2",
    "form_cloth_int_price_min":"Preis / m2(min)",
    "form_cloth_int_price_max":"Preis / m2(max)",
    "form_cloth_int_search":"Filtern nach Produktname",
    "form_cloth_int_apply":"Filter anwenden",
    "form_cloth_int_clear":"Zurücksetzen",
    "form_cloth_int_cancel":"Beenden",
    "form_cloth_int_insert":"Auswählen",
    "form_cloth_int_series":"Serien",
    "form_cloth_int_model":"Modell",
    "form_cloth_int_size":"Maße",
    "price_correction_type":"Typ",
    "price_correction_title":"Preiskorrektur",
    "price_correction_visible":"Zusätzliches",
    "price_correction_sum":"Summe",
    "price_correction_description":"Beschreibung",
    "price_correction_option_included_invisible":"",
    "price_correction_option_included_included":"Inklusivleistungen: ",
    "price_correction_option_included_free":"Bonusleistungen: ",
    "price_correction_option_included_invisible_button":"Nicht im Angebot anzeigen",
    "price_correction_option_included_included_button":"im Angebotspreis eingeschlossen",
    "price_correction_option_included_free_button":"Gratisleistungen",
    "order_name":"Auftragsname",
    "organization":"Unternehmen",
    "id":"ID",
    "client":"Kunde",
    "product":"Produkt",
    "price":"Preis",
    "status":"Zustand",
    "date_created":"Erstellungsdatum",
    "account_manager":"Kundenbetreuer/Vertrieb-MA",
    "website":"Internetseite",
    "budget":"Budget",
    "order_status":"Personnalisé",
    "order_status":"Prix Cat. client_category_id",
    "order_status":"Prix Public",
    "sum_thickness":"Buchrückendicke",
    "client_missing_category_alert":"Der ausgewählte Kunde gehört keiner Preiskategorie an!",
    "price_change_alert":"Achtung - Preisunterschied zwischen dem alten Angebot und der Neuberechnung! ({price_change}).",
    "hide_discount":"Ermäßigung im Angebot nicht anzeigen",
    "without_cover":"Ohne Umschlag",
    "old_price":"Altpreis",
    "print_run_from":"Mindestauflage",
    "print_run_to":"Auflage bis",
    "previous":"vorherige Seite",
    "next":"nächste Seite",
    "password":"Das Passwort muss mindestens 6 Zeichen enthalten und übereinstimmen.",
    "reset":"Das Password wurde zurückgesetzt!",
    "sent":"Eine Passworterinnerung wurde gesendet!",
    "token":"Das Passwort konnte aufgrund eines ungültigen Tokens nicht zurückgesetzt werden",
    "user":"Es wurde kein Konto mit dieser E-Mail-Adresse gefunden",
    "clear_all_filters":"Zurücksetzen",
    "filter_by_all_active_filters":"Filter",
    "filters":"Filter",
    "filter_by_op_in":"Betreiber",
    "filter_by_machine":"Filtern nach Maschine",
    "filter_by_stage":"Filtern nach Arbeitsschritt",
    "filter_by_element":"Filtern nach Element",
    "filter_by_action":"Filtern nach Aktion",
    "filter_by_state":"Filtern nach Zustand",
    "btn_edit":"Bearbeiten",
    "btn_create":"Erstellen",
    "btn_submit":"Anwenden",
    "btn_delete":"Entfernen",
    "btn_update":"Aktualisieren",
    "btn_toggle_drop_down":"Dropdown-Liste umschalten",
    "btn_back":"Zurück",
    "list":"Liste",
    "name":"Name",
    "color":"Farbe",
    "element":"Element",
    "state":"Zustand",
    "stage":"Arbeitsschritt",
    "component":"Komponente",
    "action":"Aktion",
    "state_type":"Zustandstyp",
    "continue":"Weiter",
    "operator_types":"Betreibertypen",
    "operator_type":"Betreibertyp",
    "operator":"Betreiber",
    "code":"Authentication Code",
    "settings_title":"Einstellungen",
    "settings_elements_title":"Elemente",
    "settings_stages_title":"Arbeitsschritte",
    "settings_components_title":"Komponente",
    "settings_actions_title":"Aktionen",
    "settings_states_title":"Zustände",
    "settings_state_types_title":"Zustandtypen",
    "settings_machines_title":"Maschinen",
    "settings_product_builder_title":"Produktbilder",
    "settings_operators_title":"Betreiber",
    "settings_operator_types_title":"Betreibertypen",
    "messages_select_product":"Druckprodukt auswählen",
    "messages_select_elements":"Elemente für Produkt auswählen",
    "messages_not_all_elements_have_stages":"Nicht alle Elemente haben Stufen",
    "messages_select_stages":"Wählen Sie die Stufen für das Produktelement aus",
    "messages_not_all_stages_have_actions":"Nicht alle Stufen haben Aktionen",
    "messages_select_actions":"Aktionen für Stufe des Produktelements auswählen",
    "messages_not_all_actions_have_states":"Nicht alle Aktionen haben Zustände",
    "messages_select_states":"Status für Aktion im Status des Produktelements auswählen",
    "messages_select_default":"Der ausgewählte Standardstatus muss einer der aktivierten sein",
    "messages_select_final":"Der ausgewählte Endzustand muss einer der aktivierten Zustände sein",
    "validation_required":"ist erforderlich",
    "validation_date":"Muss ein Datumformat sein.",
    "validation_email":"Muss eine gültige E-Mail-Adresse sein",
    "validation_number":"Darf nur Ziffern enthalten",
    "validation_rangelength":"Sollen 4 Zeichen sein",
    "validation_rangelength_remarks":"Soll zwischen 3 und 1000 Zeichen enthalten",
    "format":"Format",
    "ready_copies":"fertige Exemplare",
    "copies":"Exemplare",
    "production_number":"Herstellungsnummer",
    "personalize_operation":"Operation personalisieren",
    "find_yourself_in_the_list":"Finden Sie sich in der Liste",
    "enter_your_personal_code":"Geben Sie Ihren persönlichen Code ein",
    "koli":"Falzbögen (Signaturen)",
    "machine":"Maschine",
    "trader":"Händler",
    "technologist_in_charge":"Verantwortlicher Technologe",
    "final_date_to_be_produced":"Deadline Produktion",
    "order_remark":"Bestellung Hinweise",
    "stage_remark":"Zustand Hinweise",
    "additional_date":"Produktionsenddatum 2",
    "additional_date_remarks":"Produktion Hinweise",
    "machines":"Maschinen",
    "stages":"Arbeitsschritte",
    "elements":"Elemente",
    "actions":"Aktionen",
    "states":"Zustände",
    "title":"Titel",
    "traders":"Händler",
    "order_history":"Bestellverlauf",
    "updated_by":"Aktualisiert von-um",
    "date":"Datum",
    "remarks":"Hinweise",
    "clients":"Kunden",
    "bindings":"Bindungstypen",
    "products":"Druckprodukte",
    "show_only_mine":"nur meine anzeigen",
    "auth":"Authentifizierung",
    "on":"Ein",
    "off":"Aus",
    "error":"Fehler",
    "failed_action":"Aktion fehlgeschlagen",
    "close":"Schließen",
    "colors":"Farben",
    "required":"erforderlich",
    "saved":"Gespeichert",
    "integer":"Darf nur Ziffern enthalten",
    "exact_length":"Soll 4 Zeichen enthalten",
    "":"B.",
    "":"H.",
    "landscapes":"Querformat",
    "portraits":"Hochformat",
    "sort_by_filter":"Nach Filter sortieren",
    "sort_order":"Sortierreihenfolge",
    "distribution":"Distribution",
    "distributions":"Distributions",
    "distribution_amount":"Distributionsmenge",
    "distribution_bending":"Falzen",
    "asc":"ASC",
    "desc":"DESC",
    "remarks_date":"Hinweise Datum",
    "slug":"Slug",
    "value":"Wert",
    "new_record":"Neue Angabe",
    "event_text":"Ereignistext",
    "event_data":"Ereignisdaten",
    "event_date":"Ereignisdatum",
    "history":"Verlauf",
    "system":"System",
    "show_only_active":"Nur aktive Bestellungen anzeigen",
    "active":"Aktive",
    "done":"Fertig",
    "pages":"Seiten",
    "big_sheets":"Druckbögenanzahl",
    "final":"End-",
    "operator_permissions":"Möglichkeiten",
    "operator_permission":"Möglichkeit",
    "spine":"Buchrücken",
    "real_spine":"Tatsächlicher Buchrücken",
    "sample":"Proof",
    "samples":"Proofs",
    "bleed_inside":"Вътрешно",
    "bleed_outside":"Външно",
    "bleed_head":"Глава",
    "bleed_legs":"Крака",
    "bleeds":"Beschnittzugabe + mm",
    "technologist":"Technologe",
    "number_of_sheets_text":"Druckbögenanzahl",
    "big_sheets_marked":"Druckbögenanzahl für den Druck",
    "big_sheets_mark":"Druckbögenanzahl für den Druck vermerken",
    "number_of_sheets_text_marked":"Vermerkte Druckbögenanzahl",
    "number_of_sheets_text_mark":"Druckbögenanzahl vermerken",
    "big_sheets_in_week":"Druckbögenanzahl in der Woche",
    "number_of_sheets_in_week":"Druckbögenanzahl in der Woche",
    "with_flaps":"Mit Klappen",
    "prepress":"Prepressmontage",
    "ctp":"CTP",
    "workshop":"Lagerhaus",
    "workshop_ready_production":"Lager fertige Produktion",
    "completion":"Buchbinderei",
    "fitter":"Druckwerkstatt",
    "place":"Platz",
    "due_date":"Kundenfrist",
    "with_bookmark":"hinzufügen",
    "without_bookmark":"ohne hinzufügen",
    "urgent":"dringend",
    "in_edit":"Wird bearbeitet",
    "inactive":"Inaktiv",
    "order1":"Unverarbeitet",
    "order2":"Im Lauf",
    "order3":"Inaktiv",
    "order4":"Fertig",
    "production_second_production":"Wiederaufbereitung",
    "amount":"Anzahl",
    "no_recs_found":"Keine Datensätze gefunden.",
    "no_states":"Es sind keine Zustände angelegt",
    "no_actions":"Keine Aktionen wurden erstellt",
    "none":"Gibt es nicht",
    "btn_send_to_production":"An die Produktion senden",
    "btn_revert_production":"Aus der Produktion zurückkehren",
    "additional_date_validation":"Für die Produktion ist ein zusätzliches Datum erforderlich",
    "send_to_preparer":"An Prepress senden",
    "revert_from_preparer":"Vom Prepress zurückkehren",
    "request_real_spine":"Buchrückenmessung",
    "request_width_measure":"Buchrückenmessung anfordern",
    "width":"Dicke",
    "width_body":"Buchkörperdicke",
    "request_width_body_measure":"Buchkörperdickemessung anfordern",
    "revert":"Върни",
    "revert_to":"Върни към",
    "preparer_required":"Bitte Prepress-MA auswählen",
    "sample_custom":"Digitalproof",
    "paper_custom":"Papiersondermerkmale",
    "lace":"Lesebändchen",
    "head_and_tails_band":"Kapitelband",
    "bookmark":"Lesemarke",
    "foil_wrapped":"Scheißfolienverpackung, einzelne Exemplare",
    "foil_packed":"Schweißfolienverpackung, mehrere Exemplare",
    "total_orders":"Alle Bestellungen",
    "total_orders_without_vshm_and_others":"Alle Bestellungen ohne Klammerheftung und Andere",
    "count_orders":"Anzahl Bestellungen",
    "big_sheets":"Бр. подготовки 4/0",
    "big_sheets":"Бр. подготовки 1/1",
    "big_sheets":"Бр. подготовки 2/2",
    "number_of_sheets":"Anzahl Druckbögen 4/0",
    "number_of_sheets":"Anzahl Druckbögen 1/1",
    "number_of_sheets":"Anzahl Druckbögen 2/2",
    "dist_amount":"Anzahl Falzbägen (Signaturen)",
    "embossing_foil":"Heißfolienprägung",
    "search_by_period":"Suche nach Periode",
    "no_copies":"Es wurde keine Fertigmenge eingegeben.",
    "done_completion":"Fertig",
    "deactivated_paper":"Das Papier kann nicht deaktiviert werden, da es das einzige aktive Papier für dieses Element ist.",
    "period_from":"Zeitraum von",
    "period_to":"Zeitraum bis",
    "week":"Woche",
    "fit_sheet":"Zeitplan Druck",
    "fit_heading":"Zeitplan Druck von",
    "fit_hour":"Uhr",
    "fit_gr":"g/m²",
    "fit_order":"Druckauftrag",
    "fit_client":"Kunde",
    "fit_product":"Produkt",
    "fit_due_date":"Frist",
    "fit_tirage":"Auflage",
    "fit_paper_format":"Papierformat",
    "fit_paper":"Papiersorte",
    "fit_color_embossing":"Farbigkeit/Veredelung",
    "fit_big_sheets":"Set-Up Druckmaschine",
    "fit_tirage_sheets":"Auflagenbögen",
    "fit_paper_place":"Сектор с хартия",
    "fit_digital_real_proof":"Digitalproof Offset-Andruck Muster Ja/Nein",
    "fit_printing_start_hour":"Beginn Druckprozess (Uhr)",
    "fit_printing_end_hour":"Ende Druckprozess (Uhr)",
    "fit_tirage_time_in_hours":"Време на тираж (Часове)",
    "fit_comment_or_problems":"Kommetare Auflage / Aufgetretene Probleme",
    "fit_format":"Format",
    "fit_binding":"Bindungstyp",
    "remarks_workshop":"Hinweise Lager",
    "partially_done":"Teilweise anfertigt",
    "probe":"Proof",
    "partially_done_and_finish":"Teilweise anfertigt und beenden",
    "dones":"Fertige",
    "text_pages":"Textseiten",
    "text_print":"Textdruck",
    "text_paper":"Papier für Textseiten",
    "text_paper_density":"Grammatur Textpapier [gr]",
    "body_maculaturi":"Druckbögen + Makulatur (Inhalt)",
    "body_size":"Format Inhalt",
    "insert_pages":"Einschubblätter",
    "insert_print":"Farbigkeit Einschubblätter",
    "insert_paper":"Papiersorte Einschubblätter",
    "insert_paper_density":"Grammatur Einschubblätterpapier [gr]",
    "vlojka_maculaturi":"Druckbögen + Makulatur (Einschubblätter)",
    "size_vlojka":"Format Einschubblätter",
    "spine_width":"Buchrückendicke",
    "dashboard_main":"Instrumententafel",
};

export default de;
