import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import AuthHelper from "../helpers/AuthHelper";


class PrivateRoute extends React.Component
{
    constructor(props) {
        super(props);
    }

    render() {
        const isLoggedIn = AuthHelper.isLogged();
        const permissions = AuthHelper.getAllPermissionUrls();
        if(isLoggedIn === false)
        {
            AuthHelper.deleteUser();
            return (<Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />)
        }

        return (
            <Route {...this.props} />
        )
    }
}

export default PrivateRoute;
