const DataTableInitDataGenerator = function (columns, t) {
    let model = [];
    for(let i = 0; i < columns.length; i++)
    {
        let tmpObj = {
            label: t(columns[i]),
            field: columns[i],
            sort: 'asc',
            width: 100
        }
        model.push(tmpObj);
    }

    return {columns: model, rows: []};
}

export default DataTableInitDataGenerator;
