const local = {
  BASE_URL_API: "http://localhost:8000/api/",
  BASE_URL: "http://localhost:8000/",
  BASE_URL_IMAGE: "http://localhost:8000/storage/images/",
  BASE_URL_IMAGE_TEMP: "http://localhost:8000/storage/temporary/",
  BASE_IMAGE_NOT_FOUND: "http://localhost:8000/images/no-images.jpg",
  BASE_DOMAIN_NAME: "http://localhost:8000/",
  BASE_DOMAIN_NAME_WITHOUT: "http://localhost:8000",
  VAT: 20,
};

const localVHOST = {
  BASE_URL_API: "http://newpulsio/api/",
  BASE_URL: "http://localhost:8000/",
  BASE_URL_IMAGE: "http://newpulsio/storage/images/",
  BASE_URL_IMAGE_TEMP: "http://newpulsio/storage/temporary/",
  BASE_IMAGE_NOT_FOUND: "http://newpulsio/images/no-images.jpg",
  BASE_DOMAIN_NAME: "http://newpulsio/",
  BASE_DOMAIN_NAME_WITHOUT: "http://newpulsio",
  VAT: 20,
};

const remote = {
  BASE_URL_API: "https://backend-pulsio.blacatz.com/api/",
  BASE_URL: "https://backend-pulsio.blacatz.com/",
  BASE_URL_IMAGE: "https://backend-pulsio.blacatz.com/storage/images/",
  BASE_URL_IMAGE_TEMP: "https://backend-pulsio.blacatz.com/storage/temporary/",
  BASE_IMAGE_NOT_FOUND: "https://backend-pulsio.blacatz.com/images/no-images.jpg",
  BASE_DOMAIN_NAME: "https://backend-pulsio.blacatz.com/",
  BASE_DOMAIN_NAME_WITHOUT: "https://backend-pulsio.blacatz.com",
  VAT: 20,
};


export default remote;
