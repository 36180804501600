import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../helpers/DataTableInitDataGenerator';
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import SwalDelete from '../../helpers/SwalDelete';
import SimpleFieldsGenerator from "../../helpers/SimpleFieldsGenerator";
import Select from "react-select/";
import AuthHelper from "../../helpers/AuthHelper";

class OrderList extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState;
    }

    get initialState() {
        const {t} = this.props;
        let listColumns = ['id', 'link', 'title', 'client', 'organization', 'product', 'creation_date', 'owner',
            'website', 'hunter', 'am', 'paper_body', 'paper_cover', 'price', 'actions'];
        let userRoles = AuthHelper.getRoles();
        if (userRoles.findIndex(x => x.name === 'sales-agent') >= 0) {
            //sales-agent
            listColumns = ['id', 'link', 'title', 'client', 'organization', 'product', 'creation_date', 'owner',
                'website', 'hunter', 'am', 'paper_body', 'paper_cover', 'price', 'actions'];
        }

        return {
            isLoading: false,
            data: DataTableInitDataGenerator(listColumns, t),
            filter: {
                id: null,
                name: '',
                title: '',
                dateFrom: '',
                dateTo: '',
                product_id: '',
                user: 0,
                // showMyOrders: 0,
                saleAgent: '',
                cPros: '',
                hunters: '',
                ams: '',
                state_id: '',
                date_from: '',
                date_to: '',
                get_all: 0,
                selectedHunter: null
            },
            products: [],
            owners: [],
            saleAgents: [],
            cPros: [],
            hunters: [],
            ams: [],
            selectedMenu: '',

        }

    }

    // componentWillReceiveProps() {
    //     this.componentDidMount();
    // }

    componentDidMount() {
        this.load();
    }

    replicateOffer(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient.post('orders/replicate/'+id)
                .then( response => {
                    let url = '/distribution-test/';
                    url += response.product_id + '/' + response.order_id;
                    this.props.history.push(url);
                }).finally(r => {
                    this.setState({isLoading: false})
            })
        })
    }

    fillDataTables(response) {
        const {t} = this.props;
        let {data} = this.state;
        let items = response.orders;
        let menu = response.menu;
        let products = response.products;
        let owners = response.users.users;
        let saleAgents = response.users.saleAgents;
        let cPros = response.users.cPros;
        let hunters = response.hunters;
        let ams = response.ams;
        let rows = []; // TODO: insert class "is_front_end" background-color: #ffffc0

        items.forEach((item, i) => {
            let tmp = {
                id: item.id,
                link:
                    <div>
                    <span className="warning">
                                <span className="label label-primary">
                                    <i className="fa fa-files-o" aria-hidden="true">


                                    </i>
                                </span>
                            </span>
                        {item.link}
                    </div>,
                title: <div>
                    <span style={{color: "#" + item.color_hex, fontSize: 12, marginRight: 7}}>
                            <i className="fa fa-circle"></i>
                        </span> {item.title}
                </div>,
                client: item.client_name,
                organization: item.organization,
                product: item.product,
                creation_date: item.created_at,
                owner: item.owner,
                website: item.website,
                price: item.price,
                hunter: item.hunter_name,
                am: item.am,
                actions: <div>


                    <div className="btn-group">
                        <Link className="btn btn-warning btn-sm dropdown-toggle dropdown-toggle-split"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="sr-only">Toggle Dropdown</span>
                        </Link>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" onClick={()=>{
                                this.replicateOffer(item.id);
                            }}>{t('copy')}</a>
                            <Link className="dropdown-item" to={`/distribution-test/${item.product_id}/${item.id}`}>{t('edit')}</Link>
                            <a className="dropdown-item" onClick={()=>{
                                this.delete(item.id)
                            }}>{t('delete')}</a>
                            <Link className={'dropdown-item'} to={'/quotes/email/'+item.id}>
                                {t('email')}
                            </Link>
                            <a className="dropdown-item" onClick={() => {
                                ///order/51384

                                window.open('http://newpulsio/order/' + item.id+'?pdf=pdf' )
                                //window.open('https://backend-pulsio.blacatz.com/order/' + item.id+'?pdf=pdf');
                            }}>{t('pdf')}</a>
                        </div>
                    </div>


                </div>
            };
            rows.push(tmp);
        });


        this.setState({
            data: {columns: data.columns, rows: rows}, menu, products, owners,
            saleAgents, cPros, hunters, ams, isLoading: false
        });
    }

    load() {
        this.setState({isLoading: true});
        NetworkClient.get('orders/list')
            .then(this.fillDataTables.bind(this))

    }

    loadWithFilters() {
        this.setState({isLoading: true});
        let {filter} = this.state;
        NetworkClient.post('orders/list', filter)
            .then(this.fillDataTables.bind(this))

    }

    reset() {
        window.location.reload(false);
        return;
    }

    delete(id) {
        const {t} = this.props;
        SwalDelete(t).then(c => {
            if (c.value) {
                this.setState({isLoading: true}, () => {
                    NetworkClient.delete(`/orders/${id}`)
                        .then(response => {
                            let {data} = this.state;
                            let indexToRemove = data.rows.findIndex(r => r.id === id);
                            data.rows.splice(indexToRemove, 1);
                            this.setState({data: {columns: data.columns, rows: data.rows}, isLoading: false});
                        })
                });
            }
        })
    }

    menuOnSelect(data, e) {
        let state_id = data.id;
        this.setState({selectedMenu: data.id})

        this.setState({filter: {state_id: state_id}})
    }

    printMenu() {

        //list.sort((a, b) => (a.color > b.color) ? 1 : -1)
        let menu = this.state.menu && this.state.menu.sort((a,b) => (a.order > b.order) ? 1:-1);
        return (
           menu && menu.map((menu, k) => (
                <li className={"list-inline-item"}>
                    <button id={menu.id} className={ this.state.selectedMenu === menu.id ? "btn btn-sm btn-primary" : "btn btn-sm" }
                            value={menu.id}
                            onClick={this.menuOnSelect.bind(this, menu)}>
                        <span style={{color: "#" + menu.hex, fontSize: 12, marginRight: 7}}>
                            <i className="fa fa-circle"></i>
                        </span>
                        {menu.name}
                    </button>

                </li>
            ))
        )
    }

    filter() {
        const {t} = this.props;
        let products = this.state.products;
        let owners = this.state.owners;
        let userId = AuthHelper.getUser().id;

        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="card card-primary">
                        <div className="card-body">
                            <label>{t('status')}</label>
                            <ul className={"list-inline"}>

                                {this.printMenu()}
                            </ul>

                            <div className="row">
                                <div className="col-2">
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'id', type: 'text'}], "filter")()}
                                </div>
                                <div className="col-5">
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'name', type: 'text'}], "filter")()}
                                </div>
                                <div className="col-5">
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'title', type: 'text'}], "filter")()}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-3">
                                    <label>{t('products')}</label>
                                    {this.state.products && (
                                        <Select
                                            value={products.find(product => product.name === this.state.filter.prodcut)}
                                            options={products}
                                            onChange={(selected) => {
                                                let {filter} = this.state;
                                                filter.product_id = selected.id;
                                                this.setState({filter});
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                        />
                                    )}
                                </div>
                                <div className="col-2">
                                    <input type="checkbox" className={"mt-4"}
                                           checked={this.state.filter.user_id === userId }
                                           onChange={(e) => {
                                               let {filter} = this.state;
                                               filter.user_id = e.target.checked === true ? userId : 0;
                                               this.setState({filter});
                                           }}
                                    /> {t('show_mine')}
                                </div>

                                <div className="col-3">
                                    <label>{t('owner')}</label>
                                    {this.state.owners && (
                                        <Select
                                            value={owners.find(owners => owners.name === this.state.filter.owner)}
                                            options={owners}
                                            onChange={(selected) => {
                                                let {filter} = this.state;
                                                filter.user_id = selected.id;
                                                this.setState({filter});
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                        />
                                    )}

                                </div>
                                <div className="col-2">
                                    <label htmlFor="">{t('from')}</label>
                                    <input
                                        onChange={(event) => {
                                            let {filter} = this.state;
                                            filter.date_from = event.target.value;
                                            this.setState({filter})
                                        }}
                                        type={"date"} className={"form-control"}/>
                                </div>
                                <div className="col-2">
                                    <label htmlFor="">{t('to')}</label>
                                    <input
                                        onChange={(event) => {
                                            let {filter} = this.state;
                                            filter.date_to = event.target.value;
                                            this.setState({filter})
                                        }}
                                        type={"date"} className={"form-control"}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-3">
                                    <label>{t('sales_agent')}</label>
                                    {this.state.saleAgents && (
                                        <Select
                                            value={this.state.saleAgents.find(saleAgent => saleAgent.name === this.state.filter.saleAgent_id)}
                                            options={this.state.saleAgents}
                                            onChange={(selected) => {
                                                let {filter} = this.state;
                                                filter.saleAgent_id = selected.id;
                                                this.setState({filter});
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                        />
                                    )}
                                </div>
                                <div className="col-3">
                                    <label>{t('C-PRO')}</label>
                                    {this.state.cPros && (
                                        <Select
                                            value={this.state.cPros.find(cPro => cPro.name === this.state.filter.cPros)}
                                            options={this.state.cPros}
                                            onChange={(selected) => {
                                                let {filter} = this.state;
                                                filter.cPros = selected.id;
                                                this.setState({filter});
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                        />
                                    )}
                                </div>
                                <div className="col-3">
                                    <label>{t('hunter')}</label>
                                    {this.state.hunters && (
                                        <Select
                                            value={this.state.filter.selectedHunter}
                                            options={this.state.hunters}
                                            onChange={(selected) => {
                                                let {filter} = this.state;
                                                filter.hunter = selected.id;
                                                filter.selectedHunter = selected;
                                                this.setState({filter});
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                        />
                                    )}
                                </div>


                                <div className="col-3">
                                    <label>{t('am')}</label>
                                    {this.state.ams && (
                                        <Select
                                            value={this.state.ams.find(ams => ams.name === this.state.filter.ams)}
                                            options={this.state.ams}
                                            onChange={(selected) => {
                                                let {filter} = this.state;
                                                filter.ams = selected.id;
                                                this.setState({filter});
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                        />
                                    )}
                                </div>
                            </div>
                            {/*
                            <div className="row">
                                <div className="col-2">
                                    <input type="checkbox" className={"mt-4"}
                                           checked={this.state.filter.get_all === 1 }
                                           onChange={(e) => {
                                               let {filter} = this.state;
                                               filter.get_all = e.target.checked === true ? 1 : 0;
                                               this.setState({filter});
                                           }}
                                    /> {t('all')}
                                </div>
                            </div>
                            */}
                            <hr/>
                            <div className="row">
                                <div className="col-1">
                                    <button onClick={this.loadWithFilters.bind(this)}
                                            className={"btn btn-primary btn-sm btn-block"}>{t('apply')}</button>
                                </div>
                                <div className="col-1">
                                    <button onClick={this.reset.bind(this)}
                                            className={"btn btn-info btn-sm btn-block"}>{t('clear')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    {t('orders')} {t('states')}
                </div>

                {this.filter()}

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <Link to={'/quotes/first-step'} className={"btn btn-info btn-sm mb-3"}>
                                            {t('create')} <i className={"fa fa-plus"}/>
                                        </Link>
                                        <div className="table-responsive">
                                            <MDBDataTable
                                                striped
                                                bordered
                                                hover
                                                entries={10}
                                                data={this.state.data}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(OrderList);