import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../../../api/NetworkClient';
import Loader from '../../../../../partial/Loader';
import Languages from "../../../../../constants/Languages";
import SimpleFieldsGenerator from '../../../../../helpers/SimpleFieldsGenerator';
import Select from "react-select/";

class DistrictsForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        district: {
            id: null,
            district_code: '',
            district_name: '',
            group_id: '',
            enabled: 0,
        },
        groups: ''
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        

        if(id !== undefined)
        {
            this.load(id);
        }
        else 
        {
            this.setState({isLoading:true});
            NetworkClient.get('transport/fr/districts-groups')
                .then(response => {
                    let groups = response.groups;
                    this.setState({groups});
                }).finally(c=> {
                    this.setState({isLoading:false});
            });
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`transport/fr/districts/${id}`)
                .then(response => {
                    const district = response.district;
                    const groups = response.groups;
                    this.setState({district, groups});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {district} = this.state;
        let url = 'transport/fr/districts';
        if(district.id !== null)
        {
            url += '/' + district.id;
        }

        NetworkClient.post(url, district)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c=>{
                        this.props.history.push('/transport/france/districts');
                    })
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
        const {groups} = this.state;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('transport')} {t('district')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'district_code', type: 'number'}], "district")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'district_name', type: 'text'}], "district")()}
                                    <div className={"form-group"}>
                                    <Select
                                        value={groups && groups.find(group => group.id === this.state.district.group_id)}
                                        options={groups}
                                        getOptionLabel={(option)=> option.name}
                                        getOptionValue={(option) => option.id}
                                        onChange={(selected) => {
                                            let {district} = this.state;
                                            district.group_id = selected.id;
                                            this.setState({district : district})
                                        }}
                                    />
                                    </div>
                                    <div className={"form-group"}>
                                    <label>{t('enabled')}
                                        <input type="checkbox" className="ml-3" checked={this.state.district.enabled === 1}
                                            onChange={e => {
                                                let {district} = this.state;
                                                district.enabled = e.target.checked === true ? 1 : 0;
                                                this.setState({district})
                                            }}
                                        />
                                        </label>
                                    </div>
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.district.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(DistrictsForm);
