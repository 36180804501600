import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Select from "react-select";
import ClothModal from "./ClothModal";
import NetworkClient from "../../../api/NetworkClient";
import EmbossingFormPartial from "../partial/EmbossingFormPartial";
import EmbossingFoilFormPartial from "../partial/EmbossingFoilFormPartial";

class ClothForm extends Component {
    state = {
        isLoading: false,
        is_enabled: true,
        papers: [],
        densities: [],
        colors: [],
        selectedPaperNameId: -1,
        selectedDensity: -1,
        selectedColorId: -1,
        selectedPrint: -1,
        selectedFinishing: -1,
        groups: [],
        selectedGroup: 'normal',
        embossing: {
            is_enabled: false,
            number_of_stamps: 1,
            sizes: [
                {length: 100,height: 100}
            ]
        },
        embossing_foil: {
            is_enabled: false,
            number_of_stamps: 1,
            sizes: [
                {length: 100, height: 100, type: 122}
            ]
        },
        properties: [],
        num_pages: 4,
        nadpechaten_lak: false,
        pantones: [{id: 0, name: "0"},{id: 1, name: "1"},{id: 2, name: "2"},{id: 3, name: "3"},{id: 4, name: "4"}],
        pantone: 0,
        //TODO: Add it to static classes later
        pantones_types: [{id: 1, name: 'Стандарт'},{id: 2, name: 'Електриков'},{id: 3, name: 'Металиков'}],
        type: 'paper',
        width: 0,
        width_inches: 0,
        visible_part: 0,
        visible_part_inches: 0,
        price: 0,
        model: 0,
        supplier: '',
        min_length: 0,
        cloths: [],
        isOpen: true,
        cover_pantones_info: [],
        is_inited: false
    }

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(props) {
        if(props.initial_state !== null && !this.state.is_inited && this.state.papers.length > 0)
        {
            this.setState({is_inited: true}, () => {
                let initial_state = props.initial_state;
                this.selectPaper(initial_state.paper_name_id, () => {
                    this.selectColor(initial_state.paper_color_id);
                    this.setState({
                        cover_pantones_info: initial_state.cover_pantones_info,
                        selectedDensity: initial_state.density,
                        embossing: initial_state.embossing,
                        embossing_foil: initial_state.embossing_foil,
                        selectedFinishing: initial_state.finishing,
                        flaps: initial_state.flaps,
                        num_pages: initial_state.num_pages,
                        pantone: initial_state.pantone,
                        selectedPrint: initial_state.print,
                        properties: initial_state.properties,
                        selektiven_lak: initial_state.selektiven_lak,
                        has_flaps: initial_state.flaps > 0,
                        leftFlap: parseInt(initial_state.leftFlap),
                        rightFlap: parseInt(initial_state.rightFlap),
                        min_length: initial_state.min_length,
                        supplier: initial_state.supplier,
                        model: initial_state.model,
                        price: initial_state.price,
                        visible_part: initial_state.visible_part,
                        width: initial_state.width,
                        type: initial_state.type,
                        nadpechaten_lak: initial_state.nadpechaten_lak,
                        obemen_lak: initial_state.obemen_lak,
                    });
                });
            })

        }
    }

    componentDidMount() {
        let propsPaper = this.props.papers.slice();
        let availablePapers = [];
        let groups = [];

        for (let i = 0; i < propsPaper.length; i++)
        {
            let hasPaper = false;
            let kind = '';
            for (let j = 0; j < propsPaper[i]['groups'].length;j++)
            {
                if(propsPaper[i]['groups'][j].component === 'cloth')
                {
                    hasPaper = true;
                    kind = propsPaper[i]['groups'][j].group === 'normal' ? 'normal' : 'special';
                    break;
                }
            }

            if(hasPaper)
            {
                let copy = Object.assign({},propsPaper[i]);
                copy.group = kind;
                availablePapers.push(copy);
                if(!groups.includes(kind))
                {
                    groups.push(kind);
                }
            }
        }

        let selectedGroup = 'normal';
        if(groups.length === 1)
        {
            selectedGroup = groups[0];
        }

        groups.sort();

        let selectedPrint = this.props.element.print[0].id;
        let selectedFinishing = -1;

        if(this.props.element.finishing.length > 0)
        {
            selectedFinishing = this.props.element.finishing[0].id;
        }

        this.setState({papers: availablePapers, groups, selectedPrint, selectedFinishing, selectedGroup}, () => {
            let index = 0;
            if(groups.length === 2)
            {
                //TODO: Трябва да се добави логика за избрана хартия
                index = availablePapers.findIndex(p=>p.group === 'normal');
            }

            this.selectPaper(availablePapers[index].paper_name_id);
        })
    }

    getCloth() {
        return {
            paper_name_id: this.state.selectedPaperNameId,
            paper_color_id: this.state.selectedColorId,
            density: this.state.selectedDensity,
            is_enabled: this.state.is_enabled,
            print: this.state.selectedPrint,
            finishing: this.state.selectedFinishing,
            embossing: this.state.embossing,
            embossing_foil: this.state.embossing_foil,
            properties: this.state.properties,
            num_pages: this.state.num_pages,
            nadpechaten_lak: this.state.nadpechaten_lak,
            pantone: this.state.pantone,
            type: this.state.type,
            width: this.state.width,
            visible_part: this.state.visible_part,
            price: this.state.price,
            model: this.state.model,
            supplier: this.state.supplier,
            min_length: this.state.min_length,
            cover_pantones_info: this.state.cover_pantones_info,
            obemen_lak: this.state.obemen_lak,
            selektiven_lak: this.state.selektiven_lak,
            trigger: true
        };
    }

    selectPaper(paperNameId, callback = null) {
        let pap = this.state.papers.find(x => x.paper_name_id  === paperNameId);
        let colors = pap.colors;
        let densities = pap.colors[0].densities.filter(x=> x.components.includes('cloth'));
        this.setState({
            densities,
            colors,
            selectedPaperNameId: paperNameId,
            selectedDensity: densities[0].name,
            selectedColorId: colors[0].id,
            selectedGroup: pap.group
        },()=>{
            this.props.applyChanges(this.getCloth());
            if(callback !== null)
            {
                callback();
            }
        })
    }

    selectColor(colorNameId) {
        let color = this.state.colors.find(c => c.id === colorNameId);
        let densities = color.densities.filter(x=> x.components.includes('cloth'));
        this.setState({densities: densities, selectedDensity: densities[0].name, selectedColorId: colorNameId}, () => {
            this.props.applyChanges(this.getCloth());
        })
    }

    printPaper() {
        if(this.state.type === 'without' || this.state.type === 'cloth')
        {
            return (<></>)
        }

        return (<>
            <div className="col-4">
                <label>{this.props.t('paper')}</label>
                <div className={'' + (this.state.groups.length === 1 ? ' d-none' : '')}>
                    {this.state.groups.map( g => <>
                        <input
                            type={'radio'}
                            checked={this.state.selectedGroup === g}
                            onClick={ e => {
                                let pap = this.state.papers.filter(p => p.group === g);
                                this.selectPaper(pap[0].paper_name_id);
                                this.setState({selectedGroup: g});
                            }}
                        /> {this.props.t(g)} &nbsp;
                    </>)}
                </div>
                <Select
                    value={this.state.papers.find(x => x.paper_name_id  === this.state.selectedPaperNameId)}
                    onChange={(selected)=>{
                        this.selectPaper(selected.paper_name_id);
                    }}
                    options={this.state.papers.filter(p => p.group === this.state.selectedGroup)}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.paper_name_id}
                />
            </div>
            <div className="col-4">
                {this.state.groups.length !== 1 && <br/>}
                <label>{this.props.t('colors')}</label>
                <Select
                    value={this.state.colors.find(d => d.id === this.state.selectedColorId)}
                    onChange={(selected) => {
                        this.selectColor(selected.id)
                    }}
                    options={this.state.colors}
                    getOptionLabel={(option)=> option.paper_color_name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-4">
                {this.state.groups.length !== 1 && <br/>}
                <label>{this.props.t('density')}</label>
                <Select
                    value={this.state.densities.find( d => d.name === this.state.selectedDensity)}
                    onChange={(selected) => {
                        this.setState({selectedDensity: selected.name}, () => {
                            this.props.applyChanges(this.getCloth());
                        })
                    }}
                    options={this.state.densities}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            {this.printClientPaper()}
        </>)
    }

    printClientPaper(){
        let properties = this.props.element.properties;
        properties = properties.filter(x => x.id === 65);

        if(properties.length === 0)
        {
            return <></>
        }

        return properties.map(p => <div className={'col-12 mt-3'}>
            <input
                checked={this.state.properties.includes(p.id)}
                onChange={e => {
                    let properties = this.state.properties;

                    if(e.target.checked === true)
                    {
                        properties.push(p.id);
                    }

                    if(e.target.checked === false)
                    {
                        let index = properties.findIndex(i => i.id === p.id);
                        properties.splice(index, 1);
                    }

                    this.setState({properties}, () => {
                        this.props.applyChanges(this.getCloth());
                    })
                }}
                type="checkbox"/> {p.name}
        </div>)
    }

    printProperties() {
        let properties = this.props.element.properties;
        //debugger;
        //премахаме пантона - id - 64
        //премахаме надпечатния лак от пропъртитата също id - 62, защото го има по - надолу с др логика
        //let hasNadpechatenLak = properties.fin
        properties = properties.filter(x=>x.id !== 64 && x.id !== 62 && x.id !== 27 && x.id !== 28 && x.id !== 29 && x.id !== 65 && x.id !== 68 && x.id !== 69);
        let props =  properties.map(p => <div className={'mt-3'}>
            <input
                checked={this.state.properties.includes(p.id)}
                onChange={e => {
                    let properties = this.state.properties;

                    if(e.target.checked === true)
                    {
                        properties.push(p.id);
                    }

                    if(e.target.checked === false)
                    {
                        let index = properties.findIndex(i => i.id === p.id);
                        properties.splice(index, 1);
                    }

                    this.setState({properties}, () => {
                        this.props.applyChanges(this.getCloth());
                    })
                }}
                type="checkbox"/> {p.name}
        </div>)

        if(this.props.element.properties.findIndex( x => x.id === 68) >= 0)
        {
            props.push(<div className="mt-3">
                <input
                    type="checkbox"
                    checked={this.state.selektiven_lak}
                    onChange={(e)=>{
                        this.setState({selektiven_lak: e.target.checked}, () =>{
                            this.props.applyChanges(this.getCloth());
                        })
                    }}
                /> {this.props.t('selektiven_lak')}
            </div>)
        }

        if(this.props.element.properties.findIndex( x => x.id === 69) >= 0)
        {
            props.push(
                <div className="mt-3">
                    <input
                        type="checkbox"
                        checked={this.state.obemen_lak}
                        onChange={(e)=>{
                            this.setState({obemen_lak: e.target.checked},()=>{
                                this.props.applyChanges(this.getCloth());
                            })
                        }}
                    /> {this.props.t('obemen_lak')} (TODO: не смята още)
                </div>
            )
        }

        if(this.props.element.properties.findIndex(p=>p.id === 62) >= 0)
        {
            props.push(<>
                <div className="col-12 mt-3">
                    <input
                        type="checkbox"
                        onChange={(e)=>{
                            this.setState({nadpechaten_lak: e.target.checked}, () => {
                                this.props.applyChanges(this.getCloth());
                            })
                        }}
                        checked={this.state.nadpechaten_lak}
                    /> {this.props.t('varnish')}
                </div></>)
        }

        return props;
    }

    handleEmbossingChange(embossing) {
        this.setState({embossing}, () => {
            this.props.applyChanges(this.getCloth());
        })
    }

    handleEmbossingFoilChange(embossing_foil)
    {
        this.setState({embossing_foil}, () => {
            this.props.applyChanges(this.getCloth());
        })
    }

    embossings() {
        return (<>
            <div className={'col-5 mr-3'}>
                <EmbossingFormPartial
                    applyChanges={embossing => this.handleEmbossingChange(embossing)}
                    t={this.props.t}
                    measure={this.props.measure}
                    initial_state={this.state.embossing}
                />
            </div>
            <div className={'col-6'}>
                <EmbossingFoilFormPartial
                    t={this.props.t}
                    applyChanges={embossing_foil => this.handleEmbossingFoilChange(embossing_foil)}
                    measure={this.props.measure}
                    initial_state={this.state.embossing_foil}
                />
            </div>
        </>)
    }

    renderPantoneInner()
    {
        if (this.state.pantone === 0) {
            return (<></>)
        }

        let ht = [];

        for (let i = 0; i < this.state.pantone; i++) {
            ht.push(<>
                <div className={"col-1 mt-3"}>
                    {this.props.t('type')}
                </div>
                <div className={'col-4 mt-3'}>
                    <Select
                        onChange={(selected) => {
                            let {cover_pantones_info} = this.state;
                            cover_pantones_info[i].type = selected.id;
                            this.setState({cover_pantones_info},() => {this.props.applyChanges(this.getCloth());})
                        }}
                        value={this.props.pantones.find(p =>  p.id === this.state.cover_pantones_info[i].type)}
                        options={this.props.pantones}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className={'col-4 mt-3'}>
                    <input
                        onChange={e => {
                            let {cover_pantones_info} = this.state;
                            cover_pantones_info[i].number = e.target.value;
                            this.setState({cover_pantones_info}, () => {this.props.applyChanges(this.getCloth());});
                        }}
                        value={this.state.cover_pantones_info[i].number}
                        placeholder={this.props.t('pantone_number_show') + ' ' + (i + 1)}
                        type={'text'}
                        className={'form-control'}/>
                </div>
                <div className="col-3 mt-3">
                    <input
                        checked={this.state.cover_pantones_info[i].is_two_side === true}
                        onChange={e=>{
                            let isChecked = e.target.checked;
                            let {cover_pantones_info} = this.state;
                            cover_pantones_info[i].is_two_side = isChecked;
                            this.setState({cover_pantones_info}, () => {this.props.applyChanges(this.getCloth());});
                        }}
                        type="checkbox"/> {this.props.t('two_sided')}
                </div>
            </>)
        }

        return ht;
    }

    renderPantones()
    {
        if(!(this.props.element.properties.findIndex(p=>p.id === 64) >= 0) )
        {
            return (<></>)
        }

        return (<>
            <div className="col-12">
                <label>{this.props.t('pantone')}</label>
                <Select
                    value={this.state.pantones.find(c => c.id === this.state.pantone)}
                    onChange={(selected)=>{
                        let newPantonSize = parseInt(selected.id);
                        let cover_pantones_info = this.state.cover_pantones_info;
                        //this.state.pantone - брой избрани пантони за корица, ще служи за ориентир в масива
                        if(cover_pantones_info.length < newPantonSize)
                        {
                            while(cover_pantones_info.length < newPantonSize)
                            {
                                cover_pantones_info.push({type: 1, number: '', is_two_side: false});
                            }
                        }

                        if(cover_pantones_info.length > newPantonSize)
                        {
                            while(cover_pantones_info.length > newPantonSize)
                            {
                                cover_pantones_info.splice(cover_pantones_info.length - 1, 1);
                            }
                        }

                        this.setState({pantone: selected.id, cover_pantones_info}, ()=>{
                            this.props.applyChanges(this.getCloth());});
                    }}
                    options={this.state.pantones}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            {this.renderPantoneInner()}
        </>);
    }

    selectType(type)
    {
        this.setState({type}, () => {
            this.props.applyChanges(this.getCloth());
        })
    }

    printTypes() {
        let types = [
            {type: 'paper', name: 'form_cloth_type_paper'},
            {type: 'mixed', name: 'form_cloth_type_mixed'},
            {type: 'cloth', name: 'form_cloth_type_cloth'},
            {type: 'without', name: 'form_cloth_type_without_clothing'},
        ]


        return (<>
            <div className="col-12 mt-3">
                <label>
                    {this.props.t('type')}
                </label>
                <select
                    onChange={e =>  {
                        if(e.target.value === 'cloth')
                        {
                            this.setState({visible_part: this.props.length})
                        }
                        else {
                            this.setState({visible_part: 0})
                        }
                        this.selectType(e.target.value)
                    }}
                    className={'form-control'}>
                    {types.map(type => <option value={type.type} selected={this.state.type === type.type}>{this.props.t(type.name)}</option>)}
                </select>
            </div>
        </>)
    }

    handleClothFieldsChange(value, fieldName) {
        let state = this.state;
        state[fieldName] = value;

        this.setState(state, () => {
            this.props.applyChanges(this.getCloth());
        })
    }

    printCloth() {
        if(this.state.type === 'paper' || this.state.type === 'without')
        {
            return (<></>)
        }

        return (<>
            <div className={'col-12'}>
                <button
                    data-toggle="modal" data-target="#myModal"
                    onClick={()=>{
                        if(this.state.cloths.length === 0)
                        {
                            NetworkClient.get('quotes-cloths-select')
                                .then(response => {
                                    let {cloths} = response;
                                    this.setState({cloths})
                                })
                        }

                    }}
                    className={'btn btn-info btn-sm'}>{this.props.t('form_cloth_int_select')}</button>
            </div>
            <div className="col-4">
                <label>{this.props.t('form_cloth_width')} {this.props.measure}</label>
                <input
                    type="text"
                    className={'form-control' + (this.props.measure == 'mm' ? '' : ' d-none')}
                    value={this.state.width}
                    onChange={e=>this.handleClothFieldsChange(e.target.value,'width')}
                />

                <input
                    type="text"
                    className={'form-control'}
                    value={this.state.width_inches + (this.props.measure == 'inches' ? '' : ' d-none')}
                    onChange={e=>this.handleClothFieldsChange(e.target.value,'width_inches')}
                />
            </div>
            <div className="col-4">
                <label>{this.props.t('form_cloth_visible_part')} {this.props.measure}</label>
                {/* MM */}
                <input
                    type="number"
                    min={0}
                    max={this.props.length-1}
                    className={'form-control' + (this.props.measure == 'mm' ? '' : ' d-none')}
                    value={this.state.visible_part}
                    disabled={this.state.type==='cloth'}
                    onChange={e=>{
                        if(e.target.value >= 0 && e.target.value <= this.props.length - 1)
                        {
                            this.handleClothFieldsChange(e.target.value,'visible_part')
                            let inches = parseFloat(e.target.value * 0.0393700787).toFixed(2);
                            this.handleClothFieldsChange(inches,'visible_part_inches');
                        }
                    }}
                />
                {/* INCHES */}
                <input
                    type="number"
                    min={0}
                    max={this.props.length-1}
                    className={'form-control' + (this.props.measure == 'inches' ? '' : ' d-none')}
                    value={this.state.visible_part_inches}
                    disabled={this.state.type==='cloth'}
                    onChange={e=>{
                        if(e.target.value >= 0 && e.target.value <= this.props.length - 1)
                        {
                            this.handleClothFieldsChange(e.target.value,'visible_part_inches');
                            let mm = Math.ceil(e.target.value * 25.4);
                            this.handleClothFieldsChange(mm,'visible_part')
                        }
                    }}
                />
            </div>
            <div className="col-4">
                <label>{this.props.t('form_cloth_price')}</label>
                <input
                    type="text"
                    className={'form-control'}
                    value={this.state.price}
                    onChange={e=>this.handleClothFieldsChange(e.target.value,'price')}
                />
            </div>
            <div className="col-4">
                <label>{this.props.t('form_cloth_model')}</label>
                <input
                    type="text"
                    className={'form-control'}
                    value={this.state.model}
                    onChange={e=>this.handleClothFieldsChange(e.target.value,'model')}
                />
            </div>
            <div className="col-4">
                <label>{this.props.t('form_cloth_supplier')}</label>
                <input
                    type="text"
                    className={'form-control'}
                    value={this.state.supplier}
                    onChange={e=>this.handleClothFieldsChange(e.target.value,'supplier')}
                />
            </div>
            <div className="col-4">
                <label>{this.props.t('form_cloth_min_length')}</label>
                <input
                    type="text"
                    className={'form-control'}
                    value={this.state.min_length}
                    onChange={e=>this.handleClothFieldsChange(e.target.value,'min_length')}
                />
            </div>
        </>)
    }

    getMinimizeIcon() {
        if(this.state.isOpen)
        {
            return (<i className="fa fa-window-minimize" aria-hidden="true" />)
        }

        return (<i className="fa fa-plus" aria-hidden="true" />)
    }

    render() {
        const {t} = this.props;
        return (
            <div className={'card mt-3'}>
                <div className="card-body">
                    <ClothModal
                        t={this.props.t}
                        cloths={this.state.cloths}
                        selectCloth={(cloth) => {
                            this.setState({
                                width: cloth.height,
                                width_inches: parseFloat(cloth.height * 0.0393700787).toFixed(2),
                                visible_part: 210,
                                visible_part_inches: parseFloat(210 * 0.0393700787).toFixed(2),
                                price: cloth.price,
                                model: cloth.code,
                                supplier: cloth.vendor,
                                min_length: cloth.min_width
                            }, () => {
                                this.props.applyChanges(this.getCloth());
                            })
                        }}
                    />
                    <div className="row">
                        <div className="col-12">
                            <h3>
                                <div>
                                    <i className={'fa fa-book'}/> {t('cloth')}
                                    <button onClick={() => {
                                        this.setState({isOpen: !this.state.isOpen});
                                    }} className={'btn btn-sm btn-info float-right'}>
                                        {this.getMinimizeIcon()}
                                    </button>
                                </div>
                            </h3>
                        </div>
                    </div>

                    {this.state.is_enabled && <div className={'collapse ' + (this.state.isOpen ? 'show' : '') }>
                        <div className={'row d-none'}>
                            <div className="col-4">
                                <label>{this.props.t('pages')}</label>
                                <input
                                    value={this.state.num_pages}
                                    type="number"
                                    className={'form-control'}
                                    step={4}
                                    onChange={e => {
                                        this.setState({num_pages: e.target.value}, () => {
                                            this.props.applyChanges(this.getCloth());
                                        })
                                    }}
                                />
                                {this.state.num_pages % 4 !== 0 && <div style={{color: 'red'}}>
                                    Броя страници трябва да бъде кратен на 4!
                                </div>}
                            </div>
                        </div>
                        <div className="row">
                            {this.printTypes()}
                        </div>
                        <div className={'row mt-3' + (this.state.type === 'cloth' || this.state.type === 'without' ? ' d-none' : '')}>
                            <div className="col-4">
                                <label>{t('print')}</label>
                                <Select
                                    value={this.props.element.print.find(c => c.id === this.state.selectedPrint)}
                                    onChange={(selected) => {
                                        this.setState({selectedPrint: selected.id}, () => {
                                            this.props.applyChanges(this.getCloth());
                                        })
                                    }}
                                    options={this.props.element.print}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                />
                            </div>
                            {this.props.element.finishing.length > 0 &&
                            <div className="col-4">
                                <label>{t('finishing')}</label>
                                <Select
                                    value={this.props.element.finishing.find(c => c.id === this.state.selectedFinishing)}
                                    onChange={(selected) => {
                                        this.setState({selectedFinishing: selected.id}, () => {
                                            this.props.applyChanges(this.getCloth());
                                        });
                                    }}
                                    options={this.props.element.finishing}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                />
                            </div>
                            }
                            <div className="col-4">
                                {this.printProperties()}
                            </div>
                        </div>

                        <div className="row">
                            {this.printCloth()}
                        </div>
                        <div className={"row" + (this.state.type === 'cloth' || this.state.type === 'without' ? ' d-none' : '')}>
                            {this.renderPantones()}
                        </div>
                        <div className="row mt-2">
                            {this.printPaper()}
                        </div>
                        <div className="row">
                            {this.embossings()}
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

export default withTranslation()(ClothForm);
