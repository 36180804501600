import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../helpers/DataTableInitDataGenerator';
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import SwalDelete from '../../helpers/SwalDelete';

class CurrenciesList extends Component {
    state = {
        isLoading: false,
        data: {
            columns: [],
            rows: []
        }
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const {t} = this.props;
        let listColumns = ['id','type','value','is_main','actions'];
        this.setState({data: DataTableInitDataGenerator(listColumns, t)});
        this.load();
    }

    load() {
        this.setState({isLoading: true});
        NetworkClient.get('currencies').then(response => {
            let {data} = this.state;
            let items = response.currencies;
            let rows = [];
            items.forEach((item, i) => {
                let tmp = {
                    id: item.id,
                    type: item.type,
                    value: item.value,
                    is_main: item.is_main === 1 ? <i className={"fa fa-check"} /> : <i className={"fa fa-times"} />,
                    actions: <div>
                        <Link to={`/currencies/edit/${item.id}`} className={"btn btn-info btn-sm"}>
                            <i className={"fa fa-pen"}/>
                        </Link>
                        <button onClick={() => {
                            this.delete(item.id)
                        }} className={"btn btn-danger btn-sm d-none"}>
                            <i className={"fa fa-times"}/>
                        </button>
                    </div>
                };
                rows.push(tmp);
            });


            this.setState({data: {columns: data.columns, rows: rows}, isLoading: false});
        })
    }

    delete(id) {
        const {t} = this.props;
        SwalDelete(t).then(c => {
            if (c.value) {
                this.setState({isLoading: true}, () => {
                    NetworkClient.delete(`/currencies/${id}`)
                        .then(response => {
                            let {data} = this.state;
                            let indexToRemove = data.rows.findIndex(r => r.id === id);
                            data.rows.splice(indexToRemove, 1);
                            this.setState({data: {columns: data.columns, rows: data.rows}, isLoading: false});
                        })
                });
            }
        })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    {t('currencies')}
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <Link to={'/currencies/create'} className={"btn btn-info btn-sm mb-3"}>
                                            {t('create')} <i className={"fa fa-plus"}/>
                                        </Link>
                                        <MDBDataTable
                                            striped
                                            bordered
                                            hover
                                            entries={10}
                                            data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CurrenciesList);
