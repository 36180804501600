import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Languages from "../../../constants/Languages";
import SimpleFieldsWithColumnsGenerator from "../../../helpers/SimpleFieldsWithColumnsGenerator";
import Select from "react-select";

class ClientCategoriesForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        category: {
            id: null,
            name: '',
            website_id: -1,
            enabled: 0,
            default: 0,
            is_special: 0,
            is_sales_agent: 0
        },
        websites: []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id !== undefined) {
            this.load(id);
        }else {
            //clients-categories-get-websites
            this.loadWebsites();
        }
    }

    loadWebsites() {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`clients-categories-get-websites`)
                .then(response => {
                    const websites = response.websites;
                    this.setState({websites});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        });
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`clients-categories/${id}`)
                .then(response => {
                    const category = response.category;
                    const websites = response.websites;
                    this.setState({category, websites});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        });
    }

    store() {
        this.setState({isLoading: true});
        const {category} = this.state;
        let url = 'clients-categories';
        if (category.id !== null) {
            url += '/' + category.id;
        }

        NetworkClient.post(url, category)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        this.props.history.push('/overcharges/categories');
                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    handleCheckBoxClick(e, fieldName) {
        let {category} = this.state;
        category[fieldName] = e.target.checked === true ? 1 : 0;
        this.setState({category})
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('category')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {SimpleFieldsWithColumnsGenerator.bind(this, t, [{name: 'name', type: 'text', col: 12}], 'category')()}
                                    <div className="col-12">
                                        <label>{t('website')}</label>
                                        <Select
                                            value={this.state.websites.find(c => c.id === this.state.category.website_id)}
                                            onChange={(selected) => {
                                                let {category} = this.state;
                                                category.website_id = selected.id;
                                                this.setState({category});
                                            }}
                                            options={this.state.websites}
                                            isDisabled={this.state.category.id !== null}
                                            getOptionLabel={(option)=> option.name}
                                            getOptionValue={(option) => option.id}
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>{t('enabled')}</label>
                                        <input
                                            className={"d-block"} type="checkbox"
                                            checked={this.state.category.enabled === 1}
                                            onChange={e => this.handleCheckBoxClick.bind(this,e, 'enabled')()}
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>{t('default_category')}</label>
                                        <input
                                            className={"d-block"} type="checkbox"
                                            checked={this.state.category.default === 1}
                                            onChange={e => this.handleCheckBoxClick.bind(this,e, 'default')()}
                                        />
                                        <span style={{fontSize: 12}}>{t('if-default-category-is-set-for-this-site-will-be-overridden-by-this')}</span>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>{t('is_special')}</label>
                                        <input
                                            className={"d-block"} type="checkbox"
                                            checked={this.state.category.is_special === 1}
                                            onChange={e => this.handleCheckBoxClick.bind(this,e, 'is_special')()}
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>{t('is_sales_agent')}</label>
                                        <input
                                            className={"d-block"} type="checkbox"
                                            checked={this.state.category.is_sales_agent === 1}
                                            onChange={e => this.handleCheckBoxClick.bind(this,e, 'is_sales_agent')()}
                                        />
                                    </div>
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.category.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ClientCategoriesForm);
