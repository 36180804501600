import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../../helpers/DataTableInitDataGenerator';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import SwalDelete from '../../../helpers/SwalDelete';

class ClientCategoriesList extends Component {
    state = {
        isLoading: false,
        data: {
            columns: [],
            rows: []
        }
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {t} = this.props;
        let listColumns = ['id','name','enabled','is_special','is_sales_agent','website','default_category','actions'];
        this.setState({data: DataTableInitDataGenerator(listColumns, t)});
        this.load();
    }

    load() {
        this.setState({isLoading: true});
        NetworkClient.get('clients-categories').then(response => {
            let {data} = this.state;
            let items = response.categories;
            let rows = [];
            items.forEach((item, i) => {
                let tmp = {
                    id: item.id,
                    name: item.name,
                    enabled: item.enabled === 1 ? <i className={"fa fa-check"} /> : '',
                    is_special: item.is_special === 1 ? <i className={"fa fa-check"} /> : '',
                    is_sales_agent: item.is_sales_agent === 1 ? <i className={"fa fa-check"} /> : '',
                    website: item.website !== null ? item.website.name : '',
                    default_category: item.default === 1 ? <i className={"fa fa-check"}/> : '',
                    actions: <div>
                        <Link to={`/overcharges/categories/edit/${item.id}`} className={"btn btn-info btn-sm"}>
                            <i className={"fa fa-pen"}/>
                        </Link>
                        <button onClick={() => {
                            this.delete(item.id)
                        }} className={"btn btn-danger btn-sm"}>
                            <i className={"fa fa-times"}/>
                        </button>
                    </div>
                };
                rows.push(tmp);
            });

            this.setState({data: {columns: data.columns, rows: rows}, isLoading: false});
        })
    }

    delete(id) {
        const {t} = this.props;
        SwalDelete(t,t('all-clients-with-this-category-will-be-sett-to-default-category')).then(c => {
            if (c.value) {
                this.setState({isLoading: true}, () => {
                    NetworkClient.delete(`/clients-categories/${id}`)
                        .then(response => {
                            let {data} = this.state;
                            let indexToRemove = data.rows.findIndex(r => r.id === id);
                            data.rows.splice(indexToRemove, 1);
                            this.setState({data: {columns: data.columns, rows: data.rows}, isLoading: false});
                        }, error => {
                            this.setState({isLoading: false})
                        })
                });
            }
        })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    {t('categories')}
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <Link to={'/overcharges/categories/create'} className={"btn btn-info btn-sm mb-3"}>
                                            {t('create')} <i className={"fa fa-plus"}/>
                                        </Link>
                                        <MDBDataTable
                                            striped
                                            bordered
                                            hover
                                            entries={10}
                                            data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ClientCategoriesList);
