import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Select from 'react-select';

class CDForm extends Component {
    state = {
        isLoading: false,
        copies: 1000,
        isOpen: true,
        is_enabled: false,
        selectedType: -1,
        is_init: false,
        copiesFocus: 1000
    }

    constructor(props) {
        super(props);
    }

    getCD(trigger = true) {
        return {
            is_enabled: this.state.is_enabled,
            copies: this.state.copies,
            selectedType: this.state.selectedType,
            trigger: trigger
        }
    }

    componentWillReceiveProps(props) {
        if(props.initial_state !== null && props.initial_state !== undefined && !this.state.is_init)
        {
            let initial_state = props.initial_state;
            this.setState({
                copies: initial_state.copies,
                is_enabled: initial_state.is_enabled,
                selectedType: initial_state.selectedType,
                is_init: true
            }, () => {
                this.props.applyChanges(this.getCD());
            })
        }
    }

    getMinimizeIcon() {
        if(this.state.isOpen)
        {
            return (<i className="fa fa-window-minimize" aria-hidden="true" />)
        }

        return (<i className="fa fa-plus" aria-hidden="true" />)
    }

    render() {
        const {t} = this.props;
        return (
            <div className={'card mt-3'}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h3>
                                <div>
                                    <i className="fas fa-compact-disc" /> {t('cd')}
                                    <button onClick={()=>{
                                        this.setState({isOpen: !this.state.isOpen});
                                    }} className={'btn btn-sm btn-info float-right'}>
                                        {this.getMinimizeIcon()}
                                    </button>
                                </div>
                            </h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="checkbox"
                                checked={this.state.is_enabled}
                                onChange={e => this.setState({is_enabled: e.target.checked},()=>{this.props.applyChanges(this.getCD());})}
                            /> {t('cd')}
                        </div>
                    </div>
                    {this.state.is_enabled && <div className={'collapse ' + (this.state.isOpen ? 'show' : '') }>
                        <div className="row mt-3">
                            <div className="col-6">
                                <label>{t('type')}</label>
                                <Select
                                    value={this.state.selectedType}
                                    onChange={(selected) => {
                                        this.setState({selectedType: selected}, () => {
                                            this.props.applyChanges(this.getCD());
                                        })
                                    }}
                                    options={this.props.cd_types}
                                    getOptionLabel={(option)=> option.name}
                                    getOptionValue={(option) => option.id}
                                />
                            </div>
                            <div className="col-6">
                                <label>{t('copies')}</label>
                                <input
                                    type="number" min={0}
                                    value={this.state.copies}
                                    className={'form-control'}
                                    onChange={e => {
                                        this.setState({copies: e.target.value},() => {
                                            this.props.applyChanges(this.getCD(false));
                                        })
                                    }}
                                    onFocus={e => {
                                        this.setState({copiesFocus: e.target.value})
                                    }}
                                    onBlur={e => {
                                        if(e.target.value !== this.state.copiesFocus)
                                        {
                                            this.props.applyChanges(this.getCD());
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

export default withTranslation()(CDForm);
