import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';

const components = ['general','cover','cloth','cd','total'];

class QuotePriceInfo extends Component {
    state = {
        isLoading: false
    }

    constructor(props) {
        super(props);
    }

    renderComponentInfo(component) {
        let items = this.props.prices.filter(x=>x.component === component);
        if(items.length === 0){
            return <></>;
        }

        let html = items.map(p => <tr>
            <td>{p.info}</td>
            <td>{p.price}</td>
        </tr>);

        html.unshift(
            <tr>
                <th className={'text-center'} colSpan={2}>
                    <b>{component}</b>
                </th>
            </tr>
        )

        return html;
    }

    render() {
        return (
            <div className={'card'}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 table-responsive">
                            <table className={'table table-bordered'}>
                                <tr>
                                    <td>
                                        info
                                    </td>
                                    <td>
                                        price
                                    </td>
                                </tr>
                                {
                                    components.map(c => this.renderComponentInfo(c))
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(QuotePriceInfo);
