import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Languages from "../../../constants/Languages";

class PaperColorsForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        color: {
            id: null,
            bg_name: '',
            en_name: '',
            fr_name: '',
            de_name: '',
            us_name: '',
        }
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if(id !== undefined)
        {
            this.load(id);
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`papers/colors/${id}`)
                .then(response => {
                    const color = response.color;
                    this.setState({color});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {color} = this.state;
        let url = 'papers/colors';
        if(color.id !== null)
        {
            url += '/' + color.id;
        }

        NetworkClient.post(url, color)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        this.props.history.push('/papers/colors');
                    })
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('paper')} {t('color')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {this.state.languages.map((l, i) => <div className="form-group">
                                        <label>{l} Name</label>
                                        <input
                                            key={i}
                                            type="text" className={"form-control"}
                                            placeholder={`${l} Name`} value={this.state.color[l + '_name']}
                                            onChange={(e) => {
                                                let {color} = this.state;
                                                color[l + '_name'] = e.target.value;
                                                this.setState({color});
                                            }}
                                        />
                                    </div>)}
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.color.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PaperColorsForm);
