import Swal from 'sweetalert2';

const swalFireDelete = function(t, text = t('you-wont-be-able-to-revert-this')){
    return Swal.fire({
        title: t('are-you-sure'),
        text: text,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('yes-delete-it')
    });
}

export default swalFireDelete;
