import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import MainPageWrapper from './app/layout/MainPageWrapper';
import PaperTypeList from './app/views/papers/types/PaperTypesList';
import PaperTypesForm from './app/views/papers/types/PaperTypesForm';
import Dashboard from './app/views/dashboard/Dashboard';
import DataTablesTest from './app/tests/DataTablesTest';
import PaperNamesList from './app/views/papers/names/PaperNamesList';
import PaperNamesForm from './app/views/papers/names/PaperNamesForm';
import PaperColorsList from "./app/views/papers/colors/PaperColorsList";
import PaperColorsForm from "./app/views/papers/colors/PaperColorsForm";
import PaperDensitiesList from "./app/views/papers/densities/PaperDensitiesList";
import PaperDensitiesForm from "./app/views/papers/densities/PaperDensitiesForm";
import PaperSizesList from "./app/views/papers/sizes/PaperSizesList";
import PaperSizesForm from "./app/views/papers/sizes/PaperSizesForm";
import PapersList from "./app/views/papers/papers/PapersList";
import PaperForm from "./app/views/papers/papers/PaperForm";
import PaperSuppliersList from "./app/views/papers/suppliers/PaperSuppliersList";
import PaperSuppliersForm from "./app/views/papers/suppliers/PaperSuppliersForm";
import CurrenciesList from "./app/views/currencies/CurrenciesList";
import CurrenciesForm from "./app/views/currencies/CurrenciesForm";
import AllCountriesList from "./app/views/countries/all-countries/AllCountriesList";
import AllCountriesForm from "./app/views/countries/all-countries/AllCountriesForm";
import TestComponent from "./app/views/test/TestComponent";
import SystemCountriesLis from "./app/views/countries/system-contries/SystemCountriesLis";
import SystemCountriesForm from "./app/views/countries/system-contries/SystemCountriesForm";
import WebsiteList from "./app/views/Websites/WebsiteList";
import WebsiteForm from "./app/views/Websites/WebsiteForm";
import ClientsList from "./app/views/clients/all_clients/ClientsList";
import ProductsList from "./app/views/products/products/ProductsList";
import ProductsForm from "./app/views/products/products/ProductsForm";
import ProductPropertiesForm from "./app/views/products/properties/ProdctsProperitesForm";
import ProductPropertiesList from "./app/views/products/properties/ProductPropertiesList";
import ProductTypesForm from "./app/views/products/types/productTypesForm";
import ProductTypesList from "./app/views/products/types/productTypesList";
import PrivateRoute from "./app/router/PrivateRoute";
import Login from "./app/views/auth/Login";
import PermissionsList from "./app/views/permissions/PermissionsList";
import PermissionsForm from "./app/views/permissions/PermissionsForm";
import RolesList from "./app/views/roles/RolesList";
import RolesForm from "./app/views/roles/RolesForm";
import ClientsForm from "./app/views/clients/all_clients/ClientsForm";
import productTypesForm from "./app/views/products/types/productTypesForm";

import ClientCategoriesList from "./app/views/clients/categories/ClientCategoriesList";
import ClientCategoriesForm from "./app/views/clients/categories/ClientCategoriesForm";
import ContactTypesList from "./app/views/clients/contact_types/ContactTypesList";
import ContactTypesForm from "./app/views/clients/contact_types/ContactTypesForm";

import BusinessFieldsList from "./app/views/clients/businessFields/BusinessFieldsList";
import BusinessFieldsForm from "./app/views/clients/businessFields/BusinessFieldsForm";
import UserList from "./app/views/Users/UserList";
import UserForm from "./app/views/Users/UserForm";
import DistributionList from "./app/views/distribution/DistributionList";
import DistributionTest from "./app/views/distribution/DistributionTest";
import OrderList from "./app/views/orders/OrderList";
import OrderForm from "./app/views/orders/OrderForm";
import OverchargesList from "./app/views/overcharges/OverchargesList";
import OverchargesForm from "./app/views/overcharges/OverchargesForm";
import DistributionForm from "./app/views/distribution/DistributionForm";
import MaculaturePrintBodyList from "./app/views/maculatures/print_body/MaculaturePrintBodyList";
import MaculaturePrintBodyForm from "./app/views/maculatures/print_body/MaculaturePrintBodyForm";
import MaculatureKnigovezaneList from "./app/views/maculatures/knigovezane/MaculatureKnigovezaneList";
import MaculatureKnigovezaneForm from "./app/views/maculatures/knigovezane/MaculatureKnigovezaneForm";
import MaculaturePrintCoverList from "./app/views/maculatures/print_cover/MaculaturePrintCoverList";
import MaculaturePrintCoverForm from "./app/views/maculatures/print_cover/MaculaturePrintCoverForm";
import QuoteFirstStep from "./app/views/quotes/QuoteFirstStep";
import QuoteEmailPage from "./app/views/quotes/email/QuoteEmailPage";
import BelgiumList from './app/views/transport/countries/be/BelgiumList';
import BelgiumForm from './app/views/transport/countries/be/BelgiumForm';
import Courier from './app/views/transport/countries/bg/prices/CourierList';
import CourierList from './app/views/transport/countries/bg/prices/CourierList';
import CourierForm from './app/views/transport/countries/bg/prices/CourierForm';
import SettingsList from './app/views/transport/countries/bg/prices/SettingsList';
import SettingsForm from './app/views/transport/countries/bg/prices/SettingsForm';
import TaxesFuelForm from './app/views/transport/countries/bg/taxes/TaxesFuelForm';
import TaxesPulsioForm from './app/views/transport/countries/bg/taxes/TaxesPulsioForm';
import DistrictsList from './app/views/transport/countries/fr/districts/DistrictsList';
import DistrictsForm from './app/views/transport/countries/fr/districts/DistrictsForm';
import TransportFrOvercharges from './app/views/transport/countries/fr/overcharges/OverchargesList'
import TransportFrOverchargesForm from './app/views/transport/countries/fr/overcharges/OverchargesForm'
import EconomyList from './app/views/transport/countries/ch/prices/economy/EconomyList';
import EconomyForm from './app/views/transport/countries/ch/prices/economy/EconomyForm';
import ExpressList from './app/views/transport/countries/ch/prices/express/ExpressList';
import ExpressForm from './app/views/transport/countries/ch/prices/express/ExpressForm';
import SwitzerlandSettingsList from './app/views/transport/countries/ch/prices/settings/SettingsList';
import SwitzerlandSettingsForm from './app/views/transport/countries/ch/prices/settings/SettingsForm';
import TaxFuelForm from './app/views/transport/countries/ch/taxes/TaxFuelForm';
import TaxPulsioForm from './app/views/transport/countries/ch/taxes/TaxPulsioForm';
import TaxSecurityForm from './app/views/transport/countries/ch/taxes/TaxSecurityForm';
import UKEconomyList from './app/views/transport/countries/uk/prices/economy/EconomyList';
import UKEconomyForm from './app/views/transport/countries/uk/prices/economy/EconomyForm';
import UKExpressList from './app/views/transport/countries/uk/prices/express/ExpressList';
import UKExpressForm from './app/views/transport/countries/uk/prices/express/ExpressForm';
import UKSettingsList from './app/views/transport/countries/uk/prices/settings/SettingsList';
import UKSettingsForm from './app/views/transport/countries/uk/prices/settings/SettingsForm';
import UKTaxFuelForm from './app/views/transport/countries/uk/taxes/TaxFuelForm';
import UKTaxPulsioForm from './app/views/transport/countries/uk/taxes/TaxPulsioForm';
import UKTaxSecurityForm from './app/views/transport/countries/uk/taxes/TaxSecurityForm';


import ITEconomyList from './app/views/transport/countries/it/prices/economy/EconomyList';
import ITEconomyForm from './app/views/transport/countries/it/prices/economy/EconomyForm';
import ITExpressList from './app/views/transport/countries/it/prices/express/ExpressList';
import ITExpressForm from './app/views/transport/countries/it/prices/express/ExpressForm';
import ITSettingsList from './app/views/transport/countries/it/prices/settings/SettingsList';
import ITSettingsForm from './app/views/transport/countries/it/prices/settings/SettingsForm';
import ITTaxFuelForm from './app/views/transport/countries/it/taxes/TaxFuelForm';
import ITTaxPulsioForm from './app/views/transport/countries/it/taxes/TaxPulsioForm';
import ITTaxSecurityForm from './app/views/transport/countries/it/taxes/TaxSecurityForm';


import DEEconomyList from './app/views/transport/countries/de/prices/economy/EconomyList';
import DEEconomyForm from './app/views/transport/countries/de/prices/economy/EconomyForm';
import DEExpressList from './app/views/transport/countries/de/prices/express/ExpressList';
import DEExpressForm from './app/views/transport/countries/de/prices/express/ExpressForm';
import DESettingsList from './app/views/transport/countries/de/prices/settings/SettingsList';
import DESettingsForm from './app/views/transport/countries/de/prices/settings/SettingsForm';
import DETaxFuelForm from './app/views/transport/countries/de/taxes/TaxFuelForm';
import DETaxPulsioForm from './app/views/transport/countries/de/taxes/TaxPulsioForm';
import DETaxSecurityForm from './app/views/transport/countries/de/taxes/TaxSecurityForm';
import AUSEconomyList from './app/views/transport/countries/aus/prices/economy/EconomyList';
import AUSEconomyForm from './app/views/transport/countries/aus/prices/economy/EconomyForm';
import AUSExpressList from './app/views/transport/countries/aus/prices/express/ExpressList';
import AUSExpressForm from './app/views/transport/countries/aus/prices/express/ExpressForm';
import AUSSettingsList from './app/views/transport/countries/aus/prices/settings/SettingsList';
import AUSSettingsForm from './app/views/transport/countries/aus/prices/settings/SettingsForm';
import AUSTaxFuelForm from './app/views/transport/countries/aus/taxes/TaxFuelForm';
import AUSTaxPulsioForm from './app/views/transport/countries/aus/taxes/TaxPulsioForm';
import AUSTaxSecurityForm from './app/views/transport/countries/aus/taxes/TaxSecurityForm';
import USAForm from './app/views/transport/countries/usa/USAForm';
import TransportTypesList from './app/views/transport/settings/types/TransportTypesList';
import TransportTypesForm from './app/views/transport/settings/types/TransportTypesForm';
import TransportGroupsList from './app/views/transport/settings/groups/TransportGroupsList';
import TransportGroupsForm from './app/views/transport/settings/groups/TransportGroupsForm';
import TransportStatusesList from './app/views/transport/settings/statuses/TransportStatusesList';
import TransportStatusesForm from './app/views/transport/settings/statuses/TransportStatusesForm';
import TransportPrioritiesList from './app/views/transport/settings/priorities/TransportPrioritiesList';
import TransportPrioritiesForm from './app/views/transport/settings/priorities/TransportPrioritiesForm';
import TransportTirGroupsList from './app/views/transport/settings/tir-groups/TransportTirGroupsList';
import TransportTirGroupsForm from './app/views/transport/settings/tir-groups/TransportTirGroupsForm';
import TransportDistributionsList from './app/views/transport/settings/distributions/TransportDistributionsList';
import TransportDistributionsForm from './app/views/transport/settings/distributions/TransportDistributionsForm';
import TransportRatioForm from './app/views/transport/settings/ratio/TransportRatioForm';
import CalculatorHelper from './app/views/calculatorhelper/CalculatorHelper';
import CalculatorHelperItem from "./app/views/calculatorhelper/CalculatorHelperItem";
import CalculatorHelperItemInner from "./app/views/calculatorhelper/CalculatorHelperItemInner";
import CalculatorHelperItemInnerEdit from "./app/views/calculatorhelper/CalculatorHelperItemInnerEdit";

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    const {i18n} = this.props;
    const currentLanguage = Cookies.get('lang');

    if(currentLanguage !== undefined)
    {
      i18n.changeLanguage(currentLanguage);
    }

    return (
      <div>
      <Router>
        <Route path="/login" exact component={Login} />
        <MainPageWrapper>
          <PrivateRoute path="/" exact component={Dashboard} />
          <PrivateRoute path={"/products/properties"} exact component={ProductPropertiesList} />
          <PrivateRoute path={"/products/properties/edit/:id"} exact component={ProductPropertiesForm} />
          <PrivateRoute path={"/products/properties/create"} exact component={ProductPropertiesForm} />
          <PrivateRoute path={"/products/types"} exact component={ProductTypesList} />
          <PrivateRoute path={"/products/types/edit/:id"} exact component={ProductTypesForm} />
          <PrivateRoute path={"/products/types/create"} exact component={ProductTypesForm} />
          <PrivateRoute path={"/papers/types"} exact component={PaperTypeList} />
          <PrivateRoute path={"/papers/types/create"} exact component={PaperTypesForm} />
          <PrivateRoute path={"/papers/types/edit/:id"} exact component={PaperTypesForm} />
          <PrivateRoute path={"/papers/names"} exact component={PaperNamesList} />
          <PrivateRoute path={"/papers/names/create"} exact component={PaperNamesForm} />
          <PrivateRoute path={"/papers/names/edit/:id"} exact component={PaperNamesForm} />
          <PrivateRoute path={"/papers/colors"} exact component={PaperColorsList} />
          <PrivateRoute path={"/papers/colors/create"} exact component={PaperColorsForm} />
          <PrivateRoute path={"/papers/colors/edit/:id"} exact component={PaperColorsForm} />
          <PrivateRoute path={"/papers/densities"} exact component={PaperDensitiesList} />
          <PrivateRoute path={"/papers/densities/create"} exact component={PaperDensitiesForm} />
          <PrivateRoute path={"/papers/densities/edit/:id"} exact component={PaperDensitiesForm} />
          <PrivateRoute path={"/papers/sizes"} exact component={PaperSizesList} />
          <PrivateRoute path={"/papers/sizes/create"} exact component={PaperSizesForm} />
          <PrivateRoute path={"/papers/sizes/edit/:id"} exact component={PaperSizesForm} />
          <PrivateRoute path={"/cardboards"} exact component={PapersList} />
          <PrivateRoute path={"/cardboards/create"} exact component={PaperForm} />
          <PrivateRoute path={"/cardboards/edit/:id"} exact component={PaperForm} />
          <PrivateRoute path={"/papers/full"} exact component={PapersList} />
          <PrivateRoute path={"/papers/full/create"} exact component={PaperForm} />
          <PrivateRoute path={"/papers/full/edit/:id"} exact component={PaperForm} />
          <PrivateRoute path={"/papers/suppliers"} exact component={PaperSuppliersList} />
          <PrivateRoute path={"/papers/suppliers/create"} exact component={PaperSuppliersForm} />
          <PrivateRoute path={"/papers/suppliers/edit/:id"} exact component={PaperSuppliersForm} />
          <PrivateRoute path={"/currencies"} exact component={CurrenciesList} />
          <PrivateRoute path={"/currencies/create"} exact component={CurrenciesForm} />
          <PrivateRoute path={"/currencies/edit/:id"} exact component={CurrenciesForm} />
          <PrivateRoute path={"/all-countries"} exact component={AllCountriesList} />
          <PrivateRoute path={"/all-countries/create"} exact component={AllCountriesForm} />
          <PrivateRoute path={"/all-countries/edit/:id"} exact component={AllCountriesForm} />
          <PrivateRoute path={"/orders/list"} exact component={OrderList} />
          <PrivateRoute path={"/orders/create"} exact component={OrderForm} />
          <PrivateRoute path={"/overcharges"} exact component={OverchargesList} />
          <PrivateRoute path={"/overcharges/create"} exact component={OverchargesForm} />
          <PrivateRoute path={"/overcharges/edit/website/:website_id/category/:category_id/product/:product_id"} exact component={OverchargesForm} />
          <PrivateRoute path={"/transport/belgium"} exact component={BelgiumList} />
          <PrivateRoute path={"/transport/belgium/edit/:id"} exact component={BelgiumForm} />
          <PrivateRoute path={"/transport/bulgaria/prices/courier"} exact component={CourierList} />
          <PrivateRoute path={"/transport/bulgaria/prices/courier/edit/:id"} exact component={CourierForm} />
          <PrivateRoute path={"/transport/bulgaria/prices/settings"} exact component={SettingsList} />
          <PrivateRoute path={"/transport/bulgaria/prices/settings/edit/:id"} exact component={SettingsForm} />
          <PrivateRoute path={"/transport/bulgaria/taxes/tax-fuel"} exact component={TaxesFuelForm} />
          <PrivateRoute path={"/transport/bulgaria/taxes/tax-pulsio"} exact component={TaxesPulsioForm} />
          <PrivateRoute path={"/transport/france/districts"} exact component={DistrictsList} />
          <PrivateRoute path={"/transport/france/districts/edit/:id"} exact component={DistrictsForm} />
          <PrivateRoute path={"/transport/france/districts/create"} exact component={DistrictsForm} />
          <PrivateRoute path={"/transport/france/overcharges"} exact component={TransportFrOvercharges} />
          <PrivateRoute path={"/transport/france/overcharges/edit/:id"} exact component={TransportFrOverchargesForm} />
          <PrivateRoute path={"/transport/france/overcharges/create"} exact component={TransportFrOverchargesForm} />
          <PrivateRoute path={"/transport/switzerland/prices/economy"} exact component={EconomyList} />
          <PrivateRoute path={"/transport/switzerland/prices/economy/edit/:id"} exact component={EconomyForm} />
          <PrivateRoute path={"/transport/switzerland/prices/express"} exact component={ExpressList} />
          <PrivateRoute path={"/transport/switzerland/prices/express/edit/:id"} exact component={ExpressForm} />
          <PrivateRoute path={"/transport/switzerland/prices/settings"} exact component={SwitzerlandSettingsList} />
          <PrivateRoute path={"/transport/switzerland/prices/settings/edit/:id"} exact component={SwitzerlandSettingsForm} />
          <PrivateRoute path={"/transport/switzerland/taxes/fuel/"}  exact component={TaxFuelForm} />                  
          <PrivateRoute path={"/transport/switzerland/taxes/pulsio/"}  exact component={TaxPulsioForm} />                  
          <PrivateRoute path={"/transport/switzerland/taxes/security/"}  exact component={TaxSecurityForm} />                  
          <PrivateRoute path={"/transport/uk/prices/economy"} exact component={UKEconomyList} />
          <PrivateRoute path={"/transport/uk/prices/economy/edit/:id"} exact component={UKEconomyForm} />
          <PrivateRoute path={"/transport/uk/prices/express"} exact component={UKExpressList} />
          <PrivateRoute path={"/transport/uk/prices/express/edit/:id"} exact component={UKExpressForm} />
          <PrivateRoute path={"/transport/uk/prices/settings"} exact component={UKSettingsList} />
          <PrivateRoute path={"/transport/uk/prices/settings/edit/:id"} exact component={UKSettingsForm} />
          <PrivateRoute path={"/transport/uk/taxes/fuel/"}  exact component={UKTaxFuelForm} />                  
          <PrivateRoute path={"/transport/uk/taxes/pulsio/"}  exact component={UKTaxPulsioForm} />                  
          <PrivateRoute path={"/transport/uk/taxes/security/"}  exact component={UKTaxSecurityForm} />
          <PrivateRoute path={"/transport/it/prices/economy"} exact component={ITEconomyList} />
          <PrivateRoute path={"/transport/it/prices/economy/edit/:id"} exact component={ITEconomyForm} />
          <PrivateRoute path={"/transport/it/prices/express"} exact component={ITExpressList} />
          <PrivateRoute path={"/transport/it/prices/express/edit/:id"} exact component={ITExpressForm} />
          <PrivateRoute path={"/transport/it/prices/settings"} exact component={ITSettingsList} />
          <PrivateRoute path={"/transport/it/prices/settings/edit/:id"} exact component={ITSettingsForm} />
          <PrivateRoute path={"/transport/it/taxes/fuel/"}  exact component={ITTaxFuelForm} />                  
          <PrivateRoute path={"/transport/it/taxes/pulsio/"}  exact component={ITTaxPulsioForm} />                  
          <PrivateRoute path={"/transport/it/taxes/security/"}  exact component={ITTaxSecurityForm} /> 
          <PrivateRoute path={"/transport/de/prices/economy"} exact component={DEEconomyList} />
          <PrivateRoute path={"/transport/de/prices/economy/edit/:id"} exact component={DEEconomyForm} />
          <PrivateRoute path={"/transport/de/prices/express"} exact component={DEExpressList} />
          <PrivateRoute path={"/transport/de/prices/express/edit/:id"} exact component={DEExpressForm} />
          <PrivateRoute path={"/transport/de/prices/settings"} exact component={DESettingsList} />
          <PrivateRoute path={"/transport/de/prices/settings/edit/:id"} exact component={DESettingsForm} />
          <PrivateRoute path={"/transport/de/taxes/fuel/"}  exact component={DETaxFuelForm} />                  
          <PrivateRoute path={"/transport/de/taxes/pulsio/"}  exact component={DETaxPulsioForm} />                  
          <PrivateRoute path={"/transport/de/taxes/security/"}  exact component={DETaxSecurityForm} />  
          <PrivateRoute path={"/transport/aus/prices/economy"} exact component={AUSEconomyList} />
          <PrivateRoute path={"/transport/aus/prices/economy/edit/:id"} exact component={AUSEconomyForm} />
          <PrivateRoute path={"/transport/aus/prices/express"} exact component={AUSExpressList} />
          <PrivateRoute path={"/transport/aus/prices/express/edit/:id"} exact component={AUSExpressForm} />
          <PrivateRoute path={"/transport/aus/prices/settings"} exact component={AUSSettingsList} />
          <PrivateRoute path={"/transport/aus/prices/settings/edit/:id"} exact component={AUSSettingsForm} />
          <PrivateRoute path={"/transport/aus/taxes/fuel/"}  exact component={AUSTaxFuelForm} />                  
          <PrivateRoute path={"/transport/aus/taxes/pulsio/"}  exact component={AUSTaxPulsioForm} />                  
          <PrivateRoute path={"/transport/aus/taxes/security/"}  exact component={AUSTaxSecurityForm} /> 
          <PrivateRoute path={"/transport/USA/"}  exact component={USAForm} /> 
          <PrivateRoute path={"/transport/settings/types"}  exact component={TransportTypesList} /> 
          <PrivateRoute path={"/transport/settings/types/edit/:id"}  exact component={TransportTypesForm} /> 
          <PrivateRoute path={"/transport/settings/types/create"}  exact component={TransportTypesForm} /> 
          <PrivateRoute path={"/transport/settings/groups"}  exact component={TransportGroupsList} /> 
          <PrivateRoute path={"/transport/settings/groups/edit/:id"}  exact component={TransportGroupsForm} /> 
          <PrivateRoute path={"/transport/settings/groups/create"}  exact component={TransportGroupsForm} /> 
          <PrivateRoute path={"/transport/settings/statuses"}  exact component={TransportStatusesList} /> 
          <PrivateRoute path={"/transport/settings/statuses/edit/:id"}  exact component={TransportStatusesForm} /> 
          <PrivateRoute path={"/transport/settings/statuses/create"}  exact component={TransportStatusesForm} />
          <PrivateRoute path={"/transport/settings/priorities"}  exact component={TransportPrioritiesList} /> 
          <PrivateRoute path={"/transport/settings/priorities/edit/:id"}  exact component={TransportPrioritiesForm} /> 
          <PrivateRoute path={"/transport/settings/priorities/create"}  exact component={TransportPrioritiesForm} />
          <PrivateRoute path={"/transport/settings/tir-groups"}  exact component={TransportTirGroupsList} /> 
          <PrivateRoute path={"/transport/settings/tir-groups/edit/:id"}  exact component={TransportTirGroupsForm} /> 
          <PrivateRoute path={"/transport/settings/tir-groups/create"}  exact component={TransportTirGroupsForm} />
          <PrivateRoute path={"/transport/settings/distributions"}  exact component={TransportDistributionsList} /> 
          <PrivateRoute path={"/transport/settings/distributions/edit/:id"}  exact component={TransportDistributionsForm} /> 
          <PrivateRoute path={"/transport/settings/distributions/create"}  exact component={TransportDistributionsForm} />
          <PrivateRoute path={"/transport/settings/ratio"}  exact component={TransportRatioForm} />
          <PrivateRoute path={"/system-countries"} exact component={SystemCountriesLis} />
          <PrivateRoute path={"/system-countries/edit/:id"} exact component={SystemCountriesForm} />
          <PrivateRoute path={"/system-countries/create"} exact component={SystemCountriesForm} />
          <PrivateRoute path={"/websites"} exact component={WebsiteList} />
          <PrivateRoute path={"/website/create"} exact component={WebsiteForm} />
          <PrivateRoute path={"/website/edit/:id"} exact component={WebsiteForm} />
          <PrivateRoute path={"/all-clients"} exact component={ClientsList} />
          <PrivateRoute path={"/all-clients/create"} exact component={ClientsForm} />
          <PrivateRoute path={"/all-clients/edit/:id"} exact component={ClientsForm} />
          <PrivateRoute path={"/overcharges/categories"} exact component={ClientCategoriesList} />
          <PrivateRoute path={"/overcharges/categories/create"} exact component={ClientCategoriesForm} />
          <PrivateRoute path={"/overcharges/categories/edit/:id"} exact component={ClientCategoriesForm} />
          <PrivateRoute path={"/all-clients/contact-types"} exact component={ContactTypesList} />
          <PrivateRoute path={"/all-clients/contact-types/create"} exact component={ContactTypesForm} />
          <PrivateRoute path={"/all-clients/contact-types/edit/:id"} exact component={ContactTypesForm} />
          <PrivateRoute path={"/all-clients/business-fields"} exact component={BusinessFieldsList} />
          <PrivateRoute path={"/all-clients/business-fields/create"} exact component={BusinessFieldsForm} />
          <PrivateRoute path={"/all-clients/business-fields/edit/:id"} exact component={BusinessFieldsForm} />
          <PrivateRoute path={"/users"} exact component={UserList} />
          <PrivateRoute path={"/users/create"} exact component={UserForm} />
          <PrivateRoute path={"/users/edit/:id"} exact component={UserForm} />
          <PrivateRoute path={"/permissions"} exact component={PermissionsList} />
          <PrivateRoute path={"/permissions/create"} exact component={PermissionsForm} />
          <PrivateRoute path={"/permissions/edit/:id"} exact component={PermissionsForm} />
          <PrivateRoute path={"/roles"} exact component={RolesList} />
          <PrivateRoute path={"/roles/create"} exact component={RolesForm} />
          <PrivateRoute path={"/roles/edit/:id"} exact component={RolesForm} />
          <PrivateRoute path={"/products"} exact component={ProductsList} />
          <PrivateRoute path={"/products/create"} exact component={ProductsForm} />
          <PrivateRoute path={"/products/edit/:id"} exact component={ProductsForm} />
          <PrivateRoute path={"/distribution"} exact component={DistributionList} />
          <PrivateRoute path={"/distribution/create"} exact component={DistributionForm} />
          <PrivateRoute path={"/distribution-test/:id"} exact component={DistributionTest} />
          <PrivateRoute path={"/distribution-test/:id/:orderId"} exact component={DistributionTest} />
          {<PrivateRoute path={"/distribution-test"} exact component={DistributionTest} />}
          <PrivateRoute path={"/maculatures-print-body"} exact component={MaculaturePrintBodyList} />
          <PrivateRoute path={"/maculatures-print-body/edit/:id"} exact component={MaculaturePrintBodyForm} />
          <PrivateRoute path={"/maculatures-knigovezane"} exact component={MaculatureKnigovezaneList} />
          <PrivateRoute path={"/maculatures-knigovezane/edit/:id"} exact component={MaculatureKnigovezaneForm} />
          <PrivateRoute path={"/maculatures-cover-print"} exact component={MaculaturePrintCoverList} />
          <PrivateRoute path={"/maculatures-cover-print/edit/:id"} exact component={MaculaturePrintCoverForm} />
          <PrivateRoute path={"/quotes/first-step"} exact component={QuoteFirstStep} />
          <PrivateRoute path={"/quotes/email/:id"} exact component={QuoteEmailPage} />
          <PrivateRoute path={"/calculator-helper"} exact component={CalculatorHelper} />
          <PrivateRoute path={"/calculator-helper/item/:id"} exact component={CalculatorHelperItem} />
          <PrivateRoute path={"/calculator-helper/item-category/:categoryId/:itemId"} exact component={CalculatorHelperItemInner} />
          <PrivateRoute path={"/calculator-helper/item-category/:categoryId/:itemId/:id"} exact component={CalculatorHelperItemInnerEdit} />
        </MainPageWrapper>
      </Router>
      </div>
    );
  }

}

export default App;
