import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Select from 'react-select';

class TransportForm extends Component {
    state = {
        isLoading: false,
        selectedCountry: 2,
        delivery_address: 76,
        copies: 1000,
        transports: [],
        is_init: false,
        display_transport_price: false
    }

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(props) {
        if(this.props.transports.length > 0 && this.state.transports.length === 0)
        {
            let initialTransport = [
                {copies: 1000, selected_country: 2, delivery_address: 75, group_id: -1}
            ];
            this.setState({transports: initialTransport},() => {
                this.props.applyChanges(this.getTransport());
            })
        }

        if(props.initial_state !== null && props.initial_state !== undefined && !this.state.is_init)
        {
            this.setState({transports: props.initial_state, is_init: true, display_transport_price: props.display_transport_price }, () => {
                this.props.applyChanges(this.getTransport());
            })
        }
    }

    printSecondColumn(index) {
        if(this.props.transports.length === 0)
        {
            return (<></>);
        }

        let selId = this.state.transports[index].selected_country;
        let selectedCountry = this.props.transports.find(x=>x.country_id === selId);

        if(selectedCountry.type === 1 && selectedCountry.country_id === 2)
        {
            return (
                <>
                    <label>{this.props.t('form_address')}</label>
                    <Select
                        value={selectedCountry.departments.find(x=> parseInt(x.district_code) === parseInt(this.state.transports[index].delivery_address))  }
                        onChange={e => {
                            let {transports} = this.state;
                            transports[index].delivery_address = e.id;
                            this.setState({transports}, () => {
                                this.props.applyChanges(this.getTransport());
                            })
                        }}
                        options={selectedCountry.departments}
                        getOptionLabel={(option)=> option.district_code}
                        getOptionValue={(option) => option.id}
                    />
                </>
            )
        }
        else if(selectedCountry.type === 1){
            return (<></>)
        }

        return (
            <>
                <label>{this.props.t('type')}</label>
                <select className={'form-control'} onClick={e => {
                        let {transports} = this.state;
                        let s = e.target.value;

                        transports[index].group_id = s;
                        this.setState({transports}, () => {
                            this.props.applyChanges(this.getTransport());
                        });
                }}>
                    {selectedCountry.values.map(t => <option value={t.group_id}>
                        {t.group_name}
                    </option>)}
                </select>
            </>
        )
    }

    addNewDeliveryPoint() {
        let {transports} = this.state;
        let copyLast = Object.assign({}, transports[transports.length - 1]);
        transports.push(copyLast);
        this.setState({transports},() => {
            this.props.applyChanges(this.getTransport());
        });
    }

    deleteDeliveryPoint(index) {
        let {transports} = this.state;
        transports.splice(index, 1);
        this.setState({transports},() => {
            this.props.applyChanges(this.getTransport());
        });
    }

    renderTransports() {
        return this.state.transports.map( (t, index) => <div className="row mt-3">
            <div className="col-3">
                <label htmlFor="">{this.props.t('delivery_point')}</label>
                <br/>
                {index > 0 && <button
                    onClick={this.deleteDeliveryPoint.bind(this, index)}
                    className={'btn btn-danger btn-sm'}>{this.props.t('remove')}</button>}
                <button onClick={this.addNewDeliveryPoint.bind(this)} className={'btn btn-success btn-sm'}>{this.props.t('add')}</button>
            </div>
            <div className="col-3">
                <label>{this.props.t('country')}</label>
                <select onChange={e => {
                    let {transports} = this.state;
                    transports[index].selected_country = parseInt(e.target.value);
                    if(transports[index].selected_country !== 2 || transports[index].selected_country !== 4)
                    {
                        let selcountry = this.props.transports.find(x=>x.country_id == transports[index].selected_country);
                        let g = selcountry.values[0].group_id;
                        transports[index].group_id = g;
                    }
                    this.setState({transports})}
                } className={'form-control'}>
                    {this.props.transports.map(t => <option selected={t.country_id ===this.state.transports[index].selected_country} value={t.country_id}>
                        {t.country}
                    </option>)}
                </select>
            </div>
            <div className="col-3">
                {this.printSecondColumn(index)}
            </div>
            <div className="col-3">
                <label>{this.props.t('copies')}</label>
                <input
                    type="number"
                    min={0}
                    className={'form-control'}
                    value={this.state.transports[index].copies}
                    onBlur={e => {
                        this.props.applyChanges(this.getTransport(true));
                    }}
                    onChange={e => {
                        let {transports} = this.state;
                        transports[index].copies = e.target.value;
                        this.setState({transports},() => {
                            this.props.applyChanges(this.getTransport(false));
                        })
                    }}
                />
            </div>
        </div>)
    }

    getTransport(trigger = true) {
        //TODO:
        let copies = 0;
        for (let i=0;i < this.state.transports.length; i++)
        {
            copies += parseInt(this.state.transports[i].copies);
        }
        return {copies: copies, transport: this.state.transports, display_transport_price: this.state.display_transport_price, trigger: trigger};
    }

    render() {
        return (
            <div className={'card'}>
                <div className="card-body">
                    {this.renderTransports()}
                    <div className="row">
                        <div className="col-12 mt-1">
                            <hr/>
                            <input
                                checked={this.state.display_transport_price}
                                type="checkbox"
                                onClick={e => {
                                    this.setState({display_transport_price: !this.state.display_transport_price},() => {
                                        this.props.applyChanges(this.getTransport(false));
                                    })
                                } }
                            /> {this.props.t('display_the_transport_price_in_the_offer')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TransportForm);
