import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../../../api/NetworkClient';
import Loader from '../../../../../partial/Loader';
import Languages from "../../../../../constants/Languages";
import SimpleFieldsGenerator from '../../../../../helpers/SimpleFieldsGenerator';

class SettingsForm$ extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        price: {
            id: null,
            step: null,
            price: null,
        }
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if(id !== undefined)
        {
            this.load(id);
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`transport/bg/prices-settings/${id}`)
                .then(response => {
                    const price = response.price;
                    this.setState({price});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {price} = this.state;
        let url = '/transport/bg/prices-settings';
        if(price.id !== null)
        {
            url += '/' + price.id;
        }

        NetworkClient.post(url, price)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c=>{
                        this.props.history.push('/transport/bulgaria/price-settings');
                    })
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            Edit - BG Transport -V2 Settings Prices - Courier
                                <h1>{t('paper')} {t('color')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'step', type: 'number'}], 'price')()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'price', type: 'number'}], 'price')()}
                                    
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.price.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SettingsForm$);
