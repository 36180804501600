import React, {Component} from 'react'

import NetworkClient from '../../../api/NetworkClient';
import Swal from 'sweetalert2'
import Loader from '../../../partial/Loader';

export default class PaperTypesForm extends Component {
    state = {
        paper: {
            type: '',
            factor: '',
            bg_name: '',
            en_name: '',
            fr_name: '',
            de_name: '',
            us_name: '',
        },
        isLoading: false
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        if (id !== undefined) {
            this.load(id);
        }
    }

    load(id) {
        this.setState({isLoading: true});
        NetworkClient.get('papers/types/' + id)
            .then(response => {
                let paper = response;
                this.setState({paper: paper, isLoading: false});
            })
    }

    store() {
        let paper = this.state.paper;
        this.setState({isLoading: true});
        let url = 'papers/types';
        if (this.state.paper.id !== undefined) {
            url += '/' + this.state.paper.id;
        }

        NetworkClient.post(url, paper)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        this.props.history.push('/papers/types');
                    })
                this.setState({isLoading: false});
            }, error => {
                this.setState({isLoading: false});
            })
    }

    render() {
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1>Paper Create</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div class="card card-primary">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label>Type</label>
                                        <input
                                            type="text" className={"form-control"}
                                            placeholder="Type" value={this.state.paper.type}
                                            onChange={(e) => {
                                                let {paper} = this.state;
                                                paper.type = e.target.value;
                                                this.setState({paper})
                                            }}
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label>Factor</label>
                                        <input
                                            type="number" className={"form-control"}
                                            placeholder="Factor" value={this.state.paper.factor}
                                            onChange={(e) => {
                                                let {paper} = this.state;
                                                paper.factor = e.target.value;
                                                this.setState({paper})
                                            }}
                                        />
                                    </div>
                                    <hr/>
                                    <div class="form-group">
                                        <label>BG Name</label>
                                        <input
                                            type="text" className={"form-control"}
                                            placeholder="BG Name" value={this.state.paper.bg_name}
                                            onChange={(e) => {
                                                let {paper} = this.state;
                                                paper.bg_name = e.target.value;
                                                this.setState({paper});
                                            }}
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label>EN Name</label>
                                        <input
                                            type="text" className={"form-control"}
                                            placeholder="EN Name" value={this.state.paper.en_name}
                                            onChange={(e) => {
                                                let {paper} = this.state;
                                                paper.en_name = e.target.value;
                                                this.setState({paper});
                                            }}
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label>FR Name</label>
                                        <input
                                            type="text" className={"form-control"}
                                            placeholder="FR Name" value={this.state.paper.fr_name}
                                            onChange={(e) => {
                                                let {paper} = this.state;
                                                paper.fr_name = e.target.value;
                                                this.setState({paper});
                                            }}
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label>DE Name</label>
                                        <input
                                            type="text" className={"form-control"}
                                            placeholder="DE Name" value={this.state.paper.de_name}
                                            onChange={(e) => {
                                                let {paper} = this.state;
                                                paper.de_name = e.target.value;
                                                this.setState({paper});
                                            }}
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label>US Name</label>
                                        <input
                                            type="text" className={"form-control"}
                                            placeholder="US Name" value={this.state.paper.us_name}
                                            onChange={(e) => {
                                                let {paper} = this.state;
                                                paper.us_name = e.target.value;
                                                this.setState({paper});
                                            }}
                                        />
                                    </div>
                                    <hr/>
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.paper.id === undefined ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
