import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import Select from "react-select";
import JacketForm from "../quotes/components/JacketForm";
import BanderoleForm from "../quotes/components/BanderoleForm";
import InsertForm from "../quotes/components/InsertForm";
import TextFrom from "../quotes/components/TextFrom";
import CoverForm from "../quotes/components/CoverForm";
import ProductPropertiesQForm from "../quotes/components/ProductPropertiesQForm";
import Constants from '../../constants/Constants';
import ClothForm from "../quotes/components/ClothForm";
import QuoteInformationForm from "../quotes/components/QuoteInformationForm";
import PriceCorrectionForm from "../quotes/components/PriceCorrectionForm";
import BookmarkForm from "../quotes/components/BookmarkForm";
import CDForm from "../quotes/components/CDForm";
import CardboardForm from "../quotes/components/CardboardForm";
import EndpapersForm from "../quotes/components/EndpapersForm";
import QuotePriceInfo from "../quotes/info/QuotePriceInfo";
import AdditionalOptionsForm from "../quotes/components/AdditionalOptionsForm";
import TransportForm from "../quotes/components/TransportForm";
import Swal from "sweetalert2";

class DistributionTest extends Component {
    state = {
        isLoaded: false,
        pId: null,
        papersNew: [],
        product: null,
        html: '',
        isLoading: false,
        folding: '',
        length: '',
        height: '',
        tirage: '',
        binding: -1,
        back: 0,
        print: -1,
        finishing:-1,
        pantone: 0,
        cover_pantones_info: [],
        paper: -1,
        density: -1,
        flaps: 0,
        leftFlap: 0,
        rightFlap: 0,
        embossing:'',
        embossing_foil:'',
        punching: false,
        selektiven_lak: false,
        body: {
            num_pages: 0,
            print: -1,
            pantone: 0,
            paper: -1,
            density: "",
            nadpechaten_lak: "",
            embossing: {
                is_enabled: false
            },
            embossing_foils: {
                is_enabled: false
            },
            finishing: -1
        },
        insert: {
            num_pages: 0,
            print: -1,
            pantone: 0,
            paper: -1,
            density: "",
            nadpechaten_lak: ""
        },
        jacket: {
            paper_name_id: -1,
            paper_color_id: -1,
            density: -1
        },
        bindings: [],
        prints: [],
        pantones: [{id: 0, name: "0"},{id: 1, name: "1"},{id: 2, name: "2"},{id: 3, name: "3"},{id: 4, name: "4"}],
        //TODO: Add it to static classes later
        //pantones_types: [{id: 1, name: 'Стандарт'},{id: 2, name: 'Електриков'},{id: 3, name: 'Металиков'}],
        pantones_types: [{id: 1, name: 'Стандарт'},{id: 2, name: 'Електриков'},{id: 3, name: 'Металиков'}],
        finishings: [],
        papers: [],
        cover_densities: [],
        body_densities: [],
        insert_densities: [],
        embossings: [{id: 0, name: "0"},{id: 1, name: "1"},{id: 2, name: "2"},{id: 3, name: "3"},{id: 4, name: "4"}],
        embossing_foils: [{id: 0, name: "0"},{id: 1, name: "1"},{id: 2, name: "2"},{id: 3, name: "3"},{id: 4, name: "4"}],
        bindingsSelected: [],
        laces: [],
        heads: [],
        clients: [],
        categories: [],
        order_states: [],
        hunters: [],
        ams: [],
        priceCorrection: [],
        cd_types: [],
        foldingsSelected: [],
        cardboards: [],
        final_price: 0,
        prices: [],
        selectedProperties: [],
        lace: {
            is_enabled: false,
            selected: -1
        },
        cd: {
            is_enabled: false
        },
        printings: [],
        prepress: [],
        order_samples: [],
        transports: [],
        measure: 'mm',
        additional_options: null,
        transport: null,
        //Тези са само за да се извличат инициализиращите данни
        order_details: null,
        product_properties: null,
        initial_cover: null,
        initial_body: null,
        initial_insert: null,
        initial_jacket: null,
        initial_banderole: null,
        initial_bookmark: null,
        initial_cd: null,
        initial_additional_options: null,
        initial_transport: null,
        initial_cardboard: null,
        initial_endpapers: null,
        //calculator
        json_calculations: null,
        spine_type: 1,
        display_transport_price: false,
        overcharge: 0,
        is_shown_calculation: false
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const {t} = this.props;
        this.load();
        /*setTimeout(function(){
            this.setState({order_details: {name:'tttt'}},()=>{
                //this.setState({testt:'test'})
            })
            console.log('executed')
        }.bind(this), 2000);*/
    }

    load() {
        let pId = this.props.match.params.id;
        let orderId = this.props.match.params.orderId;
        if(pId === undefined)
        {
            pId = null;
        }

        this.setState({isLoading: true, pId});
        let dummy = 'dummy-api-call-fast-load';
        if(pId === null)
        {
            pId = 7;
        }

        let original = 'distribution-test/' + pId;
        let isEdit = orderId > 0;
        if(isEdit)
        {
            //TODO: Order Id
            original += ('/' + orderId);
        }

        NetworkClient.get(original)
            .then(response => {

                if(response.old_offer === true)
                {
                    this.setState({isLoading: false}, () => {
                        Swal.fire('Стара оферта, още не е готов трансформатора!', '', 'success')
                            .then(c => {
                                this.props.history.push('/orders/list');
                            })
                    })

                }
                else {

                let {bindings} = response;
                let {prints} = response;
                let {finishings} = response;
                let {papers} = response;
                let productInfo = response.product;
                let product = productInfo.product;
                //TODO: премахни после
                let papersNew = response.papers_new;
                let bindingsSelected = productInfo.bindingsSelected;
                let foldingsSelected = productInfo.foldingsSelected;
                let {laces} = response;
                let {pantones} = response;
                let lacesData = [];
                for (let ii = 0; ii < laces.length; ii++)
                {
                    let id = laces[ii].replace('.png','');
                    let name = laces[ii];
                    lacesData.push({id: id, name: <div>
                            <img src={Constants.BASE_URL + 'img/lace/' + name} /> <span>{id}</span>
                        </div>});
                }

                let heads = response.head;
                let headData = [];
                for (let ii = 0; ii < heads.length; ii++)
                {
                    let id = heads[ii].replace('.png','');
                    let name = heads[ii];
                    headData.push({id: id, name: <div>
                            <img src={Constants.BASE_URL + 'img/head_and_tails/' + name} /> <span>{id}</span>
                        </div>});
                }

                let {clients} = response;
                let {categories} = response;
                let {order_states} = response;
                //let filteredPapers = papers.map(p => { return {id: p.paper_name_id, name: p.name} });
                let {ams} = response;
                let {hunters} = response;
                let {cd_types} = response;
                let {cardboards} = response;
                let {printings} = response;
                let {prepress} = response;
                let {order_samples} = response;
                let {transports} = response;



                this.setState({
                    ams, hunters,order_states, categories, clients,
                    heads: headData,laces: lacesData, prints, bindings,
                    finishings, papers, product, bindingsSelected, papersNew,
                    cd_types, foldingsSelected, cardboards, printings, prepress,
                    order_samples, transports, pId: pId, pantones_types: pantones
                },() => {
                    if(!isEdit)
                    {
                        setTimeout(()=>{
                            this.setState({isLoaded: true,isLoading: false,})
                        },200);
                    }



                    if(isEdit)
                    {
                        let {order_details} = response;
                        let {priceCorrection} = response;
                        let {product_properties} = response;
                        let {cover} = response;
                        let {body} = response;
                        let {insert} = response;
                        let {jacket} = response;
                        let {banderole} = response;
                        let {bookmark} = response;
                        let {cd} = response;
                        let {additional_options} = response;
                        let {transport} = response;
                        let {cardboard} = response;
                        let {endpapers} = response;
                        let {display_transport_price} = response;
                        this.setState({
                            order_details,
                            priceCorrection,
                            product_properties,
                            display_transport_price,
                            back: product_properties.back,
                            initial_cover: cover,
                            initial_body: body,
                            initial_insert: insert,
                            initial_jacket: jacket,
                            initial_banderole: banderole,
                            initial_bookmark: bookmark,
                            initial_cd: cd,
                            initial_additional_options: additional_options,
                            initial_transport: transport,
                            initial_cardboard: cardboard,
                            initial_endpapers: endpapers
                        },()=>{
                            setTimeout(()=>{
                                this.setState({isLoaded: true,isLoading: false,})
                            },200);
                        });
                    }
                });
                }
            });

    }

    handleSimpleChange(e, fieldName) {
        let state = this.state;
        state[fieldName] = e.target.value;
        this.setState(state);
    }

    renderObshti()
    {
        return (
            <div className="row">
                <div className="col-12 text-center">
                    <hr/>
                    <b>Общи</b>
                    <hr/>
                </div>
                <div className="col-2">
                    Формат:
                </div>
                <div className="col-5">
                    <label>Широчина</label>
                    <input
                        className={"form-control"}
                        type="number"
                        placeholder={"Широчина"}
                        value={this.state.length}
                        onChange={e => this.handleSimpleChange(e, 'length')}
                    />
                </div>
                <div className="col-5">
                    <label>Височина</label>
                    <input
                        className={"form-control"}
                        type="number"
                        placeholder={"Височина"}
                        value={this.state.height}
                        onChange={e => this.handleSimpleChange(e, 'height')}
                    />
                </div>
                <div className="col-2 mt-3">
                    Тираж
                </div>
                <div className="col-10 mt-3">
                    <input
                        className={"form-control"}
                        type="number"
                        placeholder={"Тираж"}
                        value={this.state.tirage}
                        onChange={e => this.handleSimpleChange(e, 'tirage')}
                    />
                </div>

                <div className="col-2 mt-3">
                    Книговезане
                </div>
                <div className="col-10 mt-3">
                    <Select
                        value={this.state.bindings.find(c => c.id === this.state.binding)}
                        onChange={(selected)=>{
                            let state = this.state;
                            state.binding = selected.id;
                            this.setState(state);
                        }}
                        options={this.state.bindings}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-2 mt-3">
                    Гръбче
                </div>
                <div className="col-10 mt-3">
                    <input
                        className={"form-control"}
                        type="number"
                        placeholder={"Гръбче"}
                        value={this.state.back}
                        onChange={e => this.handleSimpleChange(e, 'back')}
                    />
                </div>
            </div>
        )
    }

    renderPantonInfoBody()
    {
        if(this.state.body.pantone == 0){
            return (<></>)
        }

        let ht = [];

        for (let i=0; i < this.state.body.pantone; i++)
        {
            ht.push(<><div className={"col-2 mt-3"}>
                {this.props.t('type')}
            </div>
                <div className={'col-5 mt-3'}>
                    <Select
                        onChange={(selected) => {
                        }}
                        options={this.state.pantones_types}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className={'col-5 mt-3'}>
                    <input
                        onChange={e=>{
                        }}
                        placeholder={this.props.t('pantone_number_show') + ' ' + (i+1)}
                        type={'text'}
                        className={'form-control'} />
                </div>
            </>)
        }

        return ht;
    }

    renderPantonInfoInsert()
    {
        if(this.state.insert.pantone == 0){
            return (<></>)
        }

        let ht = [];

        for (let i=0; i < this.state.insert.pantone; i++)
        {
            ht.push(<><div className={"col-2 mt-3"}>
                {this.props.t('type')}
            </div>
                <div className={'col-5 mt-3'}>
                    <Select
                        onChange={(selected) => {
                        }}
                        options={this.state.pantones_types}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className={'col-5 mt-3'}>
                    <input
                        onChange={e=>{
                        }}
                        placeholder={this.props.t('pantone_number_show') + ' ' + (i+1)}
                        type={'text'}
                        className={'form-control'} />
                </div>
            </>)
        }

        return ht;
    }

    renderPantonInfoKorica()
    {
        if(this.state.pantone == 0){
            return (<></>)
        }

        let ht = [];

        let cover_pantones_info = this.state.cover_pantones_info;
        //this.state.pantone - брой избрани пантони за корица, ще служи за ориентир в масива
        if(cover_pantones_info.length < this.state.pantone)
        {
            while(cover_pantones_info.length < this.state.pantone)
            {
                cover_pantones_info.push({type: 1, number: '', is_two_side: false});
            }
            this.setState({cover_pantones_info});
        }

        for (let i=0; i < this.state.pantone; i++)
        {
            ht.push(<><div className={"col-2 mt-3"}>
                {this.props.t('type')}
            </div>
                <div className={'col-4 mt-3'}>
                    <Select
                        value={this.state.pantones_types.find(p => p.id === cover_pantones_info[i].type)}
                        onChange={(selected) => {
                            let {cover_pantones_info} = this.state;
                            cover_pantones_info[i].type = selected.id;
                            this.setState({cover_pantones_info});
                        }}
                        options={this.state.pantones_types}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className={'col-4 mt-3'}>
                    <input
                        onChange={e=>{
                            let {cover_pantones_info} = this.state;
                            cover_pantones_info[i].number = e.target.value;
                            this.setState({cover_pantones_info});
                        }}
                        placeholder={this.props.t('pantone_number_show') + ' ' + (i+1)}
                        type={'text'}
                        className={'form-control'} />
                </div>
                <div className="col-2 mt-3">
                    <input
                        onChange={e=>{
                           let isChecked = e.target.checked;
                           let {cover_pantones_info} = this.state;
                           cover_pantones_info[i].is_two_side = isChecked;
                        }}
                        type="checkbox"/> Двустранно
                </div>
            </>)
        }

        return ht;
    }

    getKriloLimit() {
        let length = parseInt(this.state.length);
        let limit = 990;
        let gunki = 2;
        let removeFromFlaps = 10;
        let back = parseInt(this.state.back);
        let k = limit - (2 * length + gunki + (2*(length-removeFromFlaps)) + back);
        if(k < 0)
        {
            let maxAllowedFlap = length-removeFromFlaps;
            while (limit -(2 * length + gunki + (2*(maxAllowedFlap)) + back) < 0)
            {
                let res = limit -(2 * length + gunki + (2*(maxAllowedFlap)) + back);
                maxAllowedFlap--;
            }

            return maxAllowedFlap;
        }

        let result = length - removeFromFlaps;
        if(result > 0)
        {
            return result;
        }

        return 0;
    }

    hasFlapsError() {
        return (this.state.length > 0 && this.state.flaps > 0 && (this.state.leftFlap < 50 || this.state.leftFlap > this.getKriloLimit() ) )
        || (this.state.length > 0 && this.state.flaps > 0 && (this.state.rightFlap < 50 || this.state.rightFlap > this.getKriloLimit() ));
    }

    renderKorica() {
        return (<div className={"row"}>
            <div className="col-12 text-center">
                <hr/>
                <b>Корица</b>
                <hr/>
            </div>
            <div className="col-2">
                Печат
            </div>
            <div className="col-10">
                <Select
                    value={this.state.prints.find(c => c.id === this.state.print)}
                    onChange={(selected)=>{
                        let state = this.state;
                        state.print = selected.id;
                        this.setState(state);
                    }}
                    options={this.state.prints.filter(x=>[19,17,15,18,51,52].includes(x.id))}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-2 mt-3">
                Пантон
            </div>
            <div className="col-10 mt-3">
                <Select
                    value={this.state.pantones.find(c => c.id === this.state.pantone)}
                    onChange={(selected)=>{
                        let state = this.state;
                        state.pantone = selected.id;
                        this.setState(state);
                    }}
                    options={this.state.pantones}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            {this.renderPantonInfoKorica()}
            <div className="col-2 mt-3">
                Ламинат
            </div>
            <div className="col-6 mt-3">
                <Select
                    value={this.state.finishings.find(c => c.id === this.state.finishing)}
                    onChange={(selected)=>{
                        let state = this.state;
                        state.finishing = selected.id;
                        this.setState(state);
                    }}
                    options={this.state.finishings}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-2 mt-3">
                <input
                    type="checkbox"
                    checked={this.state.selektiven_lak}
                    onChange={(e)=>{
                        this.setState({selektiven_lak: e.target.checked})
                    }}
                /> Селективен лак
            </div>
            <div className="col-2 mt-3">
                <input
                    type="checkbox"
                    checked={this.state.obemen_lak}
                    onChange={(e)=>{
                        this.setState({obemen_lak: e.target.checked})
                    }}
                /> {this.props.t('obemen_lak')} (TODO: не смята още)
            </div>
            <div className="col-2 mt-3">
                Хартия
            </div>
            <div className="col-10 mt-3">
                <Select
                    value={this.state.papers.find(c => c.paper_name_id === this.state.paper)}
                    onChange={(selected)=>{
                        let state = this.state;
                        state.paper = selected.paper_name_id;
                        state.cover_densities = selected.densities;
                        state.density = null;
                        this.setState(state);
                    }}
                    options={this.state.papers}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.paper_name_id}
                />
            </div>
            <div className="col-2 mt-3">
                Грамаж
            </div>
            <div className={"col-10 mt-3"}>
                <Select
                    value={this.state.cover_densities.find(c => c.name === this.state.density)}
                    onChange={(selected)=>{
                        let state = this.state;
                        state.density = selected.name;
                        this.setState(state);
                    }}
                    options={this.state.cover_densities}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-2 mt-3">
                Крила
            </div>
            <div className="col-4 mt-3">
                <input
                    className={"form-control"}
                    type="number"
                    placeholder={"Крила"}
                    value={this.state.flaps}
                    onChange={e => {
                        if(e.target.value >= 0)
                        {
                            this.handleSimpleChange(e, 'flaps')
                            this.setState({leftFlap: e.target.value/2,rightFlap: e.target.value/2});
                        }
                    }}
                />

            </div>
            <div className="col-3 mt-3">
                <input
                    className={"form-control"}
                    type="number" value={this.state.leftFlap}
                    onChange={(e)=>{
                        if(e.target.value >= 0)
                        {
                            let totalFlaps = parseInt(e.target.value) + parseInt(this.state.rightFlap);
                            this.setState({flaps: totalFlaps, leftFlap: e.target.value})
                        }
                    }}
                />
                { this.state.length > 0 && this.state.flaps > 0 && (this.state.leftFlap < 50 || this.state.leftFlap > this.getKriloLimit() )  &&
                <div className={"col-12 mt-3 text-red"}>Ограничението на крилото е от 50 до {this.getKriloLimit()}</div> }

            </div>
            <div className="col-3 mt-3">
                <input
                    className={"form-control"} type="number"
                    value={this.state.rightFlap} onChange={(e)=>{
                        if(e.target.value > 0)
                        {
                            let totalFlaps = parseInt(e.target.value) + parseInt(this.state.leftFlap);
                            this.setState({flaps: totalFlaps, rightFlap: e.target.value})
                        }
                }}
                />
                { this.state.length > 0 && this.state.flaps > 0 && (this.state.rightFlap < 50 || this.state.rightFlap > this.getKriloLimit() )  &&
                <div className={"col-12 mt-3 text-red"}>Ограничението на крилото е от 50 до {this.getKriloLimit()}</div> }
            </div>

            <div className="col-2 mt-3">
                Сухо преге
            </div>
            <div className="col-10 mt-3">
                <Select
                    value={this.state.embossings.find(c => c.id === this.state.embossing.number_of_stamps)}
                    onChange={(selected)=>{
                        let state = this.state;
                        let e = selected.id;
                        if(e > 0)
                        {
                            state.embossing = {
                                is_enabled: true,
                                number_of_stamps: e
                            }
                        }
                        else
                        {
                            state.embossing = {
                                is_enabled: false,
                                number_of_stamps: 0
                            }
                        }

                        this.setState(state);
                    }}
                    options={this.state.embossings}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-2 mt-3">
                Топъл печат
            </div>
            <div className="col-10 mt-3">
                <Select
                    value={this.state.embossing_foils.find(c => c.id === this.state.embossing_foil.number_of_stamps)}
                    onChange={(selected)=>{
                        let state = this.state;
                        let e = selected.id;
                        if(e > 0)
                        {
                            state.embossing_foil = {
                                is_enabled: true,
                                number_of_stamps: e
                            }
                        }
                        else
                        {
                            state.embossing_foil = {
                                is_enabled: false,
                                number_of_stamps: 0
                            }
                        }

                        this.setState(state);
                    }}
                    options={this.state.embossing_foils}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-2 mt-3">
                Щанца(punching)
            </div>
            <div className="col-10 mt-3">
                <input
                    type="checkbox"
                    checked={this.state.punching}
                    onChange={(e)=>{
                        this.setState({punching: e.target.checked})
                    }}
                />
            </div>
        </div>)
    }

    renderTqlo()
    {
        return (<div className={"row"}>
            <div className="col-12 text-center">
                <hr/>
                <b>Тяло</b>
                <hr/>
            </div>
            <div className="col-2">
                Бр. стр
            </div>
            <div className="col-10">
                <input
                    type="number" className={"form-control"}
                    onChange={(e)=>{
                        let {body} = this.state;
                        body.num_pages = e.target.value;
                        this.setState({body});
                    }}
                />
                {this.state.body.num_pages % 4 !== 0 &&  <div style={{color: 'red'}}>
                        Броя страници трябва да бъде кратен на 4!
                    </div>}
            </div>
            <div className="col-2 mt-3">
                Печат
            </div>
            <div className="col-10 mt-3">
                <Select
                    value={this.state.prints.find(c => c.id === this.state.body.print)}
                    onChange={(selected)=>{
                        let {body} = this.state;
                        body.print = selected.id;
                        this.setState({body});
                    }}
                    options={this.state.prints.filter(x=> [15,16,17,52].includes(x.id))}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-2 mt-3">
                Пантон
            </div>
            <div className="col-10 mt-3">
                <Select
                    value={this.state.pantones.find(c => c.id === this.state.body.pantone)}
                    onChange={(selected)=>{
                        let {body} = this.state;
                        body.pantone = selected.id;
                        this.setState({body});
                    }}
                    options={this.state.pantones}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            {this.renderPantonInfoBody()}
            <div className="col-2 mt-3">
                Хартия
            </div>
            <div className="col-10 mt-3">
                <Select
                    value={this.state.papers.find(c => c.paper_name_id === this.state.body.paper)}
                    onChange={(selected)=>{
                        let state = this.state;
                        state.body.paper = selected.paper_name_id;
                        state.body_densities = selected.densities;
                        state.body.density = null;
                        this.setState(state);
                    }}
                    options={this.state.papers}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.paper_name_id}
                />
            </div>
            <div className="col-2 mt-3">
                Грамаж
            </div>
            <div className={"col-10 mt-3"}>
                <Select
                    value={this.state.body_densities.find(c => c.name === this.state.body.density)}
                    onChange={(selected)=>{
                        let state = this.state;
                        state.body.density = selected.name;
                        this.setState(state);
                    }}
                    options={this.state.body_densities}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-2 mt-3">
                Надпечатен лак
            </div>
            <div className="col-10 mt-3">
                <input
                    type="checkbox"
                    onChange={(e)=>{
                        let {body} = this.state;
                        body.nadpechaten_lak = e.target.checked;
                        this.setState({body})
                    }}
                    checked={this.state.body.nadpechaten_lak}
                />
            </div>
        </div>)
    }

    renderInsert()
    {
        return (<div className={"row"}>
            <div className="col-12 text-center">
                <hr/>
                <b>Вложка</b>
                <hr/>
            </div>
            <div className="col-2">
                Бр. стр
            </div>
            <div className="col-10">
                <input
                    type="number" className={"form-control"}
                    onChange={(e)=>{
                        let {insert} = this.state;
                        insert.num_pages = e.target.value;
                        this.setState({insert});
                    }}
                />
                {this.state.insert.num_pages % 4 !== 0 &&  <div style={{color: 'red'}}>
                    Броя страници трябва да бъде кратен на 4!
                </div>}
            </div>
            <div className="col-2 mt-3">
                Печат
            </div>
            <div className="col-10 mt-3">
                <Select
                    value={this.state.prints.find(c => c.id === this.state.insert.print)}
                    onChange={(selected)=>{
                        let {insert} = this.state;
                        insert.print = selected.id;
                        this.setState({insert});
                    }}
                    options={this.state.prints.filter(x=> [15,16,17,52].includes(x.id))}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-2 mt-3">
                Пантон
            </div>
            <div className="col-10 mt-3">
                <Select
                    value={this.state.pantones.find(c => c.id === this.state.insert.pantone)}
                    onChange={(selected)=>{
                        let {insert} = this.state;
                        insert.pantone = selected.id;
                        this.setState({insert});
                    }}
                    options={this.state.pantones}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            {this.renderPantonInfoInsert()}
            <div className="col-2 mt-3">
                Хартия
            </div>
            <div className="col-10 mt-3">
                <Select
                    value={this.state.papers.find(c => c.paper_name_id === this.state.insert.paper)}
                    onChange={(selected)=>{
                        let state = this.state;
                        state.insert.paper = selected.paper_name_id;
                        state.insert_densities = selected.densities;
                        state.insert.density = null;
                        this.setState(state);
                    }}
                    options={this.state.papers}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.paper_name_id}
                />
            </div>
            <div className="col-2 mt-3">
                Грамаж
            </div>
            <div className={"col-10 mt-3"}>
                <Select
                    value={this.state.insert_densities.find(c => c.name === this.state.insert.density)}
                    onChange={(selected)=>{
                        let state = this.state;
                        state.insert.density = selected.name;
                        this.setState(state);
                    }}
                    options={this.state.insert_densities}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-2 mt-3">
                Надпечатен лак
            </div>
            <div className="col-10 mt-3">
                <input
                    type="checkbox"
                    onChange={(e)=>{
                        let {insert} = this.state;
                        insert.nadpechaten_lak = e.target.checked;
                        this.setState({insert})
                    }}
                    checked={this.state.insert.nadpechaten_lak}
                />
            </div>
        </div>)
    }


    getCover() {
        //Това е за distribution test
        if(this.state.pId === null)
        {
            return {
                    paper_name_id: this.state.paper,
                    density: this.state.density,
                    print: this.state.print,
                    finishing: this.state.finishing,
                    embossing: this.state.embossing,
                    embossing_foil: this.state.embossing_foil,
                    selektiven_lak: this.state.selektiven_lak,
                    flaps: this.state.flaps,
                    punching: this.state.punching,
                    pantone: this.state.pantone,
                    cover_pantones_info: this.state.cover_pantones_info
                }
        }

        if(this.state.pId == 5 || this.state.pId == 6 || this.state.pId == 7)
        {
            return null;
        }

        if(this.state.pId == 8)
        {
            return this.state.cover;
        }

        return {
            paper_name_id: this.state.cover.paper,
            density: this.state.cover.density,
            print: this.state.cover.print,
            finishing: this.state.cover.finishing,
            embossing: this.state.cover.embossing,
            embossing_foil: this.state.cover.embossing_foil,
            selektiven_lak: this.state.cover.selektiven_lak,
            flaps: this.state.cover.flaps,

            punching: this.state.cover.punching,
            pantone: this.state.cover.pantone,
            cover_pantones_info: this.state.cover.cover_pantones_info,
            //
            paper_color_id: this.state.cover.paper_color_id,
            properties: this.state.cover.properties,
            num_pages: this.state.cover.num_pages,
            nadpechaten_lak: this.state.nadpechaten_lak,
            obemen_lak: this.state.cover.obemen_lak,
            leftFlap: this.state.leftFlap,
            rightFlap: this.state.rightFlap,
            is_enabled: this.state.cover.is_enabled
        }
    }

    calculate(callback = null) {
        //Премахаме ненужните пантони
        let {cover_pantones_info} = this.state;
        let totalCoverPantones = this.state.pantone;
        while(cover_pantones_info.length > totalCoverPantones)
        {
            cover_pantones_info.splice(cover_pantones_info.length-1, 1);
        }

        let cover = this.getCover();
        let data = {
            height: this.state.height,
            length: this.state.length,
            binding: this.state.binding,
            body: {
                paper_name_id: this.state.body.paper,
                density: this.state.body.density,
                num_pages: this.state.body.num_pages,
                print: this.state.body.print,
                nadpechaten_lak: this.state.body.nadpechaten_lak,
                pantone: this.state.body.pantone,
                embossing: this.state.body.embossing,
                embossing_foils: this.state.body.embossing_foils,
                finishing: this.state.body.finishing
            },
            insert: {
                paper_name_id: this.state.insert.paper,
                density: this.state.insert.density,
                num_pages: this.state.insert.num_pages,
                print: this.state.insert.print,
                nadpechaten_lak: this.state.insert.nadpechaten_lak,
                pantone: this.state.insert.pantone
            },
            cover: cover,
            endpapers: this.state.endpapers,
            cardboard: this.state.cardboard,
            spine_type: this.state.spine_type,
            tirage: this.state.tirage,
            back: this.state.back,
            packaging: this.state.packaging,
            selectedProperties: this.state.selectedProperties,
            lace: this.state.lace,
            head: this.state.head,
            cd: this.state.cd,
            folding: this.state.folding,
            p_id: this.state.pId,
            orderData: this.getStoreItem()
        }

        this.setState({isLoading: true})
        NetworkClient.post('distribution-test', data)
            .then(response => {
                let {html} = response;
                let {spine} = response;
                let {final_price} = response;
                let {prices} = response;
                let {json_calculations} = response;
                let {overcharge} = response;
                this.setState({isLoading: false, html, back: spine, final_price, prices, json_calculations, overcharge}, () => {
                    if(callback !== null) {
                        callback();
                    }
                })
            }, error => {
                this.setState({isLoading: false})
            })
    }

    getStoreItem() {
        let product_properties = {
            length: this.state.length,
            height: this.state.height,
            tirage: this.state.tirage,
            binding: this.state.binding,
            packaging: this.state.packaging,
            lace: this.state.lace,
            head: this.state.head,
            bookmark: this.state.bookmark,
            selectedProperties: this.state.selectedProperties,
            folding: this.state.folding,
            measure: this.state.measure,
            back: this.state.back,
            comment: this.state.comment,
            spine_type: this.state.spine_type
        }

        let cover = this.getCover();

        let item = {
            order_details: this.state.order_details,
            priceCorrection: this.state.priceCorrection,
            product_properties: product_properties,
            cover: cover,
            body: this.state.body,
            insert: this.state.insert,
            jacket: this.state.jacket,
            banderole: this.state.banderole,
            bookmark: this.state.bookmark,
            cd: this.state.cd,
            additional_options: this.state.additional_options,
            transport: this.state.transport,
            cardboard: this.state.cardboard,
            endpapers: this.state.endpapers,
            product_id: this.state.pId,
            client_id: this.state.order_details.client.id,
            name: this.state.order_details.name,
            price: 0,//todo
            price_final: 0,//todo
            budget: this.state.order_details.budget,
            size_length: this.state.length,
            size_height: this.state.height,
            copies: this.state.tirage,
            overcharge: 0,//todo
            discount: 0,//todo
            isbn: this.state.additional_options.isbn,
            printing_id: this.state.additional_options.printing.id,
            sample_id: this.state.additional_options.sample.id,
            barcode: 9999,//todo?
            preparer_id: this.state.additional_options.prepress.id,
            order_state_id: this.state.order_details.selectedOrderState,
            show_price: 0,
            measure: this.state.measure,
            is_front_end: false,
            json_calculations: this.state.json_calculations,
            display_transport_price: this.state.display_transport_price
        }

        return item;
    }

    store() {

        if(this.hasFlapsError())
        {
            alert(this.props.t('flaps_limit_store'))
            return;
        }


        this.setState({isLoading: true});
        let item = this.getStoreItem();

        let orderId = this.props.match.params.orderId;
        let url = 'orders';
        if(orderId > 0)
        {
            url += '/' + orderId;
        }

        NetworkClient.post(url, item).then(response => {
            this.setState({isLoading: false}, () => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        this.props.history.push('/orders/list');
                    })
            });
        }, error => {
            this.setState({isLoading: false});
        })
    }

    hasElement(elementName) {
        let {product} = this.state;
        if(product === null) { return false;}

        if(elementName === 'cd')
        {
            return product.propertyTypes.findIndex(pt => pt.id === 67) >= 0;
        }

        return product.components.findIndex( c => c.name === elementName ) >= 0;
    }

    getElement(elementName) {
        let {product} = this.state;
        if(product === null) { return null;}
        return product.components.find( c => c.name === elementName );
    }

    handleJacketChange(jacket) {
        this.setState({jacket}, () => {
            if(this.state.isLoaded === true && jacket.trigger)
            {
                this.calculate();
            }
        });
    }

    handleBanderoleChange(banderole)
    {
        this.setState({banderole},() => {
            if(this.state.isLoaded === true && banderole.trigger)
            {
                this.calculate();
            }
        });
    }

    handleInsertChange(insert)
    {
        this.setState({insert},()=>{
            if(this.state.isLoaded === true && insert.trigger)
            {
                this.calculate();
            }
        });
    }

    handleTextChange(body)
    {
        this.setState({body}, () => {
            if(this.state.isLoaded === true && body.trigger)
            {
                this.calculate();
            }
        })
    }

    handleCoverChange(cover)
    {
        this.setState({cover, flaps: cover.flaps, leftFlap: cover.leftFlap, rightFlap: cover.rightFlap},()=>{
            if(this.state.isLoaded === true && cover.trigger)
            {
                this.calculate();
            }
        });
    }

    handleProductPropertiesChange(properties) {
        this.setState({
            length: properties.length,
            height: properties.height,
            binding: properties.binding,
            packaging: properties.packaging,
            lace: properties.lace,
            head: properties.head,
            bookmark: properties.bookmark,
            selectedProperties: properties.selectedProperties,
            folding: properties.folding,
            measure: properties.measure,
            comment: properties.comment,
            spine_type: properties.spine_type
        },() => {
            if(this.state.isLoaded === true && properties.trigger === true)
            {
                this.calculate();
            }
        });
    }

    handleClothChange(cover)
    {
        this.setState({cover},()=>{
            if(this.state.isLoaded === true && cover.trigger === true)
            {
                this.calculate();
            }
        })
    }

    handlePriceCorrectionChange(priceCorrection)
    {
        this.setState({priceCorrection: priceCorrection.priceCorrection},()=>{
            if(this.state.isLoaded === true && priceCorrection.trigger === true)
            {
                this.calculate();
            }
        })
    }

    handleBookmarkChange(bookmark)
    {
        this.setState({bookmark},()=>{
            if(this.state.isLoaded === true && bookmark.trigger)
            {
                this.calculate();
            }
        })
    }

    handleCDChange(cd)
    {
        this.setState({cd},()=>{
            if(this.state.isLoaded === true && cd.trigger)
            {
                this.calculate();
            }
        })
    }

    handleCardboardChange(cardboard)
    {
        this.setState({cardboard},()=>{
            if(this.state.isLoaded === true)
            {
                this.calculate();
            }
        });
    }

    handleEndpapersChange(endpapers)
    {
        this.setState({endpapers},()=>{
            if(this.state.isLoaded === true)
            {
                this.calculate();
            }
        });
    }

    handleTransportChange(transport) {
        this.setState({tirage: transport.copies, transport: transport.transport, display_transport_price: transport.display_transport_price},()=>{
            if(this.state.isLoaded === true && transport.trigger)
            {
                this.calculate();
            }
        })
    }

    handleInformationQuoteChange(order_details)
    {
        this.setState({order_details},() => {
            if(this.state.isLoaded === true && order_details.trigger)
            {
                this.calculate();
            }
        })
    }

    handleAdditionalOptionsChange(additional_options)
    {
        this.setState({additional_options})
    }

    renderForTests(){
        if(this.state.pId !== null)
        {
            return (<></>)
        }

        return (<div className={'card'}>
            <div className={'card-body'}>
                {this.renderObshti()}
                {this.renderKorica()}
                {this.renderTqlo()}
                {this.renderInsert()}
            </div>
            </div>)
    }

    renderQuote(t) {
        if(this.state.pId === null)
        {
            return (<></>)
        }

        return (<>
            <QuoteInformationForm
                t={t}
                clients={this.state.clients}
                categories={this.state.categories}
                order_states={this.state.order_states}
                hunters={this.state.hunters}
                ams={this.state.ams}
                initial_state={this.state.order_details}
                applyChanges={order_details => this.handleInformationQuoteChange.bind(this)(order_details)}
                overcharge={this.state.overcharge}
            />

            <PriceCorrectionForm
                t={t}
                initial_state={this.state.priceCorrection}
                applyChanges={priceCorrection => this.handlePriceCorrectionChange.bind(this)(priceCorrection)}
            />

            <ProductPropertiesQForm
                applyChanges={properties => this.handleProductPropertiesChange.bind(this)(properties)}
                t={t}
                bindingsSelected={this.state.bindingsSelected}
                back={this.state.back}
                product={this.state.product}
                laces={this.state.laces}
                heads={this.state.heads}
                foldingsSelected={this.state.foldingsSelected}
                isHiddenBack={this.state.pId == 6 || this.state.pId == 7 || this.state.pId == 5}
                hasBackType={this.state.pId == 8}
                measure={this.state.measure}
                initial_state={this.state.product_properties}
            />
        {
            this.hasElement('cloth') &&
            <ClothForm
                applyChanges={cover => this.handleClothChange.bind(this)(cover)}
                papers={this.state.papersNew}
                t={t}
                element={this.getElement('cloth')}
                measure={this.state.measure}
                length={this.state.length}
                initial_state={this.state.initial_cover}
                pantones={this.state.pantones_types}
            />
        }
        {
                    this.state.cardboards.length > 0 &&
                    <CardboardForm
                        applyChanges={cardboard => this.handleCardboardChange.bind(this)(cardboard)}
                        t={t}
                        cardboards={this.state.cardboards}
                        measure={this.state.measure}
                        initial_state={this.state.initial_cardboard}
                        pantones={this.state.pantones_types}
                    />
        }
                {
                    this.hasElement('endpapers') &&
                    <EndpapersForm
                        applyChanges={endpapers => this.handleEndpapersChange.bind(this)(endpapers)}
                        papers={this.state.papersNew}
                        t={t}
                        element={this.getElement('endpapers')}
                        isTwoSidePantones={true}
                        measure={this.state.measure}
                        initial_state={this.state.initial_endpapers}
                        pantones={this.state.pantones_types}
                    />
                }
        {
            this.hasElement('cover') &&
            <CoverForm
                applyChanges={cover => this.handleCoverChange.bind(this)(cover)}
                papers={[...this.state.papersNew]}
                t={t}
                element={this.getElement('cover')}
                length={this.state.length}
                flapLimit={()=>{return this.getKriloLimit(); }}
                measure={this.state.measure}
                initial_state={this.state.initial_cover}
                pantones={this.state.pantones_types}
            />
        }
        {
            this.hasElement('text') &&
            <TextFrom
                applyChanges={text => this.handleTextChange.bind(this)(text)}
                papers={this.state.papersNew}
                t={t}
                isTwoSidePantones={this.state.pId == 6 || this.state.pId == 5 || this.state.pId == 7}
                element={this.getElement('text')}
                isOnePageBody={this.state.pId == 6 || this.state.pId == 5 || this.state.pId == 7}
                isHiddenClientPaper={this.state.pId == 6 || this.state.pId == 7 || this.state.pId == 5}
                measure={this.state.measure}
                initial_state={this.state.initial_body}
                pantones={this.state.pantones_types}
            />
        }

        {
            this.hasElement('insert') &&
            <InsertForm
                applyChanges={insert => this.handleInsertChange.bind(this)(insert)}
                papers={this.state.papersNew}
                t={t}
                element={this.getElement('insert')}
                measure={this.state.measure}
                initial_state={this.state.initial_insert}
                pantones={this.state.pantones_types}
            />
        }
        {
            this.hasElement('jacket') &&
            <JacketForm
                applyChanges={jacket => this.handleJacketChange.bind(this)(jacket)}
                papers={this.state.papersNew}
                t={t}
                element={this.getElement('jacket')}
                measure={this.state.measure}
                initial_state={this.state.initial_jacket}
                pantones={this.state.pantones_types}
            />
        }
        {
            this.hasElement('banderole') &&
            <BanderoleForm
                applyChanges={banderole => this.handleBanderoleChange.bind(this)(banderole)}
                papers={this.state.papersNew}
                t={t}
                element={this.getElement('banderole')}
                measure={this.state.measure}
                initial_state={this.state.initial_banderole}
                pantones={this.state.pantones_types}
            />
        }
                {this.hasElement('bookmark') &&
                <BookmarkForm
                    applyChanges={bookmark => this.handleBookmarkChange.bind(this)(bookmark)}
                    papers={this.state.papersNew}
                    t={t}
                    element={this.getElement('bookmark')}
                    measure={this.state.measure}
                    initial_state={this.state.initial_bookmark}
                    pantones={this.state.pantones_types}
                />}
                {this.hasElement('cd') && <CDForm
                    applyChanges={cd => this.handleCDChange.bind(this)(cd)}
                    t={t}
                    cd_types={this.state.cd_types}
                    measure={this.state.measure}
                    initial_state={this.state.initial_cd}
                />}
                <AdditionalOptionsForm
                    applyChanges={additional_options => this.handleAdditionalOptionsChange.bind(this)(additional_options)}
                    t={t}
                    printings={this.state.printings}
                    prepress={this.state.prepress}
                    order_samples={this.state.order_samples}
                    initial_state={this.state.initial_additional_options}
                />
                <TransportForm
                    t={t}
                    transports={this.state.transports}
                    applyChanges={transport => this.handleTransportChange.bind(this)(transport)}
                    initial_state={this.state.initial_transport}
                    display_transport_price={this.state.display_transport_price}
                />
                <QuotePriceInfo
                    prices={this.state.prices}
                />

        </>
        )
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    {t('Distribution')}
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <button onClick={e => {
                                                    this.setState({is_shown_calculation: !this.state.is_shown_calculation})
                                                }} className={'btn btn-info btn-sm'}>
                                                    {this.state.is_shown_calculation ?
                                                        <i className="fa fa-window-minimize" aria-hidden="true"/> :
                                                        <i className="fa fa-plus" aria-hidden="true"/>}
                                                </button>
                                            </div>
                                        </div>
                                        <div className={"row "  + (this.state.is_shown_calculation ? '' : 'd-none')}>
                                            <div className="col-12">Резултат</div>
                                            <div className="col-12" dangerouslySetInnerHTML={{__html: this.state.html}} />
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div className="col-12">
                                                <button
                                                    onClick={()=>{
                                                        this.calculate();
                                                    }}
                                                    className={"btn btn-info btn-sm"}>Калкулирай</button>
                                            </div>
                                        </div>
                                        <hr/>

                                        <hr/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className={"col-" + (this.state.pId !== null ? '9' : '12')}>
                                {this.renderForTests()}
                                {this.renderQuote(t)}
                            </div>
                            <div className={"col-3 " +  (this.state.pId !== null ? '' : 'd-none')}>
                                <div className="card mt-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-8">
                                                price
                                            </div>
                                            <div className="col-4">
                                                {this.state.final_price} &euro;
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div className="col-12">
                                                <button
                                                    onClick={() => {
                                                        this.calculate(this.store.bind(this))
                                                    }}
                                                    className={'btn btn-info btn-sm'}>{this.props.t('save')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(DistributionTest);
