import React, { Component } from 'react';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Swal from 'sweetalert2';
import {Link} from 'react-router-dom';
import {MDBDataTable} from 'mdbreact';
import DataTableInitDataGenerator from '../../../helpers/DataTableInitDataGenerator';
import { withTranslation } from 'react-i18next';

class PaperTypesList extends Component {
  isInited  = false;
  constructor(props) {
    super(props);
  }
  state = {
    types: [],
    isLoading: false,
    data: {
        columns: [],
        rows: []
    }
  }

  load() {
      this.setState({isLoading: true});
      NetworkClient.get('papers/types').then(response => {
        let data = {
            columns: this.state.data.columns,
            rows: response.types
        }
        let rows = [];
        for(let i = 0; i < response.types.length; i++)
        {
            let t = response.types[i];
            let action = <div>
                          <Link to={`/papers/types/edit/${t.id}`} className={"btn btn-info btn-sm"}>
                            <i className={"fa fa-pen"} />
                          </Link>
                          <button onClick={()=>{this.delete(t.id, i)}} className={"btn btn-danger btn-sm d-none"}>
                            <i className={"fa fa-times"} />
                          </button>
                        </div>
            t.action = action;
            rows.push(t);
        }
        console.log(rows);
        data.rows = rows;
        this.setState({isLoading: false, data: data});
      })
  }

  delete(id, index) {
        const {t} = this.props;
        Swal.fire({
        title: t('are-you-sure'),
        text: t('you-wont-be-able-to-revert-this'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('yes-delete-it')
      }).then((result) => {
        if (result.value) {
            this.setState({isLoading: true},()=>{
                NetworkClient.delete('papers/types/' + id)
                .then(response => {
                    let {data} = this.state;
                    let indexToRemove = data.rows.findIndex(r => r.id === id);
                    data.rows.splice(indexToRemove, 1);
                    this.setState({data: {columns:data.columns,rows:data.rows}, isLoading: false});
                })
            });

        }
      })
  }

  componentWillReceiveProps() {
    this.componentDidMount();
  }

  componentDidMount() {
    const {t, i18n} = this.props;
    this.setState({data: DataTableInitDataGenerator(['id','type','action'], t)})
    this.load();
  }

  render() {
    const {t, i18n} = this.props;
    return (
      <div>
        <Loader isLoading={this.state.isLoading} />
        <div class="content-header">
            {t('papers')} {t('type')}
        </div>

        <div class="content">
            <div class="container-fluid">
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className="card card-primary">
                            <div className="card-body">
                            <Link to={'/papers/types/create'} className={"btn btn-info btn-sm mb-3"}>
                            {t('create')} <i className={"fa fa-plus"}></i>
                            </Link>
                            <MDBDataTable
                                striped
                                bordered
                                hover
                                entriesOptions={[5, 20, 25]}
                                entries={5}
                                pagesAmount={4}
                                data={this.state.data}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(PaperTypesList);
