import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../../api/NetworkClient';
import Loader from '../../../../partial/Loader';
import Languages from "../../../../constants/Languages";
import SimpleFieldsGenerator from '../../../../helpers/SimpleFieldsGenerator';

class TransportRatioForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        ratio: {
            id: null,
            value: null

        }
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`transport/settings/ratio`)
                .then(response => {
                    const ratio = response.ratio;
                    this.setState({ratio});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }


    store() {
        this.setState({isLoading: true});
        const {ratio} = this.state;
        let url = 'transport/settings/ratio';


        NetworkClient.post(url, ratio)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c=>{
                        // this.props.history.push('/link');
                    })
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
       console.log(this.state.ratio.ratio);
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('ratio')} </h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {SimpleFieldsGenerator.bind(this,t,[{name: 'value', type: 'text'}],"ratio")()}

                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.ratio.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TransportRatioForm);
