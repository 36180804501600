import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../../../api/NetworkClient';
import Loader from '../../../../../partial/Loader';
import Languages from "../../../../../constants/Languages";
import SimpleFieldsGenerator from '../../../../../helpers/SimpleFieldsGenerator';

class TaxFuelForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        value: {
            id: null,
            value: null,
        }
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // const id = this.props.match.params.id;
        this.load();
    }

    load() {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`transport/uk/taxes/fuel`)
                .then(response => {
                    const value = response.value;
                    this.setState({value});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {value} = this.state;
        let url = 'transport/uk/taxes/fuel';
        if(value.id !== null)
        {
            url += '/' + value.id;
        }

        NetworkClient.post(url, value)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c=>{
                        // this.props.history.push('/link');
                    })
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>UK - {t('transport')} {t('tax')} {t('fuel')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {SimpleFieldsGenerator.bind(this, t, [{name: "value", type:"number"}], "value")()}
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.value.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TaxFuelForm);
