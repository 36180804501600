import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import SwalDelete from '../../../helpers/SwalDelete';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Languages from "../../../constants/Languages";
import Select from "react-select";
import SimpleFieldsWithColumnsGenerator from "../../../helpers/SimpleFieldsWithColumnsGenerator";

class ClientsForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        client: {
            id: null,
            website_id: -1,
            category_id: -1,
            hunter_id: -1,
            am_id: -1,
            isInternet: 0,
            title: null,
            currency_id: 0,
            approval_id: 0,
            payment_term_id: 0,
            billing_entities: 0,
            name: '',
            max_insurance: 0,
            number_n: '',
            street_n: '',
            city_n: '',
            postcode_n: '',
            country_n: '',
            complement_n: '',
            remarks_accounting: '',
            email_second: '',
            phone_mobile: '',
        },
        organization: {
            id: null,
            business_id: -1,
            bulstat: '',
            intra_community_vat: '',
            organization_type: '',
            trademark: '',
            code_ebp: '',
            code_ajour: '',
            civilite: '',
            prenom: '',
            nomenvoi: '',
            tel_organization: '',
            email_organization: '',
            email_organization_copy: '',
            name: '',
            address: '',
            business: '',
            invoice_address: ''
        },
        websites: [],
        client_categories_shown: [],
        client_categories: [],
        client_business_fields: [],
        hunters: [],
        ams: [],
        titles: [],
        currencies: [],
        approvals: [],
        payment_terms: [],
        billing_entities: [],
        delivery_addresses: [],
        delivery_address_empty: {
            number: '',
            street: '',
            city: '',
            post_code: '',
            email: '',
            phone: '',
            business_hours: '',
            pallets_requirements: '',
            cartons_requirements: '',
            more_information: ''
        },
        min_delivery_addresses: 1,
        max_delivery_addresses: 3,
        client_contacts: [],
        client_contacts_empty: {
            contact_type_id: null,
            name: '',
            email: '',
            phone: ''
        },
        client_contact_types: []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id !== undefined)
        {
            this.load(id);
        }
        else
        {
            this.loadViewModel();
        }
    }

    setStaticFields(response)
    {
        const websites = response.websites;
        const client_categories = response.client_categories;
        const client_business_fields = response.client_business_fields;
        const hunters = response.hunters;
        const ams = response.ams;
        const titles = response.titles;
        const currencies = response.currencies;
        const approvals = response.approvals;
        const payment_terms = response.payment_terms;
        const billing_entities = response.billing_entities;
        const delivery_addresses = [{...this.state.delivery_address_empty}];
        const client_contact_types = response.client_contact_types;
        this.setState({isLoading: false, websites, client_categories, client_business_fields, hunters, ams, titles, currencies, approvals, payment_terms, billing_entities, delivery_addresses, client_contact_types});
    }

    loadViewModel() {
        this.setState({isLoading: true}, () => {
            NetworkClient.get('all-clients/get-create-model')
                .then(response => {
                    this.setState({client_contacts: [{...this.state.client_contacts_empty}]});
                    this.setStaticFields.bind(this, response)();
                })
        })
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`all-clients/${id}`)
                .then(response => {
                    const client = response.client;
                    let organization = response.organization;
                    let delivery_addresses = response.delivery_addresses;
                    if(delivery_addresses.length === 0)
                    {
                        delivery_addresses = [];
                        delivery_addresses.push({...this.state.delivery_address_empty});
                    }
                    let client_contacts = response.client_contacts;
                    if(client_contacts === null)
                    {
                        client_contacts = [];
                        client_contacts.push({...this.state.client_contacts_empty});
                    }

                    if(organization === null)
                    {
                        organization = {
                            id: null,
                            business_id: -1
                        };
                    }
                    this.setStaticFields.bind(this, response)();
                    this.setState({client});
                    //Set selected Category
                    let categories = this.state.client_categories.filter(x => x.website_id === client.website_id);
                    this.setState({client, client_categories_shown: categories, organization, delivery_addresses, client_contacts});

                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {client} = this.state;
        const {delivery_addresses} = this.state;
        const {client_contacts} = this.state;
        const {organization} = this.state;

        let url = 'all-clients-crud';
        if (client.id !== null) {
            url += '/' + client.id;
        }

        NetworkClient.post(url, {client, delivery_addresses, client_contacts, organization})
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        //this.props.history.push('/clients');
                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    renderFirstRow(t) {
        return (
            <div className={"row"}>
                <div className="col-2">
                    <label>
                        {t('websites')}
                    </label>
                    <Select
                            value={this.state.websites.find(c => c.id === this.state.client.website_id)}
                            onChange={(selected) => {
                                let {client} = this.state;
                                client.website_id = selected.id;
                                client.category_id = -1;
                                let categories = this.state.client_categories.filter(x => x.website_id === client.website_id);
                                this.setState({client, client_categories_shown: categories});
                            }}
                            options={this.state.websites}
                            getOptionLabel={(option)=> option.name}
                            getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-2">
                    <label>{t('category')}</label>
                    <Select
                        value={
                            this.state.client_categories_shown.find(c => c.id === this.state.client.category_id) === undefined
                            ? null
                            : this.state.client_categories_shown.find(c => c.id === this.state.client.category_id)
                        }
                        onChange={(selected) => {
                            let {client} = this.state;
                            client.category_id = selected.id;
                            this.setState({client});
                        }}
                        options={this.state.client_categories_shown}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-2">
                    <label>{t('business')}</label>
                    <Select
                        value={
                            this.state.client_business_fields.find(c => c.id === this.state.organization.business_id)
                        }
                        onChange={(selected) => {
                            let {organization} = this.state;
                            organization.business_id = selected.id;
                            this.setState({organization});
                        }}
                        options={this.state.client_business_fields}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-2">
                    <label>{t('internet')}</label>
                    <br/>
                    <input type="checkbox"
                           checked={this.state.client.isInternet === 1}
                           onChange={(e) => {
                               let {client} = this.state;
                               client.isInternet = e.target.checked === true ? 1 : 0;
                               this.setState({client});
                           }}
                    />
                </div>
                <div className="col-2">
                    <label>{t('hunter')}</label>
                    <Select
                        value={
                            this.state.hunters.find(c => c.id === this.state.client.hunter_id)
                        }
                        onChange={(selected) => {
                            let {client} = this.state;
                            client.hunter_id = selected.id;
                            this.setState({client});
                        }}
                        options={this.state.hunters}
                        getOptionLabel={(option)=> option.first_name + ' ' + option.last_name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-2">
                    <label>{t('am')}</label>
                    <Select
                        value={
                            this.state.ams.find(c => c.id === this.state.client.am_id)
                        }
                        onChange={(selected) => {
                            let {client} = this.state;
                            client.am_id = selected.id;
                            this.setState({client});
                        }}
                        options={this.state.ams}
                        getOptionLabel={(option)=> option.first_name + ' ' + option.last_name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
            </div>
        )
    }

    renderSecondRow(t) {
        let columns = [{name: 'name', type: 'text', col: 3},
            {name: 'organization_type', type: 'text', col: 2},
            {name: 'trademark', type: 'text', col: 2},
            {name: 'bulstat', type: 'text', col: 2},
            {name: 'intra_community_vat', type: 'text', col: 3}];

        return (
            <div className="row">
                {SimpleFieldsWithColumnsGenerator.bind(this, t, columns, 'organization')()}
            </div>
        )
    }

    renderThirdRow(t){
        let columns = [{name: 'name', type: 'text', col: 3},
            {name: 'last_name', type: 'text', col: 3},
            {name: 'phone', type: 'text', col: 2},
            {name: 'phone_mobile', type: 'text', col: 2},
            {name: 'email', type: 'text', col: 3},
            {name: 'email_second', type: 'text', col: 3}];

        return (<div className={"row"}>
            <div className={"col-2"}>
                <label>
                        {t('title')}
                </label>
                <Select
                        value={this.state.titles.find(c => c.id === this.state.client.title)}
                        onChange={(selected) => {
                            let {client} = this.state;
                            client.title = selected.id;
                            this.setState({client});
                        }}
                        options={this.state.titles}
                        getOptionLabel={(option)=> option.title}
                        getOptionValue={(option) => option.id}
                    />
            </div>
            {SimpleFieldsWithColumnsGenerator.bind(this, t, columns, 'client')()}
        </div>)
    }

    renderMailAddressRow(t) {

        let columns = [
            {name: 'street_n', type: 'text', col: 2, label_title: 'street_name'},
            {name: 'city_n', type: 'text', col: 2, label_title: 'city'},
            {name: 'postcode_n', type: 'text', col: 2, label_title: 'post_code'},
            {name: 'country_n', type: 'text', col: 2, label_title: 'country'},
            {name: 'complement_n', type: 'text', col: 2, label_title: 'complement_n'}
        ];

        return (<div className={"row"}>
            {SimpleFieldsWithColumnsGenerator.bind(this, t, [{name: 'address', type: 'text', col: 2}], 'organization')()}
            {SimpleFieldsWithColumnsGenerator.bind(this, t, columns, 'client')()}
        </div>)
    }

    renderSixthRow(t) {
        return (
            <div className={"row"}>
                <div className="col-2">
                    <label>{t('currencies')}</label>
                    <Select
                        value={this.state.currencies.find(c => c.id === this.state.client.currency_id)}
                        onChange={(selected) => {
                            let {client} = this.state;
                            client.currency_id = selected.id;
                            this.setState({client});
                        }}
                        options={this.state.currencies}
                        getOptionLabel={(option)=> option.type}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-2">
                    <label>{t('fin_ctrl_approval')}</label>
                    <Select
                        value={this.state.approvals.find(c => c.id === this.state.client.approval_id)}
                        onChange={(selected) => {
                            let {client} = this.state;
                            client.approval_id = selected.id;
                            this.setState({client});
                        }}
                        options={this.state.approvals}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                {SimpleFieldsWithColumnsGenerator.bind(this, t, [{name: 'max_insurance', type: 'text', col: 2}], 'client')()}
                <div className="col-2">
                    <label>{t('fin_ctrl_payment_term')}</label>
                    <Select
                        value={this.state.payment_terms.find(c => c.id === this.state.client.payment_term_id)}
                        onChange={(selected) => {
                            let {client} = this.state;
                            client.payment_term_id = selected.id;
                            this.setState({client});
                        }}
                        options={this.state.payment_terms}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-2">
                    <label>{t('billing_еntities')}</label>
                    <Select
                        value={this.state.billing_entities.find(c => c.id === this.state.client.billing_entities)}
                        onChange={(selected) => {
                            let {client} = this.state;
                            client.payment_term_id = selected.id;
                            this.setState({client});
                        }}
                        options={this.state.payment_terms}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                {SimpleFieldsWithColumnsGenerator.bind(this, t, [{name: 'code_ebp', type: 'text', col: 1},{name: 'code_ajour', type: 'text', col: 1}], 'organization')()}
            </div>
        )
    }

    renderInvoiceRow(t)
    {
        let columns = [
            {name: 'prenom', type: 'text', col: 2, label_title: 'prenom'},
            {name: 'nomenvoi', type: 'text', col: 2, label_title: 'nomenvoi'},
            {name: 'tel_organization', type: 'text', col: 2, label_title: 'phone'},
            {name: 'email_organization', type: 'text', col: 2, label_title: 'email_organization'},
            {name: 'email_organization_copy', type: 'text', col: 2, label_title: 'email_organization_copy'},
            {name: 'invoice_address', type: 'text', col: 12, label_title: 'invoice_address'},
        ];

        return (<div className={"row"}>
            <div className={"col-2"}>
                <label>
                    {t('title')}
                </label>
                <Select
                    value={this.state.titles.find(c => c.id === parseInt(this.state.organization.civilite))}
                    onChange={(selected) => {
                        let {organization} = this.state;
                        organization.civilite = selected.id;
                        this.setState({organization});
                    }}
                    options={this.state.titles}
                    getOptionLabel={(option)=> option.title}
                    getOptionValue={(option) => option.id}
                />
            </div>
            {SimpleFieldsWithColumnsGenerator.bind(this, t, columns, 'organization')()}
        </div>)
    }

    handleDeliveryAddressFieldChange(e, index, fieldName)
    {
        console.log('test');
        let {delivery_addresses} = this.state;
        delivery_addresses[index][fieldName] = e.target.value;
        this.setState({delivery_addresses});
    }

    renderDeliveryAddressRow(t)
    {
        return this.state.delivery_addresses.map((da,index) => <div className={"row"}>
            <div className="col-2">
                <label>
                    {t('number')}
                </label>
                <input
                    type="text"
                    className={"form-control"}
                    placeholder={t('number')}
                    value={this.state.delivery_addresses[index].number}
                    onChange={ (e) => {this.handleDeliveryAddressFieldChange.bind(this,e,index,'number')()}}
                />
            </div>
            <div className={"col-2"}>
                <label>
                    {t('street')}
                </label>
                <input
                    type="text"
                    className={"form-control"}
                    placeholder={t('street')}
                    value={this.state.delivery_addresses[index].street}
                    onChange={ (e) => {this.handleDeliveryAddressFieldChange.bind(this,e,index,'street')()}}
                />
            </div>
            <div className={"col-2"}>
                <label>
                    {t('city')}
                </label>
                <input
                    type="text"
                    className={"form-control"}
                    placeholder={t('city')}
                    value={this.state.delivery_addresses[index].city}
                    onChange={ (e) => {this.handleDeliveryAddressFieldChange.bind(this,e,index,'city')()}}
                />
            </div>
            <div className={"col-2"}>
                <label>
                    {t('post_code')}
                </label>
                <input
                    type="text"
                    className={"form-control"}
                    placeholder={t('post_code')}
                    value={this.state.delivery_addresses[index].post_code}
                    onChange={ (e) => {this.handleDeliveryAddressFieldChange.bind(this,e,index,'post_code')()}}
                />
            </div>
            <div className={"col-2"}>
                <label>
                    {t('email')}
                </label>
                <input
                    type="text"
                    className={"form-control"}
                    placeholder={t('email')}
                    value={this.state.delivery_addresses[index].email}
                    onChange={ (e) => {this.handleDeliveryAddressFieldChange.bind(this,e,index,'email')()}}
                />
            </div>
            <div className={"col-2"}>
                <label>
                    {t('phone')}
                </label>
                <input
                    type="text"
                    className={"form-control"}
                    placeholder={t('phone')}
                    value={this.state.delivery_addresses[index].phone}
                    onChange={ (e) => {this.handleDeliveryAddressFieldChange.bind(this,e,index,'phone')()}}
                />
            </div>
            <div className={"col-2 mt-1"}>
                <label>
                    {t('business_hours')}
                </label>
                <input
                    type="text"
                    className={"form-control"}
                    placeholder={t('business_hours')}
                    value={this.state.delivery_addresses[index].business_hours}
                    onChange={ (e) => {this.handleDeliveryAddressFieldChange.bind(this,e,index,'business_hours')()}}
                />
            </div>
            <div className={"col-3 mt-2"}>
                <label>
                    {t('pallets_requirements')}
                </label>
                <input
                    type="text"
                    className={"form-control"}
                    placeholder={t('pallets_requirements')}
                    value={this.state.delivery_addresses[index].pallets_requirements}
                    onChange={ (e) => {this.handleDeliveryAddressFieldChange.bind(this,e,index,'pallets_requirements')()}}
                />
            </div>
            {/*cartons_requirements*/}
            <div className={"col-3 mt-2"}>
                <label>
                    {t('cartons_requirements')}
                </label>
                <input
                    type="text"
                    className={"form-control"}
                    placeholder={t('cartons_requirements')}
                    value={this.state.delivery_addresses[index].cartons_requirements}
                    onChange={ (e) => {this.handleDeliveryAddressFieldChange.bind(this,e,index,'cartons_requirements')()}}
                />
            </div>
            <div className={"col-3 mt-2"}>
                <label>
                    {t('more_information')}
                </label>
                <input
                    type="text"
                    className={"form-control"}
                    placeholder={t('more_information')}
                    value={this.state.delivery_addresses[index].more_information}
                    onChange={ (e) => {this.handleDeliveryAddressFieldChange.bind(this,e,index,'more_information')()}}
                />
            </div>
            <div className={"col-1 mt-2"}>
                {this.state.delivery_addresses.length - 1 === index && this.state.delivery_addresses.length < this.state.max_delivery_addresses &&
                <button className={"btn btn-info btn-sm"} onClick={()=>{
                    if(this.state.delivery_addresses.length < this.state.max_delivery_addresses)
                    {
                        let {delivery_addresses} = this.state;
                        delivery_addresses.push({...this.state.delivery_address_empty});
                        this.setState({delivery_addresses});
                    }
                }}>
                    <i className={"fa fa-plus"}  />
                </button>}
                {this.state.delivery_addresses.length > 1 &&
                <button className={"btn btn-danger btn-sm"} onClick={()=>{
                    if(this.state.delivery_addresses.length > 1)
                    {
                        SwalDelete(t).then(an => {
                            if(an.value)
                            {
                                let {delivery_addresses} = this.state;
                                delivery_addresses.splice(index, 1);
                                this.setState({delivery_addresses});
                            }
                        })
                    }
                }}>
                    <i className={"fa fa-times"} />
                </button>}
            </div>
        </div>)
    }

    handleDeliveryContactsFieldChange(e, index, fieldName)
    {
        let {client_contacts} = this.state;
        client_contacts[index][fieldName] = e.target.value;
        this.setState({client_contacts});
    }

    renderContactsRow(t)
    {
        return (<div>
            {
                this.state.client_contacts.map((c, index) =>
                    <div className={"row"}>
                        <div className={"col-12 mt-1"}>
                            <b>
                                {t('contacts')}
                            </b>
                        </div>
                        <div className={"col-2"}>
                            <label>{t('type')}</label>
                            <Select
                                value={this.state.client_contact_types.find(c => c.id === this.state.client_contacts[index].contact_type_id)}
                                onChange={(selected) => {
                                    let {client_contacts} = this.state;
                                    client_contacts[index].contact_type_id = selected.id;
                                    this.setState({client_contacts});
                                }}
                                options={this.state.client_contact_types}
                                getOptionLabel={(option)=> option.name}
                                getOptionValue={(option) => option.id}
                            />
                        </div>
                        <div className="col-3">
                            <label>{t('name')}</label>
                            <input
                                className={"form-control"}
                                type="text" value={this.state.client_contacts[index].name}
                                onChange={e => this.handleDeliveryContactsFieldChange(e,index,'name')}
                            />
                        </div>
                        <div className="col-3">
                            <label>{t('email')}</label>
                            <input
                                className={"form-control"}
                                type="text" value={this.state.client_contacts[index].email}
                                onChange={e => this.handleDeliveryContactsFieldChange(e,index,'email')}
                            />
                        </div>
                        <div className="col-3">
                            <label>{t('phone')}</label>
                            <input
                                className={"form-control"}
                                type="text" value={this.state.client_contacts[index].phone}
                                onChange={e => this.handleDeliveryContactsFieldChange(e,index,'phone')}
                            />
                        </div>
                        <div className="col-1">
                            {this.state.client_contacts.length === index + 1 &&
                            <button onClick={()=>{
                                let client_contacts = this.state.client_contacts;
                                client_contacts.push({...this.state.client_contacts_empty});
                                this.setState({client_contacts});
                            }} className={"btn btn-info btn-sm"}>
                                <i className={"fa fa-plus"} />
                            </button>
                            }
                            <button
                                onClick={()=>{
                                    SwalDelete(t).then(r => {
                                        if(r.value)
                                        {
                                            let client_contacts = this.state.client_contacts;
                                            client_contacts.splice(index, 1);
                                            this.setState({client_contacts});
                                        }
                                    })
                                }}
                                className={"btn btn-danger btn-sm"}>
                                <i className={"fa fa-times"} />
                            </button>
                        </div>
                    </div>
                )
            }
        </div>)
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('client')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-12"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {this.renderFirstRow(t)}
                                    <hr/>
                                    {this.renderSecondRow(t)}
                                    <hr/>
                                    {this.renderThirdRow(t)}
                                    <hr/>
                                    {this.renderMailAddressRow(t)}
                                    <hr/>
                                    <div className="row">
                                        {SimpleFieldsWithColumnsGenerator.bind(this, t, [{name: 'remarks_accounting', type: 'text', col: 12}], 'client')()}
                                    </div>
                                    <hr/>
                                    {this.renderSixthRow(t)}
                                    <hr/>
                                    {this.renderInvoiceRow(t)}
                                    <hr/>
                                    {this.renderDeliveryAddressRow(t)}
                                    <hr/>
                                    {this.renderContactsRow(t)}
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.client.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ClientsForm);
