import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import {Link} from 'react-router-dom';

class QuoteFirstStep extends Component {
    state = {
        isLoading: true,
        websites: []
    }

    constructor(props) {
        super(props);
        this.load();
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    load() {
        NetworkClient.get('quotes/first-step')
            .then(response => {
                let {websites} = response;
                this.setState({isLoading: false, websites});
            })
    }

    printWebsites() {
        return this.state.websites.map( w => <>
            <div>
                <h3>{w.name}</h3>
                <div className={"row"}>
                    {w.products.map(p => <Link to={'/distribution-test/'+p.id} className={"btn btn-primary"}>
                        <i className={"fas fa-clone"} /> <br/>
                        {p.name}
                    </Link>)}
                </div>
            </div>
        </>)
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    {t('page-title')}
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        {this.printWebsites()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(QuoteFirstStep);
