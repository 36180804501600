import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import Languages from "../../constants/Languages";
import Select from "react-select/";
import SimpleFieldsGenerator from "../../helpers/SimpleFieldsGenerator";
import SwalDelete from "../../helpers/SwalDelete";
import { Button } from 'reactstrap';
import DataTableInitDataGenerator from '../../helpers/DataTableInitDataGenerator';
import { MDBDataTable } from 'mdbreact';


const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 17,
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};
const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);


            

class OverchargesForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        overcharge: {
            id: null,
            product_id: '',
            website_id: '',
            category_id: '',
            property_id: '',
            percentage: ''
        },
       
        websites: [],
        products: [],
        property_types: [],
        client_categories: [],
        additional_overcharges: [],
        selected_additional_overcharges: [],
        dataTable: {
            columns: [],
            rows: [],
        },
        baseOvercharges: [], // rows
        //property_fields: [], // table header
        additional_overcharges_empty: {
            id: '',
            property_id: '',
            overcharge_percentage: ''
        }
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const website_id = this.props.match.params.website_id;
        const category_id = this.props.match.params.category_id;
        const product_id = this.props.match.params.product_id;
        const {t} = this.props;
        let listColumns = [
            'printRunFrom',
            'printRunTo',
            'overcharge_52', 
            // 'discount_52', 
            'overcharge_18',
            // 'discount_18',
            'overcharge_15',
            // 'discount_15',
            'overcharge_50', 
            // 'discount_50', 
            'overcharge_16',
            // 'discount_16',
            'overcharge_19', 
            // 'discount_19', 
            'overcharge_51', 
            // 'discount_51',            
            'overcharge_17',
            // 'discount_17',
            'actions' 
 
        ]
        
       
        this.setState({isLoading: true});
        NetworkClient.get('overcharges/get-websites-product-category')
            .then(response => {
                let websites = response.websites;
                let products = response.products;
                let client_categories = response.client_categories;
                let property_types = response.property_types;

                this.setState({
                    products: products,
                    websites: websites,
                    property_types: property_types,
                    client_categories: client_categories,
                    dataTable: DataTableInitDataGenerator(listColumns, t) ,
                    isLoading: false,
                    additional_overcharges: [{...this.state.additional_overcharges_empty}]
                } );
            }).finally(c => {
                if (website_id !== undefined) {
                    this.load(website_id, category_id, product_id);
                } 
        });        
    }


    getBaseOvercharges = () => {
        const data = {
            website_id: this.state.overcharge.website_id,
            product_id: this.state.overcharge.product_id,
            category_id: this.state.overcharge.category_id
        }

        if(data.website_id && data.product_id && data.category_id)
        {
            this.setState({isLoading: true});
            NetworkClient.post('overcharges-base', data )
            .then(response => {
                const baseOvercharges = response;
                this.setState({baseOvercharges})
                this.setState([this.state.dataTable.rows = baseOvercharges], () => {
                    this.fillDataTable(baseOvercharges)
                });
            }).finally(c => {
                if(this.state.baseOvercharges.length === 0 )
                {
                    this.handleAddRow();
                }
                this.setState({isLoading: false});
             })
        }
   
    }
    
    handleTextInputChange(e, fieldName, index) {

        let {baseOvercharges} = this.state;	
       
        baseOvercharges[index][fieldName] = e.target.value;
        this.setState({baseOvercharges}, () => {
            this.fillDataTable(baseOvercharges);
        });
    }

    fillDataTable(baseOvercharges){
        let {dataTable} = this.state;
        let rows = [];

        baseOvercharges.forEach((item, i) => {
            let tmp = {
                ids: item.ids,
                printRunFrom: <input
                min={0}
                type="number"
                style={{width: 60}}
                value={item.printRunFrom}
                onChange={e => {
                    if(e.target.value >= 0)
                    {
                        this.handleTextInputChange(e, 'printRunFrom', i )}
                    }
                }
                />,
                printRunTo: <input
                min={0}
                type="number"
                style={{width: 60}}
                value={item.printRunTo}
                onChange={e => {
                    if(e.target.value >= 0)
                    {
                        this.handleTextInputChange(e, 'printRunTo', i )}
                    }

                }
                />,
                overcharge_52: <input
                type="number"
                style={{width: 60}}
                value={item.overcharge_52}
                onChange={e => this.handleTextInputChange(e, 'overcharge_52', i )}
                />,
                // discount_52: <input
                // type="number"
                // style={{width: 60}}
                // value={item.discount_52}
                // onChange={e => this.handleTextInputChange(e, 'discount_52', i )}
                // />,
                overcharge_51 : <input
                type="number"
                style={{width: 60}}
                value={item.overcharge_51}
                onChange={e => this.handleTextInputChange(e, 'overcharge_51', i )}
                />,
                // discount_51 : <input
                // type="number"
                // style={{width: 60}}
                // value={item.discount_51}
                // onChange={e => this.handleTextInputChange(e, 'discount_51', i )}
                // />, 
                overcharge_50: <input
                type="number"
                style={{width: 60}}
                value={item.overcharge_50}
                onChange={e => this.handleTextInputChange(e, 'overcharge_50', i )}
                />,
                // discount_50: <input
                // type="number"
                // style={{width: 60}}
                // value={item.discount_50}
                // onChange={e => this.handleTextInputChange(e, 'discount_50', i )}
                // />,
                overcharge_19: <input
                type="number"
                style={{width: 60}}
                value={item.overcharge_19}
                onChange={e => this.handleTextInputChange(e, 'overcharge_19', i )}
                />,
                // discount_19: <input
                // type="number"
                // style={{width: 60}}
                // value={item.discount_19}
                // onChange={e => this.handleTextInputChange(e, 'discount_19', i )}
                // />,
                overcharge_18: <input
                type="number"
                style={{width: 60}}
                value={item.overcharge_18}
                onChange={e => this.handleTextInputChange(e, 'overcharge_18', i )}
                />,
                // discount_18: <input
                // type="number"
                // style={{width: 60}}
                // value={item.discount_18}
                // onChange={e => this.handleTextInputChange(e, 'discount_18', i )}
                // />,
                overcharge_17: <input
                type="number"
                style={{width: 60}}
                value={item.overcharge_17}
                onChange={e => this.handleTextInputChange(e, 'overcharge_17', i )}
                />,
                // discount_17: <input
                // type="number"
                // style={{width: 60}}
                // value={item.discount_17}
                // onChange={e => this.handleTextInputChange(e, 'discount_17', i )}
                // />,
                overcharge_16: <input
                type="number"
                style={{width: 60}}
                value={item.overcharge_16}
                onChange={e => this.handleTextInputChange(e, 'overcharge_16', i )}
                />,
                // discount_16: <input
                // type="number"
                // style={{width: 60}}
                // value={item.discount_16}
                // onChange={e => this.handleTextInputChange(e, 'discount_16', i )}
                // />,
                overcharge_15: <input
                type="number"
                style={{width: 60}}
                value={item.overcharge_15}
                onChange={e => this.handleTextInputChange(e, 'overcharge_15', i )}
                />,
                // discount_15: <input
                // type="number"
                // style={{width: 60}}
                // value={item.discount_15}
                // onChange={e => this.handleTextInputChange(e, 'discount_15', i )}
                // />,
                actions: <div className="btn-group">
                <button onClick={this.saveBaseOvercharge.bind(this, item)} className={"btn btn-info btn-sm d-inline-block"}>
                    <i className={"far fa-save"}/>
                </button>
                {i !== 0 && <button onClick={() => {
                    this.deleteBaseOvercharge(item.ids, i)
                }} className={"btn btn-danger btn-sm d-inline-block"}>
                    <i className={"fa fa-times"}/>
                </button>}
                {i === baseOvercharges.length -1 && (
                    <Button className="btn btn-sm" onClick={this.handleAddRow}><i className='fa fa-plus'></i></Button>
                )}
            </div>


            }
            rows.push(tmp);
        });

        this.setState({dataTable: {columns: dataTable.columns, rows:rows}})
    }

    deleteBaseOvercharge(ids,rowIndex){
        const {t} = this.props;
        let baseOvercharges = this.state.baseOvercharges;
        SwalDelete(t).then( c => {
            if(c.value) 
            {
                if( ids !== undefined)
                {
                    let convertIds = {
                        ids: Object.keys(ids).reduce((array, key) => {
                            return [...array, ids[key]]
                        }, [])
                    }
                    
                    this.setState({isLoading: true}, () => {
                       NetworkClient.post(`overcharges-base-delete`, convertIds)
                       .then(t => {
                            //this.getBaseOvercharges();
                       })
                    });
                }
                                
                baseOvercharges.splice(rowIndex, 1);
                this.setState(baseOvercharges)
                this.fillDataTable(baseOvercharges)               
                this.setState({isLoading:false})
            }
        })
    }

    saveBaseOvercharge(baseOvercharge){
        const {t} = this.props;
        
      baseOvercharge['website_id'] = this.state.overcharge.website_id;
      baseOvercharge['category_id'] = this.state.overcharge.category_id;
      baseOvercharge['product_id'] = this.state.overcharge.product_id;
      let str = baseOvercharge.ids === undefined ? '' : '/1';
 
        Swal.fire({
            title: t('are-you-sure'),
            text: t('are-you-sure'),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('yes')
        }).then( c => {
            if(c.value)
            {
                this.setState({isLoading: true}, () => {
                    // console.log(baseOvercharge);
                    NetworkClient.post('overcharges-base-store' + str, baseOvercharge)
                        .then(response => {
                            if(response.success == true)
                            {
                                Swal.fire('Ok!', '', 'success')
                                .then(c => {
                                    //this.props.history.push('/link');
                                })
                            }
                            this.setState({isLoading: false});
                        })
                });
                this.setState({isLoading: false});
            }
            
        })
    }

    handleAddRow = () => {
        const item = {
                printRunFrom: '',
                printRunTo: '',
                // discount_15: '',
                // discount_16: '',
                // discount_17: '',
                // discount_18: '',
                // discount_19: '',
                // discount_50: '',
                // discount_51: '',
                // discount_52: '',
                overcharge_15: '',
                overcharge_16: '',
                overcharge_17: '',
                overcharge_18: '',
                overcharge_19: '',
                overcharge_50: '',
                overcharge_51: '',
                overcharge_52: '',
            };
       
        this.setState({
            baseOvercharges: [...this.state.baseOvercharges, item]
        }, () => {
            this.fillDataTable(this.state.baseOvercharges)
        });
      
    }

    printBaseOvercharges(){
        const {t} = this.props;
   
        return(<div className="row mt-3">
            <div className="col-12">
                <h3>{t('base')} {t('overcharges')}</h3>
                <MDBDataTable responsive
                    striped
                    bordered
                    hover
                    entries={100}
                    data={this.state.dataTable}
                />
            </div>
        </div>)
    }

    load(website_id, category_id, product_id) {
        
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`overcharges/get-websites-product-category/${website_id}/${category_id}/${product_id}`)
                .then(response => {
                    const overcharge = response.overcharge;
                    const baseOvercharges = response.baseOvercharges;
                    const selected_additional_overcharges = response.selected_additional_overcharges;
                    this.setState({
                        overcharge,
                        baseOvercharges,
                        additional_overcharges:selected_additional_overcharges
                    });
                   
                }).finally(c => {
                    this.mapNestedArrayToNormal();
                    this.fillDataTable(this.state.baseOvercharges)
                    this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {additional_overcharges} = this.state;
        const website_id = this.state.overcharge.website_id;
        const category_id = this.state.overcharge.category_id;
        const product_id = this.state.overcharge.product_id;

        let url = 'overcharges';
        if (website_id !== null && website_id !== undefined) {
            url += '/' + website_id;
        }
        NetworkClient.post(url, {additional_overcharges, website_id, category_id, product_id})
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        // this.props.history.push('/link');
                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    getClientCategories(id, e) {

        let website_id = this.state.overcharge.website_id;
        let product_id = this.state.overcharge.product_id;
        if(website_id )
        {
            this.setState({isLoading: true});
            let url = 'get-client-category'
            NetworkClient.post(url,  {website_id, product_id})
            .then(response => {
                let client_categories = response.client_categories;
                this.setState({client_categories});
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
        }
    }

    printWebProductClintCat() {
        const {t} = this.props;
        return (
           <div>
                <div className="row">
                <div className="col-4">
                    <label>{t('websites')}</label>
                   
                     <Select
                        value={this.state.websites.find(web => web.id === this.state.overcharge.website_id)}
                        options={this.state.websites}
                        isDisabled={ this.props.match.params.website_id && true }
                        onChange={(selected) => {
                            let {overcharge} = this.state;
                            overcharge.website_id = selected.id;
                            this.setState({overcharge: overcharge});
                            this.getClientCategories(selected.id, 'website_id')
                            this.getBaseOvercharges()
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-4">
                    <label>{t('products')}</label>
                    <Select
                        value={this.state.products.find(p => p.id === this.state.overcharge.product_id)}
                        options={this.state.products}
                        isDisabled={ this.props.match.params.website_id && true }
                        onChange={(selected) => {
                            let {overcharge} = this.state;
                            overcharge.product_id = selected.id;
                            this.setState({overcharge: overcharge});
                            this.getBaseOvercharges()
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="col-4">
                    <label>{t('client')} {t('categories')}</label>
                    <Select
                        value={this.state.client_categories.find(c => c.id === this.state.overcharge.category_id)}
                        options={this.state.client_categories}
                        isDisabled={ this.props.match.params.website_id && true }
                        onChange={(selected) => {
                            let {overcharge} = this.state;
                            overcharge.category_id = selected.id;
                            this.setState({overcharge: overcharge});
                            this.getBaseOvercharges()
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
            </div>
           </div>
        )
    }

    handleAdditionalOvercharges(e, index, fieldName)
    {
     
        let {additional_overcharges} = this.state;
        additional_overcharges[index][fieldName] = e.target.value;
    
        this.setState({additional_overcharges});
    }
    
    mapNestedArrayToNormal()
    {
        let {property_types} = this.state;
        let arr = [];
        let p =  property_types.map((p, i) => p.options.map(s => arr.push(s)));
        
        this.setState({arr})
    }

    printAdditionalOvercharges() {
        const {t} = this.props;
        // let selectAddition = this.state.selected_additional_overcharges.map((c,i) => c.property_id);
        // let selectPercentage = this.state.selected_additional_overcharges.map((c,i) => c.overcharge_percentage);


        if(this.state.additional_overcharges.length === 0 )
        {
            let additional_overcharges = this.state.additional_overcharges;
            additional_overcharges.push({...this.state.additional_overcharges_empty});
            this.setState({additional_overcharges});
        }

        this.state.property_types.map(p => p.options.map(options => {
            return (
                options.selected = this.state.additional_overcharges.some(item => item.property_id === options.value)
            )
        }))
        

        return (<div>
            {
                this.state.additional_overcharges.map((c, index) =>
                <div className={"row"}>
                        <div className={"col-4"}>
                            <label>{t('product')} {t('properties')}</label>
                            <Select
                                value={this.state.arr && this.state.arr.find(p => p.value === this.state.additional_overcharges[index].property_id) }
                                // value={ console.log(this.state.property_types[1].options[3]) }
                                options={this.state.property_types}
                                formatGroupLabel={formatGroupLabel}
                                // isOptionDisabled={option => option.value === this.state.additional_overcharges[index].property_id}
                                isOptionDisabled={option => option.selected === true }
                                onChange={(selected) => {
                                    let {additional_overcharges} = this.state;
                          
                                    additional_overcharges[index].property_id = selected.value;
                                    additional_overcharges[index].selected = true;
                                    
                                   
                                    this.setState({additional_overcharges});
                                }}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                            />
                        </div>
                        <div className="col-2">
                            <label htmlFor="overcharge_percentage">{t('percentage')}</label>
                            <input type="number"
                                   className={"form-control"}
                                   value={ 
                                       this.state.additional_overcharges[index].overcharge_percentage 
                                     }
                                     onChange={e => {this.handleAdditionalOvercharges.bind(this, e, index, 'overcharge_percentage' )()}}
                            />
                        </div>
                        <div className="col-2">
                            <div className="form-group-inline mt-4">
                                {this.state.additional_overcharges.length === index +1 &&
                                <button
                                    onClick={()=>{
                                        let additional_overcharges = this.state.additional_overcharges;
                                        additional_overcharges.push({...this.state.additional_overcharges_empty});
                                        this.setState({additional_overcharges});
                                    }}
                                    className="btn btn-info btn-sm"><i className="fa fa-plus"></i>
                                </button>
                                }
                              {this.state.additional_overcharges.length > 1 &&
                                <button
                                    className="btn btn-warning btn-sm"
                                    onClick={()=> {
                                        SwalDelete(t).then(r => {
                                            if (r.value)
                                            {
                                                let additional_overcharges = this.state.additional_overcharges;
                                                additional_overcharges.splice(index, 1);
                                                this.setState({additional_overcharges});
                                            }
                                        })
                                    }}
                                >
                                    <i className="fa fa-trash"></i>
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>)
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('create')} {t('overcharges')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-12"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {this.printWebProductClintCat()}
                                    {this.printBaseOvercharges()} 
                                    <div className="row  mt-5">
                                        <div className="col-12">
                                            <h3>{t('additional')} {t('overcharges')}</h3>
                                        </div>
                                    </div>
                                    {this.printAdditionalOvercharges()}
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.overcharge.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(OverchargesForm);