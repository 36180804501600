import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../../helpers/DataTableInitDataGenerator';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import SwalDelete from '../../../helpers/SwalDelete';

class MaculatureKnigovezaneList extends Component {
    state = {
        isLoading: false,
        data: {
            columns: [],
            rows: []
        }
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {t} = this.props;
        let listColumns = [
            'id','tirage_from','tirage_to',
            'lepena_bez_kapaci','lepena_s_kapaci',
            'lepena_pur_bez_kapaci','lepena_pur_s_kapaci',
            'shita_lepena_bez_kapaci','shita_lepena_s_kapaci',
            'vshm_bez_kapaci','vshm_s_kapaci','tvurda_podvurziq_standart',
            'tvurda_podvurziq_s_forzaci_ot_tqloto','punching','actions'
        ];
        this.setState({data: DataTableInitDataGenerator(listColumns, t)});
        this.load();
    }

    load() {
        this.setState({isLoading: true});
        NetworkClient.get('maculature-knigovezane').then(response => {
            let {data} = this.state;
            let items = response.maculatures;
            let rows = [];
            items.forEach((item, i) => {
                let tmp = {
                    id: item.id,
                    tirage_from: item.tirage_from,
                    tirage_to: item.tirage_to,
                    lepena_bez_kapaci: item.lepena_bez_kapaci,
                    lepena_s_kapaci: item.lepena_s_kapaci,
                    lepena_pur_bez_kapaci: item.lepena_pur_bez_kapaci,
                    lepena_pur_s_kapaci: item.lepena_pur_s_kapaci,
                    shita_lepena_bez_kapaci: item.shita_lepena_bez_kapaci,
                    shita_lepena_s_kapaci: item.shita_lepena_s_kapaci,
                    vshm_bez_kapaci: item.vshm_bez_kapaci,
                    vshm_s_kapaci: item.vshm_s_kapaci,
                    tvurda_podvurziq_standart: item.tvurda_podvurziq_standart,
                    tvurda_podvurziq_s_forzaci_ot_tqloto: item.tvurda_podvurziq_s_forzaci_ot_tqloto,
                    punching: item.punching,
                    actions: <div>
                        <Link to={`/maculatures-knigovezane/edit/${item.id}`} className={"btn btn-info btn-sm"}>
                            <i className={"fa fa-pen"}/>
                        </Link>
                    </div>
                };
                rows.push(tmp);
            });


            this.setState({data: {columns: data.columns, rows: rows}, isLoading: false});
        })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    {t('maculatures-knigovezane')}
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <MDBDataTable
                                            striped
                                            bordered
                                            hover
                                            entries={25}
                                            data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(MaculatureKnigovezaneList);
