import React from 'react';
import TooltipLabel from './TooltipLabel';
/*
* Fields format should be {name: '', type: ''}
* Name is name from state
* Type is number, text, date
* Optional: helper_label: text for question mark
* Object name might be state main object (paper, density, order, etc.)
* */

let SimpleFieldsGenerator = function printFormFields(t, fields, objectName) {
    return fields.map((field, i) =>  <div className="form-group">
        <label>{t(field.name)}</label>
        {field.helper_label !== undefined ? <TooltipLabel label={t(field.helper_label)} /> : ''}
        <input
            key={i}
            type={field.type} className={"form-control"}
            placeholder={t(field.name)} value={this.state[objectName][field.name]}
            onChange={(e) => {
                let tmp = this.state[objectName];
                tmp[field.name] = e.target.value;
                let curState = this.state;
                curState[objectName] = tmp;
                this.setState(curState);
            }}
        />
    </div>)
}

export default SimpleFieldsGenerator;
