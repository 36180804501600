import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from "../../../api/NetworkClient";
import Loader from "../../../partial/Loader";
import {Link} from "react-router-dom";
import Swal from 'sweetalert2';


let INITIAL_STATE = {
    client: {
        id: -1,
        email: '',
        name: ''
    },
    order: {
        id: -1,
        name: '',
        state: '',
        price: 0,
        currency: 'EUR',
    },
    contacts: [],
    additional_client_contacts: [],
    is_selected: true,
    is_loading: true,
    is_shown_contacts: false,
    is_preview_mail: false,
    is_shown_client_contacts: true
}

class QuoteEmailPage extends Component {

    state = INITIAL_STATE;

    constructor(props) {
        super(props);
    }

    load(id) {
        NetworkClient.get('order/email/'+id)
            .then( response => {
                let {contacts} = response;
                let {order} = response;
                let {client} = response;
                let {additional_client_contacts} = response;

                this.setState({contacts, order, client, is_loading: false, additional_client_contacts})
            })
    }

    componentDidMount() {
       let id = this.props.match.params.id;
       if(id){
           this.load(id);
       }
    }

    sendEmails() {
        let mainEmail = {
            email: this.state.client.email,
            name: this.state.client.name
        }

        let zz = this.state.additional_client_contacts.filter(x=>x.is_selected === true);
        let tt = this.state.contacts.filter(x=>x.is_selected === true);

        NetworkClient.post('order/email', {id: this.state.order.id, contacts: [].concat(zz,tt), main_email: mainEmail});

        Swal.fire('Email sent!', '','success');
    }

    renderOrderDetailsAndOperations() {
        return (<div className="row">
            <div className={"col-8"}>
                <div className="card card-primary">
                    <div className="card-body">
                        <h5>{this.props.t('form_info')} | <span className={'fa fa-envelope'} /> {this.state.client.email}</h5>
                        <div className={'table-responsive'}>
                            <table className={'table table-bordered'}>
                                <tr>
                                    <td>{this.props.t('ID')}</td>
                                    <td>{this.props.t('name')}</td>
                                    <td>{this.props.t('state')}</td>
                                    <td>{this.props.t('price')}</td>
                                    <td>{this.props.t('PDF')}</td>
                                    <td>Email</td>
                                </tr>
                                <tr>
                                    <td>{this.state.order.id}</td>
                                    <td>{this.state.order.name}</td>
                                    <td>{this.state.order.state}</td>
                                    <td>{this.state.order.price} {this.state.order.currency}</td>
                                    <td>
                                        <a href={'https://backend-pulsio.blacatz.com/order/' + this.state.order.id +'?pdf=pdf'} target={'_blank'}>
                                            <span className={'fa fa-download'} />
                                        </a>
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox" checked={this.state.is_selected}
                                            onClick={e => {
                                                this.setState({is_selected: !this.state.is_selected})
                                            }}
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3 className={'card-title'}>{this.props.t('operations')}</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <button onClick={() => {
                                    this.sendEmails();
                                }} className={'btn btn-success btn-block'}>
                                    <span className={'fa fa-envelope'}/> Email
                                </button>
                            </div>
                            <div className="col-6">
                                <Link className={'btn btn-primary btn-block'} to={'/orders/list'}>
                                    {this.props.t('back')}
                                </Link>
                            </div>
                            <div className="col-6 mt-2">
                                <button onClick={e=>{
                                    this.setState({is_preview_mail: true, is_shown_contacts: false})
                                }} className={'btn btn-primary btn-block'}>
                                    {this.props.t('preview')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    renderPulsioContacts(){
        return (<div className={'row'}>
            <div className="col-8">
                <div className="card card-primary">
                    <div className="card-header">
                        PULSIO {this.props.t('contacts')}
                        <button onClick={e => {
                            this.setState({is_shown_contacts: !this.state.is_shown_contacts})
                        }} className={'btn btn-info btn-sm float-right'}>
                            <span> {this.state.is_shown_contacts ? <i className="fa fa-window-minimize" aria-hidden="true" /> : <i className="fa fa-plus" aria-hidden="true" />} </span>
                        </button>

                    </div>
                    <div className={"card-body " + (this.state.is_shown_contacts ? '' : 'd-none')}>
                        {this.state.contacts.map((c, index) => <div>
                            <strong>{c.name}:</strong> {c.email} &nbsp;
                            <input
                                type="checkbox"
                                checked={this.state.contacts[index].is_selected}
                                onClick={() => {
                                    let contacts = this.state.contacts;
                                    contacts[index].is_selected = !contacts[index].is_selected;
                                    this.setState({contacts})
                                }}
                            />
                        </div>)}
                    </div>
                </div>
            </div>
        </div>)
    }

    renderClientContacts() {
        return (<div className={'row'}>
            <div className="col-8">
                <div className="card card-primary">
                    <div className="card-header">
                        {this.props.t('contacts')}
                        <button onClick={e => {
                            this.setState({is_shown_client_contacts: !this.state.is_shown_client_contacts})
                        }} className={'btn btn-info btn-sm float-right'}>
                            <span> {this.state.is_shown_client_contacts ? <i className="fa fa-window-minimize" aria-hidden="true" /> : <i className="fa fa-plus" aria-hidden="true" />} </span>
                        </button>

                    </div>
                    <div className={"card-body " + (this.state.is_shown_client_contacts ? '' : 'd-none')}>
                        {this.state.additional_client_contacts.map((c, index) => <div>
                            <strong>{c.name}:</strong> {c.email}&nbsp;
                            <input
                                type="checkbox"
                                checked={this.state.additional_client_contacts[index].is_selected}
                                onClick={() => {
                                    let additional_client_contacts = this.state.additional_client_contacts;
                                    additional_client_contacts[index].is_selected = !additional_client_contacts[index].is_selected;
                                    this.setState({additional_client_contacts})
                                }}
                            />
                        </div>)}
                    </div>
                </div>
            </div>
        </div>)
    }

    render() {

        let t = this.props.t;

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('form_info')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <Loader isLoading={this.state.is_loading}/>
                        {this.renderOrderDetailsAndOperations()}
                        {this.state.additional_client_contacts.length > 0 && this.renderClientContacts()}
                        {this.renderPulsioContacts()}
                        <div className={"row " + (this.state.is_preview_mail ? ' ' : 'd-none') }>
                            <div className="col-12">
                                <iframe src="https://backend-pulsio.blacatz.com/order/51404" style={{width: '50%', height: '700px'}}
                                        frameBorder="0" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(QuoteEmailPage);