import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Select from 'react-select'

class CardboardForm extends Component {
    state = {
        isLoading: false,
        isOpen: true,
        selectedCardboard: -1,
        is_init: false
    }

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(props) {
        if(props.initial_state !== null && props.initial_state !== undefined && !this.state.is_init && this.props.cardboards.length > 0)
        {
            this.setState({selectedCardboard: props.initial_state.cardboard, is_init: true},()=>{
                this.props.applyChanges(this.getCardboard());
            })
        }

        if(!this.state.is_init && this.props.cardboards.length > 0 && (props.initial_state === null || props.initial_state === undefined))
        {
            this.setState({selectedCardboard: {"id":618,"name":"2.5 / 0"},is_init: true})
        }
    }

    getCardboard(trigger = true) {
        return {
            cardboard: this.state.selectedCardboard,
            trigger: trigger
        }
    }

    getMinimizeIcon() {
        if(this.state.isOpen)
        {
            return (<i className="fa fa-window-minimize" aria-hidden="true" />)
        }

        return (<i className="fa fa-plus" aria-hidden="true" />)
    }

    render() {
        const {t} = this.props;
        return (
            <div className={'card mt-3'}>
                <div className={'card-body'}>
                    <div className="row">
                        <div className="col-12">
                            <h3>
                                <div>
                                    <i className={'fa fa-book'} /> {t('cardboard')}
                                    <button onClick={()=>{
                                        this.setState({isOpen: !this.state.isOpen});
                                    }} className={'btn btn-sm btn-info float-right'}>
                                        {this.getMinimizeIcon()}
                                    </button>
                                </div>
                            </h3>
                        </div>
                    </div>
                    <div className={'collapse ' + (this.state.isOpen ? 'show' : '')}>
                    <div className="row">
                        <div className="col-12">
                            <label>{t('cardboard')}</label>
                            <Select
                                value={this.state.selectedCardboard}
                                onChange={(selected)=>{
                                    this.setState({selectedCardboard: selected},()=>{
                                        this.props.applyChanges(this.getCardboard());
                                    })
                                }}
                                options={this.props.cardboards}
                                getOptionLabel={(option)=> option.name}
                                getOptionValue={(option) => option.id}
                            />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CardboardForm);
