import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import LangNav from "./LangNav";
import AuthHelper from "../helpers/AuthHelper";
class MainPageWrapper extends Component {
  constructor(props) {
    super(props);
  }

  isMarkedMenuItem(item, smi, pathname) {
    let isChecked = false;

    if (item.name === "papers") {
      if (smi.name === "cardboard" && pathname.includes("cardboard")) {
        return true;
      }

      let split = pathname.split("/");
      split = split[2];
      if (smi.name === "papers" && split === "full") {
        return true;
      }

      return split === smi.name;
    } else if (item.name === "clients") {
      if (
        smi.name === "business-fields" &&
        pathname.includes("business-fields")
      ) {
        return true;
      }

      if (smi.name === "contact-types" && pathname.includes("contact-types")) {
        return true;
      }

      if (smi.name === "categories" && pathname.includes("categories")) {
        return true;
      }

      if (smi.name === "all-clients" && pathname === "/all-clients") {
        return true;
      }
    } else if (item.name === "users") {
      if (smi.name === "all_users" && pathname.includes("users")) {
        return true;
      }
    } else if (item.name === "transport") {
      if (smi.name === "belgium" && pathname.includes("/belgium")) {
        return true;
      }
    } else if (item.name === "overcharges") {
      if (smi.name === "overcharges" && pathname.includes("/overcharges")) {
        return true;
      }
    } else {
      return pathname.includes(smi.name);
    }

    /*(
          (item.name === 'papers' && pathname.includes(smi.name))
          ||
          (item.name !== 'papers' && pathname.includes(smi.name)) ? 'active' : '')
      return*/
  }

  printMenuSingleItem(item, pathname, t) {
    if (item.link !== null) {
      return (
        <li className="nav-item" key={item.link}>
          <Link
            to={item.link}
            className={"nav-link " + (pathname === item.link ? "active" : "")}
          >
            <i className="nav-icon fas fa-tachometer-alt" />
            <p>{t(item.name)}</p>
          </Link>
        </li>
      );
    }

    //exceptions papers || cardboards

    let isOpen = pathname.includes(item.name);
    if (item.name === "papers" && pathname.includes("cardboards")) {
      isOpen = true;
    }
    //if(pathname.includes(item.name) || pathname.includes('cardboards'))
    //{
    // isOpen = true;
    //}

    const subMenuItems = item["sub-menu"];

    return (
      <li
        key={item.link}
        className={"nav-item has-treeview " + (isOpen ? "menu-open" : "")}
      >
        <a href="#" className={"nav-link " + (isOpen ? "active" : "")}>
          <i className="nav-icon fas fa-tachometer-alt" />
          <p>
            {t(item.name)}
            <i className="right fas fa-angle-left" />
          </p>
        </a>
        <ul className="nav nav-treeview">
          {subMenuItems.map((smi, index) => (
            <li key={index} className="nav-item">
              <Link
                to={smi.link}
                className={
                  "nav-link " +
                  (this.isMarkedMenuItem(item, smi, pathname) ? "active" : "")
                }
              >
                <i className="far fa-circle nav-icon" />
                <p>{t(smi.name)}</p>
              </Link>
            </li>
          ))}
        </ul>
      </li>
    );
  }

  printMenu(permissions, pathname, t) {
    return permissions.map((p) => this.printMenuSingleItem(p, pathname, t));
  }

  render() {
    const { t, i18n } = this.props;
    let pathName = this.props.location.pathname;

    if (pathName === "/login") {
      return <div></div>;
    }

    let permissions = AuthHelper.getPermissionsMenuItems();
    if (permissions === null) {
      AuthHelper.deleteUser();
      window.location.href = "/login";
      return;
    }

    return (
      <div className={"wrapper"}>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light text-right row no-gutters">
          <ul className="navbar-nav col-4">
            <li>
              <a
                id={"bars"}
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <i className="fas fa-bars" />
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <img
                  alt={""}
                  style={{ width: 21, marginRight: 5 }}
                  className={"img-fluid"}
                  src={`/dist/img/flags/${i18n.language}.jpg`}
                />
                <span style={{ marginRight: 5 }}>{t(i18n.language)}</span>
                <i className="fa fa-caret-down" />
              </a>
              <LangNav t={t} i18n={i18n} />
            </li>
          </ul>
          <div className={"float-right text-right col-8"}>
            <a
              onClick={() => {
                AuthHelper.deleteUser();
                window.location.replace("/login");
              }}
            >
              {t("logout")}
            </a>
          </div>
        </nav>

        <div className="main-sidebar sidebar-dark-primary elevation-4">
          <a href="/" className="brand-link">
            <img
              src="/dist/img/AdminLTELogo.png"
              alt="AdminLTE Logo"
              className="brand-image img-circle elevation-3"
            />
            <span className="brand-text font-weight-light">Pulsio</span>
          </a>

          <div className="sidebar">
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image">
                <img
                  src="/dist/img/user2-160x160.jpg"
                  className="img-circle elevation-2"
                  alt="User Image"
                />
              </div>
              <div className="info">
                <a href="#" className="d-block">
                  User
                </a>
              </div>
            </div>

            <nav className="mt-2 mb-5">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                <li>{this.printMenu(permissions, pathName, t)}</li>

                <li className={"nav-item has-treeview " + ( pathName.includes('transport') && ' menu-open')}>
                  <a href="#" class="nav-link ">
                    <i className="nav-icon fas fa-tachometer-alt"></i>
                    <p>
                      {t("transport")}
                      <i className="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="/transport/belgium" className={"nav-link " + (pathName === '/transport/belgium' && ' active' )}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{t("BE - Belgium")}</p>
                      </a>
                    </li>
                    <li className={"nav-item has-treeview "  + (pathName.includes('bulgaria') && ' menu-open' )}>
                      <a href="#" className={"nav-link " + (pathName.includes('bulgaria') && ' active' )}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>
                          {t("BG - Bulgaria")}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                        <li className={"nav-item "  + (pathName.includes('bulgaria/prices') && ' menu-open' )}>
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("price")}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview"} style={{fontSize: 14, marginLeft: 25}}>
                            <li className={"nav-item" }>
                              <a href="/transport/bulgaria/prices/courier"
                               className={"nav-link " + (pathName.includes('/transport/bulgaria/prices/courier') && ' active' )}>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("courier")}</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="/transport/bulgaria/prices/settings"
                               className={"nav-link " + (pathName.includes('/transport/bulgaria/prices/settings') && ' active' ) }>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("settings")}</p>
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className={"nav-item " + (pathName.includes('bulgaria/taxes') && ' menu-open' ) } >
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t('taxes')}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                            <li className={"nav-item "}>
                              <a href="/transport/bulgaria/taxes/tax-fuel" className={"nav-link "  + (pathName === '/transport/bulgaria/taxes/tax-fuel' && ' active' )}>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("fuel")}
                                </p>
                              </a>
                            </li>
                            <li className={"nav-item "  }>
                              <a href="/transport/bulgaria/taxes/tax-pulsio" className={"nav-link " + (pathName === '/transport/bulgaria/taxes/tax-pulsio' && ' active' ) }>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("Pulsio")}
                                </p>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className={"nav-item has-treeview "  + (pathName.includes('france') && ' menu-open' )}>
                      <a href="#" className={"nav-link "}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>
                          {t("FR - France")}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                        <li className={"nav-item "}>
                          <a href="/transport/france/districts" className={"nav-link " + (pathName.includes('france/districts') && ' active' ) }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("districts")}
                            </p>
                          </a>
                        </li>
                        <li className={"nav-item "}>
                          <a href="/transport/france/overcharges" className={"nav-link "  + (pathName.includes('france/overcharges') && ' active' )}>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("overcharges")}
                            </p>
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li className={"nav-item has-treeview "  + (pathName.includes('switzerland') && ' menu-open' )}>
                      <a href="#" className={"nav-link " + (pathName.includes('switzerland') && ' active' )}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>
                          {t("CH - Switzerland")}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                        <li className={"nav-item "  + (pathName.includes('switzerland/prices') && ' menu-open' )}>
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("price")}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview"} style={{fontSize: 14, marginLeft: 25}}>
                            <li className={"nav-item" }>
                              <a href="/transport/switzerland/prices/economy"
                               className={"nav-link " + (pathName.includes('/transport/switzerland/prices/economy') && ' active' )}>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("economy")}</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="/transport/switzerland/prices/express"
                               className={"nav-link " + (pathName.includes('/transport/switzerland/prices/express') && ' active' ) }>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("express")}</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="/transport/switzerland/prices/settings"
                               className={"nav-link " + (pathName.includes('/transport/switzerland/prices/settings') && ' active' ) }>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("settings")}</p>
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className={"nav-item " + (pathName.includes('switzerland/taxes') && ' menu-open' ) } >
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t('taxes')}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                            <li className={"nav-item "}>
                              <a href="/transport/switzerland/taxes/fuel" className={"nav-link "  + (pathName === '/transport/switzerland/taxes/fuel' && ' active' )}>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("fuel")}
                                </p>
                              </a>
                            </li>
                            <li className={"nav-item "  }>
                              <a href="/transport/switzerland/taxes/pulsio" className={"nav-link " + (pathName === '/transport/switzerland/taxes/pulsio' && ' active' ) }>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("Pulsio")}
                                </p>
                              </a>
                            </li>
                            <li className={"nav-item "  }>
                              <a href="/transport/switzerland/taxes/security" className={"nav-link " + (pathName === '/transport/switzerland/taxes/security' && ' active' ) }>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("security")}
                                </p>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li className={"nav-item has-treeview "  + (pathName.includes('uk') && ' menu-open' )}>
                      <a href="#" className={"nav-link " + (pathName.includes('uk') && ' active' )}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>
                          {t("UK - United Kingdom")}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                        <li className={"nav-item "  + (pathName.includes('uk/prices') && ' menu-open' )}>
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("price")}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview"} style={{fontSize: 14, marginLeft: 25}}>
                            <li className={"nav-item" }>
                              <a href="/transport/uk/prices/economy"
                               className={"nav-link " + (pathName.includes('/transport/uk/prices/economy') && ' active' )}>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("economy")}</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="/transport/uk/prices/express"
                               className={"nav-link " + (pathName.includes('/transport/uk/prices/express') && ' active' ) }>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("express")}</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="/transport/uk/prices/settings"
                               className={"nav-link " + (pathName.includes('/transport/uk/prices/settings') && ' active' ) }>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("settings")}</p>
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className={"nav-item " + (pathName.includes('uk/taxes') && ' menu-open' ) } >
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t('taxes')}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                            <li className={"nav-item "}>
                              <a href="/transport/uk/taxes/fuel" className={"nav-link "  + (pathName === '/transport/uk/taxes/fuel' && ' active' )}>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("fuel")}
                                </p>
                              </a>
                            </li>
                            <li className={"nav-item "  }>
                              <a href="/transport/uk/taxes/pulsio" className={"nav-link " + (pathName === '/transport/uk/taxes/pulsio' && ' active' ) }>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("Pulsio")}
                                </p>
                              </a>
                            </li>
                            <li className={"nav-item "  }>
                              <a href="/transport/uk/taxes/security" className={"nav-link " + (pathName === '/transport/uk/taxes/security' && ' active' ) }>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("security")}
                                </p>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li className={"nav-item has-treeview "  + (pathName.includes('it') && ' menu-open' )}>
                      <a href="#" className={"nav-link " + (pathName.includes('it') && ' active' )}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>
                          {t("IT - Italy")}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                        <li className={"nav-item "  + (pathName.includes('it/prices') && ' menu-open' )}>
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("price")}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview"} style={{fontSize: 14, marginLeft: 25}}>
                            <li className={"nav-item" }>
                              <a href="/transport/it/prices/economy"
                               className={"nav-link " + (pathName.includes('/transport/it/prices/economy') && ' active' )}>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("economy")}</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="/transport/it/prices/express"
                               className={"nav-link " + (pathName.includes('/transport/it/prices/express') && ' active' ) }>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("express")}</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="/transport/it/prices/settings"
                               className={"nav-link " + (pathName.includes('/transport/it/prices/settings') && ' active' ) }>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("settings")}</p>
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className={"nav-item " + (pathName.includes('it/taxes') && ' menu-open' ) } >
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t('taxes')}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                            <li className={"nav-item "}>
                              <a href="/transport/it/taxes/fuel" className={"nav-link "  + (pathName === '/transport/it/taxes/fuel' && ' active' )}>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("fuel")}
                                </p>
                              </a>
                            </li>
                            <li className={"nav-item "  }>
                              <a href="/transport/it/taxes/pulsio" className={"nav-link " + (pathName === '/transport/it/taxes/pulsio' && ' active' ) }>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("Pulsio")}
                                </p>
                              </a>
                            </li>
                            <li className={"nav-item "  }>
                              <a href="/transport/it/taxes/security" className={"nav-link " + (pathName === '/transport/it/taxes/security' && ' active' ) }>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("security")}
                                </p>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li className={"nav-item has-treeview "  + (pathName.includes('de') && ' menu-open' )}>
                      <a href="#" className={"nav-link " + (pathName.includes('de') && ' active' )}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>
                        DE - {t("deutschland")}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                        <li className={"nav-item "  + (pathName.includes('de/prices') && ' menu-open' )}>
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("price")}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview"} style={{fontSize: 14, marginLeft: 25}}>
                            <li className={"nav-item" }>
                              <a href="/transport/de/prices/economy"
                               className={"nav-link " + (pathName.includes('/transport/de/prices/economy') && ' active' )}>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("economy")}</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="/transport/de/prices/express"
                               className={"nav-link " + (pathName.includes('/transport/de/prices/express') && ' active' ) }>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("express")}</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="/transport/de/prices/settings"
                               className={"nav-link " + (pathName.includes('/transport/de/prices/settings') && ' active' ) }>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("settings")}</p>
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className={"nav-item " + (pathName.includes('de/taxes') && ' menu-open' ) } >
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t('taxes')}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                            <li className={"nav-item "}>
                              <a href="/transport/de/taxes/fuel" className={"nav-link "  + (pathName === '/transport/de/taxes/fuel' && ' active' )}>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("fuel")}
                                </p>
                              </a>
                            </li>
                            <li className={"nav-item "  }>
                              <a href="/transport/de/taxes/pulsio" className={"nav-link " + (pathName === '/transport/de/taxes/pulsio' && ' active' ) }>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("Pulsio")}
                                </p>
                              </a>
                            </li>
                            <li className={"nav-item "  }>
                              <a href="/transport/de/taxes/security" className={"nav-link " + (pathName === '/transport/de/taxes/security' && ' active' ) }>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("security")}
                                </p>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li className={"nav-item has-treeview "  + (pathName.includes('aus') && ' menu-open' )}>
                      <a href="#" className={"nav-link " + (pathName.includes('aus') && ' active' )}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>
                        AUS - {t("Austria")}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                        <li className={"nav-item "  + (pathName.includes('aus/prices') && ' menu-open' )}>
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("price")}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview"} style={{fontSize: 14, marginLeft: 25}}>
                            <li className={"nav-item" }>
                              <a href="/transport/aus/prices/economy"
                               className={"nav-link " + (pathName.includes('/transport/aus/prices/economy') && ' active' )}>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("economy")}</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="/transport/aus/prices/express"
                               className={"nav-link " + (pathName.includes('/transport/aus/prices/express') && ' active' ) }>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("express")}</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="/transport/aus/prices/settings"
                               className={"nav-link " + (pathName.includes('/transport/aus/prices/settings') && ' active' ) }>
                                 <i className="far fa-dot-circle nav-icon"></i>
                                 <p>{t("settings")}</p>
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className={"nav-item " + (pathName.includes('aus/taxes') && ' menu-open' ) } >
                          <a href="#" className={"nav-link" }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t('taxes')}
                              <i className="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                            <li className={"nav-item "}>
                              <a href="/transport/aus/taxes/fuel" className={"nav-link "  + (pathName === '/transport/aus/taxes/fuel' && ' active' )}>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("fuel")}
                                </p>
                              </a>
                            </li>
                            <li className={"nav-item "  }>
                              <a href="/transport/aus/taxes/pulsio" className={"nav-link " + (pathName === '/transport/aus/taxes/pulsio' && ' active' ) }>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("Pulsio")}
                                </p>
                              </a>
                            </li>
                            <li className={"nav-item "  }>
                              <a href="/transport/aus/taxes/security" className={"nav-link " + (pathName === '/transport/aus/taxes/security' && ' active' ) }>
                              <i className="far fa-dot-circle nav-icon"></i>
                                <p>
                                  {t("tax")} {t("security")}
                                </p>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <a href="/transport/usa" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>{t('USA')}</p>
                      </a>
                    </li>

                    <li className={"nav-item has-treeview "  + (pathName.includes('transport/settings') && ' menu-open' )}>
                      <a href="#" className={"nav-link "}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>
                          {t("settings")}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className={"nav nav-treeview "} style={{fontSize: 14, marginLeft: 20}}>
                        <li className={"nav-item "}>
                          <a href="/transport/settings/types" className={"nav-link " + (pathName.includes('settings/types') && ' active' ) }>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("types")}
                            </p>
                          </a>
                        </li>
                        <li className={"nav-item "}>
                          <a href="/transport/settings/groups" className={"nav-link "  + (pathName.includes('settings/groups') && ' active' )}>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("groups")}
                            </p>
                          </a>
                        </li>
                        <li className={"nav-item "}>
                          <a href="/transport/settings/statuses" className={"nav-link "  + (pathName.includes('settings/statuses') && ' active' )}>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("statuses")}
                            </p>
                          </a>
                        </li>
                        <li className={"nav-item "}>
                          <a href="/transport/settings/priorities" className={"nav-link "  + (pathName.includes('settings/priorities') && ' active' )}>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("priorities")}
                            </p>
                          </a>
                        </li>
                        <li className={"nav-item "}>
                          <a href="/transport/settings/tir-groups" className={"nav-link "  + (pathName.includes('settings/tir-groups') && ' active' )}>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("tir-groups")}
                            </p>
                          </a>
                        </li>
                        <li className={"nav-item "}>
                          <a href="/transport/settings/distributions" className={"nav-link "  + (pathName.includes('settings/distributions') && ' active' )}>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("distributions")}
                            </p>
                          </a>
                        </li>
                        <li className={"nav-item "}>
                          <a href="/transport/settings/ratio" className={"nav-link "  + (pathName.includes('settings/ratio') && ' active' )}>
                            <i className="far fa-dot-circle nav-icon"></i>
                            <p>
                              {t("ratio")}
                            </p>
                          </a>
                        </li>
                      </ul>
                    </li>

                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="content-wrapper">{this.props.children}</div>
        <footer className="main-footer">
          <div className="float-right d-none d-sm-inline">
            Anything you want
          </div>
          <strong>Copyright &copy; 2020</strong> All rights reserved.
        </footer>
      </div>
    );
  }
}

export default withRouter(withTranslation()(MainPageWrapper));
