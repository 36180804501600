import React, {Component} from 'react'
import Loader from "../../../partial/Loader";
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from "../../../api/NetworkClient";
import Languages from "../../../constants/Languages";

class PaperNamesForm extends Component {
    state = {
        languages: Languages,
        name: {
            id: null,
            bg_name: '',
            en_name: '',
            fr_name: '',
            de_name: '',
            us_name: '',
        },
        uniqueByComponent: [],
        isLoading: false
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        if(id !== undefined)
        {
            this.load(id);
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`papers/names/${id}`)
                .then(response => {
                    const name = response.name;
                    const uniqueByComponent = response.uniqueByComponent;
                    this.setState({name, uniqueByComponent});
                }).finally(c => {
                    this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});

        const {name} = this.state;
        let url = 'papers/names';
        if (name.id !== null) {
            url += '/' + name.id;
        }

        NetworkClient.post(url, name)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                       this.props.history.push('/papers/names');
                    });
                this.setState({isLoading: false});
            }, error => {
                this.setState({isLoading: false});
            })
    }

    massUpdatePaper() {
        let {uniqueByComponent} = this.state;
        this.setState({isLoading: true})
        NetworkClient.post(`papers/names-mass-update/${this.state.name.id}`,uniqueByComponent)
            .then(response => {
                Swal.fire('Ok!','','success');
            }).finally(()=>{
            this.setState({isLoading: false})
        })
    }

    renderUniqueByComponent() {
        return (
            <div className="col-6">
                <div className="card card-primary">
                    <div className="card-body" style={{lineHeight: 1.5}}>
                        {this.state.uniqueByComponent.map((u, k) => <div key={k}>
                            <h3>
                                {u.product}
                                <button className={'btn btn-sm btn-info'} onClick={()=>{
                                    let newV = !u.show;
                                    let ubc = this.state.uniqueByComponent;
                                    ubc[k].show = newV;
                                    this.setState({uniqueByComponent: ubc});
                                }}>
                                    {u.show === false || u.show === undefined ? '+' : '-'}
                                </button>
                            </h3>
                            {u.elements.map( (e, elk) => <div className={u.show === true ? '' : 'd-none'} key={elk}>
                                <h4 className={'mb-1 mt-1'}>
                                    {e.component}
                                </h4>
                                <div>
                                    normal &nbsp;
                                    <input type="radio"
                                           name={"type[" + u.product_id + "]["+e.component+"]"}
                                           value="normal"
                                           onChange={(e)=>{
                                               if(e.target.checked === true)
                                               {
                                                   let ubc = this.state.uniqueByComponent;
                                                   ubc[k].elements[elk].group = 'normal';
                                                   this.setState({uniqueByComponent: ubc})
                                               }
                                           }}
                                           checked={e.group === 'normal'}
                                    />
                                    &nbsp;&nbsp;
                                    special&nbsp;
                                    <input
                                        type="radio"
                                        name={"type[" + u.product_id + "]["+e.component+"]"}
                                        value="special"
                                        onChange={(e)=>{
                                            if(e.target.checked === true)
                                            {
                                                let {uniqueByComponent} = this.state;
                                                uniqueByComponent[k].elements[elk].group = 'special';
                                                this.setState({uniqueByComponent})
                                            }
                                        }}
                                        checked={e.group === 'special'}
                                    />
                                </div>
                            </div>)}

                            <hr/>
                        </div> )}
                        <hr/>
                        <button onClick={this.massUpdatePaper.bind(this)} className={"btn btn-info btn-sm"}>
                            Update
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {t} = this.props;

        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1>{t('paper-name-create')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className={"col-6"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        {this.state.languages.map((l, i) => <div className="form-group">
                                            <label>{l} Name</label>
                                            <input
                                                key={i}
                                                type="text" className={"form-control"}
                                                placeholder={`${l} Name`} value={this.state.name[l + '_name']}
                                                onChange={(e) => {
                                                    let {name} = this.state;
                                                    name[l + '_name'] = e.target.value;
                                                    this.setState({name});
                                                }}
                                            />
                                        </div>)}
                                        <hr/>
                                        <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                            {this.state.name.id === null ? 'Create' : 'Update'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {this.state.uniqueByComponent.length > 0 && this.renderUniqueByComponent.bind(this)()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(PaperNamesForm);
