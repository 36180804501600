import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"

class AdditionalOptionsForm extends Component {
    state = {
        isLoading: false,
        isbn: '',
        additional_date_remarks: '',
        prepress: {id: -1},
        sample: {id: -1},
        printing: {id: -1},
        is_init: false,
        due_date: null,
        due_date_ymd: null
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.applyChanges(this.getAdditionalOptions())
    }

    componentWillReceiveProps(props) {
        if (props.initial_state !== null && props.initial_state !== undefined && !this.state.is_init)
        {
            let initial_state = props.initial_state;

            let date = new Date();
            if(props.initial_state.due_date !== undefined)
            {
                date = new Date(props.initial_state.due_date);
            }

            this.setState({
                isbn:  initial_state.isbn,
                additional_date_remarks: initial_state.additional_date_remarks,
                prepress: initial_state.prepress,
                sample: initial_state.sample,
                printing: initial_state.printing,
                is_init: true,
                due_date: date,
                due_date_ymd: this.formatDate(date)
            }, () => {
                this.props.applyChanges(this.getAdditionalOptions())
            })
        }
    }

    getAdditionalOptions(){
        return {
            isbn: this.state.isbn,
            additional_date_remarks: this.state.additional_date_remarks,
            prepress: this.state.prepress,
            sample: this.state.sample,
            printing: this.state.printing,
            due_date: this.state.due_date_ymd
        }
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    render() {
        return (
            <div className={'card'}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h3>
                                {this.props.t('additional_options')}
                            </h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label>{this.props.t('printings')}</label>
                            <Select
                                value={this.state.printing}
                                onChange={(selected)=>{
                                    this.setState({printing: selected},() => {
                                        this.props.applyChanges(this.getAdditionalOptions())
                                    })
                                }}
                                options={this.props.printings}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                            />
                        </div>
                        <div className="col-6">
                            <label>{this.props.t('isbn')}</label>
                            <input
                                type="text"
                                className={'form-control'}
                                onChange={e=>this.setState({isbn: e.target.value},() => {
                                    this.props.applyChanges(this.getAdditionalOptions())
                                })}
                                value={this.state.isbn}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <label>{this.props.t('additional_date_remarks')}</label>
                            <textarea
                                value={this.state.additional_date_remarks}
                                className={'form-control'} rows="5"
                                onChange={e=>this.setState({additional_date_remarks: e.target.value},() => {
                                    this.props.applyChanges(this.getAdditionalOptions())
                                })}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <label>{this.props.t('prepress')}</label>
                            <Select
                                onChange={(selected) => {
                                    this.setState({prepress: selected},() => {
                                        this.props.applyChanges(this.getAdditionalOptions())
                                    })
                                }}
                                value={this.state.prepress}
                                options={this.props.prepress}
                                getOptionLabel={option => option.first_name + ' ' + option.last_name}
                                getOptionValue={option => option.id}
                            />
                        </div>
                        <div className="col-6">
                            <label>{this.props.t('samples')}</label>
                            <Select
                                onChange={(selected) => {
                                    this.setState({sample: selected},() => {
                                        this.props.applyChanges(this.getAdditionalOptions())
                                    })
                                }}
                                value={this.state.sample}
                                options={this.props.order_samples}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <label>{this.props.t('due_date')}</label><br/>
                            <DatePicker
                                selected={this.state.due_date}
                                onChange={date => {
                                    let d = this.formatDate(date);
                                    this.setState({due_date: date, due_date_ymd: d}, () => {
                                        this.props.applyChanges(this.getAdditionalOptions())
                                    })
                                }}
                                className={'form-control'}
                            />
                        </div>
                        <div className="col-6">
                            <label>{this.props.t('initial_dead_line')}</label>
                            <input
                                type="text"
                                className={'form-control'}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(AdditionalOptionsForm);
