import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../../../../helpers/DataTableInitDataGenerator';
import NetworkClient from '../../../../../api/NetworkClient';
import Loader from '../../../../../partial/Loader';
import SwalDelete from '../../../../../helpers/SwalDelete';

class OverchargesList extends Component {
    state = {
        isLoading: false,
        data: {
            columns: [],
            rows: []
        }
    };
    componentWillReceiveProps() {
        this.componentDidMount();
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {t} = this.props;
        let listColumns = ['id', 'group', 'paper_type', 'weight_from', 'weight_to',
        'overcharge', 'k1', 'k2', 'k3', 'k4', 'k5', 'enabled', 'actions'];
        this.setState({data: DataTableInitDataGenerator(listColumns, t)});
        this.load();
    }

    load() {
        this.setState({isLoading: true});
        NetworkClient.get('transport/fr/overcharges').then(response => {
            let {data} = this.state;
            let items = response.overcharges;
            let rows = [];
            items.forEach((item, i) => {
                let tmp = {
                    id: item.id,
                    group: item.group,
                    paper_type: item.paper_type,
                    weight_from: item.weight_from,
                    weight_to: item.weight_to,
                    overcharge: item.overcharge,
                    k1: item.k1,
                    k2: item.k2,
                    k3: item.k3,
                    k4: item.k4,
                    k5: item.k5,
                    enabled: item.enabled === 1 ? (<i className="fa fa-check"></i>) : '',
                    actions: <div>
                        <Link to={`/transport/france/overcharges/edit/${item.id}`} className={"btn btn-info btn-sm"}>
                            <i className={"fa fa-pen"} />
                        </Link>
                        <button onClick={()=>{this.delete(item.id)}} className={"btn btn-danger btn-sm"}>
                            <i className={"fa fa-times"} />
                        </button>
                    </div>
                };
                rows.push(tmp);
            });


            this.setState({data:{columns: data.columns, rows: rows}, isLoading: false});
        })
    }

    delete(id) {
        const {t} = this.props;
        SwalDelete(t).then(c => {
            if(c.value)
            {
                this.setState({isLoading: true},() => {
                    NetworkClient.delete(`/transport/fr/overcharges/${id}`)
                        .then(response => {
                            let {data} = this.state;
                            let indexToRemove = data.rows.findIndex(r => r.id === id);
                            data.rows.splice(indexToRemove, 1);
                            this.setState({data: {columns:data.columns,rows:data.rows}, isLoading: false});
                        })
                });
            }
        })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                FR {t('transport')} {t('overcharges')}
                <p>
                    Formula: (k1 + (k2 * (k3 + weight))) * (1 + overcharge / 100) * k4  (weight)
                    <span style={{fontSize: 12, display: 'block'}}>* K4 (0 or 1). When K4 is 0, K4 = weight. K5 is paper type index (depends on paper type and weight)</span>
                </p>
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <Link to={'/transport/france/overcharges/create'} className={"btn btn-info btn-sm mb-3"}>
                                            {t('create')} <i className={"fa fa-plus"} />
                                        </Link>
                                        <MDBDataTable
                                            striped
                                            bordered
                                            hover
                                            entries={10}
                                            data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(OverchargesList);