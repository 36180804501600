import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal, {isLoading} from 'sweetalert2'
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import Languages from "../../constants/Languages";
import SimpleFieldsGenerator from "../../helpers/SimpleFieldsGenerator";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Select from "react-select/";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: grid * 2,
    padding: "0px 16px",
    margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    border: '1px solid',
    padding: grid,
    margin:grid,
    width: '100%',
    borderRadius: '.25rem',
    overflowY: 'scroll',
});
const measure = [ {value: 1, label: 'mm'}, {value: 2, label: 'inches'}];

class WebsiteForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        website: {
            id: null,
            name: '',
            domain: '',
            currency: '',
            default_locale: '',
            email: '',
            phone: '',
            team: '',
            address_info: '',
            has_department: 0,
            measure: '',
            relatedProducts: []
        },
        products: [],
        currencies: [],

        // states for dnd products
        inActiveProducts: [],
        activeProducts: [],
    };

    constructor(props) {
        super(props);
    }

    /*
    *   END dnd example
    */

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'inActiveProducts',
        droppable2: 'activeProducts'
    };

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        const { source, destination, product } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            const inActiveProducts = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { inActiveProducts };

            if (source.droppableId === 'droppable2') {
                state = { activeProducts: inActiveProducts };
            }

            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                inActiveProducts: result.droppable,
                activeProducts: result.droppable2,
            });
        }

    };


    /*
    *
    *   END IMPLEMENT DND
     */
    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.setState({activeProducts: [], inActiveProducts:[]})
        if(id !== undefined)
        {
            this.load(id);
        }
        else
        {
            this.setState({isLoading:true});
            NetworkClient.get('websites-products-currencies-json')
                .then(response => {
                    let products = response.products;
                    let currencies = response.currencies;
                    this.setState({products: products, currencies:currencies, isLoading: false});
                }).finally(c=> {

                this.productChecker();
            });
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`websites/${id}`)
                .then(response => {
                    const website = response.website;
                    const products = response.products;
                    const currencies = response.currencies;
                    this.setState({website: website, products: products, currencies:currencies});
                }).finally(c => {
                this.setState({isLoading: false});
                this.productChecker();
            })
        })
    }

    assigningActivityProductsToRelatedProduct(){
        let {website} = this.state;
        website.relatedProducts = this.state.activeProducts.map((item, index) => ({
                'id': item.id,
                'order': index
            }));
    }

    store() {
        this.assigningActivityProductsToRelatedProduct();
        this.setState({isLoading: true});
        const {website } = this.state;
        let url = 'websites';
        if(website.id !== null)
        {
            url += '/' + website.id;
        }

        NetworkClient.post(url, website)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c=>{
                        this.props.history.push('/websites');
                    })
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    productChecker()
    {
        let products = this.state.products;

        products.map((i,k) =>{

            if (i.isActive == 1)
            {
                this.setState({activeProducts: [...this.state.activeProducts, i].sort((a,b) => a.order - b.order)})
            }
            else
            {
                this.setState({inActiveProducts: [...this.state.inActiveProducts, i]})
            }
        });
    }


    render() {
        const {t} = this.props;
        let fieldsLang = this.state.languages.map((l) => {return {name: `${l}_name`, type:'text'}});
        let languagesForSelect = this.state.languages.map((item, index) => ({id:index,name: item}) );
        let defaultValue = {id: -1, name: this.state.website.default_locale.toString()};
        let currenciesOptions = this.state.currencies;

        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('Website')} {t('color')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-8"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {SimpleFieldsGenerator.bind(this, t,[{name: 'name', type: 'text'}], "website")()}
                                    <div className="form-group " style={{display: 'flex'}}>
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                             <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        style={getListStyle(snapshot.isDraggingOver)}>
                                                        {this.state.inActiveProducts.map((item, index) => (
                                                            <Draggable
                                                                key={item.id}
                                                                draggableId={item.id.toString()}
                                                                index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}>
                                                                        {item.name}
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>

                                             <Droppable droppableId="droppable2">
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}
                                                        style={getListStyle(snapshot.isDraggingOver)}>
                                                        {this.state.activeProducts.map((item, index) => (
                                                            <Draggable
                                                                key={item.id}
                                                                draggableId={item.id.toString()}
                                                                index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}>
                                                                        {item.name}
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                    {SimpleFieldsGenerator.bind(this,t,[{name:'domain', type: 'text'}], "website")()}

                                    <label>{t('currency')}</label>
                                    <Select
                                        value={currenciesOptions.find(currency =>currency.type === this.state.website.currency)}
                                        options={currenciesOptions}
                                        onChange={(selected) => {
                                            let {website} = this.state;
                                            website.currency = selected.type;
                                            this.setState({website:website});
                                        }}
                                        getOptionLabel={(option)=> option.type}
                                        getOptionValue={(option) => option.id}
                                    />
                                    <label>default locale</label>
                                    <Select
                                        value={languagesForSelect.find(c => (c.name !==undefined && c.name === this.state.website.default_locale) ||  c == this.state.website.default_locale)}
                                        onChange={(selected)=>{
                                            let {website} = this.state;
                                            //console.log(this.state.languages.find(c => c === this.state.website.default_locale));
                                            website.default_locale = selected.name;
                                            this.setState({website: website});
                                        }}
                                        defaultValue={defaultValue}
                                        options={languagesForSelect}
                                        getOptionLabel={(option)=> option.name}
                                        getOptionValue={(option) => option.id}
                                    />
                                    {SimpleFieldsGenerator.bind(this,t,[{name:'email', type: 'text'}], "website")()}
                                    {SimpleFieldsGenerator.bind(this,t,[{name:'phone', type: 'text'}], "website")()}
                                    {SimpleFieldsGenerator.bind(this,t,[{name:'team', type: 'text'}], "website")()}
                                    <div className="form-group">
                                        <label>Addres info</label>

                                        <textarea className={"form-control"} name={"address_info"} rows={7}
                                                  onChange={(e) => {
                                                      let {website} = this.state;
                                                      website.address_info = e.target.value;
                                                      this.setState({website});
                                                  }}
                                                  defaultValue={this.state.website.address_info}>

                                        </textarea>
                                    </div>
                                    <div className="form-group">
                                        <input type="checkbox" checked={this.state.website.has_department === 1}
                                               onChange={e => {
                                                   let {website} = this.state;
                                                   website.has_department = e.target.checked === true ? 1 : 0;
                                                   this.setState({website:website});
                                               }}
                                        /> {t('Has_Departments')}
                                    </div>
                                   <div className="form-group">
                                       <label>{t('measure')}</label>
                                       <Select
                                           value={measure.find(measure => measure.value === this.state.website.measure)}
                                           options={measure}
                                           onChange={(selected) => {
                                               let {website} = this.state;
                                               website.measure = selected.value;
                                               this.setState({website});
                                           }}
                                       />
                                   </div>

                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.website.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(WebsiteForm);