import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../helpers/DataTableInitDataGenerator';
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import SwalDelete from '../../helpers/SwalDelete';
import Select from 'react-select';
import Swal from 'sweetalert2';

class DistributionList extends Component {
    state = {
        isLoading: false,
        data: {
            columns: [],
            rows: []
        },
        list_formats: [],
        paper_cut_types: [],
        turners: [],
        filters: {
            list_format: 1,
            paper_cut_type: 11,
            turner: 0
        },
        distributions: [],
        modal: false,
        selected_papers: []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let el = document.getElementById('bars');
        el.click();
        const {t} = this.props;
        let listColumns = ['id',
            'paper_format_height',
            'paper_format_length',
            'paper_max_format_height',
            'paper_max_format_length',
            'format',
            'paper_max_format_cut_length',
            'paper_max_format_cut_height',
            'count_pages_in_print_list',
            'papers',
            'actions'
        ];
        this.setState({data: DataTableInitDataGenerator(listColumns, t)});
        this.load();
        this.loadFilters();
    }

    loadFilters() {
        this.setState({isLoading: true}, () => {
            NetworkClient.get('get-distribution-filter-fields')
                .then(response => {
                    const list_formats = response.list_formats;
                    const paper_cut_types = response.paper_cut_types;
                    const turners = response.turners;
                    this.setState({list_formats, paper_cut_types, isLoading: false, turners})
                })
        });
    }

    handleTextInputChange(e, fieldName, index) {
        let {distributions} = this.state;
        distributions[index][fieldName] = e.target.value;
        this.setState({distributions},()=>{
            this.fillDataTables(distributions);
        });
    }

    fillDataTables(distributions) {
        let {data} = this.state;
        let rows = [];

        distributions.forEach((item, i) => {
            let tmp = {
                id: item.id,
                paper_format_height: <input
                    type="number"
                    style={{width: 60}}
                    value={this.state.distributions[i].paper_format_height}
                    onChange={e=>this.handleTextInputChange(e,'paper_format_height',i)}
                />,
                paper_format_length: <input
                    type="number"
                    style={{width: 60}}
                    value={this.state.distributions[i].paper_format_length}
                    onChange={e=>this.handleTextInputChange(e,'paper_format_length',i)}
                />,
                paper_max_format_height: <input
                    type="number"
                    style={{width: 60}}
                    value={this.state.distributions[i].paper_max_format_height}
                    onChange={e=>this.handleTextInputChange(e,'paper_max_format_height',i)}
                />,
                paper_max_format_length: <input
                    type="number"
                    style={{width: 60}}
                    value={this.state.distributions[i].paper_max_format_length}
                    onChange={e=>this.handleTextInputChange(e,'paper_max_format_length',i)}
                />,
                format: <input
                    type="text"
                    style={{width: 60}}
                    value={this.state.distributions[i].format}
                    onChange={e=>this.handleTextInputChange(e,'format',i)}
                />,
                paper_max_format_cut_height: <input
                    type="text"
                    style={{width: 60}}
                    value={this.state.distributions[i].paper_max_format_cut_height}
                    onChange={e=>this.handleTextInputChange(e,'paper_max_format_cut_height',i)}
                />,
                paper_max_format_cut_length: <input
                    type="text"
                    style={{width: 60}}
                    value={this.state.distributions[i].paper_max_format_cut_length}
                    onChange={e=>this.handleTextInputChange(e,'paper_max_format_cut_length',i)}
                />,
                count_pages_in_print_list: <input
                    type="text"
                    style={{width: 60}}
                    value={this.state.distributions[i].count_pages_in_print_list}
                    onChange={e=>this.handleTextInputChange(e,'count_pages_in_print_list',i)}
                />,
                papers: <div>
                    <button data-toggle="modal" data-target="#myModal" className={"btn btn-info btn-sm"} onClick={()=>{
                        this.setState({selected_papers: this.state.distributions[i].papers})

                    }}>
                        <i className={"fa fa-desktop"}/>
                    </button>
                </div>,
                actions: <div>
                    <button onClick={this.save.bind(this, item)} className={"btn btn-info btn-sm"}>
                        <i className={"far fa-save"}/>
                    </button>
                    <button onClick={() => {
                        this.delete(item.id)
                    }} className={"btn btn-danger btn-sm"}>
                        <i className={"fa fa-times"}/>
                    </button>
                </div>
            };
            rows.push(tmp);
        });

        this.setState({data: {columns: data.columns, rows: rows}, isLoading: false});
    }

    save(distribution) {

        const {t} = this.props;

        Swal.fire({
            title: t('are-you-sure'),
            text: t('are-you-sure'),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('yes')
        }).then( c => {
            if(c.value)
            {
                this.setState({isLoading: true}, () => {
                    console.log(distribution);
                    NetworkClient.post('distribution/' + distribution.id,distribution)
                        .then(response => {
                            this.setState({isLoading: false});
                        })
                });
            }
        })


    }

    load() {
        this.setState({isLoading: true});
        let url = `distribution?paper_cut_type=${this.state.filters.paper_cut_type}&list_format=${this.state.filters.list_format}&turner=${this.state.filters.turner}`;
        NetworkClient.get(url)
            .then(response => {
            let items = response.distributions;
            this.setState({distributions: items}, () => {
                this.fillDataTables(items)
            })
        })
    }

    delete(id) {
        const {t} = this.props;

        SwalDelete(t).then( c => {
            if(c.value)
            {
                this.setState({isLoading: true}, () => {
                    
                });
            }
        })
    }

    saveInner(sp) {
        this.setState({isLoading: true}, ()=>{
            NetworkClient.post('distribution-inner/' + sp.id, sp)
                .then(response => {
                    this.setState({isLoading: false});
                })
        });
    }

    handleChangeSP(val, fieldName, index)
    {
        let {selected_papers} = this.state.selected_papers;
        selected_papers[index][fieldName] = val;
        console.log(selected_papers);
        this.setState({selected_papers})
    }

    modal() {
        return (<div className="modal" id="myModal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className={"table-responsive"}>
                            <table className={"table table-bordered"}>
                                <tr>
                                    <td>
                                        Бр. стр в кола
                                    </td>
                                    <td>
                                        Бр. гънки
                                    </td>
                                    <td>
                                        Бр. коли в печатен лист
                                    </td>
                                    <td>
                                        Мин грамаж хартия
                                    </td>
                                    <td>
                                        Макс грамаж хартия
                                    </td>
                                    <td>
                                        Действия
                                    </td>
                                </tr>
                                {this.state.selected_papers.map((sp,index) => <tr>
                                    <td>
                                        <input
                                            style={{width: 60}}
                                            type="text"
                                            value={this.state.selected_papers[index]['count_pages_in_cola']}
                                            onChange={ e => this.handleChangeSP.bind(this, e.target.value,'count_pages_in_cola',index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{width: 60}}
                                            type="text"
                                            value={sp.count_cold}
                                            onChange={ e => this.handleChangeSP.bind(this, e.target.value,'count_cold',index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{width: 60}}
                                            type="text"
                                            value={sp.count_cola_in_print_list}
                                            onChange={ e => this.handleChangeSP.bind(this, e.target.value,'count_cola_in_print_list',index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{width: 60}}
                                            type="number"
                                            value={sp.paper_min_density_size}
                                            onChange={ e => this.handleChangeSP.bind(this, e.target.value,'paper_min_density_size',index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{width: 60}}
                                            type="number"
                                            value={sp.paper_max_density_size}
                                            onChange={ e => this.handleChangeSP.bind(this,e.target.value,'paper_max_density_size',index)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={this.saveInner.bind(this, sp)} className={"btn btn-info btn-sm"}>
                                            <i className={"far fa-save"}/>
                                        </button>
                                    </td>
                                </tr>)}
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>

                </div>
            </div>
        </div>)
    }

    renderFilters(t)
    {
        return (<div className="row">
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-2">
                                <label htmlFor="">{t('list_types')}</label>
                                <Select
                                    value={this.state.list_formats.find(c => c.id === this.state.filters.list_format)}
                                    onChange={(selected)=>{
                                        let {filters} = this.state;
                                        filters.list_format = selected.id;
                                        this.setState({filters});
                                    }}
                                    options={this.state.list_formats}
                                    getOptionLabel={(option)=> option.name}
                                    getOptionValue={(option) => option.id}
                                />
                            </div>
                            <div className="col-2">
                                <label htmlFor="">{t('binding')}</label>
                                <Select
                                    value={this.state.paper_cut_types.find(c => c.id === this.state.filters.paper_cut_type)}
                                    onChange={(selected)=>{
                                        let {filters} = this.state;
                                        filters.paper_cut_type = selected.id;
                                        this.setState({filters});
                                    }}
                                    options={this.state.paper_cut_types}
                                    getOptionLabel={(option)=> option.name}
                                    getOptionValue={(option) => option.id}
                                />
                            </div>
                            <div className="col-2">
                                <label htmlFor="">{(t('turner'))}</label>
                                <Select
                                    value={this.state.turners.find(c => c.id === this.state.filters.turner)}
                                    onChange={(selected)=>{
                                        let {filters} = this.state;
                                        filters.turner = selected.id;
                                        this.setState({filters});
                                    }}
                                    options={this.state.turners}
                                    getOptionLabel={(option)=> option.name}
                                    getOptionValue={(option) => option.id}
                                />
                            </div>
                            <div className="col-4">
                                <br/>
                                <button
                                    onClick={this.load.bind(this)}
                                    className={"btn btn-info btn-sm btn-block"}>{t('search')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    {t('page-title')}
                </div>

                <div className="content">
                    <div className="container-fluid">
                        {this.modal()}
                        {this.renderFilters(t)}
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <Link to={'/distribution/create'} className={"btn btn-info btn-sm mb-3"}>
                                            {t('create')} <i className={"fa fa-plus"}/>
                                        </Link>
                                        <MDBDataTable
                                            striped
                                            bordered
                                            hover
                                            entries={100}
                                            data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(DistributionList);
