import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';

class EmbossingFoilFormPartial extends Component {
    state = {
        isLoading: false,
        embossing_foil: {
            is_enabled: false,
            number_of_stamps: 1,
            sizes: [
                {
                    length: 100,
                    height: 100,
                    lengthInches: 3.94,
                    heightInches: 3.94,
                    type: 122
                }
            ]
        },
        is_inited: false
    }

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(props) {
        if(!this.state.is_inited && props.initial_state !== null && props.initial_state !== undefined)
        {
            let embossing = props.initial_state;
            this.setState({embossing_foil: props.initial_state})
        }
    }

    render() {
        return ( <> <div
            className="row pb-3"
            style={{borderBottom: '1px solid silver'}}>
            <div className="col-6">
                <label>
                    <input
                        checked={this.state.embossing_foil.is_enabled}
                        onChange={e => {
                            let {embossing_foil} = this.state;
                            embossing_foil.is_enabled = e.target.checked;
                            this.setState({embossing_foil},()=> {
                                this.props.applyChanges(this.state.embossing_foil);
                            })
                        }}
                        type="checkbox"/> &nbsp;
                    {this.props.t('embossing_foil')}
                </label>
                <div className={(this.state.embossing_foil.is_enabled ? ' ' : 'd-none')}>
                    {this.props.t('all_sizes_in_mm')} {this.props.measure}
                </div>
            </div>
            <div className={"col-6 " + (this.state.embossing_foil.is_enabled ? ' ' : 'd-none')}>
                <label>{this.props.t('number_of_stamps')}</label>
                <select onChange={e => {
                    let embossing = this.state.embossing_foil;
                    let sizes = embossing.sizes;
                    let value = e.target.value;
                    if(sizes.length < value)
                    {
                        while(sizes.length < value)
                        {
                            sizes.push({
                                length: 100,
                                height: 100,
                                lengthInches: 3.94,
                                heightInches: 3.94,
                                type: 122
                            })
                        }
                    }

                    if(sizes.length > value)
                    {
                        while(sizes.length > value)
                        {
                            sizes.splice(sizes.length - 1,1);
                        }
                    }

                    embossing.sizes = sizes;
                    embossing.number_of_stamps = value;

                    this.setState({embossing_foil: embossing}, () => {
                        this.props.applyChanges(this.state.embossing_foil);
                    });
                }} className={'form-control'}>
                    <option selected={this.state.embossing_foil.number_of_stamps == 1} value="1">1</option>
                    <option selected={this.state.embossing_foil.number_of_stamps == 2} value="2">2</option>
                    <option selected={this.state.embossing_foil.number_of_stamps == 3} value="3">3</option>
                    <option selected={this.state.embossing_foil.number_of_stamps == 4} value="4">4</option>
                </select>
            </div>
        </div>
            <div className={"row mt-3 " + (this.state.embossing_foil.is_enabled ? ' ' : 'd-none') }>
                {this.state.embossing_foil.sizes.map( (size, index) => {
                    return (<>
                        <div className="col-3">
                            <label>{this.props.t('length')}</label>
                            <input
                                type="number"
                                min={3.94}
                                value={this.state.embossing_foil.sizes[index].lengthInches}
                                onChange={e => {
                                    let {embossing_foil} = this.state;
                                    let inches = e.target.value;
                                    embossing_foil.sizes[index].length = Math.ceil(inches * 25.4);
                                    embossing_foil.sizes[index].lengthInches = inches;
                                    this.setState({embossing_foil},()=>{
                                        this.props.applyChanges(this.state.embossing_foil);
                                    })
                                }}
                                className={"form-control"}
                            />
                            <input
                                value={this.state.embossing_foil.sizes[index].length}
                                onChange={e => {
                                    let {embossing_foil} = this.state;
                                    embossing_foil.sizes[index].length = e.target.value;
                                    let inches = parseFloat(e.target.value * 0.0393700787).toFixed(2);
                                    embossing_foil.sizes[index].lengthInches = inches;
                                    this.setState({embossing_foil},()=>{
                                        this.props.applyChanges(this.state.embossing_foil);
                                    });
                                }}
                                type="number"
                                min={100}
                                className={"form-control" + (this.props.measure === 'mm' ? '' : ' d-none')}
                            />
                            {this.state.embossing_foil.sizes[index].length < 100 && <div className={'text-red'}>
                                {this.props.t('minimal_size')} 100 {this.props.measure}
                            </div>}
                        </div>
                        <div className="col-3">
                            <label>{this.props.t('height')}</label>
                            <input
                                min={3.94}
                                value={this.state.embossing_foil.sizes[index].heightInches}
                                onChange={e => {
                                    let {embossing_foil} = this.state;
                                    let inches = e.target.value;
                                    embossing_foil.sizes[index].height = Math.ceil(inches * 25.4);
                                    embossing_foil.sizes[index].heightInches = inches;
                                    this.setState({embossing_foil},()=>{
                                        this.props.applyChanges(this.state.embossing_foil);
                                    })
                                }}
                                type="number"
                                className={"form-control"}
                            />
                            <input
                                value={this.state.embossing_foil.sizes[index].height}
                                onChange={e => {
                                    let {embossing_foil} = this.state;
                                    embossing_foil.sizes[index].height = e.target.value;
                                    let inches = parseFloat(e.target.value * 0.0393700787).toFixed(2);
                                    embossing_foil.sizes[index].heightInches = inches;
                                    this.setState({embossing_foil},()=>{
                                        this.props.applyChanges(this.state.embossing_foil);
                                    });
                                }}
                                type="number"
                                min={100}
                                className={"form-control" + (this.props.measure === 'mm' ? '' : ' d-none')}
                            />
                            {this.state.embossing_foil.sizes[index].height < 100 && <div className={'text-red'}>
                                {this.props.t('minimal_size')} 100 {this.props.measure}
                            </div>}
                        </div>
                        {/*TODO: Трябва да се извличат от базата. След като калкулатор помощника бива разделен на категории */}
                        <div className="col-6">
                            <label>{this.props.t('foil')}</label>
                            <select onChange={e => {
                                let {embossing_foil} = this.state;
                                embossing_foil.sizes[index].type = parseInt(e.target.value);
                                this.setState({embossing_foil});
                            }} className={'form-control'}>
                                <option selected={this.state.embossing_foil.sizes[index].type === 144} value="144"> Gold - gloss</option>
                                <option selected={this.state.embossing_foil.sizes[index].type === 122} value="122"> Silver - gloss</option>
                                <option selected={this.state.embossing_foil.sizes[index].type === 123} value="123"> Holographic</option>
                                <option selected={this.state.embossing_foil.sizes[index].type === 124} value="124"> Colorful</option>
                            </select>
                        </div>
                    </>)
                })}
            </div>
        </>)
    }
}

export default withTranslation()(EmbossingFoilFormPartial);
