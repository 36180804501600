import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import Languages from "../../constants/Languages";
import Select from "react-select";

class PermissionsForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        permission: {
            id: null,
            role_id: -1,
            permission: []
        },
        menuItems: [],
        roles: []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id !== undefined)
        {
            this.load(id);
        }
        else
        {
            this.getMenuItems();
        }
    }

    getMenuItems() {
        this.setState({isLoading: true}, () => {
        NetworkClient.get('permissions-menu-items')
            .then(response => {
                let {menu_items} = response;
                let {roles} = response;
                this.setState({menuItems: menu_items, isLoading: false, roles});
            })
        });
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`permissions/${id}`)
                .then(response => {
                    let permission = response.permission;
                    permission.permission = JSON.parse(permission.permission);
                    const menuItems = response.menu_items;
                    const {roles} = response;
                    this.setState({permission, menuItems, roles});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {permission} = this.state;
        let url = 'permissions';
        if (permission.id !== null) {
            url += '/' + permission.id;
        }

        NetworkClient.post(url, permission)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        //this.props.history.push('/link');
                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    printMenuItems() {
        return this.state.menuItems.map(mi => <div className={"mt-2"} style={{fontSize: 19}}>
            <label>
            <input
                type={"checkbox"}
                onChange={(e) => {
                    let newValue = e.target.checked;
                    let permission = this.state.permission;
                    let menuItems = this.state.menuItems;
                    //debugger;
                    if(newValue === true)
                    {
                        let item = menuItems.find(m => m.name === mi.name);
                        permission.permission.push(item);
                    }
                    else
                    {
                        let indexToRemove = permission.permission.findIndex(m => m.name === mi.name);
                        permission.permission.splice(indexToRemove, 1);
                    }

                    this.setState({permission});
                }}
                checked={this.state.permission.permission.findIndex(p => p.name === mi.name) !== -1}
            />
            <span className={"ml-2"}>
                {mi.name}
            </span>
            </label>
        </div>)
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('permissions')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className={"col-6"}>
                                <div className="card card-primary">


                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>{t('role')}</label>
                                            <Select
                                                value={this.state.roles.find(role => role.id === this.state.permission.role_id)}
                                                options={this.state.roles}
                                                onChange={(selected) => {
                                                    let {permission} = this.state;
                                                    permission.role_id = selected.id;
                                                    this.setState({permission});
                                                }}
                                                getOptionLabel={(option)=> option.display_name}
                                                getOptionValue={(option) => option.id}
                                            />
                                        </div>


                                        <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                            {this.state.permission.id === null ? 'Create' : 'Update'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <b>
                                            {t('permissions')}
                                        </b>
                                        <div className={"mt-3"}>
                                            {this.printMenuItems()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PermissionsForm);
