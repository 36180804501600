import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../../../../../helpers/DataTableInitDataGenerator';
import NetworkClient from '../../../../../../api/NetworkClient';
import Loader from '../../../../../../partial/Loader';
import SwalDelete from '../../../../../../helpers/SwalDelete';

class SettingsList extends Component {
    state = {
        isLoading: false,
        data: {
            columns: [],
            rows: []
        }
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {t} = this.props;
        let listColumns = ['id', 'type', 'step', 'price', 'actions'];
        this.setState({data: DataTableInitDataGenerator(listColumns, t)});
        this.load();
    }

    load() {
        this.setState({isLoading: true});
        NetworkClient.get('transport/ch/prices/settings').then(response => {
            let {data} = this.state;
            let items = response.settings;
            let rows = [];
            items.forEach((item, i) => {
                let tmp = {
                    id: item.id,
                    type: item.type,
                    step: item.step,
                    price: item.price,
                    actions: <div>
                        <Link to={`/transport/switzerland/prices/settings/edit/${item.id}`} className={"btn btn-info btn-sm"}>
                            <i className={"fa fa-pen"} />
                        </Link>
                    </div>
                };
                rows.push(tmp);
            });


            this.setState({data:{columns: data.columns, rows: rows}, isLoading: false});
        })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    {t('transport')} {t('settings')} {t('prices')}
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                
                                        <MDBDataTable
                                            striped
                                            bordered
                                            hover
                                            entries={10}
                                            data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SettingsList);