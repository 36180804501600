import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Select from 'react-select';

class PriceCorrectionForm extends Component {
    state = {
        isLoading: false,
        priceCorrection: [],
        types: [],
        isOpen: false,
        current_language: null,
        is_inited: false,
        priceCorrectionFocus: -1
    }

    constructor(props) {
        super(props);

    }
    componentWillReceiveProps(props) {
        if(!this.state.is_inited && props.initial_state.length > 0)
        {
            let priceCorrection = {...props.initial_state};
            priceCorrection = Object.values(priceCorrection);
            let isOpen = false;
            for (let i = 0; i < priceCorrection.length; i++)
            {
                if(priceCorrection[i].type >= 1  || (priceCorrection[i].amount !== null && priceCorrection[i].amount.length > 0)
                    || (priceCorrection[i].description !== null && priceCorrection[i].description.length > 0) )
                {
                    isOpen = true;
                }
            }
            this.setState({priceCorrection, is_inited: true, isOpen});
        }

        this.componentDidMount();
    }

    componentDidMount() {
        if(this.state.current_language !== this.props.i18n.language)
        {
            let item = {
                type: '',
                amount: '',
                description: ''
            }
            let priceCorrection = [];
            for (let i=0;i<4;i++){
                priceCorrection.push({...item});
            }

            let types = [
                {id: 0, name: this.props.t('none')},
                {id: 1, name: this.props.t('price_correction_option_included_invisible_button')},
                {id: 2, name: this.props.t('price_correction_option_included_included_button')},
                {id: 3, name: this.props.t('price_correction_option_included_free_button')}
            ];

            this.setState({priceCorrection, types,current_language: this.props.i18n.language});
        }
    }

    handleChange(value, index, fieldName) {
        let {priceCorrection} = this.state;
        priceCorrection[index][fieldName] = value;
        this.setState({priceCorrection},() => {
            this.props.applyChanges(this.getPriceCorrection());
        });
    }

    getPriceCorrection(trigger = false) {
        return {priceCorrection: this.state.priceCorrection, trigger: trigger};
    }

    getMinimizeIcon() {
        if(this.state.isOpen)
        {
            return (<i className="fa fa-window-minimize" aria-hidden="true" />)
        }

        return (<i className="fa fa-plus" aria-hidden="true" />)
    }

    render() {
        return (
            <div className={'card mt-3'}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h3>
                                <div>
                                    <i className={'fa fa-book'} /> {this.props.t('price_correction_title')}
                                    <button onClick={()=>{
                                        this.setState({isOpen: !this.state.isOpen});
                                    }} className={'btn btn-sm btn-info float-right'}>
                                        {this.getMinimizeIcon()}
                                    </button>
                                </div>
                            </h3>
                        </div>
                    </div>
                    <div className={'collapse ' + (this.state.isOpen ? 'show' : '')}>
                        {this.state.priceCorrection.map((p,index) => <div className={'row mb-3'}>
                            <div className="col-4">
                                <label>{this.props.t('type')} {index+1}</label>
                                <Select
                                    value={this.state.types.find(x=>x.id == this.state.priceCorrection[index].type)}
                                    onChange={(selected) => {
                                        //
                                        let sel = selected.id;
                                        let {priceCorrection} = this.state;
                                        priceCorrection[index].type = sel;
                                        this.setState(priceCorrection,() => {
                                            this.props.applyChanges(this.getPriceCorrection());
                                        });
                                    }}
                                    options={this.state.types}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                />
                            </div>
                            <div className="col-4">
                                <label>{this.props.t('amount')} {index+1}</label>
                                <input
                                    type="number"
                                    className={'form-control'}
                                    value={this.state.priceCorrection[index].amount}
                                    onChange={e=> {
                                        if(e.target.value >= 0)
                                        {
                                            this.handleChange(e.target.value,index,'amount')
                                        }
                                    }}
                                    onFocus={e => {
                                        this.setState({priceCorrectionFocus: e.target.value})
                                    }}
                                    onBlur={e => {
                                        if(e.target.value !== this.state.priceCorrectionFocus)
                                        {
                                            this.props.applyChanges(this.getPriceCorrection(true));
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-4">
                                <label>{this.props.t('description')} {index+1}</label>
                                <input
                                    type="text"
                                    className={'form-control'}
                                    value={this.state.priceCorrection[index].description}
                                    onChange={e=>this.handleChange(e.target.value,index,'description')}
                                />
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PriceCorrectionForm);
