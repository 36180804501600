import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Languages from "../../../constants/Languages";
import SimpleFieldsGenerator from "../../../helpers/SimpleFieldsGenerator";
import DragAndDropTwoColumns from "../../../helpers/DragAndDropTwoColumns";
import DragAndDropForPapers from "../../../helpers/DragAndDropForPapers";
import update from 'immutability-helper';
import productTypesForm from "../types/productTypesForm";

// const [] = [{id: "1", name: "name"}, {id: "2", name: "name2"}];


class ProductsForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        propertyTypes: [],
        prints: [],
        printsSelected: [],
        bindings: [],
        bindingsSelected: [],
        finishings: [],
        finishingsSelected: [],
        foldings: [],
        foldingsSelected: [],
        properties: [],
        papers: [],
        formattedPapers: [],
        selectedCoverPaper: [],
        generalProperties: [],
        cover: false,
        text: false,
        insert: false,
        jacket: false,
        banderole: false,
        cloth: false,
        cardboard: false,
        endpapers: false,

        product: {
            id: null,
            enabled: 0,
            propertyTypes: [],
            fixed_cost: 0,
            components: [
                {
                    name: 'cover',
                    print: [],
                    finishing: [],
                    is_enabled: 0,
                    papers: [],
                    has_finishing: 1,
                    has_print: 1,
                    has_flaps: 1,
                    has_embossing: 1,
                    has_embossing_foil: 1,
                    has_pantone: 1,
                    has_paper_client: 1,
                    has_varnish: 0,
                    is_checked_flash: 0,
                    has_additional_options: 1,
                    properties: []
                },
                {
                    name: 'text',
                    print: [],
                    finishing: [],
                    is_enabled: 0,
                    papers: [],
                    has_finishing: 1,
                    has_print: 1,
                    has_flaps: 0,
                    has_embossing: 1,
                    has_embossing_foil: 1,
                    has_pantone: 1,
                    has_paper_client: 1,
                    has_varnish: 1,
                    is_checked_flash: 0,
                    has_additional_options: 1,
                    properties: []
                },
                {
                    name: 'insert',
                    print: [],
                    finishing: [],
                    is_enabled: 0,
                    papers: [],
                    has_finishing: 0,
                    has_print: 1,
                    has_flaps: 0,
                    has_embossing: 0,
                    has_embossing_foil: 0,
                    has_pantone: 0,
                    has_paper_client: 0,
                    has_varnish: 0,
                    is_checked_flash: 0,
                    has_additional_options: 1,
                    properties: []
                },
                {
                    name: 'jacket',
                    print: [],
                    finishing: [],
                    is_enabled: 0,
                    papers: [],
                    has_finishing: 1,
                    has_print: 1,
                    has_flaps: 0,
                    has_embossing: 0,
                    has_embossing_foil: 0,
                    has_pantone: 0,
                    has_paper_client: 0,
                    has_varnish: 0,
                    is_checked_flash: 0,
                    has_additional_options: 1,
                    properties: []
                },
                {
                    name: 'banderole',
                    print: [],
                    finishing: [],
                    is_enabled: 0,
                    papers: [],
                    has_finishing: 1,
                    has_print: 1,
                    has_flaps: 0,
                    has_embossing: 0,
                    has_embossing_foil: 0,
                    has_pantone: 0,
                    has_paper_client: 0,
                    has_varnish: 0,
                    is_checked_flash: 0,
                    has_additional_options: 1,
                    properties: []
                },
                {
                    name: 'cloth',
                    print: [],
                    finishing: [],
                    is_enabled: 0,
                    papers: [],
                    has_finishing: 1,
                    has_print: 1,
                    has_flaps: 0,
                    has_embossing: 1,
                    has_embossing_foil: 1,
                    has_pantone: 1,
                    has_paper_client: 1,
                    has_varnish: 0,
                    is_checked_flash: 0,
                    has_additional_options: 1,
                    properties: []
                },
                {
                    name: 'cardboard',
                    print: [],
                    finishing: [],
                    is_enabled: 0,
                    papers: [],
                    has_finishing: 0,
                    has_print: 0,
                    has_flaps: 0,
                    has_embossing: 0,
                    has_embossing_foil: 0,
                    has_pantone: 0,
                    has_paper_client: 0,
                    has_varnish: 0,
                    is_checked_flash: 0,
                    has_additional_options: 1,
                    properties: []
                },
                {
                    name: 'endpapers',
                    print: [],
                    finishing: [],
                    is_enabled: 0,
                    papers: [],
                    has_finishing: 0,
                    has_print: 1,
                    has_flaps: 0,
                    has_embossing: 0,
                    has_embossing_foil: 0,
                    has_pantone: 0,
                    has_paper_client: 0,
                    has_varnish: 0,
                    is_checked_flash: 0,
                    has_additional_options: 1,
                    properties: []
                },
                {
                    name: 'bookmark',
                    print: [],
                    finishing: [],
                    is_enabled: 0,
                    papers: [],
                    has_finishing: 1,
                    has_print: 1,
                    has_flaps: 0,
                    has_embossing: 0,
                    has_embossing_foil: 0,
                    has_pantone: 0,
                    has_paper_client: 0,
                    has_varnish: 0,
                    is_checked_flash: 0,
                    has_additional_options: 1,
                    properties: []
                },


            ]
        },
    };


    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }


    isExists(paper, element) {
        let colors = Object.values(paper.colors);
        //debugger;
        let isExists = false;
        for(let i = 0; i < Object.keys(colors).length; i++)
        {
            let densities = colors[i].densities;
            //debugger;
            for(let j = 0; j < densities.length; j++)
            {
                if(densities[j]['has_' + element] === 1)
                {
                    isExists = true;
                    break;
                }
            }
        }

        return isExists;
    }

    fetchData(callback = null) {

        //property types
        let prints = this.state.propertyTypes.filter(p => { return p.type_id === 4 });
        let foldings = this.state.propertyTypes.filter(p => { return p.type_id === 3 });
        let bindings = this.state.propertyTypes.filter(p => { return p.type_id === 2});
        let finishings = this.state.propertyTypes.filter(p => { return p.type_id === 1 });
        //let papersHasCover = this.state.papers.filter(paper => {return paper.has_cover == 1} );
        let formattedPapers = {
            cover: this.state.papers.filter(paper => {
                return this.isExists(paper,'cover');
            } ).map(e => Object.assign({}, e)),
            text: this.state.papers.filter(paper => {
                return this.isExists(paper,'text');
            } ).map(e => Object.assign({}, e)),
            insert: this.state.papers.filter(paper => {
                return this.isExists(paper,'text');
            } ).map(e => Object.assign({}, e)),
            jacket: this.state.papers.filter(paper => {
                return this.isExists(paper,'jacket');
            } ).map(e => Object.assign({}, e)),
            banderole: this.state.papers.filter(paper => {
                return this.isExists(paper,'banderole');
            } ).map(e => Object.assign({}, e)),
            cloth: this.state.papers.filter(paper => {
                return this.isExists(paper,'cloth');
            }).map(e => Object.assign({}, e)),
            cardboard: this.state.papers.filter(paper => {
                return this.isExists(paper,'cardboard');
            } ).map(e => Object.assign({}, e)),
            endpapers: this.state.papers.filter(paper => {
                return this.isExists(paper,'endpapers');
            } ).map(e => Object.assign({}, e)),
            bookmark: this.state.papers.filter(paper => {
                return this.isExists(paper,'bookmark');
            } ).map(e => Object.assign({}, e)),
        };

        console.log(this.state.properties)
        let generalProperties = this.state.properties.filter( (property, key) => {
            return property.type.slug === "general"
                && property.id !== 30
                && property.id !== 31
                && property.id !== 33
                && property.id !== 53
                && property.id !== 54
                && property.id !== 58
        })
        //30 - jacket
        //31 - banderole
        //33 - insert
        //53 - offset printing
        //54 - digital printing
        //58 - bookmark

        this.setState({
            foldings, prints, bindings, finishings, formattedPapers, generalProperties
        }, () => {
            callback();
        });
    }

    componentDidMount() {

        const id = this.props.match.params.id;

        if (id !== undefined) {
            this.load(id);
        } else {
            this.setState({isLoading: true});
            NetworkClient.get('get-product-properties-and-papers')
                .then(response => {
                    let propertyTypes = response.propertyTypes;
                    let papers = response.papers;
                    let properties = response.properties;
                    this.setState({propertyTypes, papers, properties, isLoading: false})
                    this.fetchData(()=>{});
                })
        }

    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`products/${id}`)
                .then(response => {
                    let propertyTypes = response.propertyTypes;
                    let papers = response.papers;
                    let properties = response.properties;
                    let bindingsSelected = response.bindingsSelected;
                    let foldingsSelected = response.foldingsSelected;
                    this.setState({propertyTypes, papers, properties, isLoading: false}, () => {
                        this.fetchData(()=>{
                            let productR = response.product;
                            let {product} = this.state;

                            setTimeout(() => {
                                let productP = {id: productR.id,
                                    enabled:  productR.enabled,
                                    fixed_cost: productR.fixed_cost,
                                    bg_name: productR[`bg_name`],
                                    en_name: productR[`en_name`],
                                    de_name: productR[`de_name`],
                                    us_name: productR[`us_name`],
                                    fr_name: productR[`fr_name`],
                                    propertyTypes: productR.propertyTypes,
                                    components: product.components.map( (comp) => {
                                        let index = productR.components.findIndex(pr => pr.name === comp.name);
                                        let old = this.state.product;
                                        //debugger print
                                        if(index >= 0)
                                        {
                                            return {
                                                name: comp.name,
                                                print: productR.components[index].print,
                                                finishing: productR.components[index].finishing,
                                                is_enabled: productR.components[index].is_enabled,
                                                papers: productR.components[index].papers,
                                                has_finishing: comp.has_finishing,
                                                has_print: comp.has_print,
                                                has_flaps: comp.has_flaps,
                                                has_embossing: comp.has_embossing,
                                                has_embossing_foil: comp.has_embossing_foil,
                                                has_pantone: comp.has_pantone,
                                                has_paper_client: comp.has_paper_client,
                                                has_varnish: comp.has_varnish,
                                                is_checked_flash: comp.is_checked_flash,
                                                has_additional_options: comp.has_additional_options,
                                                properties: productR.components[index].properties
                                            }
                                        }

                                        return comp;
                                    }),
                                    bindingsSelected,
                                    foldingsSelected
                                };
                                this.setState({product: productP, bindingsSelected, foldingsSelected})
                            },1);
                        });
                        //end
                    })
                }).finally(c => {
                //this.setState({isLoading: false});
            })
        })


    }

    store() {
        this.setState({isLoading: true});
        //this.mergePropertyTypes();
        const {product} = this.state;
        let url = 'products';
        if (product.id !== null) {
            url += '/' + product.id;
        }

        NetworkClient.post(url, {product: product, foldings: this.state.foldingsSelected, bindings: this.state.bindingsSelected, propertyTypes: this.state.product.propertyTypes})
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        //this.props.history.push('/product');
                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    renderFoldings(t)
    {
        return (<div className="col-6">
            <label className={"ml-3"}> {t('type')} {t('folding')}</label>
            <div style={{display: "flex", height: "250px"}}>
                {this.state.foldings.length > 0 &&
                <DragAndDropTwoColumns

                    handleSelected={ this.selectedPropertyFoldings.bind(this)}
                    items={this.state.foldings}
                    selected={this.state.foldingsSelected}/>}

            </div>
        </div>);
    }

    renderBinding(t)
    {
        return (
            <div className="col-6">
                <label>{t('binding')}</label>
                <div style={{display: "flex", height: "250px"}}>
                    {this.state.bindings.length > 0 &&
                    <DragAndDropTwoColumns
                        handleSelected={ this.selectedPropertyBindings.bind(this)}
                        items={this.state.bindings}
                        selected={[...this.state.bindingsSelected]} />}
                </div>
            </div>
        )
    }

    isEqualsArrays(arrayOne, arrayTwo)
    {
        let isChanged = false;
        if(arrayOne.length !== arrayTwo.length)
        {
            isChanged = true;
        }

        for(let i = 0; i < arrayOne.length && isChanged === false; i++)
        {
            if(i >= arrayTwo.length)
            {
                isChanged = true;
                break;
            }

            if(arrayOne[i].id !== arrayTwo[i].id)
            {
                isChanged = true;
                break;
            }
        }

        return isChanged;
    }

    selectedPropertyFoldings(selected)
    {
        if(this.isEqualsArrays(selected, this.state.foldingsSelected))
        {
            this.setState({foldingsSelected: selected});
        }
    }

    selectedPropertyBindings(selected)
    {
        if(this.isEqualsArrays(selected, this.state.bindingsSelected))
        {
            this.setState({bindingsSelected: selected});
        }
    }

    selectedPropertyPrint(selected, index)
    {
        if(this.isEqualsArrays(selected, this.state.product.components[index].print))
        {
            this.setState(this.state.product.components[index].print = selected );
        }
    }

    selectedPropertyFinishing(selected, index)
    {
        if(this.isEqualsArrays(selected, this.state.product.components[index].finishing))
        {
            this.setState(this.state.product.components[index].finishing = selected );
        }
    }

    selectedPapers(selected, index)
    {
        // let arr = this.state.selectedCoverPaper;
        var arr = this.state.selectedCoverPaper;
        if (arr.some((p, i )=> p === selected))
        {
             arr = arr.filter(p => p !== selected)

            this.setState({
                selectedCoverPaper: [...this.state.selectedCoverPaper = arr]
            })
        }
        else {
            this.state.selectedCoverPaper.push(selected);
        }

        this.setState(this.state.product.components[index].papers = this.state.selectedCoverPaper )
    }

    collapse(index)
    {
        switch (index) {
            case "cover":
                this.setState({ cover: !this.state.cover });
                break;
            case "text":
                this.setState({ text: !this.state.text });
                break;
            case "insert":
                this.setState({ insert: !this.state.insert });
                break;
            case "jacket":
                this.setState({ jacket: !this.state.jacket });
                break;
            case "banderole":
                this.setState({ banderole: !this.state.banderole });
                break;
            case "cloth":
                this.setState({ cloth: !this.state.cloth });
                break;
            case "cardboard":
                this.setState({ cardboard: !this.state.cardboard });
                break;
            case "endpapers":
                this.setState({ endpapers: !this.state.endpapers });
                break;
        }

    }

    renderComponentsWithPapers(t)
    {

        return (<div>
            {this.state.product.components.map((c, i) => <div>
                <div className="card card-primary">
                    <div className="card-header">
                        <h5>
                            {t(c.name)}
                            <i
                                className={ (this.state[(c.name)] ? "fas fa-plus float-right" : "fas fa-minus float-right")}
                                onClick={ () => {
                                    this.collapse(c.name)
                                }} />
                        </h5>

                            <span>
                                Enabled <input
                                type={"checkbox"} onChange={(e)=>{
                                    let {product} = this.state;
                                    product.components[i].is_enabled = e.target.checked === true ? 1 : 0;
                                    this.setState({product});
                            }}
                                checked={c.is_enabled === 1} />
                            </span>
                    </div>
                    <div id={i} className={"card-body px-0 collapse" + (
                        this.state[(c.name)] === true ? '' : 'in'
                    )} >
                        <div className="row">
                            {c.has_print === 1 &&
                            <div className="col-6">
                                <label className={"ml-3"}> {t('print')}</label>
                                <div style={{display: "flex", height: "250px"}}>
                                    {this.state.prints.length > 0 &&
                                    <DragAndDropTwoColumns
                                        loopIndex={i}
                                        handleSelected={ this.selectedPropertyPrint.bind(this) }
                                        items={[...this.state.prints]}
                                        selected={this.state.product.components[i].print}
                                    />}
                                </div>
                            </div>
                            }
                            {c.has_finishing === 1 &&
                            <div className="col-6">
                                <label>{t('finishings')}</label>
                                <div style={{display: "flex", height: "250px"}}>
                                    {this.state.finishings.length > 0 &&
                                    <DragAndDropTwoColumns
                                        loopIndex={i}
                                        handleSelected={ this.selectedPropertyFinishing.bind(this) }
                                        items={[...this.state.finishings]}
                                        selected={this.state.product.components[i].finishing} />}
                                </div>
                            </div>
                            }
                        </div>
                        <div className="row">
                            <div className="mt-3 col-md-12">
                                <div className="form-group">
                                    <label>{t("papers")}</label>
                                    {this.state.formattedPapers[c.name] !== undefined &&
                                    <DragAndDropForPapers
                                        component={c}
                                        loopIndex={i}
                                        paperType={c.name}
                                        selectOrDeselectSingleDensitiy={(paperId, index, colorId, selected, paperIndex)=>{
                                            let {formattedPapers} = this.state;
                                            let papers = this.state.formattedPapers[c.name];
                                            //debugger;
                                            let colors = papers[paperIndex].colors[colorId];
                                            let component = this.state.product.components[i];
                                            let selectedPaperIds = component.papers;
                                            let {product} = this.state;
                                            if(selected === true)
                                            {
                                                selectedPaperIds.push(colors.densities[index].paper_id);
                                            }
                                            else
                                            {
                                                for(let i = 0; i < selectedPaperIds.length; i++)
                                                {
                                                    if(selectedPaperIds[i] === colors.densities[index].paper_id)
                                                    {
                                                        selectedPaperIds.splice(i, 1);
                                                    }
                                                }
                                            }
                                            component.papers = selectedPaperIds;
                                            //let currentcomponent = product.components[i];
                                            //let com = component;
                                            product.components[i] = component;
                                            //debugger;
                                            //this.setState({product: product});
                                            this.setState({test: 123})
                                        }}
                                        selectOrDeselectAll={(index, selected)=>{
                                            let {formattedPapers} = this.state;
                                            let papers = this.state.formattedPapers[c.name];
                                            let component = this.state.product.components[i];
                                            let selectedPaperIds = component.papers;
                                            let selectedPapersFromThisId = [];
                                            let colors = Object.values(papers[index].colors);
                                            for(let i = 0; colors.length; i++)
                                            {
                                                if(colors[i] === undefined){break;}
                                                let densities = colors[i].densities;
                                                for(let j=0;j<densities.length;j++)
                                                {
                                                    densities[j].selected = selected;
                                                    if(!selectedPaperIds.includes(densities[j].paper_id))
                                                    {
                                                        selectedPaperIds.push(densities[j].paper_id);
                                                    }
                                                    selectedPapersFromThisId.push(densities[j].paper_id);
                                                }
                                                colors[i].densities = densities;
                                            }

                                            papers[index].colors = colors;
                                            formattedPapers[c.name] = papers;
                                            let {product} = this.state;

                                            component.papers = selectedPaperIds;
                                            if(selected === false)
                                            {
                                                for (let i = 0; i<selectedPapersFromThisId.length;i++)
                                                {
                                                    if(selectedPaperIds.includes(selectedPapersFromThisId[i]))
                                                    {
                                                        let indexOfPaper = selectedPaperIds.indexOf(selectedPapersFromThisId[i]);
                                                        selectedPaperIds.splice(indexOfPaper, 1);
                                                    }
                                                }
                                                //component.papers = [];
                                            }
                                            product.components[i] = component;
                                            this.setState({formattedPapers, product});
                                        }}
                                        selectedPaper={ this.selectedPapers.bind(this) }
                                        items={this.state.formattedPapers[c.name].slice(0,4)}
                                        selected={[]}
                                        // selected={this.state.product.components.find(x=>x.name === c.name).papers}
                                    />}
                                </div>
                            </div>

                            {this.state.properties.filter(p => p.type_id === 5 && !([30,31,32,33,53,54,56,57,58,59,60,61,67].includes(p.id))).map( propertyC => <div className={'col-12'}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.state.product.components[i].properties.find(x=>x.id === propertyC.id)}
                                        onChange={(e)=>{
                                            let componentProperties = this.state.product.components.find(x=>x.name === c.name).properties;
                                            let {product} = this.state;
                                            if(e.target.checked === true)
                                            {
                                                componentProperties.push({id: propertyC.id, type: '5'})
                                            }
                                            else
                                            {
                                                let indexToRemove = componentProperties.findIndex(x=>x.id === propertyC.id);
                                                componentProperties.splice(indexToRemove, 1);
                                            }
                                            let indexOfComponent = product.components.findIndex(x=>x.name === c.name);
                                            product.components[indexOfComponent].properties = componentProperties;
                                            this.setState({product});
                                        }}/>
                                    {propertyC.translation.name}
                                </label>
                            </div>)}
                        </div>


                    </div>
                </div>
            </div>)}
        </div>)
    }


    renderGeneralProperties()
    {
        let generalProperties = this.state.generalProperties;
        let ids = [30, 31, 53, 54, 56, 57, 58, 59, 60, 61, 62, 67];
        let {product} = this.state;
        return generalProperties.filter(p => ids.includes(p.id))
            .map( property =>
            <li>
                <label>
                    <input
                        type="checkbox"
                        checked={product.propertyTypes.find(x=>x.id === property.id)}
                        onChange={(e)=>{
                            let isChecked = e.target.checked;
                            let {product} = this.state;
                            let properties = product.propertyTypes;
                            if(isChecked)
                            {
                                properties.push(property.id);
                            }

                            product.propertyTypespropertyTypes = properties;
                            this.setState({product});
                        }}
                    />{property.translation.name}</label>
            </li>)
    }

    render() {
        const {t} = this.props;
        let fieldsLang = this.state.languages.map((l) => {
            return {name: `${l}_name`, type: 'text'}
        });
        //return (<div> {JSON.stringify(this.state.product)}</div>)

        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('product')} {t('create')}</h1>
                            </div>
                        </div>
                        <div className="row">
                            <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                {this.state.product.id === null ? t('Create') : t('Update')}
                            </button>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                {/*Product Name*/}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h5>{t('name')}</h5>
                                    </div>
                                    <div className="card-body">
                                        {SimpleFieldsGenerator.bind(this, t, fieldsLang, "product")()}
                                    </div>
                                </div>
                                {/*Product Name End*/}
                                {/*Properties*/}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h5>{t('properties')}</h5>
                                        <div>
                                            <div>
                                                <span>
                                                    {t('enabled')}
                                                </span>
                                                &nbsp;
                                                <input
                                                    checked={this.state.product.enabled===1}
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        let {product} = this.state;
                                                        product.enabled = e.target.checked === true ? 1 : 0;
                                                        this.setState({product});
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="card-body px-0">
                                        <div className="row">
                                            {this.renderFoldings.bind(this)(t)}
                                            {this.renderBinding.bind(this)(t)}
                                        </div>
                                        <div className="form-group  mt-4 ml-3 ">
                                            <ul className={"list-unstyled"}>

                                                {
                                                    this.renderGeneralProperties()
                                                }
                                            </ul>
                                        </div>
                                        <div className="form-group  mt-4 ml-3 mr-3">
                                            {SimpleFieldsGenerator.bind(this, t, [{
                                                name: 'fixed_cost',
                                                type: 'number'
                                            }], "product")()}
                                        </div>
                                    </div>
                                </div>
                                {/*Properties end*/}
                            </div>
                            <div className="col-6">
                                {this.renderComponentsWithPapers.bind(this,t)()}
                                {/*{this.renderCoverComponent()}*/}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default withTranslation()(ProductsForm);
