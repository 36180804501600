import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../../api/NetworkClient';
import Loader from '../../../../partial/Loader';
import Languages from "../../../../constants/Languages";
import Select from 'react-select';
import SimpleFieldsGenerator from '../../../../helpers/SimpleFieldsGenerator';

class TransportDistributionsForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        distribution: {
            id: null,
            name: null,
            info: null,
            barcode: null,
            country_id: null,
            zip_code: null,
            city: null,
            address: null,
            number: null,
            info: null,
            email: null,
            phone: null,
            contact_person: null
        },
        countries: []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if(id !== undefined)
        {
            this.load(id);
        }
        else
        {
            this.setState({isLoading: true}, () => {
                NetworkClient
                    .get(`transport/settings/distributions-get-countries`)
                    .then(response => {
                        const countries = response.countries;
                        this.setState({ countries});
                    }).finally(c => {
                    this.setState({isLoading: false});
                })
            })
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`transport/settings/distributions/${id}`)
                .then(response => {
                    const distribution = response.distribution;
                    const countries = response.countries;
                    this.setState({distribution, countries});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {distribution} = this.state;
        let url = 'transport/settings/distributions';
        if(distribution.id !== null)
        {
            url += '/' + distribution.id;
        }

        NetworkClient.post(url, distribution)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c=>{
                        this.props.history.push('/transport/settings/distributions');
                    })
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('Settings')} {t('distribution')} {this.state.distribution.id !== null ? t("update") : t('create')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                {SimpleFieldsGenerator.bind(this,t,[{name: 'name', type: 'text' }],"distribution")()}
                                <div className="form-group">
                                    <Select
                                        value={this.state.countries.find(c => c.id === this.state.distribution.country_id)}
                                        onChange={(selected)=>{
                                            let {distribution} = this.state;
                                            distribution.country_id = selected.id;
                                            this.setState({distribution});
                                        }}
                                        options={this.state.countries}
                                        getOptionLabel={(option)=> option.name}
                                        getOptionValue={(option) => option.id}
                                    />
                                </div>
                                {SimpleFieldsGenerator.bind(this,t,[{name: 'zip_code', type: 'text' }],"distribution")()}
                                {SimpleFieldsGenerator.bind(this,t,[{name: 'city', type: 'text' }],"distribution")()}
                                {SimpleFieldsGenerator.bind(this,t,[{name: 'address', type: 'text' }],"distribution")()}
                                {SimpleFieldsGenerator.bind(this,t,[{name: 'number', type: 'text' }],"distribution")()}
                                {SimpleFieldsGenerator.bind(this,t,[{name: 'info', type: 'text' }],"distribution")()}
                                {SimpleFieldsGenerator.bind(this,t,[{name: 'contact_person', type: 'text' }],"distribution")()}
                                {SimpleFieldsGenerator.bind(this,t,[{name: 'email', type: 'text' }],"distribution")()}
                                {SimpleFieldsGenerator.bind(this,t,[{name: 'phone', type: 'text' }],"distribution")()}
                                <div className="form-control">
                                    <label>
                                        <input className="mr-2" type="checkbox" checked={this.state.distribution.barcode === 1}
                                               onChange={e => {
                                                   let {distribution} = this.state;
                                                   distribution.barcode = e.target.checked === true ? 1 : 0;
                                                   this.setState({distribution});
                                               }}
                                        />
                                        {t('barcode')}
                                    </label>
                                </div>    
                                   

                                    
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.distribution.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TransportDistributionsForm);
