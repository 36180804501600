import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../../helpers/DataTableInitDataGenerator';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import SwalDelete from '../../../helpers/SwalDelete';
import './PaperList.css';

class PapersList extends Component {
    state = {
        isLoading: false,
        data: {
            columns: [],
            rows: []
        },
        enabled: 1,
    };

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const {t} = this.props;
        let listColumns = ['id','name',
            'color','height','length','density',
            'volume_ratio','price_per_ton','price_per_sheet','overcharge',
            'has_cover', 'has_text', 'has_jacket', 'has_banderole', 'has_cloth',
            'has_endpapers','has_bookmark','actions'
        ];
        let has_cardboard = this.props.location.pathname === '/cardboards' ? 1 : 0;
        if(has_cardboard === 1)
        {
            listColumns = ['id','name',
                'color','height','length','density',
                'volume_ratio','price_per_ton','price_per_sheet','overcharge',
                'thickness','thickness_additional','actions'];
        }

        let columns = DataTableInitDataGenerator(listColumns, t);
        columns.columns[0].width = 15;
        columns.columns[1].width = 15;
        this.setState({data: columns});
        this.load();
    }

    load() {
        this.setState({isLoading: true});
        let has_cardboard = this.props.location.pathname === '/cardboards' ? 1 : 0;

        NetworkClient.get('papers',null,null,{enabled: this.state.enabled, has_cardboard: has_cardboard})
            .then(response => {
                let {data} = this.state;
                let papers = response.papers;
                let rows = [];
                papers.forEach((item, i) => {
                let tmp = {
                    id: item.id,
                    name: item.name,
                    has_cover: item.has_cover === 1 ? <i className={"fa fa-check"} />  : '',
                    has_text: item.has_text === 1 ? <i className={"fa fa-check"} />  : '',
                    has_jacket: item.has_jacket === 1 ? <i className={"fa fa-check"} />  : '',
                    has_banderole: item.has_banderole === 1 ? <i className={"fa fa-check"} />  : '',
                    has_cloth: item.has_cloth === 1 ? <i className={"fa fa-check"} />  : '',
                    has_cardboard: item.has_cardboard === 1 ? <i className={"fa fa-check"} />  : '',
                    has_endpapers: item.has_endpapers === 1 ? <i className={"fa fa-check"} />  : '',
                    has_bookmark: item.has_bookmark === 1 ? <i className={"fa fa-check"} />  : '',
                    color: item.color,
                    height: item.height,
                    length: item.length,
                    density: item.density,
                    volume_ratio: item.volume_ratio,
                    price_per_ton: item.price_per_ton,
                    price_per_sheet: item.price_per_sheet,
                    overcharge: item.overcharge,
                    thickness: item.thickness,
                    thickness_additional: item.thickness_additional,
                    actions: <div>
                        <Link to={has_cardboard === 0 ? `/papers/full/edit/${item.id}` : `/cardboards/edit/${item.id}`} className={"btn btn-info btn-sm"}>
                            <i className={"fa fa-pen"}/>
                        </Link>
                    </div>
                };
                rows.push(tmp);
            });


            this.setState({data: {columns: data.columns, rows: rows}, isLoading: false});
        })
    }

    render() {
        const {t} = this.props;
        let has_cardboard = this.props.location.pathname === '/cardboards' ? 1 : 0;

        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    {has_cardboard === 0 ? t('papers') : t('cardboard')}
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className={"table-responsive"}>
                                            <Link to={has_cardboard === 0 ? '/papers/full/create' : '/cardboards/create'} className={"btn btn-info btn-sm mb-3"}>
                                                {t('create')} <i className={"fa fa-plus"}/>
                                            </Link>
                                            <button
                                                className={this.state.enabled === 1 ? "btn btn-info btn-sm mb-3" : "btn btn-sm mb-3"}
                                                onClick={()=>{
                                                    if(this.state.enabled !== 1){
                                                        this.setState({enabled: 1}, ()=>{this.load()})
                                                    }
                                                }}
                                            >Active</button>
                                            &nbsp;
                                            <button
                                                className={this.state.enabled === 0 ? "btn btn-info btn-sm mb-3" : "btn btn-sm mb-3"}
                                                onClick={()=>{
                                                    if(this.state.enabled !== 0){
                                                        this.setState({enabled: 0}, ()=>{this.load()})
                                                    }
                                                }}
                                            >Inactive</button>
                                            <MDBDataTable
                                                className={"font-size-correction"}
                                                bordered
                                                striped
                                                hover
                                                entries={10}
                                                data={this.state.data}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PapersList);
