import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import Languages from "../../constants/Languages";
import Select from 'react-select';
import TooltipLabel from "../../helpers/TooltipLabel";
import './CalculatorHelper.css';

class CalculatorHelper extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        categories: []
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        this.load.bind(this)();
    }

    load() {
        this.setState({isLoading: true});
        NetworkClient.get('calculator-helper-first-step')
            .then((function(response) {
                this.setState({isLoading: false, categories: response.categories});
            }).bind(this))
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('calculator_helper')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className="row">
                        {this.state.categories.map(c => <div onClick={e => {
                            this.props.history.push(`/calculator-helper/item/${c.id}`)
                        }} className={"col-md-6 pointer"}>
                            <div className="card card-primary p-5 text-center">
                                {c.name}
                            </div>
                        </div>)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CalculatorHelper);
