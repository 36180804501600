import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import Languages from "../../constants/Languages";
import Select from 'react-select';

class DistributionForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        item: {
            id: null,
            is_landscape: false,
            paper_format_height: 0,
            paper_format_length: 0,
            paper_max_format_height: 0,
            paper_max_format_length: 0,
            format: '',
            paper_cut_type:-1,
            list_format:'70x100',
            has_turner: 0,
            model: '',
            divider_model: 1,
            paper_back: null,
            paper_max_format_cut_length: 0,
            paper_max_format_cut_height: 0,
            count_pages_in_print_list: 0
        },
        formats: [],
        models: [
            {id: 1, name: 'Късата страна'},
            {id: 2, name: 'Дългата страна'}
        ],
        bindings: [],
        papers: []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.load();
    }

    pushPaper(){
        let {papers} = this.state;
        papers.push({
            count_pages_in_cola: 0,
            count_cold: 0,
            count_cola_in_print_list: 0,
            paper_min_density_size: 0,
            paper_max_density_size: 0
        })
        this.setState({papers})
    }

    load() {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`distribution/create`)
                .then(response => {
                    const turners = response.turners;
                    const formats = response.formats;
                    const bindings = response.bindings;
                    this.setState({turners, formats, bindings});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {color} = this.state;
        let url = 'link';
        if (color.id !== null) {
            url += '/' + color.id;
        }

        NetworkClient.post(url, color)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        this.props.history.push('/link');
                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    handleChange(value, fieldName) {
        let {item} = this.state;
        item[fieldName] = value;
        this.setState({item});
    }

    renderMain() {
        return (<div className="row">
            <div className="col-12">
                <label htmlFor="">
                    Портрет
                </label>
                <br/>
                <input
                    value={this.state.item.is_landscape}
                    onChange={e=>this.handleChange(e.target.checked,'is_landscape')}
                    type="checkbox"/>
            </div>
            <div className="col-12">
                <h3>Формат хартия</h3>
            </div>
            <div className="col-12">
                <label htmlFor="">Широчина</label>
                <input
                    type="text" className={"form-control"}
                    value={this.state.item.paper_format_height}
                    onChange={e=>this.handleChange(e.target.value,'paper_format_height')}
                />
            </div>
            <div className="col-12">
                <label htmlFor="">Дължина</label>
                <input
                    className={"form-control"}
                    type="text" value={this.state.item.paper_format_length}
                    onChange={e=>this.handleChange(e.target.value,'paper_format_length')}
                />
            </div>
            <div className="col-12">
                <h3>Макс формат за печат</h3>
            </div>
            <div className="col-12">
                <label htmlFor="">Широчина</label>
                <input
                    className={"form-control"}
                    type="text" value={this.state.item.paper_max_format_height}
                    onChange={e=>this.handleChange(e.target.value,'paper_max_format_height')}
                />
            </div>
            <div className="col-12">
                <label htmlFor="">Дължина</label>
                <input
                    className={"form-control"}
                    type="text" value={this.state.item.paper_max_format_length}
                    onChange={e=>this.handleChange(e.target.value,'paper_max_format_length')}
                />
            </div>
            <div className="col-12">
                <label htmlFor="">Формат</label>
                <Select
                    value={this.state.formats.find(c => c.name === this.state.item.format)}
                    onChange={(selected)=>{
                        let {item} = this.state;
                        item.format = selected.name;
                        this.setState({item});
                    }}
                    options={this.state.formats}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-12">
                <label htmlFor="">Брой страници в печатен лист</label>
                <input
                    type="number"
                    value={this.state.item.count_pages_in_print_list}
                    className={"form-control"}
                    onChange={e=>this.handleChange(e.target.value,'count_pages_in_print_list')}
                />
            </div>
            <div className="col-12">
                <h3>Модел</h3>
            </div>
            <div className="col-12">
                <label htmlFor="">Страна</label>
                <Select
                    value={this.state.models.find(c => c.id === this.state.item.model)}
                    onChange={(selected)=>{
                        let {item} = this.state;
                        item.model = selected.id;
                        this.setState({item});
                    }}
                    options={this.state.models}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-12">
                <label htmlFor="">Делител</label>
                <input
                    type="number" className={"form-control"}
                    value={this.state.item.divider_model}
                    min={1}
                    onChange={e=>this.handleChange(e.target.value,'divider_model')}
                />
            </div>
            <div className="col-12">
                <h3>Обрязан формат</h3>
            </div>
            <div className="col-12">
                <h5>Шита-Лепена</h5>
                <label htmlFor="">Ширина</label>
                <input type="text" className={"form-control"}/>
                <label htmlFor="">Височина</label>
                <input type="text" className={"form-control"}/>
            </div>
            <div className="col-12">
                <h5>Лепена</h5>
                <label htmlFor="">Ширина</label>
                <input type="text" className={"form-control"}/>
                <label htmlFor="">Височина</label>
                <input type="text" className={"form-control"}/>
            </div>
            <div className="col-12">
                <h5>PUR</h5>
                <label htmlFor="">Ширина</label>
                <input type="text" className={"form-control"}/>
                <label htmlFor="">Височина</label>
                <input type="text" className={"form-control"}/>
            </div>
            <div className="col-12">
                <h5>Твърда - Прав гръб</h5>
                <label htmlFor="">Ширина</label>
                <input type="text" className={"form-control"}/>
                <label htmlFor="">Височина</label>
                <input type="text" className={"form-control"}/>
            </div>

            <div className="col-12">
                <h5>Твърда - Кръглен гръб</h5>
                <label htmlFor="">Ширина</label>
                <input type="text" className={"form-control"}/>
                <label htmlFor="">Височина</label>
                <input type="text" className={"form-control"}/>
            </div>
        </div>)
    }

    delete()
    {

    }

    renderPapers()
    {
        return (<div className={"row"}>
            <div className="col-12">
                <h3>Хартии</h3>
                <button
                    onClick={()=>{this.pushPaper()}}
                    className={"btn btn-info btn-sm"}>+</button>
            </div>
            {this.state.papers.map((p, index) => <div className={"row"}>
                <div className="col-12">
                    <hr/>
                </div>
                <div className="col-12">
                    <button className={'btn btn-sm btn-danger'} onClick={()=>{
                        let {papers} = this.state;
                        papers.splice(index, 1);
                        this.setState({papers});
                    }}>X</button>
                </div>
                <div className={"col-12"}>
                    <label htmlFor="">Брой страници в кола</label>
                    <input type="text" className={"form-control"}/>
                </div>
                <div className={"col-12"}>
                    <label htmlFor="">Брой гънки</label>
                    <input type="text" className={"form-control"}/>
                </div>
                <div className={"col-12"}>
                    <label htmlFor="">Бр. коли в печатен лист</label>
                    <input type="text" className={"form-control"}/>
                </div>
                <div className={"col-12"}>
                    <label htmlFor="">Мин грамаж хартия</label>
                    <input type="number" className={"form-control"}/>
                </div>
                <div className={"col-12"}>
                    <label htmlFor="">Макс грамаж хартия</label>
                    <input type="number" className={"form-control"}/>
                </div>
                <div className="col-12">
                    <hr/>
                </div>
            </div>)}
        </div>)
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('distribution')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className={"col-md-6"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        {this.renderMain()}
                                        <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                            {this.state.item.id === null ? 'Create' : 'Update'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        {this.renderPapers()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(DistributionForm);
