const bg = {

    "regular_mitnica": "Такса митница (лв)",
    "regular_kg": "Тегло на палет (400)[X](кг)",
    "regular_first_kg_price": "Цена за първия палет до (400)[X]кг. (лв)",
    "regular_after_kg": "Цена за всеки следващ палет (400)[X]кг. (лв)",
    "regular_fix": "Фиксиран разход - сухопътен транспорт (лв)",
    "regular_price_kg_walk": "Цена за килограм - сухопътен транспорт (лв)",
    "regular_minimal_value_land": "Минимална цена (кг * цена за кг) - сухопътен транспорт (лв)",
    "express_first_kg_price": "Цена до (100)[X]кг тегло (лв)",
    "express_after_kg": "Цена за следващите (100)[Y] килограми (лв)",
    "express_kg": "Тегло X (кг)",
    "express_kg_each": "Тегло Y (кг)",

    "c_pro": "",
    "owner": "Потребител",
    "show_mine" : "Покажи само моите поръчки",

    "maculatures-cover-print" : "Макулатури корица",
    "uv_lak": "УВ Лак",
    "selektiven_lak": "Селективен лак",
    "maculatures-knigovezane": "Макулатури книговезки",
    "lepena_bez_kapaci" : "Лепена без капаци",
    "lepena_s_kapaci" : "Лепена с капаци",
    "lepena_pur_s_kapaci" : "PUR с капаци",
    "lepena_pur_bez_kapaci" : "PUR без капаци",
    "shita_lepena_s_kapaci" : "Шита-лепена с капаци",
    "shita_lepena_bez_kapaci" : "Шита-лепена без капаци",
    "vshm_bez_kapaci" : "ВШМ без капаци",
    "vshm_s_kapaci" : "ВШМ с капаци",
    "tvurda_podvurziq_standart" : "Твърда стандартна",
    "tvurda_podvurziq_s_forzaci_ot_tqloto" : "Твърда с форзаци от тялото",
    "maculatures-print-body" : "Макулатури печат тяло",
    "tirage_from" : "Тираж от",
    "tirage_to" : "Тираж до",
    "print_one" : "1+0",
    "print_two" : "2+0",
    "print_three" : "3+0",
    "print_four" : "4+0",
    "pantone" : "Пантон",
    "nadpechaten_lak" : "Надпеч. лак 1+0",
    "laminate"  : "ламинат",
    "punching" : "Щанцоване",

    "paper_format_height" : "Формат х-я Височина",
    "paper_format_length" : "Формат х-я Дължина",
    "paper_max_format_height": "Мax Формат за печат Височина",
    "paper_max_format_length": "Мax Формат за печат Дължина",
    "paper_max_format_cut_height" : "Max обрязан формат Височина",
    "paper_max_format_cut_length" : "Max обрязан формат Дължина",
    "count_pages_in_print_list" : "Брой стр в печатен лист",
    "iso3_explained" : "Country Code 3 Symbols",
    "density_text_lb" : "Плътност тяло (lbs)",
    "density_cover_lb" : "Плътност корица (lbs)",
    "default_locale": "Default locale",
    "has_cover" : "Покритие",
    "has_text" : "Текст",
    "has_jacket" : "Обложка",
    "has_banderole" : "Бандерол",
    "has_cloth" : "Облекло",
    "has_endpapers" : "Форзац",
    'all_countries': 'Всички Държави',
    'pricePerTon': 'Цена за Тон',
    "countries_delivery": 'Държави доставка',
    "complement":"Допълнително инфо.",
    "cur_val":"Актуална Стойност",
    "permissions":"Привилегии",
    "list":"Списък",
    "id":"ID",
    "name":"Име",
    "display_name":"Име на Показ",
    "description":"Описание",
    "create_new":"Създай",
    "view":"Изглед",
    "delete":"Изтрий",
    "submit":"Приложи",
    "edit":"Редактирай",
    "permission":"Привилегя",
    "updated":"Обнови",
    "added":"Добавен",
    "deleted":"Изтрит",
    "all_permissions":"Всички Привилегии",
    "users":"Потребители",
    "all_users":"Всички Потребители",
    "roles":"Роли",
    "role":"Роля",
    "add":"Добави",
    "all_roles":"Всички Роли",
    "assign_permissions":"Назначи Привилегии",
    "assign_roles":"Назначи Роля",
    "create_user":"Създай Потребител",
    "assign_websites":"Назначи Уебсайт",
    "websites":"Уебсайтове",
    "domain":"Домейни",
    "currency":"Валута",
    "email":"Email",
    "phone":"Телефон",
    "team":"Отбор",
    "address_info":"Адрес Информация",
    "orders_not_final":"Списък Недовършени Оферти",
    "default_category":"Категория по Подразбиране",
    "is_special":"Е Специален",
    "special_category":"Специална Категория",
    "website":"Уебсайт",
    "has_departments":"Има Департаменти",
    "weight":"Тегло",
    "price":"Цена",
    "type":"Тип",
    "step":"Стъпка",
    "update":"Обнови",
    "update_to_current_rate":"Обнови до Актуалния Курс",
    "value":"Стойност",
    "current_value":"Актуална Стойност",
    "current_rate":"Актуален Курс",
    "min_price":"Минимална Цена",
    "max_price":"Максимална Цена",
    "fixed_price":"Фиксирана Цена",
    "per":"за",
    "for":"за",
    "from":"от",
    "to":"до",
    "surcharge":"преразход",
    "economy":"Икономична",
    "express":"Експресна",
    "courier":"Куриер",
    "delivery":"Доставка",
    "color":"Цвят",
    "operations":"Операции",
    "orders_list":"Списък Оферти",
    "status":"Статус",
    "state":"Състояние",
    "select_state":"Избери Състояние",
    "link":"Свържи",
    "linked_orders":"Свързани Оферти",
    "finish":"Завърши",
    "back":"Назад",
    "contacts":"Контакти",
    "cancel":"Откажи",
    "error":"Грешка",
    "preview":"Преглед",
    "calculate":"Калкулирай",
    "custom_size":"Размер по избор",
    "standard_size":"Стандартен Размер",
    "final_size":"Краен Размер",
    "length":"Широчина",
    "height":"Височина",
    "company_name":"Име на Фирма",
    "first_name":"Име",
    "last_name":"Фамилия",
    "products":"Продукти",
    "properties":"Свойства",
    "deliveries":"Доставки",
    "papers":"Хартии",
    "sizes":"Размери",
    "densities":"Плътности",
    "suppliers":"Доставчици",
    "colors":"Цветове",
    "names":"Имена",
    "types":"Тип",
    "maculatures":"Макулатури",
    "transport":"Транспорт",
    "districts":"Квартали",
    "overcharges":"Допълнителни Такси",
    "prices":"Цени",
    "settings":"Настройки",
    "taxes":"Такси",
    "tax_pulsio":"Такса Pulsio",
    "tax_security":"Такса Сигурност",
    "tax_fuel":"Такса Гориво",
    "calculator_helper":"Помощник Калкулатор",
    "currencies":"Валути",
    "orders":"Оферти",
    "current_states":"Актуални Състояния",
    "states":"Състояния",
    "clients":"Клиенти",
    "categories":"Категории",
    "contact_types":"Типове Контакти",
    "navigation":"Навигация",
    "create":"Създай",
    "total_papers":"Общо Хартии",
    "total_clients":"Общо Клиенти",
    "total_products":"Общо Продукти",
    "total_orders":"Общо Поръчки",
    "enabled":"Активен",
    "enable":"Активирай",
    "question_delete":"Сигурни ли сте, че искате да изтиите това?",
    "sign_in":"Влез",
    "remember_me":"Запомни ме",
    "login_msg":"Започнете нова сесия",
    "password":"Парола",
    "key":"Ключ",
    "info":"Инфо",
    "category":"Категория",
    "mobile_phone":"Мобилен Телефон",
    "business":"Бизнес",
    "bulstat":"Булстат",
    "address":"Адрес",
    "invoice":"Фактура",
    "invoice_address":"Адрес на фактуриране",
    "delivery_address":"Адрес Доставка",
    "number":"Номер",
    "street_name":"Име на Улица",
    "city":"Град",
    "post_code":"Пощенски Код",
    "add_contact":"Добави Контакт",
    "title":"Обръщение",
    "waste":"Отпадъци",
    "percent":"Процент",
    "new_order":"Нова Поръчка",
    "select_product_in_orders":"Изберете Продукт, за когото да е новата поръчка",
    "select_type_in_orders":"Изберете Тип поръчка",
    "select_website_in_orders":"Изберете Уебсайт, за когото да е новата поръчка",
    "comment":"Коментар",
    "client":"Клиент",
    "product":"Продукт",
    "company":"Компания",
    "finished_size_length":"Краен размер Дължина",
    "finished_size_height":"Краен размер Височина",
    "copies":"Копия",
    "bindings":"Bindings",
    "order":"Поръчка",
    "foil":"Фолио",
    "banderole":"Бандерол",
    "jacket":"Обложка",
    "wrapped":"Опаковани",
    "total_price":"Общо Цена",
    "overcharge":"Надценка",
    "discount":"Отстъпка",
    "final_price":"Крайна Цена",
    "budget":"Бюджет",
    "base_overcharges":"Основни Надценки",
    "additional_overcharges":"Допълнителни Надценки",
    "property":"Опция",
    "percentage":"Процент",
    "remove":"Премахни",
    "paper":"Хартия",
    "text":"Текст",
    "text2":"Тяло",
    "density":"Плътност",
    "price_per_sheet":"Цена за страница",
    "volume_ratio":"Обемност",
    "volume_ratio_alt":"Обемност (алт)",
    "coef1_sewed":"Коеф 1 (шито)",
    "coef2_sewed":"Коеф 2 (шито)",
    "coef3_sewed":"Коеф 3 (шито)",
    "coef1_stitched":"Коеф 1 (лепено)",
    "coef2_stitched":"Коеф 2 (лепено)",
    "coef3_stitched":"Коеф 3 (лепено)",
    "cover":"Покритие",
    "size":"Размер",
    "price_per_ton_with_overcharge":"Цена на тон с Надценка, ДДС",
    "price_per_sheet_with_overcharge":"Цена на страница с Надценка, ДДС",
    "vat":"ДДС",
    "book":"Книга",
    "paper_group":"хартиена група",
    "special":"Специална",
    "normal":"Нормална",
    "no_group":"Без Група",
    "profile":"Профил",
    "sign_out":"Излез",
    "folding":"Сгъване",
    "binding":"Подвързване",
    "print":"Печат",
    "finishing":"Довършителни",
    "insert":"Вложка",
    "days":"Дни",
    "new_password":"Нова Парола",
    "repeat_password":"Повтори Парола",
    "group":"Група",
    "factor":"Коефицент",
    "action_done":"Операцията извършена",
    "success":"Успех",
    "price_per_ton":"Цена за Тон",
    "all":"Всички",
    "wrong_id":"Грешно ID",
    "formats":"Формати",
    "format":"Формат",
    "thickness":"Дебелина/Картон",
    "france":"Франция",
    "united_kingdom":"Обединено Кралство",
    "bulgaria":"България",
    "belgium":"Белгия",
    "switzerland":"Швейцария",
    "belgium_trans_info":"Белгийския транспорт е равен на 59 френски департамент.",
    "mr_ms":"г-н/г-жо",
    "mr":"г-н",
    "mrs":"г-жо",
    "country":"Държава",
    "countries":"Държави",
    "default_currency":"Валута по подразбиране",
    "groups":"Групи",
    "code":"Код",
    "close":"Затвори",
    "delivery_days":"Дни за доставка",
    "delivery_point":"Точка на доставяне",
    "shipments":"Доставки",
    "statuses":"Статуси",
    "priorities":"Приоритети",
    "tir_groups":"Тир Групи",
    "tir_group":"Тир Група",
    "printings":"Печатници",
    "priority":"Приоритет",
    "remarks":"Особености",
    "remarks_trader":"Забележка Търговец",
    "remarks_logistic":"Забележка Логистика",
    "zip_code":"Пощенски Код",
    "delivery_from_print":"Ден доставка от печат",
    "delivery_loading":"Ден потегляне",
    "delivery_day":"Доставка най-късно",
    "copies_total":"Копия",
    "copies_sending":"Копия изпращане",
    "copies_left":"Оставащи копия",
    "kg":"кг",
    "schedule":"График",
    "calendar":"Календар",
    "schedules":"Графици",
    "search":"Търси",
    "year":"Година",
    "week":"Седмица",
    "already_exists":"вече съществува",
    "has_items":"има елементи",
    "created_by":"Създадено от",
    "assign_delivery":"Създай Доставка",
    "must_be_less_than":"трябва да е по-рано от",
    "must_be_greater_than":"трябва да е по-късно от",
    "trader":"Търговец",
    "package_number":"Номер на Пратка",
    "not_set":"Няма зададена",
    "active":"Активна",
    "no_orders_assigned_yet":"Няма назначени Поръчки",
    "other":"Друг",
    "action":"Действие",
    "actions":"Действия",
    "need_update":"Трябва да обновите!",
    "total":"Общо",
    "remaining":"Оставащи",
    "to_clients_address":"до адрес на клиента на територията на Р. България",
    "our_warehouse":"ФРАНКО наш склад",
    "point":"точка",
    "points":"точки",
    "clients_delivery_addresses":"Съществуващи клиентски адреси",
    "street":"Улица",
    "address_remarks":"Забележка Адрес",
    "enter_address_manually":"Въведи адрес ръчно",
    "locked":"Заключена",
    "unlocked":"Отключена",
    "inactive":"Неактивна",
    "shipped":"Изпратена",
    "not_shipped":"Неизпратена",
    "assigned":"Назначени",
    "selected":"Избрани",
    "pallet":"Палет",
    "paper_factor":"Коефицент хартии",
    "additional_costs":"Допълнителни разходи",
    "reset":"Изчисти",
    "payer":"Платец",
    "assign_for_shipment":"Назначи към пратка",
    "yes":"Да",
    "no":"Не",
    "boxes":"Кашони",
    "mark_as_shipped":"Маркирай като Доставена",
    "mark_all_as_shipped":"Маркирай всички като Доставени",
    "mark_as_not_shipped":"Маркирай като Недоставена",
    "mark_all_as_not_shipped":"Маркирай всички като Неизпратени",
    "send_to_transporter":"Изпрати към Транспортьор",
    "transporter_has_locked_the_delivery":"Транспортьорът е заключил доставката",
    "mark_as_assigned_to_tir_group":"Маркирай като разпределена в Тир Група",
    "mark_as_is_shipping":"Маркирай като Потеглила",
    "mark_as_not_shipping":"Маркирай като непотеглила",
    "mark_all_as_assigned_to_tir_group":"Маркирай всички като разпределени по Тир Групи",
    "mark_all_as_not_assigned_to_tir_group":"Маркирай всички като Не-разпределени по Тир Групи",
    "dates":"Дати",
    "sending":"За изпращане",
    "transporter":"Транспортьор",
    "see_on_map":"Виж на картата",
    "delivery_express_day":"Експресна доставка",
    "date":"Дата",
    "additional_options":"Допълнителни Опции",
    "barcode":"Баркод",
    "isbn":"ISBN",
    "generate":"Генерирай",
    "tickets":"Етикети",
    "ticket":"Стикер",
    "all_tickets":"Всички етикети",
    "ex":"бр.",
    "cnt":"бр.",
    "documents":"Документи",
    "bdl":"BDL",
    "cmr":"CMR",
    "all_bd_ls":"Всички BDLs",
    "all_cm_rs":"Всички CMRs",
    "only_marked":"Само маркирани",
    "cloth":"Облекло",
    "cardboard":"Мукава",
    "endpapers":"Форзац",
    "editor":"Издател",
    "consignment_number":"Номер на пратка",
    "number_of_copies_per_carton":"Бр. в кашон",
    "number_of_copies":"Доставени бр.",
    "title_label":"Заглавие",
    "label_ex":"бр.",
    "nothing_selected":"Няма избрани елементи!",
    "copies_in_a_box":"Бройки в кашон",
    "count_boxes":"Брой кашони",
    "warehouse":"Склад",
    "damaged_papers":"Развалени хартии",
    "component":"Компонент",
    "component_is_required":"Компонент e задължителен",
    "group_is_required_for_book":"Групата е задължителна за Книга",
    "thickness_additional":"Дебелина/Дунапрен",
    "difference":"Разлика",
    "mark":"Маркирай",
    "un_mark":"Размаркирай",
    "no_boxes_assigned":"Няма зачислени Кашони",
    "shipments_order":"Поръчка за доставка",
    "all_shipment_order":"Всички поръчки за доставки",
    "select_from_addresses":"Избери от адреси",
    "enter_other_address":"Въведи друг адрес",
    "must_not_be_deleted":"Обектър не трябва да бъде изтриван!",
    "mm":"мм",
    "is_shipped":"Доставена",
    "system":"Система",
    "width":"Широчина",
    "":"Д",
    "":"В",
    "":"Ш",
    "created_at":"Създадена на",
    "optional":"допълнителен",
    "production_number":"Номер на Джоб",
    "distributions":"Дистрибуции",
    "distribution":"Дистрибуция",
    "contact_person":"Лице за контакт",
    "client_has_orders":"Клиентът има поръчки!",
    "suspend":"Премахни",
    "suspended_by":"Suspended by",
    "suspended_at":"Suspended at",
    "suspended":"Премахнати",
    "deleted_by":"Изтрит на",
    "deleted_at":"Изтрит на",
    "organization":"Организация",
    "levs":"лева",
    "export_tn_ts":"Изтегли TNT-та",
    "bdl_date_label":"Документ създаден на",
    "bdl_proof_of_delivery":"Доставка",
    "bdl_title":"Заглавие",
    "bdl_number_of_copies_per_carton":"Бр. в кашон",
    "bdl_number_of_cartons":"Бр. кашони",
    "bdl_total_quantity":"Общо количество",
    "bdl_number_of_pallets":"Бр. палета",
    "bdl_total":"Общо",
    "bdl_consignment_number":"Пратка номер",
    "bdl_delivered_to":"Да се достави до",
    "bdl_on_behalf_of":"На вниманието на",
    "bdl_date":"Дата:",
    "bdl_signature":"Подпис и печат:",
    "assign_other_contact_in_documents":"Назначи друг контакт за Документите",
    "production":"Производство",
    "productivity":"Производителност",
    "circulation":"Тираж",
    "term":"Срок",
    "machine":"Машина",
    "book_cover":"Подвързия",
    "amount":"Количество",
    "schedule_status":"Статус",
    "schedule_client":"Клиент",
    "schedule_title":"Заглавие",
    "schedule_circulation":"Тираж",
    "schedule_format":"Формат",
    "schedule_remarks":"Забележка",
    "schedule_color":"Цветност",
    "schedule_term":"Срок",
    "schedule_machine":"Машина",
    "schedule_book_cover":"Подвързия",
    "schedule_amount":"Количество",
    "schedule_body":"Тяло",
    "schedule_cover":"Корица",
    "schedule_cover_extras":"Екстри Корица",
    "schedule_cloth":"Обложка",
    "schedule_endpapers":"Форзац",
    "filter":"Филтър",
    "preparations":"Подготовки",
    "schedule_preparations":"Подготовки",
    "schedule_technologist":"Технолог",
    "due_date":"Крайна дата за изработване",
    "schedule_fitter":"Монтажник",
    "schedule_prepress":"Препрес",
    "copy":"Копие",
    "all_technologists":"Всички Технолози",
    "all_fitters":"Всички Монтажисти",
    "all_prepressers":"Всички Препечат",
    "schedule_production_number":"Джоб Номер",
    "show_only_mine":"Покажи само моите",
    "schedule_employees":"Служители",
    "schedule_type":"Тип",
    "schedule_state":"Състояние",
    "schedule_bodies":"Телa",
    "schedule_sheets":"Коли",
    "create_and_ship":"Създай и изпрати",
    "update_and_ship":"Обнови и изпрати",
    "filter_by_trader":"Филтрирай по Търговец",
    "no_trader_selected":"Няма избран Търговец",
    "filter_by_printing":"Филтрирай по Печатница",
    "filter_by_tir_group":"Филтрирай по Тир група",
    "filter_by_delivery_from_printing_day":"Ден доставка от Печат",
    "filter_by_delivery_arrival_day":"Дата на доставка",
    "no_printing_selected":"Не е избрана Печатница",
    "no_tir_group_selected":"Не е избрана Тир група",
    "no_delivery_from_printing_day_selected":"Не е избран ден за доставка от Печат",
    "no_delivery_arrival_day_selected":"Не е избран ден за доставка най-късно",
    "no_delivery_from_expedition_selected":"Не е избран ден на порегляне",
    "filter_by_delivery_from_expedition_day":"Ден на потегляне",
    "filter_by_id":"Филтрирай по ID",
    "filter_by_job":"Филтрирай по № джоб",
    "filter_by_country":"Филтрирай по Страна",
    "no_country_selected":"Не е избрана Страна",
    "id_invalid":"ID е невалиден",
    "job_invalid":"Джоб е невалиден",
    "filter_by_package_number":"Филтрирай по Номер на пратка",
    "filter_by_isbn":"Филтрирай по ISBN",
    "filter_by_order_name":"Филтрирай по Заглавие",
    "filter_by_trader_name":"Филтрирай по Търговец",
    "all_packages":"Всички Точки",
    "all_books":"Всички Книги",
    "dropped":"Отпаднала",
    "books_dropped":"Отпаднала",
    "books_title":"Заглавие",
    "books_package_number":"Номер на пратка",
    "books_order_id":"Номер на поръчка",
    "books_address":"Адрес на доставка",
    "books_delivery_date":"Дата на доставка",
    "books_boxes":"Брой кашони",
    "books_printing":"Печатница",
    "books_client":"Клиент",
    "books_trader":"Търговец",
    "books_copies_ordered":"Брой поръчани",
    "books_copies_expedited":"Брой заминали",
    "books_difference":"Разлика",
    "books_weight_ordered":"Тегло(кг) поръчани",
    "books_weight_per_unit":"Тегло(кг) за бройка",
    "points_all_point":"Пратки за Транспорт ",
    "tir_group":"Тир Група",
    "client":"Клиент",
    "trader":"Търговец",
    "shipment_n":"Пратка №",
    "boxes":"Брой Кашони",
    "zip_code":"Пощенски Код",
    "address":"Адрес на доставка",
    "priority":"Приоритет",
    "delivery_date":"Доставка най-късно",
    "remarks_trader":"Особености Търговци",
    "from_printing_date":"Ден от печат",
    "loading_date":"Ден товарене",
    "weight_ordered":"Поръчани кг",
    "total_copies_ordered":"Общ/Поръчан брой",
    "total_weight_ordered":"Общо/Поръчани кг",
    "total_copies_expedited":"Общо/Заминал брой",
    "price_kg":"Цена/(КГ)",
    "phone":"Телефон",
    "email":"Email",
    "country":"Държава",
    "remarks_logistic":"Особености Логистика",
    "tickets_address":"Адрес",
    "tickets_editor":"Клиент",
    "tickets_consignment_number":"Ref",
    "tickets_order_number":"№ на поръчка",
    "tickets_title_label":"Заглавие",
    "tickets_number_of_copies_per_carton":"Бр. в кашон",
    "tickets_number_of_copies":"Бр.",
    "tickets_label_ex":"",
    "return_shipment":"Върни пратката",
    "update_remarks":"Обнови особености",
    "financial_ctrl":"Финансов Контрол",
    "fin_company_name":"Име на фирма",
    "fin_client_name":"Име на Kлиента",
    "fin_client_phone":"Телефон",
    "fin_client_email":"Email",
    "fin_trader_name":"Име на Търговец",
    "fin_order_price":"Стойност на Поръчката",
    "fin_financial_ctrl_state":"Становище по Поръчка",
    "fin_financial_ctrl_remarks":"Коментар към становище",
    "fin_super_am_state":"Становище по Поръчка Super AM",
    "fin_super_am_remarks":"Коментар към становище на Super AM",
    "fin_updated_by":"Последно Обновена от",
    "fin_title":"Заглавие",
    "restore_client":"Сигурни ли сте, че искате да възстановите този Клиент?",
    "client_exists":"Клиент със същия емейл адрес съществува.",
    "restore":"Възстанови",
    "all_reports":"Всички Репорти",
    "sorted_by_id":"Сортирани по ID",
    "sorted_by_country":"Сортирани по Страна",
    "sorted_by_post_code":"Сортирани по Пощенски код",
    "export_documents":"Екпорт Документи",
    "sorted_by_tir_group":"Сортирани по Тир група",
    "sorted_by_city":"Сортирани по Град",
    "sorted_by_organization":"Сортирани по Организация",
    "sorted_by_client":"Сортирани по Клиент",
    "sorted_by_package_number":"Сортирани по Номер на пратка",
    "reports_all_point":"Доставки за транспорт",
    "reports_tir_group":"Тир група",
    "reports_delivery_date":"Дата на доставка",
    "reports_country":"Държава",
    "reports_post_code":"Пощенски код",
    "reports_city":"Град",
    "reports_address":"Адрес",
    "reports_number":"Номер",
    "reports_organization":"Организация",
    "reports_client":"Клиент",
    "reports_phone":"Телефон",
    "reports_email":"Емейл",
    "reports_phone_optional":"Телефон (допълнителен)",
    "reports_email_optional":"Емейл (допълнителен)",
    "reports_order_name":"Заглавие",
    "reports_package_number":"Номер на пратка",
    "reports_prints":"Количество реално заминало",
    "reports_boxes":"Бр. кашони",
    "reports_weight":"КГ.",
    "mark_as_arrived":"Маркирай като Доставена",
    "mark_as_departed":"Маркирай като Недоставена",
    "transport_ctrl":"Контрол Транспорт",
    "not_expedited_yet":"Непотеглила",
    "is_shipping":"Потеглила",
    "not_assigned":"Неразпределена",
    "sending_copies":"Бр. изпратени",
    "order_id":"Номер на поръчка",
    "date_today":"Днешна дата",
    "date_marked_from_trader":"Дата въведена от Търговец",
    "date_marked_from_warehouse":"Дата въведена от Склад",
    "filter_by_client":"Филтрирай по Клиент",
    "auto_rescheduled_for_week":"Преразпределена в седмица",
    "rescheduled_prints":"Преразпределени бройки",
    "rescheduled_weight":"Преразпределени килограми",
    "quick_shipments_switch":"Доставки бърза смяна",
    "quick_prices_switch":"Цени бърза смяна",
    "sending_boxes":"Кашони изпратени",
    "deny_reschedule":"Откажи препращане",
    "proceed_reschedule":"Приеми за препращане",
    "proceed_reschedule_and_ship":"Приеми за препращане прати и към Транспорт",
    "reschedule":"Преразпределение",
    "reschedule_history":"Преразпределения",
    "denied":"Отказан/а",
    "rescheduled":"Преразпределен/а",
    "sent":"Изпратен/а",
    "copies_rescheduled":"Бр. препразпределени",
    "weight_rescheduled":"Кг. препразпределени",
    "unresolved":"Предстоящ/а",
    "copy_to_clipboard":"Copy to clipboard",
    "no_boxes":"Не са вписани кашони",
    "agreement_day":"Договорена дата за доставяне",
    "filter_by_delivery_agreement_day":"Договорена дата за доставяне",
    "reports_delivery_date_trader":"Дата на доставка/Търговец",
    "reports_delivery_date_agreement":"Договорена дата на доставка",
    "reports_delivery_date_final":"Крайна дата на доставка",
    "denied_prints":"Отказани бройки",
    "type_of_print":"Вид печат",
    "message_boxes":"Транспортьорът е въвел кашони и бройки, но пратката не е маркирана като потеглила и не може да бъде преразпределена",
    "language":"Език",
    "reception_company":"Фирма получател (включва се в етикета)",
    "display_the_transport_price_in_the_offer":"Покажи цената на транспорта в офертата",
    "global_search":"Търсене навсякъде",
    "client_val_email":"Този мейл вече е регистриран",
    "client_val_name":"Клиент с това име вече е регистиран",
    "client_val_last":"Клиент с това име Kвече е регистиран",
    "client_val_org_name":"Организация с това име вече е регистрирана",
    "client_val_org_bulstat":"Този булстат вече е регистриран",
    "sales_agent":"Търговски агент",
    "disapproved":"Неодобрена",
    "approved":"Одобрена",
    "pending":"Чакаща",
    "organizations":"Организации",
    "show_only_disapproved":"Покажи само неудобрените",
    "history":"История",
    "user":"Потребител",
    "log":"Запис",
    "updated_at":"Обновен на",
    "system_generated":"Системно генериран/а",
    "system_updated":"Системно обновен/а",
    "client_email_registered":"Този емейл вече е регистиран в базата данни",
    "client_fln_ames_registered":"Това име и фамилия вече са регистирани в базата данни",
    "techno_card":"Тех. Карта",
    "techno_card_upload":"Качи",
    "techno_card_filename":"Файл",
    "techno_card_date":"Дата",
    "techno_card_status":"Статус",
    "activate":"Активирай",
    "techno_card_comment":"Коментар",
    "download":"Свали",
    "fin_sam_override":"Решинието за състоянието на поръчката на Super AM има по-голяма тежест от това на Финансовия контрол",
    "max_insurance":"Максимална допустима експозиция",
    "new_max_insurance":"Нова максимална допустима експозиция",
    "order_is_in_production":"Поръчката е в производство и нежния статус не може да бъде променян",
    "showing_results_after":"Показване на резултати след",
    "generated_client_financial_excel_file":"Генериране на Excel документ за финансов контрол на клиент",
    "generated_excel_file_for_schedule":"Генериране на Excel документ за финансов контрол по транспортна седмица",
    "all_shipments":"Всички Доставки",
    "all_prices":"Всички Цени",
    "printing":"Печатница",
    "in_production":"В Производство",
    "sent_to_fin_ctrl":"Изпратена към Финансов Контрол",
    "statistics":"Статистики",
    "total_shipments":"Общо пратки",
    "sending_shipments":"Изпратени пратки",
    "rescheduled_shipments":"Преразпределени пратки",
    "locale":"Локализация",
    "hunter":"Ловец",
    "quotes":"Оферти",
    "quote":"Оферта",
    "offer":"Оферти",
    "offers":"Оферта",
    "order_now":"Поръчай сега",
    "copies_difference":"Бр. разлика",
    "tirage":"Брой копия",
    "redistribute":"Преразпредели",
    "per_packs":"Фолирано в пакети",
    "packaging":"Опаковане: фолиране в пакети, в кашони върху палети",
    "pdf_issue294":"Негарантиран печат по водата за тялото.",
    "print_run_from":"Тираж от",
    "print_run_to":"Тираж до",

    "printRunFrom":"Тираж от",
    "printRunTo":"Тираж до",
    "overcharge_52":"0+0 [%]",
    "discount_52":"Отстъпка: 0+0",
    "overcharge_18":"1+0 [%]",
    "discount_18":"Отстъпка: 1+0",
    "overcharge_15":"1+1 [%]",
    "discount_15":"Отстъпка: 1+1",
    "overcharge_50":"2+0 [%]",
    "discount_50":"Отстъпка: 2+0",
    "overcharge_16":"2+2 [%]",
    "discount_16":"Отстъпка: 2+2",
    "overcharge_19":"4+0 [%]",
    "discount_19":"Отстъпка: 4+0",
    "overcharge_51":"4+1 [%]",
    "discount_51":"Отстъпка: 4+1",
    "overcharge_17":"4+4 [%]",
    "discount_17":"Отстъпка: 4+4 [%]",




    "pdf_issue296":"Внимание, при този размер подгъви, корицата няма да бъде отпечатана по водата!",
    "pdf_issue33":"+ надпечатен лак на тялото",
    "notifications":"Нотификации",
    "notification":"Нотификация",
    "is_active":"Активен",
    "off":"Изключен",
    "on":"Включен",
    "business_hours":"Работно време",
    "pallets_requirements":"Изисквания към палета",
    "cartons_requirements":"Изисквания към кашони",
    "more_information":"Допълнителна информация",
    "none":"Няма",
    "neutral_delivery":"Анонимна доставка",
    "preparers":"Предпечат",
    "preparer":"Предпечат",
    "duplicated_property":"Дублиран запис",
    "is_default":"По подразбиране",
    "is_final":"Крайно",
    "spine":"Гръбче",
    "real_spine":"Реално гръбче",
    "sample":"Проба",
    "samples":"Проби",
    "bleed_inside":"Вътрешно",
    "bleed_outside":"Външно",
    "bleed_head":"Глава",
    "bleed_legs":"Крака",
    "bleeds":"Обрязване + мм",
    "technologist":"Технолог",
    "has_sample":"Пусната",
    "address_delivery1":"Адрес Доставка 1",
    "address_delivery2":"Адрес Доставка 2",
    "address_delivery3":"Адрес Доставка 3",
    "contacts1":"Контакти 1",
    "contacts2":"Контакти 2",
    "contacts3":"Контакти 3",
    "contacts4":"Контакти 4",
    "contacts5":"Контакти 5",
    "foil":"Фолио",
    "number_of_stamps":"Брой печата",
    "all_sizes_in_mm":"Размери",
    "embossing":"Сухо преге",
    "embossing_foil":"Топъл печат",
    "load":"Натоварване",
    "holidays":"Почивни дни",
    "short_name":"Кратко име",
    "working_days":"Работни дни",
    "capacity":"Капацитет",
    "period":"Период",
    "load_lepena":"Лепена",
    "load_shita":"Шита",
    "load_tvarda":"Твърда",
    "load_s_kapaci":"С капаци",
    "load_vshm":"ВШМ",
    "load_drugi":"Друг вид поръчки",
    "free_capacity1":"Свободен капацитет %",
    "free_capacity2":"Свободен капацитет бр",
    "total40for_period":"Общо подготвки 4+0 за периода",
    "total40s_for_period":"Общо листа 4+0 за периода",
    "total11for_period":"Общо подготовки 1+1 за периода",
    "total11s_for_period":"Общо листа 1+1 за периода",
    "download_excel":"Свали Excel",
    "download_detailed_excel":"Свали детайлен Excel",
    "dencity":"Грамаж",
    "count_pages":"Бр. листа",
    "supplier":"Доставчик",
    "am":"AM",
    "paper_body":"Хартия тяло",
    "paper_cover":"Хартия корица",
    "calculated_transport_price":"Изчислена цена на транспорт",
    "calculated_transport_copies":"Изчислени бр. копия на транспорт",
    "calculated_transport_weight":"Изчислено тегло на транспорт",
    "books_price_be":"Цена транспорт BE",
    "recipient_email":"Email получател",
    "recipient_phone":"Тел. получател",
    "recipient":"Получател",
    "paper_type":"Тип хартия (тяло)",
    "missing_paper":"не е налична в списъка с хартии - за да се зареди офертата тя е заменена с хартия",
    "disabled":"Неактивна",
    "disabled":"Неактивни",
    "enabled":"Активни",
    "all":"Всички",
    "paper_validation":"Всички",
    "pantone_number":"Пантон",
    "pantone_numbers":"Пантона",
    "pantone_type":"Тип пантони",
    "pdf_paper_client":"Клиентска хартия ({PAPER_NAME} - {PAPER_DENSITY} g - {BIG_SHEETS} бр. листа {PAPER_SIZE})",
    "pdf_paper_client_special":"Специална хартия ({PAPER_NAME} - {PAPER_DENSITY} g - {BIG_SHEETS} бр. листа {PAPER_SIZE})",
    "pdf_paper_client_special2":"Специална хартия ({PAPER_NAME} - {PAPER_DENSITY} g)",
    "paper_client":"Клиентска хартия",
    "special_client":"Специална хартия",
    "users_excel_id":"ID",
    "users_excel_first_name":"Име",
    "users_excel_last_name":"Фамилия",
    "users_excel_email":"Email",
    "users_excel_phone":"Телефон",
    "users_excel_role":"Роля",
    "users_excel_websites":"Сайт/ове",
    "price_per_sheet2":"Цена на лист",
    "fin_ctrl_currency":"Валута",
    "fin_ctrl_approval":"Покритие",
    "fin_ctrl_payment_term":"Условия на плащане",
    "fin_intra_community_vat":"Валиден ДДС № за EC",
    "field_required":"Задължително поле",
    "format_error":"Формата е извън технологичния лимит (мин {MIN_MM} мм / макс {MAX_MM} мм). Моля, да го промените или се свържете с наш сътрудник за индивидуална оферта.",
    "format_error1":"Формата е извън технологичния лимит (",
    "format_error2":").",
    "format_error3":"Моля, да го промените или се свържете с наш сътрудник за индивидуална оферта.",
    "spine_error":"Дебелината на гръбчето е извън технологичния лимит (мин {MIN_MM} мм / макс {MAX_MM} мм). За експертен съвет и индивидуална консултация, моля, свържете с наш сътрудник.",
    "spine_error1":"Дебелината на гръбчето е извън технологичния лимит (",
    "spine_error2":"мм).",
    "spine_error3":"За експертен съвет и индивидуална консултация, моля, свържете с наш сътрудник.",
    "due_date_validation":"Моля, попълнете полето - крайна дата за изработване",
    "initial_dead_line":"Първоначален срок",
    "books_format":"Формат",
    "pantone_serial":"Пантон номер",
    "pantone_amount":"Количество пантон",
    "prep_remarks":"Забележка състояние",
    "prep_remarks_probe":"Забележка проба",
    "documentation":"Документация",
    "remarks_accounting":"Забележки счетоводство",
    "billing_ntities":"Фактуриране",
    "volume_without":"Обем m3 (без кашони)",
    "ratio":"Коефициент",
    "at_name":"При",
    "tirage_label_ex":"Тираж",
    "shipment_price":"Цена доставка в лв",
    "failed":"Неуспешно удостоверяване на потребител.",
    "throttle":"Твърде много опити за логин. Моля, опитайте отново след :seconds секунди.",
    "default":"По подразбиране",
    "data_error":"Грешка в данните",
    "calc_error":"Грешка при пресмятането",
    "db_error":"Грешка свързана с базата от данни",
    "paper_color_density_mismatch_text":"Комбинацията хартия/цвят/плътност за тази оферта (текст) не бе намерена в базата от данни. Моля изберете други!",
    "paper_color_density_mismatch_cover":"Комбинацията хартия/цвят/плътност за тази оферта (корица) не бе намерена в базата от данни. Моля изберете други!",
    "paper_color_density_mismatch_insert":"Комбинацията хартия/цвят/плътност за тази оферта (вложка) не бе намерена в базата от данни. Моля изберете други!",
    "zero_copies":"Брой копия трябва да бъде по-голям от 0.",
    "no_hat_db_record":"Не може да бъде намерен вида капителбанд в базата от данни.",
    "no_lace_db_record":"Не може да бъде намерен вида ласе в в базата от данни.",
    "text_pages_change":"Text pages change: :FROM :TO",
    "insert_pages_change":"Insert pages change: :FROM :TO",
    "cover_pages_change":"Cover pages change: :FROM :TO",
    "titles":"Г-н.",
    "titles":"Г-жa.",
    "organization_type":"Legal form",
    "trademark":"Търговско наименование",
    "address_correspondence":"Адрес за кореспонденция",
    "code_ebp":"Код EBP",
    "code_ajour":"Код AJOUR",
    "civilite":"Обръщение",
    "prenom":"Име (фактура)",
    "nomenvoi":"Фамилия (фактура)",
    "email_organization":"E-mail (фактура)",
    "email_organization_copy":"E-mail в копие (фактура)",
    "siege_social":"седалище",
    "create":"Създай Нов",
    "edit":"Промени",
    "copy":"Копирай",
    "delete":"Изтрий",
    "submit":"Изпрати",
    "email":"Email",
    "order":"Поръчка",
    "overcharges_main":"Надценки",
    "overcharges_list":"Списък",
    "overcharges_create":"Създай",
    "title":"Заглавие",
    "client_email":"Мейлът е регистриран на друг клиент",
    "new_client":"Клиентът не може да бъде създаден, прекъсване на поръчката",
    "client_id":"Клиентът не е намерен, прекъсване на поръчката",
    "order_save":"Поръчката не може да бъде запазена",
    "headings_create":"New Order",
    "headings_edit":"Edit Order",
    "filter_id":"Id",
    "filter_client":"Клиент / Организация / Контакт",
    "filter_status":"Статус",
    "filter_name":"Заглавие",
    "filter_product_type":"Продукт",
    "filter_apply":"Филтрирай",
    "filter_clear":"Изчисти",
    "filter_owner":"Потребител",
    "filter_show_mine":"Покажи само моите поръчки",
    "filter_allproducts":"Всички",
    "filter_allusers":"Всички",
    "filter_from":"От дата",
    "filter_to":"До дата",
    "form_pricing":"Ценообразуване",
    "form_price_final":"Крайна цена",
    "form_price":"Цена",
    "form_print_run":"Тираж",
    "form_info":"Информация за поръчката",
    "form_cover":"Корица",
    "form_text":"Тяло",
    "form_insert":"Вложка",
    "form_properties":"Продуктови характеристики",
    "form_order_name":"Име на поръчката (Ref.)",
    "form_budget":"Бюджет",
    "form_overcharge_default":"Надценка по подразбиране",
    "form_overcharge_total":"Крайна надценка",
    "form_overcharge_change":"Промяна",
    "form_discount":"Отстъпка",
    "form_category":"Изберете категория",
    "form_client_new":"Нов клиент",
    "form_client_select":"Изберете клиент",
    "form_client_name":"Клиентско име",
    "form_client_email":"Клиентски Email",
    "form_client_phone":"Клиентски телефон",
    "form_client_company":"Име на компанията",
    "form_pages":"Страници",
    "form_print":"Печат",
    "form_finishing":"Покритие",
    "form_paper":"Хартия",
    "form_color":"Цват на хартия",
    "form_density":"Грамаж на хартия",
    "form_weight":"Тегло",
    "form_height":"Височина",
    "form_length":"Дължина",
    "form_size":"Обрязан формат",
    "form_folding":"Вид сгъвка",
    "form_binding":"Книговезане",
    "form_copies":"Брой копия",
    "form_address":"Адрес за доставка",
    "form_delivery":"Допълнителна точка за доставка",
    "form_comment":"Коментар за поръчката",
    "form_add_cover":"Корица",
    "form_add_insert":"Вложка",
    "form_add_jacket":"Обложка",
    "form_add_banderole":"Бандерол",
    "form_history_id":"ID",
    "form_history_section_title":"История на поръчка",
    "form_history_ids":"ID / Поръчка Id",
    "form_history_action":"Събитие",
    "form_history_user_names":"Потребител",
    "form_history_date":"Дата",
    "form_history_additional_data":"Допълнителна информация",
    "form_banderole":"Бандерол",
    "form_jacket":"Обложка",
    "form_additional_languages":"Мутации на черното",
    "form_main_language_copies":"Брой копия - основен език",
    "form_add_language_copies":"Брой копия - допълнителен език",
    "form_add_language_mutation_count":"мутации на ЧЕРНОТО",
    "form_cloth":"Облекло",
    "form_cardboard":"Мукава",
    "form_endpapers":"Форзац",
    "form_add_endpapers":"Форзац",
    "form_cloth_type":"Тип",
    "form_cloth_type_paper":"Облекло - хартия",
    "form_cloth_type_mixed":"Облекло - смесено",
    "form_cloth_type_cloth":"Облекло - платно",
    "form_cloth_type_without_clothing":"Без облекло",
    "form_cloth_width":"Ширина на платно",
    "form_cloth_price":"Цена на платно (кв.м)",
    "form_cloth_visible_part":"Видима част на платното",
    "form_cloth_model":"Име/Модел",
    "form_cloth_supplier":"Доставчик",
    "form_cloth_min_length":"Разфасовка (м)",
    "form_cardboard_paper_name_suffix":"(Дебелина картон мм. / Дебелина дунапрен мм.)",
    "form_bookmark_insert":"С влагане",
    "form_bookmark_no_insert":"Без влагане",
    "form_bookmarks":"Разделителя",
    "form_spine_type":"Вид гръбче",
    "form_spine_type_flat":"Право",
    "form_spine_type_round":"Кръгло",
    "form_cloth_int_select":"Каталози платна",
    "form_cloth_int_vendor":"Доставчик",
    "form_cloth_int_type":"Тип",
    "form_cloth_int_price":"Цена на m²",
    "form_cloth_int_price_min":"Цена на m² (min)",
    "form_cloth_int_price_max":"Цена на m² (max)",
    "form_cloth_int_search":"Филтър по име на продукт",
    "form_cloth_int_apply":"Приложи",
    "form_cloth_int_clear":"Премахни",
    "form_cloth_int_cancel":"Откажи",
    "form_cloth_int_insert":"Вмъкнете избора",
    "form_cloth_int_series":"Серия",
    "form_cloth_int_model":"Модел",
    "form_cloth_int_size":"Размери",
    "price_correction_type":"Вид",
    "price_correction_title":"Добавки към цената",
    "price_correction_visible":"Безплатно",
    "price_correction_sum":"Сума",
    "price_correction_description":"Описание",
    "price_correction_option_included_invisible":"",
    "price_correction_option_included_included":"Услуги включени в цената: ",
    "price_correction_option_included_free":"Безплатни услуги: ",
    "price_correction_option_included_invisible_button":"Да не се показва в PDF",
    "price_correction_option_included_included_button":"Вклчено в цената",
    "price_correction_option_included_free_button":"Подарък",
    "order_name":"Име на поръчката",
    "organization":"Организация",
    "id":"Id",
    "client":"Клиент",
    "product":"Продукт",
    "price":"Цена",
    "status":"Статус",
    "date_created":"Дата на създаване",
    "account_manager":"AM/Hunter",
    "website":"Уебсайт",
    "budget":"Бюджет",
    "order_status":"Personnalisé",
    "order_status":"Prix Cat. client_category_id",
    "order_status":"Prix Public",
    "sum_thickness":"Дебелина на гръбчето",
    "client_missing_category_alert":"Избраният клиент не принадлежи към нито една ценова категория!",
    "price_change_alert":"Внимание, разлика между старата оферта и преизчислената цена! ({price_change}).",
    "hide_discount":"Да не се показва отстъпката в PDF",
    "without_cover":"Без корица",
    "old_price":"Стара цена",
    "print_run_from":"Тираж от",
    "print_run_to":"Тираж до",
    "previous":"« Назад",
    "next":"Напред »",
    "password":"Паролата трябва да бъде поне шест знака и да съвпада.",
    "reset":"Паролата е ресетната!",
    "sent":"Изпратено е напомняне за вашата парола!",
    "token":"Този токен за ресет на парола е невалиден.",
    "user":"Потребител с такъв e-mail адрес не може да бъде открит.",
    "clear_all_filters":"Изчисти",
    "filter_by_all_active_filters":"Филтрирай",
    "filters":"Филтри",
    "filter_by_op_in":"Опрератор",
    "filter_by_machine":"Филтрирай по Машина",
    "filter_by_stage":"Филтрирай по Етап",
    "filter_by_element":"Филтрирай по Елемент",
    "filter_by_action":"Филтрирай по Действие",
    "filter_by_state":"Филтрирай по Състояние",
    "btn_edit":"Редактирай",
    "btn_create":"Създай",
    "btn_submit":"Приложи",
    "btn_delete":"Изтрий",
    "btn_update":"Обнови",
    "btn_toggle_drop_down":"Изкарай дропдаун опции",
    "btn_back":"Назад",
    "list":"Списък",
    "name":"Име",
    "color":"Цвят",
    "element":"Елемент",
    "state":"Състояние",
    "stage":"Етап",
    "component":"Компонент",
    "action":"Действие",
    "state_type":"Тип състояние",
    "continue":"Продължи",
    "operator_types":"Типове оператори",
    "operator_type":"Тип оператор",
    "operator":"Оператор",
    "code":"Персонален код",
    "settings_title":"Настройки",
    "settings_elements_title":"Елементи",
    "settings_stages_title":"Етапи",
    "settings_components_title":"Компоненти",
    "settings_actions_title":"Действия",
    "settings_states_title":"Състояния",
    "settings_state_types_title":"Типове състояния",
    "settings_machines_title":"Машини",
    "settings_product_builder_title":"Продуктов конструктор",
    "settings_operators_title":"Оператори",
    "settings_operator_types_title":"Типове оператори",
    "messages_select_product":"Изберете продукт",
    "messages_select_elements":"Изберете елементи за продукта",
    "messages_not_all_elements_have_stages":"Не всички елементи имат етапи",
    "messages_select_stages":"Изберете етапи за елемент на продукта",
    "messages_not_all_stages_have_actions":"Не всички етапи имат действия",
    "messages_select_actions":"Изберете действия за етап на елемент на продукта",
    "messages_not_all_actions_have_states":"Не всички действия имат състояния",
    "messages_select_states":"Изберете състояния за действие в етап на елемент на продукта",
    "messages_select_default":"Избраното по подразбиране състояние трябва да бъде измежду избраните състояния",
    "messages_select_final":"Избраното крайно състояние трябва да бъде измежду избраните състояния",
    "validation_required":"е задължителен",
    "validation_date":"трябва да е дата",
    "validation_email":"трябва да е валиден email адрес",
    "validation_number":"трябва да е само цифри",
    "validation_rangelength":"трябва да е от 4 цифри",
    "validation_rangelength_remarks":"трябва да е от 3 до 1000 символа",
    "format":"Формат",
    "ready_copies":"Готови бройки",
    "copies":"Тираж",
    "production_number":"№ джоб",
    "personalize_operation":"Персонализиране на операцията",
    "find_yourself_in_the_list":"Намерете се в списъка",
    "enter_your_personal_code":"Въведете персоналния си код",
    "koli":"Бр. коли",
    "machine":"Машина",
    "trader":"Търговец",
    "technologist_in_charge":"Отговорен технолог",
    "final_date_to_be_produced":"Крайна дата",
    "order_remark":"Зажележка за поръчка",
    "stage_remark":"Забележка за етап",
    "additional_date":"Допълнителна дата 2",
    "additional_date_remarks":"Забележки производство",
    "machines":"Машини",
    "stages":"Етапи",
    "elements":"Елементи",
    "actions":"Действия",
    "states":"Състояния",
    "title":"Заглавие",
    "traders":"Търговци",
    "order_history":"История на поръчката",
    "updated_by":"Последно обновена от-на",
    "date":"Дата",
    "remarks":"Забележка",
    "clients":"Клиенти",
    "bindings":"Книговезане",
    "products":"Продукти",
    "show_only_mine":"Покажи само моите",
    "auth":"Идентификация",
    "on":"Включен",
    "off":"Изключен",
    "error":"Грешка",
    "failed_action":"Операцията е неуспешна",
    "close":"Затвори",
    "colors":"Цветност",
    "required":"задължителен",
    "saved":"Запазено",
    "integer":"трябва да е само числа",
    "exact_length":"трябва да е 4 числа",
    "":"Д.",
    "":"В.",
    "landscapes":"Landscape distributions",
    "portraits":"Portrait distributions",
    "sort_by_filter":"Сортирай по Филтър",
    "sort_order":"Посока на сортиране",
    "distribution":"Кола",
    "distributions":"Коли",
    "distribution_amount":"Бр. коли за сгъване",
    "distribution_bending":"Сгъване",
    "asc":"Възходящ",
    "desc":"Низходящ",
    "remarks_date":"Дата забелeжка",
    "slug":"Условие",
    "value":"Стойност",
    "new_record":"Нов запис",
    "event_text":"Текст",
    "event_data":"Данни",
    "event_date":"Дата",
    "history":"История",
    "system":"Система",
    "show_only_active":"Покажи само активните",
    "active":"Активна",
    "done":"Готова",
    "pages":"Бр. страници",
    "big_sheets":"Бр. л. за печат",
    "final":"Крайно",
    "operator_permissions":"Възможности",
    "operator_permission":"Възможност",
    "spine":"Гръбче",
    "real_spine":"Реално гръбче",
    "sample":"Проба",
    "samples":"Проби",
    "bleed_inside":"Вътрешно",
    "bleed_outside":"Външно",
    "bleed_head":"Глава",
    "bleed_legs":"Крака",
    "bleeds":"Обрязване + мм",
    "technologist":"Технолог",
    "number_of_sheets_text":"Бр. листа",
    "big_sheets_marked":"Бр. отбелязани л. за печат",
    "big_sheets_mark":"Бр. л. за печат отбележи",
    "number_of_sheets_text_marked":"Бр. отбелязани листа",
    "number_of_sheets_text_mark":"Бр. листа отбележи",
    "big_sheets_in_week":"Бр. л. за печат в седмицата",
    "number_of_sheets_in_week":"Бр. листа в седмицата",
    "with_flaps":"С капаци",
    "prepress":"Монтаж",
    "ctp":"CTP",
    "workshop":"Склад",
    "workshop_ready_production":"Склад готова продукция",
    "completion":"Довършителен цех",
    "fitter":"Печатен цех",
    "place":"Място",
    "due_date":"Клиентски срок",
    "with_bookmark":"С влагане",
    "without_bookmark":"Без влагане",
    "urgent":"Необратена",
    "in_edit":"В процес",
    "inactive":"Неактивна",
    "order1":"Необработена",
    "order2":"В процес",
    "order3":"Неактивна",
    "order4":"Готова",
    "production_second_production":"Повторна изработка",
    "amount":"Брой",
    "no_recs_found":"Няма намерени записи.",
    "no_states":"Няма създадени състояния",
    "no_actions":"Няма създадени действия",
    "none":"Няма",
    "btn_send_to_production":"Изпрати в производство",
    "btn_revert_production":"Откажи производството",
    "additional_date_validation":"Полето - Допълнителна дата 2 е задължително",
    "send_to_preparer":"Прати към предпечат",
    "revert_from_preparer":"Върни от предпечат",
    "request_real_spine":"Измерване на гръбче",
    "request_width_measure":"Измерване на дебелина",
    "width":"Дебелина",
    "width_body":"Дебелина на тяло",
    "request_width_body_measure":"Измерване на дебелина",
    "revert":"Върни",
    "revert_to":"Върни към",
    "preparer_required":"Моля, попълнете полето предпечат",
    "sample_custom":"Цифрова проба",
    "paper_custom":"Особености хартия",
    "lace":"Ласе",
    "head_and_tails_band":"Капителбанд",
    "bookmark":"Разделител",
    "foil_wrapped":"Опаковане поединично",
    "foil_packed":"Фолирано в пакети",
    "total_orders":"Тотал всичко",
    "total_orders_without_vshm_and_others":"Тотал без ВШМ и Други",
    "count_orders":"Бр. поръчки",
    "big_sheets":"Бр. подготовки 4+0",
    "big_sheets":"Бр. подготовки 1+1",
    "big_sheets":"Бр. подготовки 2+2",
    "number_of_sheets":"Бр. листа 4+0",
    "number_of_sheets":"Бр. листа 1+1",
    "number_of_sheets":"Бр. листа 2+2",
    "dist_amount":"Бр. коли",
    "embossing_foil":"Топъл печат",
    "search_by_period":"Търси по Период",
    "no_copies":"Няма въведени Готови бройки",
    "done_completion":"Готова",
    "deactivated_paper":"За този елемент това е единствената хартия и не може да бъде деактивирана.",
    "period_from":"Период от",
    "period_to":"Период до",
    "week":"Седмица",
    "fit_sheet":"График за печат",
    "fit_heading":"График за печат на",
    "fit_hour":"ч.",
    "fit_gr":"гр.",
    "fit_order":"Пор.",
    "fit_client":"Клиент",
    "fit_product":"Продукт",
    "fit_due_date":"Срок",
    "fit_tirage":"Тираж",
    "fit_paper_format":"Формат на хартията",
    "fit_paper":"Хартия",
    "fit_color_embossing":"Цветност/ламинат",
    "fit_big_sheets":"Подготовки на машината",
    "fit_tirage_sheets":"Тиражни листи",
    "fit_paper_place":"Сектор с хартия",
    "fit_digital_real_proof":"Дигитална проба Реална проба Мостра Да/Не",
    "fit_printing_start_hour":"Начало за печат (Час)",
    "fit_printing_end_hour":"Край (Час)",
    "fit_tirage_time_in_hours":"Време на тираж (Часове)",
    "fit_comment_or_problems":"Коментар по тиража / Възникнали проблеми",
    "fit_pages":"Бр. стр.",
    "fit_format":"Формат",
    "fit_binding":"Книговезане",
    "remarks_workshop":"Забележка склад",
    "partially_done":"Частично изработени",
    "probe":"Проба",
    "partially_done_and_finish":"Частично изработени и завърши",
    "dones":"Готови",
    "text_pages":"Бр. стр. тяло",
    "text_print":"Печат тяло",
    "text_paper":"Х-я тяло",
    "text_paper_density":"Грамаж х-я тяло",
    "body_maculaturi":"Бр. големи листа + макулатури тяло",
    "body_size":"Формат х-я тяло",
    "insert_pages":"Бр. стр. вложка",
    "insert_print":"Печат вложка",
    "insert_paper":"Х-я вложка",
    "insert_paper_density":"Грамаж х-я вложка",
    "vlojka_maculaturi":"Бр. големи листа + макулатури вложка",
    "size_vlojka":"Формат х-я вложка",
    "spine_width":"Гръбче",
    "dashboard_main":"Табло",
};

export default bg;
