import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../../../../../helpers/DataTableInitDataGenerator';
import NetworkClient from '../../../../../../api/NetworkClient';
import Loader from '../../../../../../partial/Loader';
import SwalDelete from '../../../../../../helpers/SwalDelete';

class ExpressList extends Component {
    state = {
        isLoading: false,
        data: {
            columns: [],
            rows: []
        }
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {t} = this.props;
        let listColumns = ['id', 'weight', 'price', 'order', 'actions'];
        this.setState({data: DataTableInitDataGenerator(listColumns, t)});
        this.load();
    }

    load() {
        this.setState({isLoading: true});
        NetworkClient.get('transport/uk/prices/express').then(response => {
            let {data} = this.state;
            let items = response.express;
            let rows = [];
            items.forEach((item, i) => {
                let tmp = {
                    id: item.id,
                    weight: item.weight + ' kg',
                    price: item.price + ' bgn',
                    order: item.order,


                    actions: <div>
                        <Link to={`/transport/uk/prices/express/edit/${item.id}`} className={"btn btn-info btn-sm"}>
                            <i className={"fa fa-pen"} />
                        </Link>
                    </div>
                };
                rows.push(tmp);
            });


            this.setState({data:{columns: data.columns, rows: rows}, isLoading: false});
        })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                UK -  {t('transport')} {t('express')} {t('prices')}
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <MDBDataTable
                                            striped
                                            bordered
                                            hover
                                            entries={10}
                                            data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ExpressList);