import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Select from "react-select";
import EmbossingFormPartial from "../partial/EmbossingFormPartial";
import EmbossingFoilFormPartial from "../partial/EmbossingFoilFormPartial";

class CoverForm extends Component {
    state = {
        isLoading: false,
        is_enabled: true,
        papers: [],
        densities: [],
        colors: [],
        selectedPaperNameId: -1,
        selectedDensity: -1,
        selectedColorId: -1,
        selectedPrint: -1,
        selectedFinishing: -1,
        groups: [],
        selectedGroup: 'normal',
        embossing: null,
        embossing_foil: null,
        properties: [],
        num_pages: 4,
        nadpechaten_lak: false,
        pantones: [{id: 0, name: "0"},{id: 1, name: "1"},{id: 2, name: "2"},{id: 3, name: "3"},{id: 4, name: "4"}],
        pantone: 0,
        //TODO: Add it to static classes later
        pantones_types: [{id: 1, name: 'Стандарт'},{id: 2, name: 'Електриков'},{id: 3, name: 'Металиков'}],
        has_flaps: false,
        flaps: 0,
        leftFlap: 0,
        rightFlap: 0,
        cover_pantones_info: [],
        selektiven_lak: false,
        obemen_lak: false,
        isOpen: true,
        is_inited: false
    }

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(props) {
        if(props.initial_state !== null && !this.state.is_inited && this.state.papers.length > 0)
        {
            let initial_state = props.initial_state;
            this.selectPaper(initial_state.paper_name_id, () => {
                this.selectColor(initial_state.paper_color_id);
                this.setState({
                    is_inited: true,
                    cover_pantones_info: initial_state.cover_pantones_info,
                    selectedDensity: initial_state.density,
                    embossing: initial_state.embossing,
                    embossing_foil: initial_state.embossing_foil,
                    selectedFinishing: initial_state.finishing,
                    flaps: initial_state.flaps,
                    num_pages: initial_state.num_pages,
                    pantone: initial_state.pantone,
                    selectedPrint: initial_state.print,
                    properties: initial_state.properties,
                    selektiven_lak: initial_state.selektiven_lak,
                    has_flaps: initial_state.flaps > 0,
                    leftFlap: parseInt(initial_state.leftFlap),
                    rightFlap: parseInt(initial_state.rightFlap),
                    is_enabled: initial_state.is_enabled,
                    obemen_lak: initial_state.obemen_lak,
                });
            });
        }
    }

    componentDidMount() {
        let propsPaper = this.props.papers.slice();
        let availablePapers = [];
        let groups = [];

        for (let i = 0; i < propsPaper.length; i++)
        {
            let hasPaper = false;
            let kind = '';
            for (let j = 0; j < propsPaper[i]['groups'].length;j++)
            {
                if(propsPaper[i]['groups'][j].component === 'cover')
                {
                    hasPaper = true;
                    //kind = Object.assign("", propsPaper[i]['groups'][j].group);
                    kind = propsPaper[i]['groups'][j].group === 'normal' ? 'normal' : 'special';
                    //debugger;
                    break;
                }
            }

            if(hasPaper)
            {
                let copy = Object.assign({},propsPaper[i]);
                copy.group = kind;
                availablePapers.push(copy);
                if(!groups.includes(kind))
                {
                    groups.push(kind);
                }
            }
        }

        let selectedGroup = 'normal';
        if(groups.length === 1)
        {
            selectedGroup = groups[0];
        }

        groups.sort();

        let selectedPrint = this.props.element.print[0].id;
        let selectedFinishing = -1;

        if(this.props.element.finishing.length > 0)
        {
            selectedFinishing = this.props.element.finishing[0].id;
        }

        this.setState({papers: availablePapers, groups, selectedPrint, selectedFinishing,selectedGroup }, () => {
            let index = 0;
            if(groups.length === 2)
            {
                //TODO: Трябва да се добави логика за избрана хартия
                index = availablePapers.findIndex(p=>p.group === 'normal');
            }

            this.selectPaper(availablePapers[index].paper_name_id);
        })
    }

    getCover(trigger = true) {
        return {
            paper: this.state.selectedPaperNameId,
            paper_color_id: this.state.selectedColorId,
            density: this.state.selectedDensity,
            is_enabled: this.state.is_enabled,
            print: this.state.selectedPrint,
            finishing: this.state.selectedFinishing,
            embossing: this.state.embossing,
            embossing_foil: this.state.embossing_foil,
            properties: this.state.properties,
            num_pages: this.state.num_pages,
            nadpechaten_lak: this.state.nadpechaten_lak,
            pantone: this.state.pantone,
            flaps: parseInt(this.state.leftFlap) + parseInt(this.state.rightFlap),
            leftFlap: this.state.leftFlap,
            rightFlap: this.state.rightFlap,
            cover_pantones_info: this.state.cover_pantones_info,
            obemen_lak: this.state.obemen_lak,
            selektiven_lak: this.state.selektiven_lak,
            punching: this.state.punching,
            trigger: trigger
        };
    }

    selectPaper(paperNameId, callback = null) {
        let pap = this.state.papers.find(x => x.paper_name_id  === paperNameId);
        let colors = pap.colors;
        let densities = pap.colors[0].densities.filter(x=> x.components.includes('cover'));
        this.setState({
            densities,
            colors,
            selectedPaperNameId: paperNameId,
            selectedDensity: densities[0].name,
            selectedColorId: colors[0].id,
            selectedGroup: pap.group
        },()=>{
            this.props.applyChanges(this.getCover());
            if(callback !== null)
            {
                callback();
            }
        })
    }

    selectColor(colorNameId) {
        let color = this.state.colors.find(c => c.id === colorNameId);
        let densities = color.densities.filter(x=> x.components.includes('cover'));
        this.setState({densities: densities, selectedDensity: densities[0].name, selectedColorId: colorNameId}, () => {
            this.props.applyChanges(this.getCover());
        })
    }

    printPaper() {
        return (<>
            <div className="col-4">
                <label>{this.props.t('paper')}</label>
                <div className={'' + (this.state.groups.length === 1 ? ' d-none' : '')}>
                    {this.state.groups.map( g => <>
                        <input
                            type={'radio'}
                            checked={this.state.selectedGroup === g}
                            onClick={ e => {
                                let pap = this.state.papers.filter(p => p.group === g);
                                this.selectPaper(pap[0].paper_name_id);
                                this.setState({selectedGroup: g});
                            }}
                        /> {this.props.t(g)} &nbsp;
                    </>)}
                </div>
                <Select
                    value={this.state.papers.find(x => x.paper_name_id  === this.state.selectedPaperNameId)}
                    onChange={(selected)=>{
                        this.selectPaper(selected.paper_name_id);
                    }}
                    options={this.state.papers.filter(p => p.group === this.state.selectedGroup)}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.paper_name_id}
                />
            </div>
            <div className="col-4">
                {this.state.groups.length !== 1 && <br/>}
                <label>{this.props.t('colors')}</label>
                <Select
                    value={this.state.colors.find(d => d.id === this.state.selectedColorId)}
                    onChange={(selected) => {
                        this.selectColor(selected.id)
                    }}
                    options={this.state.colors}
                    getOptionLabel={(option)=> option.paper_color_name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-4">
                {this.state.groups.length !== 1 && <br/>}
                <label>{this.props.t('density')}</label>
                <Select
                    value={this.state.densities.find( d => d.name === this.state.selectedDensity)}
                    onChange={(selected) => {
                        this.setState({selectedDensity: selected.name}, () => {
                            this.props.applyChanges(this.getCover());
                        })
                    }}
                    options={this.state.densities}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            {this.printClientPaper()}
        </>)
    }

    printClientPaper(){
        let properties = this.props.element.properties;
        properties = properties.filter(x => x.id === 65);

        if(properties.length === 0)
        {
            return <></>
        }

        return properties.map(p => <div className={'col-12 mt-3'}>
            <input
                checked={this.state.properties.includes(p.id)}
                onChange={e => {
                    let properties = this.state.properties;

                    if(e.target.checked === true)
                    {
                        properties.push(p.id);
                    }

                    if(e.target.checked === false)
                    {
                        let index = properties.findIndex(i => i.id === p.id);
                        properties.splice(index, 1);
                    }

                    this.setState({properties}, () => {
                        this.props.applyChanges(this.getCover());
                    })
                }}
                type="checkbox"/> {p.name}
        </div>)
    }

    printProperties() {
        let properties = this.props.element.properties;
        //debugger;
        //премахаме пантона - id - 64
        //премахаме надпечатния лак от пропъртитата също id - 62, защото го има по - надолу с др логика
        //let hasNadpechatenLak = properties.fin
        //27,28,29 - крила(flaps), сухо преге(embossing) и топъл печат(embossing_foil)
        //Клиентска хартия 65
        //Селективен лак 68
        //Обемен лак 69

        properties = properties.filter(x=>x.id !== 64 && x.id !== 62 && x.id !== 27 && x.id !== 28 && x.id !== 29 && x.id !== 65 && x.id !== 68 && x.id !== 69);
        let props =  properties.map(p => <div className={'mt-3'}>
            <input
                checked={this.state.properties.includes(p.id)}
                onChange={e => {
                    let properties = this.state.properties;

                    if(e.target.checked === true)
                    {
                        properties.push(p.id);
                    }

                    if(e.target.checked === false)
                    {
                        let index = properties.findIndex(i => i.id === p.id);
                        properties.splice(index, 1);
                    }

                    this.setState({properties}, () => {
                        this.props.applyChanges(this.getCover());
                    })
                }}
                type="checkbox"/> {p.name}
        </div>)

        if(this.props.element.properties.findIndex( x => x.id === 68) >= 0)
        {
            props.push(<div className="mt-3">
                <input
                    type="checkbox"
                    checked={this.state.selektiven_lak}
                    onChange={(e)=>{
                        this.setState({selektiven_lak: e.target.checked}, () =>{
                            this.props.applyChanges(this.getCover());
                        })
                    }}
                /> {this.props.t('selektiven_lak')}
            </div>)
        }

        if(this.props.element.properties.findIndex( x => x.id === 69) >= 0)
        {
            props.push(
                <div className="mt-3">
                    <input
                        type="checkbox"
                        checked={this.state.obemen_lak}
                        onChange={(e)=>{
                            this.setState({obemen_lak: e.target.checked},()=>{
                                this.props.applyChanges(this.getCover());
                            })
                        }}
                    /> {this.props.t('obemen_lak')} (TODO: не смята още)
                </div>
            )
        }

        return props;
    }

    flaps() {
        if(!(this.props.element.properties.findIndex(p=>p.id === 27) >= 0) )
        {
            return (<></>)
        }

        return (<>
                <div className="col-12">
                    <label>
                        <input
                            onChange={e => {
                                this.setState({has_flaps: e.target.checked, flaps: 0, leftFlap: 0, rightFlap: 0},()=>{
                                    this.props.applyChanges(this.getCover(false));
                                })
                            }}
                            checked={this.state.has_flaps}
                            type="checkbox"/> &nbsp;
                        {this.props.t('with_flaps')}
                    </label>
                </div>
                <div className={"col-12 " + (this.state.has_flaps ? '' : 'd-none')}>
                    <div className="row">
                        <div className="col-4 mt-3">
                            <label>{this.props.t('flaps')}</label>
                            <input
                                className={"form-control"}
                                type="number"
                                placeholder={""}
                                value={this.state.flaps}
                                onChange={e => {
                                    if(e.target.value >= 0)
                                    {
                                        this.setState({leftFlap: e.target.value/2,rightFlap: e.target.value/2, flaps: e.target.value}, () => {
                                            this.props.applyChanges(this.getCover());
                                        });
                                    }
                                }}
                            />

                        </div>
                        <div className="col-4 mt-3">
                            <label>{this.props.t('left')}</label>
                            <input
                                className={"form-control"}
                                type="number" value={this.state.leftFlap}
                                onChange={(e)=>{
                                    if(e.target.value >= 0)
                                    {
                                        let totalFlaps = parseInt(e.target.value) + parseInt(this.state.rightFlap);
                                        this.setState({flaps: totalFlaps, leftFlap: e.target.value},()=>{
                                            this.props.applyChanges(this.getCover());
                                        })
                                    }
                                }}
                            />
                            {  this.props.length > 0 && this.state.flaps > 0 && (this.state.leftFlap < 50 || this.state.leftFlap > this.props.flapLimit()) &&
                            <div className={"col-12 mt-3 text-red"}>Ограничението на крилото е от 50 до {this.props.flapLimit()}</div> }

                        </div>
                        <div className="col-4 mt-3">
                            <label>{this.props.t('right')}</label>
                            <input
                                className={"form-control"} type="number"
                                value={this.state.rightFlap} onChange={(e)=>{
                                if(e.target.value > 0)
                                {
                                    let totalFlaps = parseInt(e.target.value) + parseInt(this.state.leftFlap);
                                    this.setState({flaps: totalFlaps, rightFlap: e.target.value},()=>{
                                        this.props.applyChanges(this.getCover());
                                    })
                                }
                            }}
                            />
                            { this.props.length > 0 && this.state.flaps > 0 && (this.state.rightFlap < 50 || this.state.rightFlap > this.props.flapLimit())  &&
                            <div className={"col-12 mt-3 text-red"}>Ограничението на крилото е от 50 до {this.props.flapLimit()}</div> }
                        </div>
                    </div>
                </div>
        </>)
    }

    handleEmbossingChange(embossing) {
        this.setState({embossing}, () => {
            this.props.applyChanges(this.getCover());
        })
    }

    handleEmbossingFoilChange(embossing_foil)
    {
        this.setState({embossing_foil}, () => {
            this.props.applyChanges(this.getCover());
        })
    }

    embossings() {
        return (<>
            <div className={'col-5 mr-3'}>
                <EmbossingFormPartial
                    applyChanges={embossing => this.handleEmbossingChange(embossing)}
                    t={this.props.t}
                    measure={this.props.measure}
                    initial_state={this.state.embossing}
                />
            </div>
            <div className={'col-6'}>
                <EmbossingFoilFormPartial
                    t={this.props.t}
                    measure={this.props.measure}
                    applyChanges={embossing_foil => this.handleEmbossingFoilChange(embossing_foil)}
                    initial_state={this.state.embossing_foil}
                />
            </div>
        </>)
    }

    renderPantoneInner()
    {
        if (this.state.pantone === 0) {
            return (<></>)
        }

        let ht = [];

        for (let i = 0; i < this.state.pantone; i++) {
            ht.push(<>
                <div className={"col-1 mt-3"}>
                    {this.props.t('type')}
                </div>
                <div className={'col-4 mt-3'}>
                    <Select
                        onChange={(selected) => {
                            let {cover_pantones_info} = this.state;
                            cover_pantones_info[i].type = selected.id;
                            this.setState({cover_pantones_info})
                        }}
                        value={this.props.pantones.find(p => p.id === this.state.cover_pantones_info[i].type)}
                        options={this.props.pantones}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className={'col-4 mt-3'}>
                    <input
                        onChange={e => {
                            let {cover_pantones_info} = this.state;
                            cover_pantones_info[i].number = e.target.value;
                            this.setState({cover_pantones_info}, () => {this.props.applyChanges(this.getCover(false));});
                        }}
                        value={this.state.cover_pantones_info[i].number}
                        placeholder={this.props.t('pantone_number_show') + ' ' + (i + 1)}
                        type={'text'}
                        className={'form-control'}/>
                </div>
                <div className="col-3 mt-3">
                    <input
                        checked={this.state.cover_pantones_info[i].is_two_side === true}
                        onChange={e=>{
                            let isChecked = e.target.checked;
                            let {cover_pantones_info} = this.state;
                            cover_pantones_info[i].is_two_side = isChecked;
                            this.setState({cover_pantones_info}, () => {this.props.applyChanges(this.getCover());});
                        }}
                        type="checkbox"/> {this.props.t('two_sided')}
                </div>
            </>)
        }

        return ht;
    }

    renderPantones()
    {
        if(!(this.props.element.properties.findIndex(p=>p.id === 64) >= 0) )
        {
            return (<></>)
        }

        return (<>
            <div className="col-12 mt-3">
                <label>{this.props.t('pantone')}</label>
                <Select
                    value={this.state.pantones.find(c => c.id === this.state.pantone)}
                    onChange={(selected)=>{
                        let newPantonSize = parseInt(selected.id);
                        let cover_pantones_info = this.state.cover_pantones_info;
                        //this.state.pantone - брой избрани пантони за корица, ще служи за ориентир в масива
                        if(cover_pantones_info.length < newPantonSize)
                        {
                            while(cover_pantones_info.length < newPantonSize)
                            {
                                cover_pantones_info.push({type: 1, number: '', is_two_side: false});
                            }
                        }

                        if(cover_pantones_info.length > newPantonSize)
                        {
                            while(cover_pantones_info.length > newPantonSize)
                            {
                                cover_pantones_info.splice(cover_pantones_info.length - 1, 1);
                            }
                        }

                        this.setState({pantone: selected.id, cover_pantones_info}, ()=>{
                            this.props.applyChanges(this.getCover());});
                    }}
                    options={this.state.pantones}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            {this.renderPantoneInner()}
        </>);
    }

    getMinimizeIcon() {
        if(this.state.isOpen)
        {
            return (<i className="fa fa-window-minimize" aria-hidden="true" />)
        }

        return (<i className="fa fa-plus" aria-hidden="true" />)
    }

    render() {
        const {t} = this.props;
        return (
            <div className={'card mt-3'}>
                <div className="card-body">
                <h3>
                    <div>
                        <i className={'fa fa-book'} /> {t('cover')}
                        <button onClick={()=>{
                            this.setState({isOpen: !this.state.isOpen});
                        }} className={'btn btn-sm btn-info float-right'}>
                            {this.getMinimizeIcon()}
                        </button>
                    </div>
                </h3>
                    <div className={'collapse ' + (this.state.isOpen ? 'show' : '') }>
                        <div>
                            <input type="checkbox" checked={this.state.is_enabled}
                                   onClick={()=>{
                                       this.setState({is_enabled: !this.state.is_enabled},()=>{
                                           this.props.applyChanges(this.getCover());
                                       })
                                   }}/> &nbsp;
                            {this.props.t('cover')}
                        </div>

                        {this.state.is_enabled && <>
                            <div className={'row'}>
                                <div className="col-4">
                                    <label>{this.props.t('pages')}</label>
                                    <input
                                        readOnly={true}
                                        value={this.state.num_pages}
                                        type="number"
                                        className={'form-control'}
                                        onChange={e => {
                                            this.setState({num_pages: e.target.value}, () => {
                                                this.props.applyChanges(this.getCover());
                                            })
                                        }}
                                    />
                                    {this.state.num_pages % 4 !== 0 &&  <div style={{color: 'red'}}>
                                        Броя страници трябва да бъде кратен на 4!
                                    </div>}
                                </div>
                            </div>
                            <div className={'row mt-3'}>
                                <div className="col-4">
                                    <label>{t('print')}</label>
                                    <Select
                                        value={this.props.element.print.find(c => c.id === this.state.selectedPrint)}
                                        onChange={(selected)=>{
                                            this.setState({selectedPrint: selected.id},()=>{
                                                this.props.applyChanges(this.getCover());
                                            })
                                        }}
                                        options={this.props.element.print}
                                        getOptionLabel={(option)=> option.name}
                                        getOptionValue={(option) => option.id}
                                    />
                                </div>
                                {this.props.element.finishing.length > 0 &&
                                <div className="col-4">
                                    <label>{t('finishing')}</label>
                                    <Select
                                        value={this.props.element.finishing.find(c => c.id === this.state.selectedFinishing)}
                                        onChange={(selected) => {
                                            this.setState({selectedFinishing: selected.id}, () => {
                                                this.props.applyChanges(this.getCover());
                                            });
                                            /*66,48,4,5 тези ид-та за + селективен лак, който трябва да е с чекбокс*/
                                        }}
                                        options={this.props.element.finishing.filter(x=> ![66,48,4,5].includes(x.id))}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                    />
                                </div>
                                }
                                <div className="col-4">
                                    {this.printProperties()}
                                </div>
                            </div>
                            <div className="row mt-3">
                                {this.renderPantones()}
                            </div>

                            <div className="row mt-3">
                                {this.printPaper()}
                            </div>

                            <div className="row mt-3">
                                {this.embossings()}
                            </div>
                            <div className="row mt-3">
                                {this.flaps()}
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CoverForm);
