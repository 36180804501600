const fr = {
    "regular_mitnica": "Такса митница (лв)",
    "regular_kg": "Тегло на палет (400)[X](кг)",
    "regular_first_kg_price": "Цена за първия палет до (400)[X]кг. (лв)",
    "regular_after_kg": "Цена за всеки следващ палет (400)[X]кг. (лв)",
    "regular_fix": "Фиксиран разход - сухопътен транспорт (лв)",
    "regular_price_kg_walk": "Цена за килограм - сухопътен транспорт (лв)",
    "regular_minimal_value_land": "Минимална цена (кг * цена за кг) - сухопътен транспорт (лв)",
    "express_first_kg_price": "Цена до (100)[X]кг тегло (лв)",
    "express_after_kg": "Цена за следващите (100)[Y] килограми (лв)",
    "express_kg": "Тегло X (кг)",
    "express_kg_each": "Тегло Y (кг)",
    
    "c_pro": "",
    "owner": "Proprietaire",
    "show_mine" : "Mes commandes",

    "maculatures-cover-print" : "Макулатури корица",
    "uv_lak": "УВ Лак",
    "selektiven_lak": "Селективен лак",
    "maculatures-knigovezane": "Макулатури книговезки",
    "lepena_bez_kapaci" : "Лепена без капаци",
    "lepena_s_kapaci" : "Лепена с капаци",
    "lepena_pur_s_kapaci" : "PUR с капаци",
    "lepena_pur_bez_kapaci" : "PUR без капаци",
    "shita_lepena_s_kapaci" : "Шита-лепена с капаци",
    "shita_lepena_bez_kapaci" : "Шита-лепена без капаци",
    "vshm_bez_kapaci" : "ВШМ без капаци",
    "vshm_s_kapaci" : "ВШМ с капаци",
    "tvurda_podvurziq_standart" : "Твърда стандартна",
    "tvurda_podvurziq_s_forzaci_ot_tqloto" : "Твърда с форзаци от тялото",
    "maculatures-print-body" : "Макулатури печат тяло",
    "tirage_from" : "Тираж от",
    "tirage_to" : "Тираж до",
    "print_one" : "1+0",
    "print_two" : "2+0",
    "print_three" : "3+0",
    "print_four" : "4+0",
    "pantone" : "Пантон",
    "nadpechaten_lak" : "Надпеч. лак 1+0",
    "laminate"  : "ламинат",
    "punching" : "Щанцоване",

    "paper_format_height" : "Формат х-я Височина",
    "paper_format_length" : "Формат х-я Дължина",
    "paper_max_format_height": "Мax Формат за печат Височина",
    "paper_max_format_length": "Мax Формат за печат Дължина",
    "paper_max_format_cut_height" : "Max обрязан формат Височина",
    "paper_max_format_cut_length" : "Max обрязан формат Дължина",
    "count_pages_in_print_list" : "Брой стр в печатен лист",
    "iso3_explained" : "Country Code 3 Symbols",
    "density_text_lb" : "Densité text lbs",
    "density_cover_lb" : "Densité coverture lbs",
    "default_locale": "Default locale",
    "has_cover" : "Couverture",
    "has_text" : "Texte",
    "has_jacket" : "Jaquette",
    "has_banderole" : "Bandeau",
    "has_cloth" : "Contrecollé sur carton",
    "has_endpapers" : "Pages de gardes",
    'pricePerTon': 'Prix par Tonne',
    "countries_delivery": "Pays livraison",
    "all_countries":"Tous les pays",
    "complement":"Complément",
    "cur_val":"Valeur Actuelle",
    "permissions":"Аutorisations",
    "list":"Liste",
    "id":"ID",
    "name":"Nom",
    "display_name":"Nom",
    "description":"Description",
    "create_new":"Créer",
    "view":"Vue",
    "delete":"Effacer",
    "submit":"Continuer",
    "edit":"Modifier",
    "permission":"Autorisation",
    "updated":"Actualisé",
    "added":"Ajouté",
    "deleted":"Supprimé",
    "all_permissions":"Toutes Autorisations",
    "users":"Utilisateurs",
    "all_users":"Tous les Utilisateurs",
    "roles":"Rôles",
    "role":"Rôle",
    "add":"Ajouter",
    "all_roles":"Tous les Rôles",
    "assign_permissions":"Attribuer des Autorisations",
    "assign_roles":"Attribuer des Rôles",
    "create_user":"Créer un Utilisateur",
    "assign_websites":"Attribuer Sites Internet",
    "websites":"Sites Internet",
    "domain":"Domaine",
    "currency":"Devise",
    "email":"Email",
    "phone":"Téléphone",
    "team":"Équipe",
    "address_info":"Adresse Infos",
    "orders_not_final":"Commandes Liste non Définitive",
    "default_category":"Catégorie par Défaut",
    "is_special":"Est-ce Spécial",
    "special_category":"Catégorie Spéciale",
    "website":"Site Internet",
    "has_departments":"A Départements",
    "weight":"Poids",
    "price":"Prix",
    "type":"Type",
    "step":"Étape",
    "update":"Mettre à Jour",
    "update_to_current_rate":"Mise à Jour de Taux Actuel",
    "value":"Valeur",
    "current_value":"Valeur Actuelle",
    "current_rate":"Taux Actuel",
    "min_price":"Prix ​​Min",
    "max_price":"Prix ​​Max",
    "fixed_price":"Prix ​​Fixe",
    "per":"pour",
    "for":"pour",
    "from":"de",
    "to":"à",
    "surcharge":"surtaxe",
    "economy":"Économie",
    "express":"Express",
    "courier":"Courrier",
    "delivery":"Livraison",
    "color":"Couleur",
    "operations":"Opérations",
    "orders_list":"Liste Commandes",
    "status":"Statut",
    "state":"Etat",
    "select_state":"Sélectionnez l'État",
    "link":"Lien",
    "linked_orders":"Ordres Liés",
    "finish":"Terminer",
    "back":"Arrière",
    "contacts":"Contacts",
    "cancel":"Annuler",
    "error":"Erreur",
    "preview":"Aperçu",
    "calculate":"Calculer",
    "custom_size":"Format Personnalisé",
    "standard_size":"Format Standard",
    "final_size":"Format fini",
    "length":"Longueur",
    "height":"Hauteur",
    "company_name":"Dénomination sociale",
    "first_name":"Prénom",
    "last_name":"Nom",
    "products":"Produits",
    "properties":"Propriétés",
    "deliveries":"Livraisons",
    "papers":"Papiers",
    "sizes":"Formates",
    "densities":"Densités",
    "suppliers":"Fournisseurs",
    "colors":"Couleurs",
    "names":"Noms",
    "types":"Types",
    "maculatures":"Maculatures",
    "transport":"Transport",
    "districts":"Département",
    "overcharges":"Mark up",
    "prices":"Prix",
    "settings":"Paramètres",
    "taxes":"Taxes",
    "tax_pulsio":"Тax Pulsio",
    "tax_security":"Taxe Сécurité",
    "tax_fuel":"Fuel Tax",
    "calculator_helper":"Calculatrice Aide",
    "currencies":"Taux de change",
    "orders":"Devis ",
    "current_states":"États Actuel",
    "states":"États",
    "clients":"Clients",
    "categories":"Catégories",
    "contact_types":"Types de Contact",
    "navigation":"Navigation",
    "create":"Créer",
    "total_papers":"Papiers",
    "total_clients":"Nombre de Clients",
    "total_products":"Nombre de Produits",
    "total_orders":"Nombre de Commandes",
    "enabled":"Activée",
    "enable":"Activer",
    "question_delete":"Etes-vous sûr de vouloir supprimer ?",
    "sign_in":"S'identifier",
    "remember_me":"Se souvenir de moi",
    "login_msg":"Démarre une nouvelle session",
    "password":"Mot de passe",
    "key":"Clé",
    "info":"Info",
    "category":"Catégorie",
    "mobile_phone":"Téléphone 2",
    "business":"Secteur",
    "bulstat":"Numéro SIRET/SIREN",
    "address":"Adresse",
    "invoice":"Facture",
    "invoice_address":"Adresse de Facturation",
    "delivery_address":"Adresse de Livraison",
    "number":"Nombre",
    "street_name":"Rue",
    "city":"Ville",
    "post_code":"Code Postal",
    "add_contact":"Ajouter Contact",
    "title":"Civilité",
    "waste":"Déchets",
    "percent":"Pour Cent",
    "new_order":"Nouvel Commande",
    "select_product_in_orders":"Choisissez un produit pour lequel vous souhaitez effectuer une nouvelle commande",
    "select_type_in_orders":"Sélectionner le Тype de Commande",
    "select_website_in_orders":"Choisissez un site pour lequel vous souhaitez effectuer une nouvelle commande",
    "comment":"Remarques",
    "client":"Client",
    "product":"Produit",
    "company":"Société",
    "finished_size_length":"Longueur",
    "finished_size_height":"Hauteur",
    "copies":"Exemplaires",
    "bindings":"Reliure",
    "order":"Commande",
    "foil":"Sous film",
    "banderole":"Bandeau",
    "jacket":"Jaquette",
    "wrapped":"à l'unité",
    "total_price":"Prix ​​Total",
    "overcharge":"Mark up",
    "discount":"Remise",
    "final_price":"Prix Final",
    "budget":"Budget",
    "base_overcharges":"Mark up de Base",
    "additional_overcharges":"Mark up Supplémentaires",
    "property":"Propriété",
    "percentage":"Pourcentage",
    "remove":"Retirer",
    "paper":"Papier",
    "text":"Texte",
    "text2":"Texte",
    "density":"Densité",
    "price_per_sheet":"Prix par Feuille",
    "volume_ratio":"Volume papier",
    "coef1_sewed":"Coef 1 (cousu)",
    "coef2_sewed":"Coef 2 (cousu)",
    "coef3_sewed":"Coef 3 (cousu)",
    "coef1_stitched":"Coef 1 (collé)",
    "coef2_stitched":"Coef 2 (collé)",
    "coef3_stitched":"Coef 3 (collé)",
    "volume_ratio_alt":"Volume papier (alt)",
    "cover":"Couverture",
    "size":"Taille",
    "price_per_ton_with_overcharge":"Prix par tonne avec Mark up, TVA",
    "price_per_sheet_with_overcharge":"Prix par feuille avec Mark up, TVA",
    "vat":"TVA",
    "book":"Livre",
    "paper_group":"groupe papier",
    "special":"Spécial",
    "normal":"Ordinaire",
    "no_group":"Sans groupe",
    "profile":"Profil",
    "sign_out":"Se Déconnecter",
    "folding":"Pliage",
    "binding":"Reliure",
    "print":"Impression",
    "finishing":"Finition",
    "insert":"Cahier hors texte",
    "days":"Journées",
    "new_password":"Nouveau mot de passe",
    "repeat_password":"Répéter le mot de passe",
    "group":"Groupe",
    "factor":"Facteur",
    "action_done":"Action Done",
    "success":"Succès",
    "price_per_ton":"Prix par Tonne",
    "all":"Tous",
    "wrong_id":"Mauvais ID",
    "formats":"Formates",
    "format":"Format",
    "thickness":"Carton/Epaisseur",
    "france":"France",
    "united_kingdom":"Royaume-Uni",
    "bulgaria":"Bulgarie",
    "belgium":"Belgique",
    "switzerland":"Suisse",
    "belgium_trans_info":"Le transport pour la Belgique est idem comme pour le 59 departement FR.",
    "mr_ms":"M./Mme",
    "mr":"M.",
    "mrs":"Mme",
    "country":"Pays",
    "countries":"Pays",
    "default_currency":"Devise par défaut",
    "groups":"Groupes",
    "code":"Code",
    "close":"Fermer",
    "delivery_days":"Jours de livraison",
    "delivery_point":"Point de livraison",
    "shipments":"Livraisons",
    "statuses":"Statuses",
    "priorities":"Priorités",
    "tir_groups":"Tir Groupes",
    "tir_group":"Tir Groupe",
    "printings":"Imprimantes",
    "priority":"Priorité",
    "remarks":"Remarques",
    "remarks_trader":"Remarques Trader",
    "remarks_logistic":"Remarques Logistic",
    "zip_code":"Code Postal",
    "delivery_from_print":"Arrivée de l'imprimerie",
    "delivery_loading":"Date d'expédition",
    "delivery_day":"Date de livraison",
    "copies_total":"Copies",
    "copies_sending":"Envoi de copies",
    "copies_left":"Copies Gauche",
    "kg":"kg",
    "schedule":"Programme",
    "schedules":"Programmes",
    "calendar":"Calendrier",
    "search":"Chercher",
    "year":"An",
    "week":"Semaine",
    "already_exists":"existe déjà",
    "has_items":"a articles",
    "created_by":"Créé par",
    "assign_delivery":"Attribuer Livraison",
    "must_be_less_than":"doit être inférieure à",
    "must_be_greater_than":"doit être supérieure à",
    "trader":"Commerçant",
    "package_number":"Nombre paquet",
    "not_set":"Pas encore mis",
    "active":"Actif",
    "no_orders_assigned_yet":"Pas de commande encore affectés",
    "other":"Autre",
    "action":"Action",
    "actions":"Actes",
    "need_update":"Vous devez mettre à jour!",
    "total":"Total",
    "remaining":"Restant",
    "to_clients_address":"aux clients adresse sur le territoire de la Bulgarie",
    "our_warehouse":"FRANCO notre entrepôt",
    "point":"point",
    "points":"points",
    "clients_delivery_addresses":"Adresses de livraison",
    "street":"Rue",
    "address_remarks":"Remarques Adressse",
    "enter_address_manually":"Entrez l'adresse manuellement",
    "locked":"Fermé",
    "unlocked":"Ouvert",
    "inactive":"Inactif",
    "shipped":"Shipped",
    "not_shipped":"Pas livré",
    "assigned":"Attribué",
    "selected":"Choisi",
    "pallet":"Palette",
    "paper_factor":"Facteur papier",
    "additional_costs":"Coûts additionnels",
    "reset":"Réinitialiser",
    "payer":"Payeur",
    "assign_for_shipment":"Pour l'expédition",
    "yes":"Oui",
    "no":"Non",
    "boxes":"Cartons",
    "mark_as_shipped":"Marquer comme livré",
    "mark_all_as_shipped":"Marquer tous les produits comme livrés",
    "mark_as_not_shipped":"Marquer comme non livré",
    "mark_all_as_not_shipped":"Marquer tous comme non livrés",
    "send_to_transporter":"Envoyer à Transporter",
    "transporter_has_locked_the_delivery":"Le transporteur a verrouillé la livraison",
    "mark_as_assigned_to_tir_group":"Marquer comme affecté au groupe Tir",
    "mark_as_is_shipping":"Marquer comme expédié",
    "mark_as_not_shipping":"Marquer comme ne pas envoyer",
    "mark_all_as_assigned_to_tir_group":"Marquer tout comme assigné au groupe Tir",
    "mark_all_as_not_assigned_to_tir_group":"Marquer tous comme NON assignés à aucun groupe Tir",
    "dates":"Délai",
    "sending":"Envoi",
    "transporter":"Transporteur",
    "see_on_map":"Voir sur la carte",
    "delivery_express_day":"Date de livraison express",
    "date":"Date",
    "additional_options":"Options additionelles",
    "barcode":"Code à barre",
    "isbn":"ISBN",
    "generate":"Générer",
    "tickets":"Billets",
    "ticket":"Billet",
    "all_tickets":"Tous les billets",
    "ex":"ex.",
    "documents":"Documents",
    "bdl":"BDL",
    "cmr":"CMR",
    "all_bd_ls":"Tous les BDL",
    "all_cm_rs":"Tous les CMR",
    "only_marked":"Seulement marqué",
    "cloth":"Contrecollé sur carton",
    "cardboard":"Carton",
    "endpapers":"Pages de gardes",
    "editor":"Editeur",
    "consignment_number":"Num Tpt",
    "number_of_copies_per_carton":"Nbre ex./carton",
    "number_of_copies":"Nbre ex./adresse",
    "title_label":"TITRE",
    "label_ex":"Nbr",
    "nothing_selected":"Rien sélectionné!",
    "copies_in_a_box":"Copies dans une boîte",
    "count_boxes":"Nombre de boîtes",
    "warehouse":"Entrepôt",
    "damaged_papers":"Papiers problématiques",
    "component":"Composant",
    "component_is_required":"Composant requis",
    "group_is_required_for_book":"Groupe est requis pour le livre",
    "thickness_additional":"Mousse/Epaisseur",
    "difference":"Difference",
    "mark":"Marque",
    "un_mark":"Annuler",
    "no_boxes_assigned":"Il n'y a pas de boîtes assignées",
    "shipments_order":"Commande d'expédition",
    "all_shipment_order":"Tous les cdes d'expédition",
    "select_from_addresses":"Sélectionner des adresses",
    "enter_other_address":"Entrez une autre adresse",
    "must_not_be_deleted":"L'élément ne doit pas être supprimé!",
    "mm":"mm",
    "is_shipped":"Shipped",
    "system":"Système",
    "width":"Largeur",
    "":"L",
    "":"H",
    "":"W",
    "created_at":"Créé le",
    "optional":"optionnel",
    "production_number":"Numéro de production",
    "distributions":"Distributions",
    "distribution":"Distribution",
    "contact_person":"Contact",
    "client_has_orders":"Le client a des commandes!",
    "suspend":"Suspendre",
    "suspended_by":"Suspendu par",
    "suspended_at":"Suspendu le",
    "suspended":"Suspendu",
    "deleted_by":"Supprimé par",
    "deleted_at":"Supprimé le",
    "organization":"Société",
    "levs":"levs",
    "export_tn_ts":"Exportation TNT",
    "bdl_date_label":"Document édité le",
    "bdl_proof_of_delivery":"Bon de livraison",
    "bdl_title":"Titre",
    "bdl_number_of_copies_per_carton":"Quantité/colis",
    "bdl_number_of_cartons":"Nombre de colis",
    "bdl_total_quantity":"Quantité totale",
    "bdl_number_of_pallets":"Nombre de palettes",
    "bdl_total":"Total",
    "bdl_consignment_number":"Référence de transport",
    "bdl_delivered_to":"A livrer chez",
    "bdl_on_behalf_of":"Pour le compte de",
    "bdl_date":"Date:",
    "bdl_signature":"Signature et tampon:",
    "assign_other_contact_in_documents":"Attribuer un autre contact dans Documents",
    "production":"Production",
    "productivity":"Productivité",
    "circulation":"Circulation",
    "term":"Terme",
    "machine":"Machine",
    "book_cover":"Couverture de livre",
    "amount":"Montant",
    "schedule_status":"Statut",
    "schedule_client":"Client",
    "schedule_title":"Titre",
    "schedule_circulation":"Tirage",
    "schedule_format":"Format",
    "schedule_remarks":"Remarques",
    "schedule_color":"Couleur",
    "schedule_term":"Terme",
    "schedule_machine":"Machine",
    "schedule_book_cover":"Couverture de livre",
    "schedule_amount":"Montant",
    "schedule_body":"Corps",
    "schedule_cover":"Couverture",
    "schedule_cover_extras":"Couverture extras",
    "schedule_cloth":"Tissu",
    "schedule_endpapers":"Endpapers",
    "filter":"Filtre",
    "preparations":"Les préparatifs",
    "schedule_preparations":"Les préparatifs",
    "schedule_technologist":"Technologue",
    "due_date":"Date d'échéance",
    "schedule_fitter":"Installateur",
    "schedule_prepress":"Prépresse",
    "copy":"Copie",
    "all_technologists":"Tous les Technologues",
    "all_fitters":"Tous les Fitters",
    "all_prepressers":"Tous les Prépresseurs",
    "schedule_production_number":"Numéro de production",
    "show_only_mine":"Afficher seulement le mien",
    "schedule_employees":"Des employés",
    "schedule_type":"Type",
    "schedule_state":"Etat",
    "schedule_bodies":"Corps",
    "schedule_sheets":"Sheets",
    "create_and_ship":"Créer et expédier",
    "update_and_ship":"Mise à jour et expédition",
    "filter_by_trader":"Filtrer par Commerçant",
    "no_trader_selected":"Aucun commerçant sélectionné",
    "filter_by_printing":"Filtrer par Impression",
    "filter_by_tir_group":"Filtrer par groupe Tir",
    "filter_by_delivery_from_printing_day":"Par arrivée de l'imprimerie",
    "filter_by_delivery_arrival_day":"Par date de livraison",
    "no_printing_selected":"Aucune impression sélectionnée",
    "no_tir_group_selected":"Aucun groupe Tir sélectionné",
    "no_delivery_from_printing_day_selected":"Non Livraison à partir du jour d'impression sélectionné",
    "no_delivery_arrival_day_selected":"N ° de réf.",
    "no_delivery_from_expedition_selected":"Non Livraison du jour de l'expédition sélectionné",
    "filter_by_delivery_from_expedition_day":"Par date d'expédition",
    "filter_by_id":"Filtrer par identifiant",
    "filter_by_job":"Filtre par N de production",
    "filter_by_country":"Filtrer par pays",
    "no_country_selected":"Aucun pays sélectionné",
    "id_invalid":"L'ID est invalide",
    "job_invalid":"JOB n'est pas valide",
    "filter_by_package_number":"Filtrer par numéro de paquet",
    "filter_by_isbn":"Filtrer par ISBN",
    "filter_by_order_name":"Filtrer par nom de commande",
    "filter_by_trader_name":"Filtrer par nom du commerçant",
    "all_packages":"Tous les points",
    "all_books":"Tous les livres",
    "dropped":"Chuté",
    "books_dropped":"Chuté",
    "books_title":"Titre",
    "books_package_number":"Numéro de colis",
    "books_order_id":"Numéro de commande",
    "books_address":"Adresse",
    "books_delivery_date":"Date de livraison",
    "books_boxes":"Cartons",
    "books_printing":"Impression",
    "books_client":"Client",
    "books_trader":"Commerçant",
    "books_copies_ordered":"Copies commandées",
    "books_copies_expedited":"Copies rapides",
    "books_difference":"Différence",
    "books_weight_ordered":"Poids(kg) commandé",
    "books_weight_per_unit":"Poids(kg) par article",
    "points_all_point":"Livraisons pour le transport",
    "tir_group":"Groupe Tir",
    "client":"Client",
    "trader":"Commerçant",
    "shipment_n":"Expédition №",
    "boxes":"Cartons",
    "zip_code":"Code Postal",
    "address":"Adresse",
    "priority":"Priorité",
    "delivery_date":"Date de livraison",
    "remarks_trader":"Remarques Trader",
    "from_printing_date":"Date de l'impression",
    "loading_date":"Date de chargement",
    "weight_ordered":"Commandé kg",
    "total_copies_ordered":"Total des exemplaires commandés",
    "total_weight_ordered":"Poids total kg",
    "total_copies_expedited":"Total des copies accélérées",
    "price_kg":"Prix ​​kg",
    "phone":"Téléphone",
    "email":"Email",
    "country":"Pays",
    "remarks_logistic":"Remarques Logistics",
    "tickets_address":"Adresse",
    "tickets_editor":"Client",
    "tickets_consignment_number":"Réf tpt",
    "tickets_order_number":"N de commande",
    "tickets_title_label":"Titre",
    "tickets_number_of_copies_per_carton":"Ex./carton",
    "tickets_number_of_copies":"Ex.",
    "tickets_label_ex":"",
    "return_shipment":"Retourner l'envoi",
    "update_remarks":"Mise à jour des remarques",
    "financial_ctrl":"Contrôle Fi",
    "fin_company_name":"Nom de la compagnie",
    "fin_client_name":"Nom du client",
    "fin_client_phone":"Téléphone Client",
    "fin_client_email":"Email Client",
    "fin_trader_name":"Trader Name",
    "fin_order_price":"Nom du commerçant",
    "fin_financial_ctrl_state":"État financier Ctrl",
    "fin_financial_ctrl_remarks":"Observations financières",
    "fin_super_am_state":"État Super AM",
    "fin_super_am_remarks":"Super AM Remarques",
    "fin_updated_by":"Dernière mise à jour par",
    "restore_client":"Voulez-vous vraiment restaurer ce client?",
    "fin_title":"Titre",
    "client_exists":"Un client avec la même adresse e-mail existe déjà.",
    "restore":"Restaurer",
    "all_reports":"All Reports",
    "sorted_by_id":"Sorted by ID",
    "sorted_by_country":"Sorted by Country",
    "sorted_by_post_code":"Sorted by Post Code",
    "export_documents":"Export Documents",
    "sorted_by_tir_group":"Sorted By Tir group",
    "sorted_by_city":"Sorted By City",
    "sorted_by_organization":"Sorted By Organization name",
    "sorted_by_client":"Sorted By Client name",
    "sorted_by_package_number":"Sorted By Package number",
    "reports_all_point":"Shipments for transport",
    "reports_tir_group":"Tir group",
    "reports_delivery_date":"Delivery date",
    "reports_country":"Country",
    "reports_post_code":"Post code",
    "reports_city":"City",
    "reports_address":"Address",
    "reports_number":"Number",
    "reports_organization":"Organization",
    "reports_client":"Client",
    "reports_phone":"Phone",
    "reports_email":"Email",
    "reports_phone_optional":"Phone optional",
    "reports_email_optional":"Email optional",
    "reports_order_name":"Title",
    "reports_package_number":"Package number",
    "reports_prints":"Copies",
    "reports_boxes":"Boxes count",
    "reports_weight":"Weight (kg)",
    "mark_as_arrived":"Mark as Arrived",
    "mark_as_departed":"Mark as Departed",
    "transport_ctrl":"Transport Ctrl",
    "not_expedited_yet":"Not expedited yet",
    "is_shipping":"Is shipping",
    "not_assigned":"Not assigned",
    "sending_copies":"Shipping copies",
    "order_id":"Order ID",
    "date_today":"Date Today",
    "date_marked_from_trader":"Date marked by Trader",
    "date_marked_from_warehouse":"Date marked by Warehouse",
    "filter_by_client":"Filter by Client",
    "auto_rescheduled_for_week":"Rescheduled for week",
    "rescheduled_prints":"Rescheduled Prints",
    "rescheduled_weight":"Rescheduled Weight",
    "quick_shipments_switch":"Quick Shipments switch",
    "quick_prices_switch":"Quick Prices switch",
    "sending_boxes":"Shipping Boxes",
    "deny_reschedule":"Deny Reschedule",
    "proceed_reschedule":"Proceed Reschedule",
    "proceed_reschedule_and_ship":"Proceed Reschedule and Ship",
    "reschedule":"Reschedule",
    "reschedule_history":"Reschedule History",
    "denied":"Denied",
    "rescheduled":"Rescheduled",
    "sent":"Sent",
    "copies_rescheduled":"Copies rescheduled",
    "weight_rescheduled":"Weight rescheduled",
    "unresolved":"Pending",
    "copy_to_clipboard":"Copy to clipboard",
    "no_boxes":"No boxes recorded",
    "agreement_day":"Agreement day",
    "filter_by_delivery_agreement_day":"Filter by Delivery Agreement day",
    "reports_delivery_date_trader":"Delivery date/Trader",
    "reports_delivery_date_agreement":"Delivery date/Agreement",
    "reports_delivery_date_final":"Delivery date/Final",
    "denied_prints":"Denied copies",
    "type_of_print":"Type d'impression",
    "message_boxes":"La commande n'est pas marqué comme expédié",
    "language":"La langue",
    "reception_company":"Société de reception (inclus dans l'étiquette)",
    "display_the_transport_price_in_the_offer":"Afficher le prix du transport dans le devis",
    "global_search":"Recherche globale",
    "client_val_email":"Veuillez renseigner le \"Adresse e-mail\"",
    "client_val_name":"Veuillez renseigner le \"Prénom\"",
    "client_val_last":"Veuillez renseigner le \"Nom\"",
    "client_val_org_name":"Cette société est déjà enregistré",
    "client_val_org_bulstat":"Le numéro SIRET est déjà enregistré",
    "sales_agent":"Agent commercial",
    "disapproved":"Refusé",
    "approved":"Approuvé",
    "organizations":"Organisations",
    "show_only_disapproved":"Afficher uniquement les personnes désapprouvées",
    "history":"Histoire",
    "pending":"Еn attendant",
    "user":"Utilisateur",
    "log":"Bûche",
    "updated_at":"Mis à jour à",
    "system_generated":"Système généré",
    "system_updated":"Mise à jour du système",
    "client_email_registered":"Cet email est déjà enregistré dans notre base client",
    "client_fln_ames_registered":"Le prénom et le nom sont déjà enregistrés dans notre base client",
    "techno_card":"Tech. Card",
    "techno_card_upload":"Télécharger",
    "techno_card_filename":"Fichier",
    "techno_card_date":"Date",
    "techno_card_status":"Statut",
    "activate":"Activer",
    "techno_card_comment":"Commentaire",
    "download":"Télécharger",
    "fin_sam_override":"Super AM surmonte la décision de l'Etat de contrôle financier",
    "max_insurance":"Assurance maximale",
    "new_max_insurance":"Nouvelle assurance maximale",
    "order_is_in_production":"L'ordre est en production et son état ne peut pas être modifié",
    "showing_results_after":"Showing results after",
    "generated_client_financial_excel_file":"Generated client financial control Excel file",
    "generated_excel_file_for_schedule":"Generated financial control Excel file for transport schedule",
    "all_shipments":"All Shipments",
    "all_prices":"All Prices",
    "printing":"Printing",
    "in_production":"In Production",
    "sent_to_fin_ctrl":"Sent to Financial Control",
    "statistics":"Statistics",
    "total_shipments":"Total Shipments",
    "sending_shipments":"Sending Shipments",
    "rescheduled_shipments":"Rescheduled Shipments",
    "locale":"Translation",
    "hunter":"Chasseur",
    "quotes":"Devis",
    "quote":"Devis",
    "offer":"Devis",
    "offers":"Devis",
    "order_now":"Commandez",
    "copies_difference":"Différence de copies",
    "tirage":"Tirage",
    "redistribute":"Redistribute",
    "per_packs":"Sous film par paquet",
    "packaging":"Conditionnement: sous film par paquet, caisses carton sur palette",
    "pdf_issue294":"Sens des fibres non garanti.",
    "print_run_from":"Print Run From",
    "print_run_to":"Print Run To",
    "printRunFrom":"Print Run From",
    "printRunTo":"Print Run To",

    "overcharge_52":"0+0 [%]",
    "discount_52":"Remise: 0+0",
    "overcharge_18":"1+0 [%]",
    "discount_18":"Remise: 1+0",
    "overcharge_15":"1+1 [%]",
    "discount_15":"Remise: 1+1",
    "overcharge_50":"2+0 [%]",
    "discount_50":"Remise: 2+0",
    "overcharge_16":"2+2 [%]",
    "discount_16":"Remise: 2+2",
    "overcharge_19":"4+0 [%]",
    "discount_19":"Remise: 4+0",
    "overcharge_51":"4+1 [%]",
    "discount_51":"Remise: 4+1",
    "overcharge_17":"4+4 [%]",
    "discount_17":"Remise: 4+4 [%]",

    "pdf_issue296":"Attention, la taille des rabats implique une impression de la couverture contre le sens des fibres",
    "pdf_issue33":"+ vernis machine R°/V°",
    "notifications":"Notifications",
    "notification":"Notification",
    "is_active":"Is Active",
    "off":"Off",
    "on":"On",
    "business_hours":"Heures d'ouverture",
    "pallets_requirements":"Exigences palettes",
    "cartons_requirements":"exigences cartons",
    "more_information":"Informations supplémentaires",
    "none":"Aucun",
    "neutral_delivery":"Livraison neutre",
    "preparers":"Prepress",
    "preparer":"Prepress",
    "duplicated_property":"Propriété doublée",
    "is_default":"Est par défaut",
    "is_final":"Est final",
    "spine":"Spine",
    "real_spine":"Real spine",
    "sample":"Essai",
    "samples":"Essai",
    "bleed_inside":"Вътрешно",
    "bleed_outside":"Външно",
    "bleed_head":"Глава",
    "bleed_legs":"Крака",
    "bleeds":"Обрязване + мм",
    "technologist":"Technologist",
    "has_sample":"A un échantillon",
    "address_delivery1":"Adresse de livraison 1",
    "address_delivery2":"Adresse de livraison 2",
    "address_delivery3":"Adresse de livraison 3",
    "contacts1":"Contacts 1",
    "contacts2":"Contacts 2",
    "contacts3":"Contacts 3",
    "contacts4":"Contacts 4",
    "contacts5":"Contacts 5",
    "foil":"Film (foil)",
    "number_of_stamps":"Nombre de tampons",
    "all_sizes_in_mm":"Dimension",
    "embossing":"Gaufrage",
    "embossing_foil":"Marquage à chaud",
    "load":"Charge",
    "holidays":"Vacances",
    "short_name":"Nom court",
    "working_days":"Jours de travail",
    "capacity":"Capacité",
    "period":"Période",
    "load_lepena":"Лепена",
    "load_shita":"Шита",
    "load_tvarda":"Твърда",
    "load_s_kapaci":"С капаци",
    "load_vshm":"ВШМ",
    "load_drugi":"Друг вид поръчки",
    "free_capacity1":"Свободен капацитет %",
    "free_capacity2":"Свободен капацитет бр",
    "total40for_period":"Общо подготвки 4+0 за периода",
    "total40s_for_period":"Общо листа 4+0 за периода",
    "total11for_period":"Общо подготовки 1+1 за периода",
    "total11s_for_period":"Общо листа 1+1 за периода",
    "download_excel":"Download Excel",
    "download_detailed_excel":"Download detailed Excel",
    "dencity":"Density",
    "count_pages":"Pages",
    "supplier":"Supplier",
    "am":"AM",
    "paper_body":"Papier interieur",
    "paper_cover":"Papier couverture",
    "calculated_transport_price":"Prix de transport calculé",
    "calculated_transport_copies":"Calculated transport copies",
    "calculated_transport_weight":"Calculated transport weight",
    "books_price_be":"Prix tpt BE",
    "recipient_email":"Email destinataire",
    "recipient_phone":"Tél. destinataire",
    "recipient":"Destinataire",
    "paper_type":"Type de papier (Interieur)",
    "missing_paper":"n'est plus disponible - pour afficher l'offre, il est remplacé par le papier",
    "disabled":"Disabled",
    "disabled":"Inactif",
    "enabled":"Actif",
    "paper_validation":"Всички",
    "pantone_number":"Pantone",
    "pantone_numbers":"Pantones",
    "pantone_type":"Type pantone",
    "pdf_paper_client":"Papier client ({PAPER_NAME} - {PAPER_DENSITY} g - {BIG_SHEETS} feuilles {PAPER_SIZE})",
    "pdf_paper_client_special":"Papier spéciale ({PAPER_NAME} - {PAPER_DENSITY} g - {BIG_SHEETS} feuilles {PAPER_SIZE})",
    "pdf_paper_client_special2":"Papier spéciale ({PAPER_NAME} - {PAPER_DENSITY} g)",
    "paper_client":"Papier client",
    "special_client":"Papier spéciale",
    "users_excel_id":"ID",
    "users_excel_first_name":"Prénom",
    "users_excel_last_name":"Nom",
    "users_excel_email":"Email",
    "users_excel_phone":"Tél.",
    "users_excel_role":"Rôle",
    "users_excel_websites":"Site/s Internet",
    "price_per_sheet2":"Prix par feuille",
    "fin_ctrl_currency":"Devise",
    "fin_ctrl_approval":"Agrément",
    "fin_ctrl_payment_term":"Conditions de paiement",
    "fin_intra_community_vat":"TVA intra.",
    "field_required":"Champ obligatoire",
    "format_error":"Le format choisi est hors limites techniques (min {MIN_MM} mm / max {MAX_MM} mm). Modifiez svp le format ou contactez nous pour un devis personnalisé.",
    "format_error1":"Le format choisi est hors limites techniques (",
    "format_error2":").",
    "format_error3":"Modifiez svp le format ou contactez nous pour un devis personnalisé.",
    "spine_error":"L’épaisseur du dos est hors limites techniques (min {MIN_MM} mm / max {MAX_MM} mm). Contactez nous pour un conseil et devis personnalisé.",
    "spine_error1":"L’épaisseur du dos est hors limites techniques (",
    "spine_error2":"mm).",
    "spine_error3":"Contactez nous pour un conseil et devis personnalisé.",
    "due_date_validation":"SVP remplissez le champs - date d'échéance",
    "initial_dead_line":"Délai initial",
    "books_format":"Формат",
    "pantone_serial":"Numéro Pantone",
    "pantone_amount":"Quantité pantone",
    "prep_remarks":"Забележка състояние",
    "prep_remarks_probe":"Забележка проба",
    "documentation":"Documentation",
    "remarks_accounting":"Remarques compta",
    "billing_ntities":"Entités de facturation",
    "volume_without":"Volume m3 (sans cartons)",
    "ratio":"Coefficient",
    "at_name":"Chez",
    "tirage_label_ex":"Tirage",
    "shipment_price":"Coût transpoort en lv",
    "failed":"Ces identifiants ne correspondent pas à nos enregistrements",
    "throttle":"Trop de tentatives de connexion. Veuillez essayer de nouveau dans :seconds secondes.",
    "default":"Default",
    "data_error":"Data error",
    "calc_error":"Database error",
    "paper_color_density_mismatch_text":"Pour cette commande un des critères n'est plus valide - nom du papier / couleur / densité (texte).",
    "paper_color_density_mismatch_cover":"Pour cette commande un des critères n'est plus valide - nom du papier / couleur / densité (couverture).",
    "paper_color_density_mismatch_insert":"Pour cette commande un des critères n'est plus valide - nom du papier / couleur / densité (cahier ).",
    "zero_copies":"Nombre d'exemplaires doit être supérieur à zéro.",
    "no_hat_db_record":"Pas trouvé dans la base de données.",
    "no_lace_db_record":"Pas trouvé dans la base de données.",
    "text_pages_change":"Text pages change: :FROM :TO",
    "insert_pages_change":"Insert pages change: :FROM :TO",
    "cover_pages_change":"Cover pages change: :FROM :TO",
    "titles":"M.",
    "titles":"Mme",
    "organization_type":"Forme juridique",
    "trademark":"Nom Commercial",
    "address_correspondence":"Adresse de correspondance",
    "code_ebp":"Code EBP",
    "code_ajour":"Code AJOUR",
    "civilite":"Civilité",
    "prenom":"Prénom envoi F",
    "nomenvoi":"Nom envoi F",
    "email_organization":"E-mail envoi F",
    "email_organization_copy":"E-mail copie F",
    "siege_social":"siège social",
    "create":"Créer un Нouveau",
    "edit":"Мodifier",
    "copy":"Copie",
    "delete":"Delete",
    "submit":"Submit",
    "email":"Email",
    "order":"Order",
    "overcharges_main":"Surcharges",
    "overcharges_list":"Liste",
    "overcharges_create":"Créer",
    "title":"Titre",
    "side":"Страна",
    "amount":"Брой",
    "params1":"Основни Параметри",
    "params2":"Параметри (Голям Лист)",
    "params3":"Параметри (Двоен/Единичен Лист)",
    "inactive":"Неактивна",
    "side_type":"Ш",
    "side_type":"В",
    "side_type":"Къса Страна",
    "side_type":"Дълга Страна",
    "side_type":"Ш + В",
    "basic_params_id":"ID",
    "basic_params_enabled":"Активна",
    "basic_params_type":"Тип",
    "basic_params_name":"Име (съкр.)",
    "basic_params_notes":"Бележки",
    "basic_params_color":"Цвят (в ексел)",
    "basic_params_correction_x":"(Корекции по X)",
    "basic_params_correction_y":"Корекции по Y",
    "basic_params_min_x":"Мин. X",
    "basic_params_max_x":"Макс. X",
    "basic_params_min_y":"Мин. Y",
    "basic_params_max_y":"Макс. Y",
    "basic_params_price_ratio":"Коеф. цена",
    "basic_params_default_volume_ratio":"Обемен коеф. по подр.",
    "basic_params_min_double_sheet_x":"Мин. X (лист/дв. лист)",
    "basic_params_min_double_sheet_y":"Мин. Y (лист/дв. лист)",
    "basic_params_correction_min_double_sheet_x":"Корекция мин. X (лист/дв. лист)",
    "basic_params_correction_min_double_sheet_y":"Корекция мин. Y (лист/дв. лист)",
    "basic_params_max_double_sheet_x":"Макс. X (лист/дв. лист)",
    "basic_params_max_double_sheet_y":"Макс. X (лист/дв. лист)",
    "machine_type":"-",
    "machine_type":"Офсетов печат",
    "machine_type":"Платно",
    "machine_type":"Мукава",
    "machine_type":"Дигитален печат",
    "element_type":"Неразпознат Елемент",
    "element_type":"Тяло",
    "element_type":"Корица",
    "element_type":"Вложка",
    "element_type":"Ед. лист",
    "element_type":"Обложка/Бандерол",
    "element_type":"Платно",
    "element_type":"Мукава",
    "element_type":"Форзац",
    "processing_type_always_type_name":"Винаги",
    "processing_type_always_type_color":"#ffcccc",
    "processing_type_always":"(0)",
    "processing_type_always":"(1)",
    "processing_type_always":"(2)",
    "processing_type_binding_type_name":"Книговезане",
    "processing_type_binding_type_color":"#ffeecc",
    "processing_type_binding_rel_col":"Лепена",
    "processing_type_binding_rel_pur":"Лепена ПУР",
    "processing_type_binding_rel_couscol":"Шита-лепена",
    "processing_type_binding_rel_agr":"Шита с телчета",
    "processing_type_binding_rel_apb":"Шито с телчета с „уши”",
    "processing_type_binding_rel_ann":"Спирала",
    "processing_type_binding":"-",
    "processing_type_finishing_type_name":"Довършителни",
    "processing_type_finishing_type_color":"#c8ffde",
    "processing_type_finishing_pel_mat":"Мат",
    "processing_type_finishing_pel_bri":"Гланц",
    "processing_type_finishing_pel_soft":"Кадифе",
    "processing_type_finishing_pel_sel":"Селективен лак",
    "processing_type_finishing_pel_selv":"Обемен лак",
    "processing_type_finishing_pel_vs":"Кадифе + Сел. лак",
    "processing_type_color_type_name":"Цвят",
    "processing_type_color_type_color":"#caecf8",
    "processing_type_color":"1+0",
    "processing_type_color":"1+1",
    "processing_type_color":"2+2",
    "processing_type_color":"4+0",
    "processing_type_color":"4+4",
    "processing_type_live_type_name":"На живо",
    "processing_type_live_type_color":"#ead9f2",
    "processing_type_live":"Да (1)",
    "processing_type_clothfix_type_name":"ТП - Корица/Харитя",
    "processing_type_clothfix_type_color":"#c8d0ca",
    "processing_type_clothfix":"Да (1)",
    "processing_type_type_type_name":"Вид",
    "processing_type_type_type_color":"#cff4f0",
    "processing_type_type_normal":"нормален",
    "processing_type_type_mixed":"смесен",
    "processing_type_type_spine":"гръбче",
    "processing_type_flaps_type_name":"Крила",
    "processing_type_flaps_type_color":"#ffe7cc",
    "processing_type_flaps":"(0)",
    "processing_type_flaps":"(1)",
    "client_email":"Email is registered to another client",
    "new_client":"Cannot create new client, aborting order",
    "client_id":"Client not found, aborting order",
    "order_save":"Cannot save the order",
    "headings_create":"New Order",
    "headings_edit":"Edit Order",
    "filter_id":"Id",
    "filter_client":"Client / Societe / Contacts supplementaires",
    "filter_status":"Etat",
    "filter_name":"Reference",
    "filter_product_type":"Product",
    "filter_apply":"Appliquer",
    "filter_clear":"Eliminer",
    "filter_owner":"Proprietaire",
    "filter_show_mine":"Mes commandes",
    "filter_allproducts":"Tous les produits",
    "filter_allusers":"Tous les utilisateurs",
    "filter_from":"de",
    "filter_to":"a",
    "form_pricing":"Tarification",
    "form_price_final":"Prix final",
    "form_price":"Prix",
    "form_print_run":"Tirage",
    "form_info":"Information",
    "form_cover":"Couverture",
    "form_text":"Interieur",
    "form_insert":"Cahier hors texte",
    "form_properties":"Propriétés du produit",
    "form_order_name":"Référence",
    "form_budget":"Budget",
    "form_overcharge_default":"Mark up par défaut",
    "form_overcharge_total":"Mark up final",
    "form_overcharge_change":"Modification",
    "form_discount":"Remise",
    "form_category":"Choisir une catégorie",
    "form_client_new":"Nouveau client",
    "form_client_select":"Sélectionnez un client",
    "form_client_name":"Nom",
    "form_client_email":"Email",
    "form_client_phone":"Téléphone",
    "form_client_company":"Société",
    "form_pages":"Pages",
    "form_print":"Impression",
    "form_finishing":"Finition",
    "form_paper":"Papier",
    "form_color":"Couleur du papier",
    "form_density":"Densité du papier",
    "form_weight":"Poids",
    "form_height":"Hauteur",
    "form_length":"Longueur",
    "form_size":"Format fini",
    "form_folding":"Pliage",
    "form_binding":"Reliure",
    "form_copies":"Tirage total",
    "form_address":"Département",
    "form_delivery":"Ajouter point de livraison",
    "form_comment":"Remarques",
    "form_add_cover":"Couverture",
    "form_add_insert":"Cahier hors texte",
    "form_add_jacket":"Jaquette",
    "form_add_banderole":"Bandeau",
    "form_history_id":"ID",
    "form_history_section_title":"Historique des commandes",
    "form_history_ids":"ID / Order Id",
    "form_history_action":" Événement",
    "form_history_user_names":"Utilisateur",
    "form_history_date":"Date",
    "form_history_additional_data":"Information complémentaire",
    "form_banderole":"Bandeau",
    "form_jacket":"Jaquette",
    "form_additional_languages":"Repiquage du noir",
    "form_main_language_copies":" ex. en langue principal",
    "form_add_language_copies":"Nbr ex. en langue supplémentaire",
    "form_add_language_mutation_count":"Nbr mutations du NOIRE",
    "form_cloth":"Contrecollé sur carton",
    "form_cardboard":"Carton",
    "form_endpapers":"Pages de gardes",
    "form_add_endpapers":"Pages de gardes",
    "form_cloth_type":"Type de couverture",
    "form_cloth_type_paper":"Habillage couverture: papier",
    "form_cloth_type_mixed":"Habillage couverture: mixte toile + papier",
    "form_cloth_type_cloth":"Habillage couverture: toile",
    "form_cloth_type_without_clothing":"Sans habillage",
    "form_cloth_width":"Largeur de la toile",
    "form_cloth_price":"Prix papier/toile pour la couverture (m²)",
    "form_cloth_visible_part":"Partie visible",
    "form_cloth_model":"Modèle",
    "form_cloth_supplier":"Fournisseur",
    "form_cloth_min_length":"Longueur min (m)",
    "form_cardboard_paper_name_suffix":"(Carton épaisseur mm / Mousse épaisseur mm)",
    "form_bookmark_insert":"Inséré",
    "form_bookmark_no_insert":"Non Inséré",
    "form_bookmarks":"Marque pages",
    "form_spine_type":"Type du dos",
    "form_spine_type_flat":"Droit",
    "form_spine_type_round":"Rond",
    "form_cloth_int_select":"Catalogues toiles",
    "form_cloth_int_vendor":"Fournisseur",
    "form_cloth_int_type":"Type",
    "form_cloth_int_price":"Prix m²",
    "form_cloth_int_price_min":"Prix m² (min)",
    "form_cloth_int_price_max":"Prix m² (max)",
    "form_cloth_int_search":"Filtrer par nom du produit",
    "form_cloth_int_apply":"Appliquer",
    "form_cloth_int_clear":"Eliminer",
    "form_cloth_int_cancel":"Annuler",
    "form_cloth_int_insert":"Insérer le choix",
    "form_cloth_int_series":"Séries",
    "form_cloth_int_model":"Modèle",
    "form_cloth_int_size":"Dimensions",
    "price_correction_type":"Type",
    "price_correction_title":"Frais supplémentaires",
    "price_correction_visible":"Offerte",
    "price_correction_sum":"Montant",
    "price_correction_description":"Description",
    "price_correction_option_included_invisible":"",
    "price_correction_option_included_included":"Prestations incluses: ",
    "price_correction_option_included_free":"Prestations offertes: ",
    "price_correction_option_included_invisible_button":"Ne pas afficher dans le devis",
    "price_correction_option_included_included_button":"Inclus",
    "price_correction_option_included_free_button":"Offerte",
    "order_name":"Référence",
    "organization":"Société",
    "id":"Id",
    "client":"Client",
    "product":"Product",
    "price":"Prix",
    "status":"Status",
    "date_created":"Date création",
    "account_manager":"AM/Hunter",
    "website":"Site Internet",
    "budget":"Budget",
    "order_status":"Personnalisé",
    "order_status":"Prix Cat. client_category_id",
    "order_status":"Prix Public",
    "sum_thickness":"Épaisseur du dos",
    "client_missing_category_alert":"Le client sélectionné ne fait pas partie à une catégorie de prix!",
    "price_change_alert":"Attention - différence prix entre l'ancienne offre et le recalcule! ({price_change}).",
    "hide_discount":"Ne pas afficher la remise dans le devis",
    "without_cover":"Sans couverture",
    "old_price":"Prix ancient",
    "print_run_from":"Imprimer Run From",
    "print_run_to":"Imprimer Run To",
    "previous":"« Précédent",
    "next":"Suivant »",
    "password":"Password",
    "reset":"Votre mot de passe a été réinitialisé !",
    "sent":"Nous vous avons envoyé par courriel le lien de réinitialisation du mot de passe !",
    "token":"Ce jeton de réinitialisation du mot de passe n'est pas valide.",
    "user":"Aucun utilisateur n'a été trouvé avec cette adresse e-mail.",
    "clear_all_filters":"Reset",
    "filter_by_all_active_filters":"Filter",
    "filters":"Filters",
    "filter_by_op_in":"Operator",
    "filter_by_machine":"Filter by Machine",
    "filter_by_stage":"Filter by Stage",
    "filter_by_element":"Filter by Element",
    "filter_by_action":"Filter by Action",
    "filter_by_state":"Filter by State",
    "btn_edit":"Edit",
    "btn_create":"Create",
    "btn_submit":"Submit",
    "btn_delete":"Delete",
    "btn_update":"Update",
    "btn_toggle_drop_down":"Toggle Drop-down",
    "btn_back":"Back",
    "list":"List",
    "name":"Name",
    "color":"Color",
    "element":"Element",
    "state":"State",
    "stage":"Stage",
    "component":"Component",
    "action":"Action",
    "state_type":"State Type",
    "continue":"Continue",
    "operator_types":"Operator Types",
    "operator_type":"Operator Type",
    "operator":"Operator",
    "code":"Authentication Code",
    "settings_title":"Settings",
    "settings_elements_title":"Elements",
    "settings_stages_title":"Stages",
    "settings_components_title":"Components",
    "settings_actions_title":"Actions",
    "settings_states_title":"States",
    "settings_state_types_title":"State Types",
    "settings_machines_title":"Machines",
    "settings_product_builder_title":"Product Builder",
    "settings_operators_title":"Operators",
    "settings_operator_types_title":"Operator types",
    "messages_select_product":"Select product",
    "messages_select_elements":"Select elements for product",
    "messages_not_all_elements_have_stages":"Not all elements have stages",
    "messages_select_stages":"Select stages for products element",
    "messages_not_all_stages_have_actions":"Not all stages have actions",
    "messages_select_actions":"Select actions for stage of products element",
    "messages_not_all_actions_have_states":"Not all actions have states",
    "messages_select_states":"Select states for action in state of products element",
    "messages_select_default":"The select default state must be one of the checked states",
    "messages_select_final":"The select final state must be one of the checked states",
    "validation_required":"is required",
    "validation_date":"must be of date type",
    "validation_email":"must be valid email address",
    "validation_number":"must be only digits",
    "validation_rangelength":"must be 4 digits",
    "validation_rangelength_remarks":"must be between 3 to 1000 characters",
    "format":"Format",
    "ready_copies":"Copies done",
    "copies":"Tirage",
    "production_number":"Production number",
    "personalize_operation":"Personalize operation",
    "find_yourself_in_the_list":"Find yourself in the list",
    "enter_your_personal_code":"Enter your personal code",
    "koli":"Feuille machine",
    "machine":"Machine",
    "trader":"Trader",
    "technologist_in_charge":"Technologist",
    "final_date_to_be_produced":"Produced date final",
    "order_remark":"Order remarks",
    "stage_remark":"Stage remarks",
    "additional_date":"Date d'échéance 2",
    "additional_date_remarks":"Remarques production",
    "machines":"Machines",
    "stages":"Stages",
    "elements":"Elements",
    "actions":"Actions",
    "states":"States",
    "title":"Title",
    "traders":"Traders",
    "order_history":"Order history",
    "updated_by":"Updated by-at",
    "date":"Date",
    "remarks":"Remarks",
    "clients":"Clients",
    "bindings":"Bindings",
    "products":"Products",
    "show_only_mine":"Show only mine",
    "auth":"Authentication",
    "on":"On",
    "off":"Off",
    "error":"Error",
    "failed_action":"Failed action",
    "close":"Close",
    "colors":"Colors",
    "required":"required",
    "saved":"Saved",
    "integer":"must be only digits",
    "exact_length":"must be 4 digits",
    "":"L.",
    "":"H.",
    "landscapes":"Landscape distributions",
    "portraits":"Portrait distributions",
    "sort_by_filter":"Sort by filter",
    "sort_order":"Sort order",
    "distribution":"Distribution",
    "distributions":"Distributions",
    "distribution_amount":"Cahiers",
    "distribution_bending":"Сгъване",
    "asc":"ASC",
    "desc":"DESC",
    "remarks_date":"Remarks date",
    "slug":"Slug",
    "value":"Value",
    "new_record":"New record",
    "event_text":"Event text",
    "event_data":"Event data",
    "event_date":"Event date",
    "history":"History",
    "system":"System",
    "show_only_active":"Show only active orders",
    "active":"Active",
    "done":"Done",
    "pages":"Pages",
    "big_sheets":"Бр. л. за печат",
    "final":"Крайно",
    "operator_permissions":"Възможности",
    "operator_permission":"Възможност",
    "spine":"Гръбче",
    "real_spine":"Реално гръбче",
    "sample":"Проба",
    "samples":"Проби",
    "bleed_inside":"Вътрешно",
    "bleed_outside":"Външно",
    "bleed_head":"Глава",
    "bleed_legs":"Крака",
    "bleeds":"Обрязване + мм",
    "technologist":"Технолог",
    "number_of_sheets_text":"Бр. листа",
    "big_sheets_marked":"Бр. отбелязани л. за печат",
    "big_sheets_mark":"Бр. л. за печат отбележи",
    "number_of_sheets_text_marked":"Бр. отбелязани листа",
    "number_of_sheets_text_mark":"Бр. листа отбележи",
    "big_sheets_in_week":"Бр. л. за печат в седмицата",
    "number_of_sheets_in_week":"Бр. листа в седмицата",
    "with_flaps":"С капаци",
    "prepress":"Монтаж",
    "ctp":"CTP",
    "workshop":"Склад",
    "workshop_ready_production":"Склад готова продукция",
    "completion":"Довършителен цех",
    "fitter":"Печатен цех",
    "place":"Място",
    "due_date":"Клиентски срок",
    "with_bookmark":"С влагане",
    "without_bookmark":"Без влагане",
    "urgent":"Необратена",
    "in_edit":"В процес",
    "order1":"Необработена",
    "order2":"В процес",
    "order3":"Неактивна",
    "order4":"Готова",
    "production_second_production":"Повторна изработка",
    "no_recs_found":"No records found.",
    "no_states":"Няма създадени състояния",
    "no_actions":"Няма създадени действия",
    "none":"Няма",
    "btn_send_to_production":"Envoyer en production",
    "btn_revert_production":"Annuler la production",
    "additional_date_validation":"Le champs - Date d'échéance est obligatoire",
    "send_to_preparer":"Envoyer au prépresse",
    "revert_from_preparer":"Annuler la prépresse",
    "request_real_spine":"Измерване на гръбче",
    "request_width_measure":"Измерване на дебелина",
    "width":"Дебелина",
    "width_body":"Дебелина на тяло",
    "request_width_body_measure":"Измерване на дебелина",
    "revert":"Върни",
    "revert_to":"Върни към",
    "preparer_required":"SVP - remplissez le champ prépresse",
    "sample_custom":"Цифрова проба",
    "paper_custom":"Particularité papier",
    "lace":"Ласе",
    "head_and_tails_band":"Капителбанд",
    "bookmark":"Разделител",
    "foil_wrapped":"Опаковане поединично",
    "foil_packed":"Фолирано в пакети",
    "total_orders":"Тотал всичко",
    "total_orders_without_vshm_and_others":"Тотал без ВШМ и Други",
    "count_orders":"Бр. поръчки",
    "big_sheets":"Бр. подготовки 4+0",
    "big_sheets":"Бр. подготовки 1+1",
    "big_sheets":"Бр. подготовки 2+2",
    "number_of_sheets":"Бр. листа 4+0",
    "number_of_sheets":"Бр. листа 1+1",
    "number_of_sheets":"Бр. листа 2+2",
    "dist_amount":"Бр. коли",
    "embossing_foil":"Топъл печат",
    "search_by_period":"Търси по Период",
    "no_copies":"Няма въведени Готови бройки",
    "done_completion":"Готова",
    "deactivated_paper":"Le papier ne peut pas être désactivé - car c'est le seul papier actif pour l'élément.",
    "period_from":"Period from",
    "period_to":"Period to",
    "week":"Week",
    "fit_sheet":"График за печат",
    "fit_heading":"График за печат на",
    "fit_hour":"ч.",
    "fit_gr":"гр.",
    "fit_order":"Пор.",
    "fit_client":"Клиент",
    "fit_product":"Продукт",
    "fit_due_date":"Срок",
    "fit_tirage":"Тираж",
    "fit_paper_format":"Формат на хартията",
    "fit_paper":"Хартия",
    "fit_color_embossing":"Цветност/ламинат",
    "fit_big_sheets":"Подготовки на машината",
    "fit_tirage_sheets":"Тиражни листи",
    "fit_paper_place":"Сектор с хартия",
    "fit_digital_real_proof":"Дигитална проба Реална проба Мостра Да/Не",
    "fit_printing_start_hour":"Начало за печат (Час)",
    "fit_printing_end_hour":"Край (Час)",
    "fit_tirage_time_in_hours":"Време на тираж (Часове)",
    "fit_comment_or_problems":"Коментар по тиража / Възникнали проблеми",
    "fit_format":"Формат",
    "fit_binding":"Книговезане",
    "fit_pages":"Бр. стр.",
    "remarks_workshop":"Забележка склад",
    "partially_done":"Частично изработени",
    "probe":"Проба",
    "partially_done_and_finish":"Частично изработени и завърши",
    "dones":"Готови",
    "text_pages":"Nb pages int.",
    "text_print":"Impression int.",
    "text_paper":"Papier int.",
    "text_paper_density":"Densité int.",
    "body_maculaturi":"Nb de feuilles + déchets int",
    "body_size":"Format papier int.",
    "insert_pages":"Nb pages cahier 2",
    "insert_print":"Impression cahier 2",
    "insert_paper":"Papier cahier 2",
    "insert_paper_density":"Densité cahier 2",
    "vlojka_maculaturi":"Nb de feuilles + déchets cahier 2",
    "size_vlojka":"Format papier cahier 2",
    "spine_width":"Épaisseur du dos",
    "dashboard_main":"Tableau de Bord",
    "first_name":"First Name",
    "last_name":"Last Name",
    "phone":"Phone",
    "create_new":"Create",
    "users":"Users",
    "view":"View",
    "new_password":"New Password",
    "new_password2":"Confirm your new password",
    "password2":"Confirm your password",
    "edit_user":"Edit user",
    "edit_password":"Change password",
};

export default fr;
