import Cookies from 'js-cookie'

export default {
    isLogged: function ()
    {
        return Cookies.get('user') !== undefined;
    },
    getUser: function ()
    {
        if(this.isLogged())
        {
            return JSON.parse(Cookies.get('user'));
        }

        return null;
    },
    setUser: function (user)
    {
        let objAssign = Object.assign({},user);
        objAssign.permissions = null;
        Cookies.set('user', JSON.stringify(objAssign));
        Cookies.set('menu', user.permissions);
        let newU = Cookies.get('user');
    },
    deleteUser: function ()
    {
        Cookies.remove('user');
        Cookies.remove('menu');
        Cookies.remove('urls');
    },
    getRoles()
    {
        let user = this.getUser();
        if(user !== null)
        {
            return user.roles;
        }

        return [];
    },
    getPermissionsMenuItems: function()
    {
        let menu = Cookies.get('menu');
        if(menu === undefined || menu === 'undefined')
        {
            return null;
        }

        return JSON.parse(menu);
    },
    getAllPermissionUrls: function()
    {
        let urls = Cookies.get('urls');
        let menu = Cookies.get('menu');
        if(urls === undefined || urls === 'undefined' || urls === null)
        {
            if(menu === undefined || menu === 'undefined')
            {
                return null;
            }
        }

        if(urls !== undefined && urls.length > 0)
        {
            return JSON.parse(urls);
        }

        let parsedMenu = JSON.parse(menu);
        let tmpUrls = [];
        for (let i = 0; i < parsedMenu.length;i++)
        {
            if(parsedMenu[i].link === null)
            {
                let innerLinks = parsedMenu[i]['sub-menu'];
                for (let j = 0; j < innerLinks.length; j++)
                {
                    tmpUrls.push(innerLinks[j].link);
                }
            }
            else
            {
                tmpUrls.push(parsedMenu[i].link);
            }
        }

        let tmpUrlsStringify = JSON.stringify(tmpUrls);
        Cookies.set('urls', tmpUrlsStringify);

        return tmpUrls;
    }
}
