import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Languages from "../../../constants/Languages";
import SimpleFieldsGenerator from "../../../helpers/SimpleFieldsGenerator";
import productTypesList from "./productTypesList";

class ProductTypesForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        productType: {
            id: null,
            slug: ''

        }
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if(id !== undefined)
        {
            this.load(id);
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`products/types/${id}`)
                .then(response => {
                    const productType = response.productType;
                    this.setState({productType: productType});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {productType} = this.state;
        let url = 'products/types';
        console.log('------------------')
        console.log(productType);
        if(productType.id !== null)
        {

            url += '/' + productType.id;
        }

        NetworkClient.post(url, productType)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c=>{
                        this.props.history.push('/products/types');
                    })
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
        let fieldsLang = this.state.languages.map((l) => {return {name: `${l}_name`, type:'text'}});
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{this.state.productType.id === null ? t('create') : t('edit')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {SimpleFieldsGenerator.bind(this,t,[{name: 'slug', type: 'text'}], "productType")()}
                                    {SimpleFieldsGenerator.bind(this,t,fieldsLang,"productType")()}


                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.productType.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ProductTypesForm);