import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../../../../api/NetworkClient';
import Loader from '../../../../../../partial/Loader';
import Languages from "../../../../../../constants/Languages";
import SimpleFieldsGenerator from '../../../../../../helpers/SimpleFieldsGenerator';

class EconomyForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        economy: {
            id: null,
            wight: null,
            price: null,
        }
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if(id !== undefined)
        {
            this.load(id);
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`transport/de/prices/economy/${id}`)
                .then(response => {
                    const economy = response.economy;
                    this.setState({economy});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {economy} = this.state;
        let url = 'transport/de/prices/economy';
        if(economy.id !== null)
        {
            url += '/' + economy.id;
        }

        NetworkClient.post(url, economy)
            .then(response => {


                if(response.error)
                {
                    Swal.fire('Error!', response.error, 'error').then((c) => {
      
                    });
                }
                else
                {
                    Swal.fire("Ok!", "", "success").then((c) => {
                           this.props.history.push("/transport/de/prices/economy");
                   });
                }                    
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>DE - {t('transport')} {t('economy')} {t('prices')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'weight', type: 'number'}], "economy")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'price', type: 'number'}], "economy")()}
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.economy.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(EconomyForm);
