import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Swal from "sweetalert2";
import NetworkClient from "../../../../../api/NetworkClient";
import Loader from "../../../../../partial/Loader";
import Languages from "../../../../../constants/Languages";
import SimpleFieldsGenerator from "../../../../../helpers/SimpleFieldsGenerator";

class CourierForm extends Component {
  state = {
    isLoading: false,
    languages: Languages,
    price: {
      id: null,
      weight: "",
      price: "",
    },
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    if (id !== undefined) {
      this.load(id);
    }
  }

  load(id) {
    this.setState({ isLoading: true }, () => {
      NetworkClient.get(`transport/bg/courier-prices/${id}`)
        .then((response) => {
          const price = response.price;
          this.setState({ price });
        })
        .finally((c) => {
          this.setState({ isLoading: false });
        });
    });
  }

  store() {
    this.setState({ isLoading: true });
    const { price } = this.state;
    let url = "transport/bg/courier-prices";
    if (price.id !== null) {
      url += "/" + price.id;
    }

    NetworkClient.post(url, price)
      .then((response) => {
          if(response.error)
          {
              Swal.fire('Error!', response.error, 'error').then((c) => {

              });
          }
          else
          {
              Swal.fire("Ok!", "", "success").then((c) => {
                     this.props.history.push("/transport/bulgaria/prices/courier");
             });
          }
       
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Loader isLoading={this.state.isLoading} />
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  {t("edit")} {t("transport")} {t("courier")} {t("price")} /#{" "}
                  {this.state.price.order}
                </h1>
              </div>
            </div>
          </div>
        </section>
        <div className={"content"}>
          <div className={"container-fluid"}>
            <div className={"col-md-6"}>
              <div className="card card-primary">
                <div className="card-body">
                {SimpleFieldsGenerator.bind(this,t,[{name: 'weight', type: 'number'}],"price")()}
                {SimpleFieldsGenerator.bind(this,t,[{name: 'price', type: 'number'}],"price")()}
                  <button
                    onClick={this.store.bind(this)}
                    className={"btn btn-info btn-sm"}
                  >
                    {this.state.price.id === null ? "Create" : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CourierForm);
