import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Languages from "../../../constants/Languages";

class PaperSizesForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        size: {
            id: null,
            height: 0,
            length: 0
        }
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id !== undefined) {
            this.load(id);
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`papers/sizes/${id}`)
                .then(response => {
                    const size = response.size;
                    this.setState({size});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {size} = this.state;
        let url = 'papers/sizes';
        if (size.id !== null) {
            url += '/' + size.id;
        }

        NetworkClient.post(url, size)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        this.props.history.push('/papers/sizes');
                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    printFormFields(t) {
        let fields = ['height','length'];

        return fields.map((field, i) =>  <div className="form-group">
            <label>{t(field)}</label>
            <input
                key={i}
                type="number" className={"form-control"}
                placeholder={t(field)} value={this.state.size[field]}
                onChange={(e) => {
                    let {size} = this.state;
                    size[field] = e.target.value;
                    this.setState({size});
                }}
            />
        </div>)
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('paper')} {t('size')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {this.printFormFields(t)}
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.size.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PaperSizesForm);
