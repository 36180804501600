import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../../helpers/DataTableInitDataGenerator';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import SwalDelete from '../../../helpers/SwalDelete';

class MaculaturePrintCoverList extends Component {
    state = {
        isLoading: false,
        data: {
            columns: [],
            rows: []
        }
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {t} = this.props;
        let listColumns = [
            'tirage_from', 'tirage_to',
            'print_one','print_two','print_three','print_four',
            'nadpechaten_lak','pantone','laminate','uv_lak','selektiven_lak',
            'embossing','embossing_foil','punching','actions'
        ];
        this.setState({data: DataTableInitDataGenerator(listColumns, t)});
        this.load();
    }

    load() {
        this.setState({isLoading: true});
        NetworkClient.get('maculature-cover-print').then(response => {
            let {data} = this.state;
            let items = response.maculatures;
            let rows = [];
            items.forEach((item, i) => {
                let tmp = {
                    tirage_from: item.tirage_from,
                    tirage_to: item.tirage_to,
                    print_one: item.print_one,
                    print_two: item.print_two,
                    print_three: item.print_three,
                    print_four: item.print_four,
                    nadpechaten_lak: item.nadpechaten_lak,
                    pantone: item.pantone,
                    laminate: item.laminate,
                    uv_lak: item.uv_lak,
                    selektiven_lak: item.selektiven_lak,
                    embossing: item.embossing,
                    embossing_foil: item.embossing_foil,
                    punching: item.punching,
                    actions: <div>
                        <Link to={`/maculatures-cover-print/edit/${item.id}`} className={"btn btn-info btn-sm"}>
                            <i className={"fa fa-pen"}/>
                        </Link>
                    </div>
                };
                rows.push(tmp);
            });


            this.setState({data: {columns: data.columns, rows: rows}, isLoading: false});
        })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    {t('maculatures-cover-print')}
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <MDBDataTable
                                            striped
                                            bordered
                                            hover
                                            entries={25}
                                            data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(MaculaturePrintCoverList);
