import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Select from "react-select";


const INITIAL_STATE = {
    isLoading: false,
    name: '',
    budget: '',
    selectedClient: -1,
    selectedCategory: -1,
    selectedOrderState: 1,
    selectedAM: null,
    selectedHunter: null,
    overcharge_change: 0,
    discount: 0,
    hide_discount: false,
    new_client: false,
    client: {
        id: '',
        name: '',
        last_name: '',
        email: '',
        phone: '',
        am_id: -1,
        hunter_id: -1
    },
    isOpen: true
};

class QuoteInformationForm extends Component {
    state = INITIAL_STATE;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.applyChanges(this.getOrderInformation())
    }

    getOrderInformation(trigger = false) {
        return {
            name: this.state.name,
            overcharge_default: this.props.overcharge,
            budget: this.state.budget,
            selectedClient: this.state.selectedClient,
            selectedCategory: this.state.selectedCategory,
            selectedOrderState: this.state.selectedOrderState,
            selectedAM: this.state.selectedAM,
            selectedHunter: this.state.selectedHunter,
            overcharge_change: this.state.overcharge_change,
            discount: this.state.discount,
            hide_discount: this.state.hide_discount,
            new_client: this.state.new_client,
            client: this.state.client,
            trigger: trigger
        }
    }

    componentWillReceiveProps(props) {
        if(props.initial_state !== null && this.state.name === '')
        {
            this.setState({...props.initial_state})
        }
    }

    handleSimpleChange(value, fieldName) {
        let state = this.state;
        state[fieldName] = value;
        this.setState(state,() => {
            this.props.applyChanges(this.getOrderInformation())
        });
    }

    handleClientChange(value, fieldName)
    {
        let {client} = this.state;
        client[fieldName] = value;
        this.setState({client},() => {
            this.props.applyChanges(this.getOrderInformation())
        });
    }

    getTotalOvercharge() {

        let overchargeChange = 0;
        if(Number.isInteger(parseInt(this.state.overcharge_change)))
        {
            overchargeChange =  parseInt(this.state.overcharge_change);
        }

        return this.props.overcharge + overchargeChange;
    }

    renderClientInformation() {
        let isDisabled = !this.state.new_client;

        return (<div className={'row'}>
            <div className="col-6 mt-1">
                <label>{this.props.t('first_name')}</label>
                <input
                    type="text"
                    disabled={isDisabled}
                    value={this.state.client.name}
                    className={'form-control'}
                    onChange={e=>this.handleClientChange(e.target.value,'name')}
                />
            </div>
            <div className="col-6 mt-1">
                <label>{this.props.t('last_name')}</label>
                <input
                    type="text"
                    disabled={isDisabled}
                    value={this.state.client.last_name}
                    className={'form-control'}
                    onChange={e=>this.handleClientChange(e.target.value,'last_name')}
                />
            </div>
            <div className="col-6 mt-1">
                <label>{this.props.t('email')}</label>
                <input
                    type="text"
                    disabled={isDisabled}
                    value={this.state.client.email}
                    className={'form-control'}
                    onChange={e=>this.handleClientChange(e.target.value,'email')}
                />
            </div>
            <div className="col-6 mt-1">
                <label>{this.props.t('phone')}</label>
                <input
                    type="text"
                    disabled={isDisabled}
                    value={this.state.client.phone}
                    className={'form-control'}
                    onChange={e=>this.handleClientChange(e.target.value,'phone')}
                />
            </div>
            <div className="col-6 mt-1">
                <label>{this.props.t('am')}</label>
                <Select
                    value={this.state.selectedAM}
                    isDisabled={isDisabled}
                    options={this.props.ams}
                    onChange={(selected) => {
                        this.setState({selectedAM: selected},() => {
                            this.props.applyChanges(this.getOrderInformation())
                        });
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-6 mt-1">
                <label>{this.props.t('hunter')}</label>
                <Select
                    value={this.state.selectedHunter}
                    isDisabled={isDisabled}
                    options={this.props.hunters}
                    onChange={(selected) => {
                        this.setState({selectedHunter: selected},() => {
                            this.props.applyChanges(this.getOrderInformation())
                        });
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
        </div>)
    }

    getSelectedClient() {
        if(this.state.client.id > 0) {
            return this.props.clients.find(x=>x.id === this.state.client.id);
        }

        return this.state.selectedClient;
    }

    renderClients() {
        return (
            <>
                <div className="row mt-3">
                    <div className={"col-6 " + (this.state.new_client === true ? 'd-none' : '')}>
                        <label>{this.props.t('form_client_select')}</label>
                        <Select
                            value={this.getSelectedClient()}
                            options={this.props.clients}
                            onChange={(selected) => {

                                let selectedAM = this.props.ams.find(x=> x.id === selected.am_id);
                                let selectedHunter = this.props.hunters.find(x=>x.id === selected.hunter_id);

                                this.setState({
                                    selectedClient: selected,
                                    selectedCategory: selected.category_id,
                                    client: selected,
                                    selectedAM,
                                    selectedHunter
                                },() => {
                                    this.props.applyChanges(this.getOrderInformation(true))
                                });
                            }}
                            getOptionLabel={(option) => option.name + ' ' + option.last_name}
                            getOptionValue={(option) => option.id}
                        />
                    </div>
                </div>
                {this.renderClientInformation()}
            </>
        )
    }

    getMinimizeIcon() {
        if(this.state.isOpen)
        {
            return (<i className="fa fa-window-minimize" aria-hidden="true" />)
        }

        return (<i className="fa fa-plus" aria-hidden="true" />)
    }

    render() {
        return (
            <div className={'card mt-3'}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h3>
                               <i className={'fa fa-user'} /> {this.props.t('form_info')}
                                <button onClick={()=>{
                                    this.setState({isOpen: !this.state.isOpen});
                                }} className={'btn btn-sm btn-info float-right'}>
                                    {this.getMinimizeIcon()}
                                </button>
                            </h3>
                        </div>
                    </div>
                    <div className={'collapse ' + (this.state.isOpen ? 'show' : '') }>
                    <div className="row mt-3">
                        <div className="col-6">
                            <label>{this.props.t('form_order_name')}</label>
                            <input
                                type="text"
                                value={this.state.name}
                                className={'form-control'}
                                onChange={e=>this.handleSimpleChange(e.target.value,'name')}
                            />
                        </div>
                        <div className="col-4">
                            <label>{this.props.t('form_overcharge_default')} [%]</label>
                            <input
                                type="text"
                                value={this.props.overcharge}
                                disabled={true}
                                className={'form-control'}
                            />
                        </div>
                        <div className="col-2">
                            <label>{this.props.t('form_overcharge_total')}</label>
                            <div className="progress">
                                <div role="progressbar"
                                     className={"progress-bar " + (this.getTotalOvercharge() >= 0 ? '' : 'bg-danger')}
                                     aria-valuenow={this.getTotalOvercharge()}
                                     aria-valuemin="0"
                                     aria-valuemax="100"
                                     style={{width: (this.getTotalOvercharge() >= 0 ? this.getTotalOvercharge() + '%' : '52%')}}> {this.getTotalOvercharge()}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <label>{this.props.t('budget')} [&euro;]</label>
                            <input
                                type="number"
                                value={this.state.budget}
                                className={'form-control'}
                                onChange={e=>this.handleSimpleChange(e.target.value,'budget')}
                            />
                        </div>
                        <div className="col-4">
                            <label>{this.props .t('category')}</label>
                            <Select
                                value={this.props.categories.find(x=>x.id === this.state.selectedCategory)}
                                options={this.props.categories}
                                onChange={(selected) => {
                                    this.setState({selectedCategory: selected.id},() => {
                                        this.props.applyChanges(this.getOrderInformation())
                                    });
                                }}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isDisabled={!this.state.new_client}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <label>{this.props.t('state')}</label>
                            <Select
                                value={this.props.order_states.find(x=>x.id === this.state.selectedOrderState)}
                                options={this.props.order_states}
                                onChange={(selected) => {
                                    this.setState({selectedOrderState: selected.id},() => {
                                        this.props.applyChanges(this.getOrderInformation())
                                    });
                                }}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                            />
                            <br/>
                            <label>
                                {this.props.t('new_client')}
                            </label><br/>
                            <input
                                type="checkbox"
                                onChange={(e)=>{
                                    let client = INITIAL_STATE.client;
                                    this.setState({new_client: e.target.checked, client, selectedClient: null, selectedAM: null, selectedHunter: null, selectedCategory: 4},() => {
                                        this.props.applyChanges(this.getOrderInformation())
                                    })
                                }}
                                checked={this.state.new_client}
                            />
                        </div>
                        <div className="col-4">
                            <label>{this.props.t('form_overcharge_change')} [%]</label>
                            <input
                                type="number"
                                value={this.state.overcharge_change}
                                className={'form-control'}
                                onChange={e=>this.handleSimpleChange(e.target.value,'overcharge_change')}
                            />
                        </div>
                        <div className="col-2">
                            <label>{this.props.t('discount')} [&euro;] TODO</label>
                            <input
                                type="number"
                                value={this.state.discount}
                                className={'form-control'}
                                onChange={e=>this.handleSimpleChange(e.target.value,'discount')}
                            />
                            <br/>
                            <label>
                                {this.props.t('hide_discount')}
                            </label>
                            <br/>
                            <input
                                type="checkbox"
                                onChange={(e)=>{
                                    this.setState({hide_discount: e.target.checked},() => {
                                        this.props.applyChanges(this.getOrderInformation())
                                    })
                                }}
                                checked={this.state.hide_discount}
                            />
                        </div>
                    </div>
                    {this.renderClients()}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(QuoteInformationForm);
