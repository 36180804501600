import axios from "axios";
import Constants from "../constants/Constants";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'
import AuthHelper from "../helpers/AuthHelper";

export default {
  get(url, success, failure, params) {
    return this.request({
      method: "get",
      url,
      params,
      success,
      failure
    });
  },
  post(url, data, success, failure) {
    return this.request({
      method: "post",
      url,
      data,
      success,
      failure
    });
  },
  put(url, data, success, failure) {
    return this.request({
      method: "put",
      url,
      data,
      success,
      failure
    });
  },
  delete(url, success, failure) {
    return this.request({
      method: "delete",
      url,
      success,
      failure
    });
  },

  request: (options = {}) => {
    if (!options.url) {
      console.log("URL is required");
      return;
    }
    let lang = 'en';
    if(Cookies.get('lang') !== undefined)
    {
      lang = Cookies.get('lang');
    }

    options.url += `?lang=${lang}`;
    if(options.method === 'get')
    {
      console.log('test');
    }

    let data = Object.assign(
      {
        method: "get",
        baseURL: Constants.BASE_URL_API
      },
      options
    );

    if(data.params == undefined){
      data.params = {};
    }

    const user = AuthHelper.getUser();
    if(user !== null)
    {
      data.params = Object.assign(data.params, {api_token: user.api_token});
    }

    //data.params = Object.assign(data.params, lang);

    return new Promise((resolve, reject) => {
      axios(data)
        .then(response => {
          if (options.success) {
            options.success(response.data);
          }
          resolve(response.data);
        })

        .catch(error => {
          let statusCode = error.response.status;

          if(statusCode === 401)
          {
            AuthHelper.deleteUser();
            window.location.replace('/login');
            return;
          }

          if(error.response)
          {
            let errorMessages = error.response.data.errors;
            let strings = '';
            for (var key in errorMessages) {
              if (errorMessages.hasOwnProperty(key)) {
                strings += errorMessages[key] + '<br/>';
              }
            }

            if(strings.length === 0 && error.response.data.error)
            {
              strings = error.response.data.error;
            }

            Swal.fire('Error!', strings, 'error');
          }


          if (options.failure) {
            options.failure(error);
          }
          reject(error);
        });
    });
  }
};
