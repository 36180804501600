import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import Languages from "../../constants/Languages";
import './CalculatorHelper.css';

class CalculatorHelperItemInnerEdit extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        categoryId: null,
        itemId: null,
        id: null,
        material: 0,
        work: 0
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        this.load.bind(this)();
    }

    load() {
        this.setState({isLoading: true});
        const categoryId = this.props.match.params.categoryId;
        const itemId = this.props.match.params.itemId;
        const id = this.props.match.params.id;

        NetworkClient.get(`calculator-helper-item-inner-edit/${categoryId}/${itemId}/${id}`)
            .then((function(response) {
                this.setState({isLoading: false, material: response.inner.material, work: response.inner.work});
            }).bind(this))
    }

    update() {
        const categoryId = this.props.match.params.categoryId;
        const itemId = this.props.match.params.itemId;
        const id = this.props.match.params.id;
        NetworkClient.post(`calculator-helper-item-inner-edit/${categoryId}/${itemId}/${id}`,{material: this.state.material, work: this.state.work})
            .then(response => {
                if(response.success === true)
                {
                    Swal.fire('Ok!', '', 'success')
                        .then(c=>{

                        })
                }
            })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('calculator_helper')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className="col-6">
                                <div className="card card-primary p-5">
                                    <div className="form-group">
                                        <label>{t('material')}</label>
                                        <input
                                            className={"form-control"} type="number"
                                            value={this.state.material}
                                            onChange={e => {
                                                let val = e.target.value;
                                                if(val > 0)
                                                {
                                                    this.setState({material: val})
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>{t('work')}</label>
                                        <input
                                            className={"form-control"} type="number"
                                            value={this.state.work}
                                            onChange={e => {
                                                let val = e.target.value;
                                                if(val > 0)
                                                {
                                                    this.setState({work: val})
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button onClick={this.update.bind(this)} className={"btn btn-info btn-sm"}>
                                            {t('update')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CalculatorHelperItemInnerEdit);
