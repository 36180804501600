import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import bg from './translations/bg';
import en from './translations/en';
import de from './translations/de';
import fr from './translations/fr';
import us from './translations/us';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en
      },
      bg: {
        translation: bg
      },
      de: {
          translation: de
      },
      fr: {
          translation: fr
      },
      us: {
          translation: us
      }
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
