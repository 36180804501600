import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Select from "react-select";
import EmbossingFormPartial from "../partial/EmbossingFormPartial";
import EmbossingFoilFormPartial from "../partial/EmbossingFoilFormPartial";

class BanderoleForm extends Component {
    state = {
        isLoading: false,
        is_enabled: false,
        papers: [],
        densities: [],
        colors: [],
        selectedPaperNameId: -1,
        selectedDensity: -1,
        selectedColorId: -1,
        selectedPrint: -1,
        selectedFinishing: -1,
        groups: [],
        selectedGroup: 'normal',
        embossing: null,
        embossing_foil: null,
        properties: [],
        isOpen: true,
        selektiven_lak: false,
        obemen_lak: false,
        height: 0,
        heightFocus: 0,
        heightInches: 0
    }

    constructor(props) {
        super(props);
    }

    printClientPaper(){
        let properties = this.props.element.properties;
        properties = properties.filter(x => x.id === 65);

        if(properties.length === 0)
        {
            return <></>
        }

        return properties.map(p => <div className={'col-12 mt-3'}>
            <input
                checked={this.state.properties.includes(p.id)}
                onChange={e => {
                    let properties = this.state.properties;

                    if(e.target.checked === true)
                    {
                        properties.push(p.id);
                    }

                    if(e.target.checked === false)
                    {
                        let index = properties.findIndex(i => i.id === p.id);
                        properties.splice(index, 1);
                    }

                    this.setState({properties}, () => {
                        this.props.applyChanges(this.getBanderole());
                    })
                }}
                type="checkbox"/> {p.name}
        </div>)
    }

    printProperties() {
        let props = this.props.element.properties.filter(x=>x.id !== 65).map(p => <div className={'mt-3'}>
            <input
                checked={this.state.properties.includes(p.id)}
                onChange={e => {
                    let properties = this.state.properties;

                    if(e.target.checked === true)
                    {
                        properties.push(p.id);
                    }

                    if(e.target.checked === false)
                    {
                        let index = properties.findIndex(i => i.id === p.id);
                        properties.splice(index, 1);
                    }

                    this.setState({properties}, () => {
                        this.props.applyChanges(this.getBanderole());
                    })
                }}
                type="checkbox"/> {p.name}
        </div>)

        props.push(<>
            <div className="mt-3">
                <input
                    type="checkbox"
                    checked={this.state.selektiven_lak}
                    onChange={(e)=>{
                        this.setState({selektiven_lak: e.target.checked}, () =>{
                            this.props.applyChanges(this.getBanderole());
                        })
                    }}
                /> {this.props.t('selektiven_lak')}
            </div>
            <div className="mt-3">
                <input
                    type="checkbox"
                    checked={this.state.obemen_lak}
                    onChange={(e)=>{
                        this.setState({obemen_lak: e.target.checked},()=>{
                            this.props.applyChanges(this.getBanderole());
                        })
                    }}
                /> {this.props.t('obemen_lak')}(TODO: не смята още)
            </div>
        </>)

        return props;
    }

    componentWillReceiveProps(props) {
        if(props.initial_state !== null && props.initial_state !== undefined && !this.state.is_init && this.state.papers.length > 0)
        {
            let initial_state = props.initial_state;
            this.setState({is_init: true}, () => {
                this.selectPaper(initial_state.paper_name_id, () => {
                    this.selectColor(initial_state.paper_color_id);
                    this.setState({
                        cover_pantones_info: initial_state.cover_pantones_info,
                        selectedDensity: initial_state.density,
                        embossing: initial_state.embossing,
                        embossing_foil: initial_state.embossing_foil,
                        selectedFinishing: initial_state.finishing,
                        num_pages: initial_state.num_pages,
                        pantone: initial_state.pantone,
                        selectedPrint: initial_state.print,
                        properties: initial_state.properties,
                        nadpechaten_lak: initial_state.nadpechaten_lak,
                        is_enabled: initial_state.is_enabled,
                        obemen_lak: initial_state.obemen_lak,
                        selektiven_lak: initial_state.selektiven_lak,
                        height: initial_state.height
                    });
                });
            });
        }
    }

    componentDidMount() {
        let propsPaper = this.props.papers.slice();
        let availablePapers = [];
        let groups = [];

        for (let i = 0; i < propsPaper.length; i++)
        {
            let hasPaper = false;
            let kind = '';
            for (let j = 0; j < propsPaper[i]['groups'].length;j++)
            {
                if(propsPaper[i]['groups'][j].component === 'banderole')
                {
                    hasPaper = true;
                    kind = propsPaper[i]['groups'][j].group === 'normal' ? 'normal' : 'special';
                    break;
                }
            }

            if(hasPaper)
            {
                let copy = Object.assign({},propsPaper[i]);
                copy.group = kind;
                availablePapers.push(copy);
                if(!groups.includes(kind))
                {
                    groups.push(kind);
                }
            }
        }

        let selectedGroup = 'normal';
        if(groups.length === 1)
        {
            selectedGroup = groups[0];
        }

        groups.sort();

        let selectedPrint = this.props.element.print[0].id;
        let selectedFinishing = -1;

        if(this.props.element.finishing.length > 0)
        {
            selectedFinishing = this.props.element.finishing[0].id;
        }


        this.setState({papers: availablePapers, groups, selectedPrint, selectedFinishing, selectedGroup }, () => {
            let index = 0;
            if(groups.length === 2)
            {
                //TODO: Трябва да се добави логика за избрана хартия
                index = availablePapers.findIndex(p=>p.group === 'normal');
            }

            this.selectPaper(availablePapers[index].paper_name_id);
        })
    }

    getBanderole(trigger = true) {
        return {
            paper_name_id: this.state.selectedPaperNameId,
            paper_color_id: this.state.selectedColorId,
            density: this.state.selectedDensity,
            is_enabled: this.state.is_enabled,
            print: this.state.selectedPrint,
            finishing: this.state.selectedFinishing,
            embossing: this.state.embossing,
            embossing_foil: this.state.embossing_foil,
            properties: this.state.properties,
            selektiven_lak: this.state.selektiven_lak,
            obemen_lak: this.state.obemen_lak,
            height: this.state.height,
            trigger: trigger
        };
    }

    selectPaper(paperNameId, callback = null) {
        let pap = this.state.papers.find(x => x.paper_name_id  === paperNameId);
        let colors = pap.colors;

        let densities = pap.colors[0].densities.filter(x=> x.components.includes('banderole'));
        this.setState({
            densities,
            colors,
            selectedPaperNameId: paperNameId,
            selectedDensity: densities[0].name,
            selectedColorId: colors[0].id,
            selectedGroup: pap.group
        },()=>{
            this.props.applyChanges(this.getBanderole());
            if(callback !== null)
            {
                callback();
            }
        })
    }

    selectColor(colorNameId) {
        let color = this.state.colors.find(c => c.id === colorNameId);
        let densities = color.densities.filter(x=> x.components.includes('banderole'));
        this.setState({densities: densities, selectedDensity: densities[0].name, selectedColorId: colorNameId}, () => {
            this.props.applyChanges(this.getBanderole());
        })
    }

    printPaper() {
        return (<>
            <div className="col-4">
                <label>{this.props.t('paper')}</label>
                <div className={'' + (this.state.groups.length === 1 ? ' d-none' : '')}>
                    {this.state.groups.map( g => <>
                        <input
                            type={'radio'}
                            checked={this.state.selectedGroup === g}
                            onClick={ e => {
                                let pap = this.state.papers.filter(p => p.group === g);
                                this.selectPaper(pap[0].paper_name_id);
                                this.setState({selectedGroup: g});
                            }}
                        /> {this.props.t(g)} &nbsp;
                    </>)}
                </div>
                <Select
                    value={this.state.papers.find(x => x.paper_name_id  === this.state.selectedPaperNameId)}
                    onChange={(selected)=>{
                        this.selectPaper(selected.paper_name_id);
                    }}
                    options={this.state.papers.filter(p => p.group === this.state.selectedGroup)}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.paper_name_id}
                />
            </div>
            <div className="col-4">
                {this.state.groups.length !== 1 && <br/>}
                <label>{this.props.t('colors')}</label>
                <Select
                    value={this.state.colors.find(d => d.id === this.state.selectedColorId)}
                    onChange={(selected) => {
                        this.selectColor(selected.id)
                    }}
                    options={this.state.colors}
                    getOptionLabel={(option)=> option.paper_color_name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-4">
                {this.state.groups.length !== 1 && <br/>}
                <label>{this.props.t('density')}</label>
                <Select
                    value={this.state.densities.find( d => d.name === this.state.selectedDensity)}
                    onChange={(selected) => {
                        this.setState({selectedDensity: selected.name}, () => {
                            this.props.applyChanges(this.getBanderole());
                        })
                    }}
                    options={this.state.densities}
                    getOptionLabel={(option)=> option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            {this.printClientPaper()}
        </>)
    }

    handleEmbossingChange(embossing) {
        this.setState({embossing}, () => {
            this.props.applyChanges(this.getBanderole());
        })
    }

    handleEmbossingFoilChange(embossing_foil)
    {
        this.setState({embossing_foil}, () => {
            this.props.applyChanges(this.getBanderole());
        })
    }

    embossings() {
        return (<>
            <div className={'col-5 mr-3'}>
                <EmbossingFormPartial
                    applyChanges={embossing => this.handleEmbossingChange(embossing)}
                    t={this.props.t}
                    measure={this.props.measure}
                    initial_state={this.state.embossing}
                />
            </div>
            <div className={'col-6'}>
                <EmbossingFoilFormPartial
                    t={this.props.t}
                    applyChanges={embossing_foil => this.handleEmbossingFoilChange(embossing_foil)}
                    measure={this.props.measure}
                    initial_state={this.state.embossing_foil}
                />
            </div>
        </>)
    }

    getMinimizeIcon() {
        if(this.state.isOpen)
        {
            return (<i className="fa fa-window-minimize" aria-hidden="true" />)
        }

        return (<i className="fa fa-plus" aria-hidden="true" />)
    }

    render() {
        const {t} = this.props;
        return (
            <div className={'card mt-3'}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h3>
                                <div>
                                    <i className={'fa fa-book'} /> {t('banderole')}
                                    <button onClick={()=>{
                                        this.setState({isOpen: !this.state.isOpen});
                                    }} className={'btn btn-sm btn-info float-right'}>
                                        {this.getMinimizeIcon()}
                                    </button>
                                </div>
                            </h3>
                        </div>
                    </div>
                <div>
                    <input
                        type="checkbox"
                        checked={this.state.is_enabled}
                        onChange={e => this.setState({is_enabled: e.target.checked},()=>{this.props.applyChanges(this.getBanderole());})}
                    /> {t('banderole')}
                </div>
                {this.state.is_enabled && <div className={'collapse ' + (this.state.isOpen ? 'show' : '')}>
                    <div className={'row'}>
                        <div className="col-12 mt-3">
                            <label>{this.props.t('height')} {this.props.measure}</label>
                            <input
                                type="number" min={0}
                                className={"form-control" + (this.props.measure === 'inches' ? '' : ' d-none')}
                                value={this.state.heightInches}
                                onChange={e => {
                                    if(e.target.value >= 0)
                                    {
                                        let inches = parseInt(e.target.value);
                                        let mm = Math.ceil(inches * 25.4);
                                        this.setState({height: mm, heightInches: inches},()=>{this.props.applyChanges(this.getBanderole(false));});
                                    }
                                }}
                                onFocus={e => {
                                    this.setState({heightFocus: e.target.value})
                                }}
                                onBlur={e => {
                                    if(e.target.value !== this.state.heightFocus)
                                    {
                                        this.props.applyChanges(this.getBanderole());
                                    }
                                }}
                            />
                            <input
                                type="number" min={0}
                                className={"form-control" + (this.props.measure === 'mm' ? '' : ' d-none')}
                                value={this.state.height}
                                onChange={e => {
                                    if(e.target.value >= 0)
                                    {
                                        let inches = parseFloat(e.target.value * 0.0393700787).toFixed(2);
                                        this.setState({height: parseInt(e.target.value), heightInches: inches},()=>{this.props.applyChanges(this.getBanderole(false));});
                                    }
                                }}
                                onFocus={e => {
                                    this.setState({heightFocus: e.target.value})
                                }}
                                onBlur={e => {
                                    if(e.target.value !== this.state.heightFocus)
                                    {
                                        this.props.applyChanges(this.getBanderole());
                                    }
                                }}
                            />
                        </div>
                        <div className="col-4 mt-3">
                            <label>{t('print')}</label>
                            <Select
                                value={this.props.element.print.find(c => c.id === this.state.selectedPrint)}
                                onChange={(selected)=>{
                                    this.setState({selectedPrint: selected.id},()=>{
                                        this.props.applyChanges(this.getBanderole());
                                    })
                                }}
                                options={this.props.element.print}
                                getOptionLabel={(option)=> option.name}
                                getOptionValue={(option) => option.id}
                            />
                        </div>
                        {this.props.element.finishing.length > 0 &&
                        <div className="col-4 mt-3">
                            <label>{t('finishing')}</label>
                            <Select
                                value={this.props.element.finishing.find(c => c.id === this.state.selectedFinishing)}
                                onChange={(selected) => {
                                    this.setState({selectedFinishing: selected.id}, () => {
                                        this.props.applyChanges(this.getBanderole());
                                    });
                                }}
                                options={this.props.element.finishing}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                            />
                        </div>
                        }
                        <div className="col-4 mt-3">
                            {this.printProperties()}
                        </div>
                    </div>
                    <div className="row">
                        {this.printPaper()}
                    </div>
                    <div className="row mt-3">
                        {this.embossings()}
                    </div>
                </div>}
                </div>
            </div>
        );
    }
}

export default withTranslation()(BanderoleForm);
