import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import Languages from "../../constants/Languages";
import Select  from "react-select"
import SimpleFieldsGenerator from "../../helpers/SimpleFieldsGenerator";

class UserForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        websites: [],
        roles: [],
        user: {
            id: null,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            gender: '',
            language: '',
            password: '',
            roles: [],
            websites: []
        }
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    setMultiSelect(){

    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if(id !== undefined)
        {
            this.load(id);
        }
        else
        {
            this.loadWebAndRoles();
        }
    }

    loadWebAndRoles() {
        this.setState({isLoading: true}, () => {
            NetworkClient.get('users/get-websites-roles')
                .then(response => {
                    this.setState({
                        websites: response.webs,
                        roles: response.roles,
                        isLoading: false
                    });
                })
        })
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`users/edit/${id}`)
                .then(response => {
                    const user = response.user;
                    const websites = response.webs;
                    const roles = response.roles;
                    this.setState({roles, websites, user});
                }).finally(c => {
                this.setState({ isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {user} = this.state;
        let url = 'users';
        if(user.id !== null)
        {
            url += '/' + user.id;
        }

        NetworkClient.post(url, user)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c=>{
                        this.props.history.push('/users');
                    })
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    render() {

        const {t} = this.props;
        let languagesForSelect = this.state.languages.map((item, index) => ({id:index,name: item}) );
        let defaultValue = {id: -1, name: this.state.user.language.toString()};
        let gender = [ { id:0, gender: t('mr')}, {id: 1, gender: t('mrs') } ];
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('create')} {t('user')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {SimpleFieldsGenerator.bind(this,t,[{name:'first_name', type: 'text'}], "user")()}
                                    {SimpleFieldsGenerator.bind(this,t,[{name:'last_name', type: 'text'}], "user")()}
                                    {SimpleFieldsGenerator.bind(this,t,[{name:'email', type: 'text'}], "user")()}
                                    {SimpleFieldsGenerator.bind(this,t,[{name:'phone', type: 'text'}], "user")()}
                                    <div className="form-group">
                                        <label htmlFor="gander">{t('mr')} / {t('mrs')}</label>
                                        <Select
                                            value={gender.find(g => g.id === this.state.user.gender)}
                                            options={gender}
                                            getOptionLabel={(option)=> option.gender}
                                            getOptionValue={(option) => option.id}
                                            onChange={(selected)=>{
                                                let {user} = this.state;
                                                user.gender = selected.id;
                                                this.setState({user: user});
                                            }}
                                        />
                                    </div>

                                   <div className="form-group">
                                       <label htmlFor="language">{t('language')}</label>
                                       <Select
                                           value={languagesForSelect.find(c => (c.name !== undefined && c.name === this.state.user.language) ||  c == this.state.user.language)}
                                           onChange={(selected)=>{
                                               let {user} = this.state;
                                               //console.log(this.state.languages.find(c => c === this.state.website.default_locale));
                                               user.language = selected.name;
                                               this.setState({user: user});
                                           }}

                                           options={languagesForSelect}
                                           getOptionLabel={(option)=> option.name}
                                           getOptionValue={(option) => option.id}
                                       />
                                   </div>

                                    {SimpleFieldsGenerator.bind(this,t,[{name:'password', type: 'text'}], "user")()}
                                    {SimpleFieldsGenerator.bind(this,t,[{name:'password_confirm', type: 'text'}], "user")()}

                                    <div className="form-group">
                                        <label htmlFor="websites">{t('roles')}</label>
                                        <Select
                                            isMulti={true}
                                            // value={this.state.roles.find(r => r.id === this.state.user.roles)}
                                            value={this.state.user.roles }
                                            options={this.state.roles}
                                            onChange={(selected) => {

                                                let {user} = this.state;
                                                user.roles = selected;
                                                this.setState({user:user});
                                            }}
                                            getOptionLabel={(option)=> option.name}
                                            getOptionValue={(option) => option.id}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="websites">{t('websites')}</label>
                                        <Select
                                            isMulti={true}
                                            value={this.state.user.websites}
                                            options={this.state.websites}
                                            onChange={(selected) => {
                                                let {user} = this.state;
                                                user.websites = selected;
                                                this.setState({user:user});
                                            }}
                                            getOptionLabel={(option)=> option.name}
                                            getOptionValue={(option) => option.id}
                                        />
                                    </div>
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.user.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(UserForm);