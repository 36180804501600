import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Languages from "../../../constants/Languages";

class PaperSuppliersForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        supplier: {
            id: null,
            name: ''
        }
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id !== undefined) {
            this.load(id);
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`papers/suppliers/${id}`)
                .then(response => {
                    const supplier = response.supplier;
                    this.setState({supplier});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {supplier} = this.state;
        let url = 'papers/suppliers';
        if (supplier.id !== null) {
            url += '/' + supplier.id;
        }

        NetworkClient.post(url, supplier)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        this.props.history.push('/papers/suppliers');
                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('paper')} {t('supplier')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label>{t('name')}</label>
                                        <input
                                            type="text" className={"form-control"}
                                            placeholder={t('name')} value={this.state.supplier.name}
                                            onChange={(e) => {
                                                let {supplier} = this.state;
                                                supplier.name = e.target.value;
                                                this.setState({supplier});
                                            }}
                                        />
                                    </div>
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.supplier.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PaperSuppliersForm);
