import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../../../helpers/DataTableInitDataGenerator';
import NetworkClient from '../../../../api/NetworkClient';
import Loader from '../../../../partial/Loader';

class BelgiumList extends Component {
    state = {
        isLoading: false,
        data: {
            columns: [],
            rows: []
        }
    };

    constructor(props) {
        super(props);
    }
    componentWillReceiveProps() {
        this.componentDidMount();
    }

    componentDidMount() {
        const {t} = this.props;
        let listColumns = ['id', 'group', 'paper', 'from', 'to', 'overcharge', 'k1', 'k2', 'k3', 'k4', 'k5', 'enabled', 'actions'];
        this.setState({data: DataTableInitDataGenerator(listColumns, t)});
        this.load();
    }

    load() {
        this.setState({isLoading: true});
        NetworkClient.get('transport/be/overcharges').then(response => {
            let {data} = this.state;
            let items = response.overcharges;
            let rows = [];
            items.forEach((item, i) => {
                let tmp = {
                    id: item.id,
                    group: item.group,
                    paper: item.paper,
                    from: item.from,
                    to: item.to,
                    overcharge: item.overcharge,
                    k1: item.k1,
                    k2: item.k2,
                    k3: item.k3,
                    k4: item.k4,
                    k5: item.k5,
                    enabled: item.enabled === 1 ? <span className='fa fa-check'></span> : '',
                    actions: <div>
                        <Link to={`/transport/belgium/edit/${item.id}`} className={"btn btn-info btn-sm"}>
                            <i className={"fa fa-pen"} />
                        </Link>
                    </div>
                };
                rows.push(tmp);
            });


            this.setState({data:{columns: data.columns, rows: rows}, isLoading: false});
        })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    <h5> {t('BE Transport-V2 Overcharges-title')}</h5>
                    <h5> {t('Formula: (k1 + (k2 * (k3 + weight))) * (1 + overcharge / 100) * k4{weight}')}</h5>
                    <p> {t('* K4 (0 or 1). When K4 is 0, K4 = weight. K5 is paper type index (depends on paper type and weight)')}</p>
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                           
                                    <div className="card-body">
                                        <MDBDataTable
                                            striped
                                            bordered
                                            hover
                                            entries={10}
                                            data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(BelgiumList);