import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import DataTableInitDataGenerator from '../../../helpers/DataTableInitDataGenerator';
import SimpleFieldsWithColumnsGenerator from '../../../helpers/SimpleFieldsWithColumnsGenerator';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import SwalAskBeforeAction from '../../../helpers/SwalAskBeforeAction';
import Moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AuthHelper from "../../../helpers/AuthHelper";

class ClientsList extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState;
    }

    get initialState() {
        const {t} = this.props;
        let listColumns = ['id','name','last_name','email','phone','company_name','max_insurance','website','actions'];
        let userRoles = AuthHelper.getRoles();
        if(userRoles.findIndex(x => x.name === 'sales-agent') >= 0)
        {
            //sales-agent
            listColumns = ['id','name','last_name','email','phone','company_name','website','actions'];
        }

        return {
            isLoading: false,
            data: DataTableInitDataGenerator(listColumns, t),
            filters: {
                am_id: -1,
                hunter_id: -1,
                company_name: '',
                trademark: '',
                bulstat: '',
                fin_intra_community_vat: '',
                name: '',
                last_name: '',
                phone: '',
                mobile_phone: '',
                email: '',
                email_second: '',
                city: '',
                country: ''
            },
            ams: [],
            hunters: [],
            isOpenSuspensionModal: false,
            modal_client_id: -1,
            suspended_remark: '',
            is_delete: false,
            response: []
        };
    }

    componentDidMount() {
        this.load();
    }

    printIdColumn(item) {
        if(item.suspended_at === null && item.deleted_at === null) {
            return <div>{item.id}</div>
        }

        if(item.suspended_at !== null) {
            return <div className={"badge badge-warning"}>
                <span data-tip={`User is suspended at: ${item.suspended_at} from 
                ${item.suspended_by !== null ? (item.suspended_by.first_name + ' ' + item.suspended_by.last_name) : ''}, Remarks: ${item.remarks_suspended}`}>
                    {item.id}
                </span>
                <ReactTooltip place={"right"}/>
            </div>
        }

        return <div className={"badge badge-danger"}>
                <span data-tip={`User is deleted at: ${item.deleted_at} from 
                ${item.deleted_by !== null ? (item.deleted_by.first_name + ' ' + item.deleted_by.last_name) : ''}, Remarks: ${item.remarks_deleted}`}>
                    {item.id}
                </span>
            <ReactTooltip place={"right"}/>
        </div>
    }

    fillDataTables(response) {
        let {t} = this.props;
        let {data} = this.state;
        let hunters = response.hunters;
        let ams = response.ams;
        let items = response.clients;
        let rows = [];
        items.forEach((item, i) => {
            let tmp = {
                id: this.printIdColumn(item),
                id_num:item.id,
                name: item.name,
                last_name: item.last_name,
                email: item.email,
                phone: item.phone,
                company_name: item.organization !== null ? item.organization.name : '',
                max_insurance: item.max_insurance,
                website: item.website !== null ? item.website.name : '',
                actions: <div>
                    <Link to={`/all-clients/edit/${item.id}`} className={"btn btn-info btn-sm"}>
                        <i className={"fa fa-pen"}/>
                    </Link>
                    <button className={"btn btn-info btn-sm"} onClick={
                        ()=>{
                            this.setState({is_delete: false}, () => {
                                this.toggleModal(item.id)
                            })
                        }
                    }>{t('suspend')}</button>
                    <button className={"btn btn-info btn-sm"} onClick={()=>{
                        SwalAskBeforeAction(t).then(r=>{
                            if(r.value)
                            {
                                NetworkClient.post('all-clients/restore-user/' + item.id)
                                    .then(response => {
                                        let clientsFromResponse  = this.state.response;
                                        clientsFromResponse.clients[i].suspended_at = null;
                                        clientsFromResponse.clients[i].deleted_at = null;
                                        this.fillDataTables(clientsFromResponse);
                                        Swal.fire('Ok!', '', 'success');
                                    })
                            }
                        })
                    }}>{t('restore')}</button>
                    <button onClick={() => {
                        this.setState({is_delete: true}, () => {
                            this.toggleModal(item.id)
                        })
                    }} className={"btn btn-danger btn-sm"}>
                        <i className={"fa fa-times"}/>
                    </button>
                </div>
            };
            rows.push(tmp);
        });

        this.setState({data: {columns: data.columns, rows: rows}, isLoading: false, hunters, ams, response});
    }

    load() {
        this.setState({isLoading: true});
        NetworkClient.get('all-clients')
            .then(this.fillDataTables.bind(this))
    }

    loadWithFilters() {
        this.setState({isLoading: true});
        let {filters} = this.state;
        NetworkClient.post('all-clients', filters)
            .then(this.fillDataTables.bind(this))
    }

    reset() {
        this.setState(this.initialState, () => {
            this.load();
        });
    }

    toggleModal(id) {
        this.setState({modal_client_id: id, suspended_remark: ''}, () => {
            this.setState({isOpenSuspensionModal: !this.state.isOpenSuspensionModal});
        })
    }

    submitRemarks() {
        let {t} = this.props;
        let userId = this.state.modal_client_id;
        let remarks = this.state.suspended_remark;
        let isDelete = this.state.is_delete;

        let url = 'all-clients/suspend-user/' + userId;
        if(isDelete)
        {
            url = 'all-clients/delete-user/' + userId;
        }

        this.setState({isLoading: true}, ()=> {
            NetworkClient.post(url,{remarks: remarks})
                .then(response => {
                    let clientsFromResponse  = this.state.response;
                    let i = this.state.response.clients.findIndex(r => r.id === userId);
                    //TODO: Deleted and suspended by should be login name
                    if(!isDelete)
                    {
                        clientsFromResponse.clients[i].suspended_at = Moment().format("DD-MM-YYYY hh:mm:ss")
                        clientsFromResponse.clients[i].remarks_suspended = remarks;
                        clientsFromResponse.clients[i].suspended_by = {first_name: 'Me', last_name: ''};
                    }
                    else
                    {
                        clientsFromResponse.clients[i].deleted_at = Moment().format("DD-MM-YYYY hh:mm:ss")
                        clientsFromResponse.clients[i].remarks_deleted = remarks;
                        clientsFromResponse.clients[i].deleted_by = {first_name: 'Me', last_name: ''};
                    }

                    this.fillDataTables(clientsFromResponse);
                    this.setState({isLoading: false, modal_client_id: -1, suspended_remark: '', isOpenSuspensionModal: false})
                    Swal.fire('Ok!', '', 'success');
                })
        })
    }

    render() {
        const {t} = this.props;
        const filterColumns = [
            [
                {name: 'id', type: 'text', col: 2},
                {name: 'company_name', type: 'text', col: 2},
                {name: 'trademark', type: 'text', col: 2},
                {name: 'bulstat', type: 'text', col: 2},
                {name: 'fin_intra_community_vat', type: 'text', col: 2}
            ],
            [
                {name: 'name', type: 'text', col: 2},
                {name: 'last_name', type: 'text', col: 2},
                {name: 'phone', type: 'text', col: 2},
                {name: 'mobile_phone', type: 'text', col: 2},
                {name: 'email', type: 'text', col: 2},
                {name: 'email_second', type: 'text', col: 2},
            ],
            [
                {name: 'city', type: 'text', col: 2},
                {name: 'country', type: 'text', col: 2},
            ]
        ];

        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <div className="content-header">
                    {t('clients')}
                </div>
                <div>
                    <Modal isOpen={this.state.isOpenSuspensionModal}
                           toggle={this.toggleModal.bind(this, -1)}
                           backdrop={this.toggleModal.bind(this, -1)}>
                        <ModalHeader toggle={this.toggleModal.bind(this, -1)}>{t('remarks')}</ModalHeader>
                        <ModalBody>
                            <textarea value={this.state.suspended_remark}
                                      className={"form-control"}
                                      onChange={(e)=>{
                                          this.setState({suspended_remark: e.target.value})
                                      }}
                            ></textarea>
                        </ModalBody>
                        <ModalFooter>
                            <Button color={"info"} className={"btn btn-info btn-sm"} onClick={this.submitRemarks.bind(this)}>{t('submit')}</Button>
                            <Button color={"danger"} className={"btn btn-info btn-sm"} onClick={this.toggleModal.bind(this,  -1)}>{t('close')}</Button>
                        </ModalFooter>
                    </Modal>
                </div>
                <div className="content">
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            {SimpleFieldsWithColumnsGenerator.bind(this, t, filterColumns[0], 'filters')()}
                                        </div>
                                        <div className="row">
                                            {SimpleFieldsWithColumnsGenerator.bind(this, t, filterColumns[1], 'filters')()}
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            {SimpleFieldsWithColumnsGenerator.bind(this, t, filterColumns[2], 'filters')()}
                                            <div className="col-2 form-group">
                                                <label>{t('am')}</label>
                                                <Select
                                                    value={this.state.ams.find(i => i.id === this.state.filters.am_id)}
                                                    onChange={(selected)=>{
                                                        let {filters} = this.state;
                                                        filters.am_id = selected.id;
                                                        this.setState({filters});
                                                    }}
                                                    options={this.state.ams}
                                                    getOptionLabel={(option)=> option.first_name + ' ' + option.last_name}
                                                    getOptionValue={(option) => option.id}
                                                />
                                            </div>
                                            <div className="col-2 form-group">
                                                <label>{t('hunter')}</label>
                                                <Select
                                                    value={this.state.hunters.find(i => i.id === this.state.filters.hunter_id)}
                                                    onChange={(selected)=>{
                                                        let {filters} = this.state;
                                                        filters.hunter_id = selected.id;
                                                        this.setState({filters});
                                                    }}
                                                    options={this.state.ams}
                                                    getOptionLabel={(option)=> option.first_name + ' ' + option.last_name}
                                                    getOptionValue={(option) => option.id}
                                                />
                                            </div>
                                            <div className="col-1 d-flex align-items-center pt-2">
                                                <button onClick={this.loadWithFilters.bind(this)}
                                                        className={"btn btn-info btn-sm"} >{t('search')}</button>
                                            </div>
                                            <div className="col-1 d-flex align-items-center pt-2">
                                                <button onClick={this.reset.bind(this)}
                                                        className={"btn btn-info btn-sm"} >{t('reset')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-12"}>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <Link to={'/all-clients/create'} className={"btn btn-info btn-sm mb-3"}>
                                            {t('create')} <i className={"fa fa-plus"}/>
                                        </Link>
                                        <MDBDataTable
                                            striped
                                            bordered
                                            hover
                                            entries={15}
                                            entriesOptions={[15,50,100]}
                                            data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ClientsList);
