import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../../api/NetworkClient';
import Loader from '../../../../partial/Loader';
import Languages from "../../../../constants/Languages";
import SimpleFieldsGenerator from '../../../../helpers/SimpleFieldsGenerator';

class USAForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        settings: {
            id: null,
            regular_mitnica: null,
            regular_kg: null,
            regular_first_kg_price: null,
            regular_after_kg: null,
            regular_fix: null,
            regular_price_kg_walk: null,
            regular_minimal_value_land: null,
            express_first_kg_price: null,
            express_after_kg: null,
            express_kg: null,
            express_kg_each: null,
        }
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // const id = this.props.match.params.id;

        this.load();
        
    }

    load() {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`transport/usa`)
                .then(response => {
                    const settings = response.settings;
                    this.setState({settings});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {settings} = this.state;
        let url = 'transport/usa';

        NetworkClient.post(url, settings)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c=>{
                        // this.props.history.push('/link');
                    })
            })
            .finally(()=>{
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('transport')} - USA - {t('settings')}</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p>
                                 Стандартна = Такса митница (лв) + Цена за първия палет (лв) + (ако килограмите на пратката > Тегло на палет (кг), тогава изчисляваме колко допълнителни палета са необходими *
                                 <br></br> Цена за всеки следващ палет (лв)) + Фиксиран разход - сухопътен транспорт (лв) + килограмите на пратката * Цена за килограм - сухопътен транспорт (лв)
                                </p>
                                <p>
                                Експресна = Цена до X тегло (лв) + ( ако теглото на пратката е > от Тегло X (кг) - се изчислява колко пъти кратно на Y се събира закръглено до цяло число * Цена за следващите Y килограми (лв))
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <h1>Стандартна</h1>
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'regular_mitnica', type: 'number'}], "settings")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'regular_kg', type: 'number'}], "settings")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'regular_first_kg_price', type: 'number'}], "settings")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'regular_after_kg', type: 'number'}], "settings")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'regular_fix', type: 'number'}], "settings")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'regular_price_kg_walk', type: 'number'}], "settings")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'regular_minimal_value_land', type: 'number'}], "settings")()}
                                    <h1>Експресна</h1>
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'express_first_kg_price', type: 'number'}], "settings")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'express_after_kg', type: 'number'}], "settings")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'express_kg', type: 'number'}], "settings")()}
                                    {SimpleFieldsGenerator.bind(this, t, [{name: 'express_kg_each', type: 'number'}], "settings")()}
                                    
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.settings.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(USAForm);
