import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Select from "react-select";

class ProductPropertiesQForm extends Component {
    container = null;
    state = {
        isLoading: false,
        length: 210,
        height: 297,
        lengthInches: 0,
        heightInches: 0,
        //tirage: 1000,
        packaging: -1,
        lace: {
            is_enabled: false,
            selected: -1
        },
        head: {
            is_enabled: false,
            selected: -1
        },
        bookmark: {
            is_enabled: false,
            selected: 1
        },
        isCustomSize: false,
        isOpen: true,
        measure: 'mm',
        folding: '',
        selectedProperties: [],
        comment: '',
        binding: '',
        spine_type: 1,
        is_inited: false
    }

    constructor(props) {
        super(props);
        this.props.applyChanges(this.getProductProperties(false))
    }

    componentWillReceiveProps(props) {
        let bindings = this.props.bindingsSelected;
        if(bindings.length > 0 && this.state.binding === '')
        {
            this.setState({binding: bindings[0].id},() => {
                this.props.applyChanges(this.getProductProperties(false))
            })
        }

        let foldings = this.props.foldingsSelected;
        if(foldings.length > 0 && this.state.folding === '')
        {
            this.setState({folding: foldings[0].id},() => {
                this.props.applyChanges(this.getProductProperties(false))
            })
        }

        if(!this.state.is_inited && props.initial_state !== null)
        {
            let initial_state = {...props.initial_state};
            this.setState({
                length: initial_state.length,
                height: initial_state.height,
                binding: initial_state.binding,
                packaging: initial_state.packaging,
                lace: initial_state.lace,
                head: initial_state.head,
                bookmark: initial_state.bookmark,
                selectedProperties: initial_state.selectedProperties,
                folding: initial_state.folding,
                measure: initial_state.measure,
                comment: initial_state.comment,
                is_inited: true,
                spine_type: initial_state.spine_type
            })
        }
    }

    componentDidMount() {

    }

    getProductProperties(trigger = true) {
        return {
            length: this.state.length,
            height: this.state.height,
            binding: this.state.binding,
            packaging: this.state.packaging,
            lace: this.state.lace,
            head: this.state.head,
            bookmark: this.state.bookmark,
            measure: this.state.measure,
            folding: this.state.folding,
            selectedProperties: this.state.selectedProperties,
            comment: this.state.comment,
            trigger: trigger,
            spine_type: this.state.spine_type
        }
    }

    packagingHandleChange(val) {
        this.setState({packaging: val}, () => {
            this.props.applyChanges(this.getProductProperties());
        })
    }

    renderProperties() {
        let properties = this.props.product.propertyTypes;
        let ht = [];
        //30-jacket
        //31-banderole
        //62-varnish-nadpechaten lak
        //56 - lace - още опции
        //57 - head and tails band - още опции
        //58 - bookmark - още опции
        //61 && 32 с чекбокс foil-wrapped, per packs
        //54 - Дигитален печат
        //53 - Офсетов печат
        //60 - щанцоване
        //67 - CD
        let hasFoils = properties.findIndex(x=> x.id === 61 || x.id === 32);
        if(hasFoils)
        {
            let foil = properties.find(x=>x.id === 32);
            let perPacks = properties.find(x=>x.id === 61);
            //TODO: ednoto ili drugoto
            if(foil && perPacks)
            {
                ht.push(<div className={'row mt-3'}>
                    <div className="col-12 d-none">
                        <label>{this.props.t('packaging')}</label>
                    </div>
                    <div className="col-4">
                        <input
                            type="radio"
                            checked={this.state.packaging === 32}
                            onChange={e=>this.packagingHandleChange(32)}
                        /> {foil.name}
                    </div>
                    <div className="col-4">
                        <input
                            type="radio"
                            checked={this.state.packaging === 61}
                            onChange={e=>this.packagingHandleChange(61)}
                        /> {perPacks.name}
                    </div>
                    <div className="col-4">
                        <input
                            type="radio"
                            checked={this.state.packaging === -1}
                            onChange={e=>this.packagingHandleChange(-1)}
                        /> {this.props.t('none')}
                    </div>
                </div>)
            }
        }

        //Lace
        let laceIndex = properties.findIndex(x=>x.id === 56);
        if(laceIndex > -1)
        {
            ht.push(<div className={'row mt-3'}>
                <div className={'col-12'}>
                    <input
                        checked={this.state.lace.is_enabled}
                        onChange={e => {
                            let {lace} = this.state;
                            lace.is_enabled = e.target.checked;
                            this.setState({lace}, () => {
                                if(lace.is_enabled === false)
                                {
                                    this.props.applyChanges(this.getProductProperties());
                                }
                            })
                        }}
                        type={'checkbox'} /> {properties[laceIndex].name}
                </div>
                <div className={"col-12 " + (this.state.lace.is_enabled ? '' : ' d-none')}>
                    <Select
                        onChange={(selected) => {
                            let {lace} = this.state;
                            lace.selected = selected.id;
                            this.setState({lace}, () => {
                                this.props.applyChanges(this.getProductProperties());
                            })
                        }}
                        value={this.props.laces.find(x=> parseInt(x.id) === parseInt(this.state.lace.selected))}
                        options={this.props.laces}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
            </div>)
        }

        let headAndTailsIndex = properties.findIndex(x=>x.id === 57);
        if(headAndTailsIndex > -1)
        {
            ht.push(<div className={'row mt-3'}>
                <div className="col-12">
                    <input
                        type="checkbox"
                        onChange={e => {
                            let {head} = this.state;
                            head.is_enabled = e.target.checked;
                            this.setState({head}, () => {
                                this.props.applyChanges(this.getProductProperties(!head.is_enabled));
                            })
                        }}
                        disabled={this.props.back < 10}
                        checked={this.state.head.is_enabled}
                    /> {properties[headAndTailsIndex].name}
                </div>
                <div className={"col-12 " + (this.state.head.is_enabled ? '' : ' d-none')}>
                    <Select
                        onChange={(selected) => {
                            let {head} = this.state;
                            head.selected = selected.id;
                            this.setState({head}, () => {
                                this.props.applyChanges(this.getProductProperties());
                            })
                        }}
                        value={this.props.heads.find(x=> parseInt(x.id) === parseInt(this.state.head.selected))}
                        options={this.props.heads}
                        getOptionLabel={(option)=> option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
            </div>)
        }

        for(let i = 0; i < properties.length; i++)
        {
            let prop = properties[i];
            if(![61,32,30,31,62,56,57,58,53,54,67].includes(prop.id))
            {
                ht.push(<div className={'row mt-3'}>
                    <div className={'col-12'}>
                        <input type={'checkbox'}
                               checked={this.state.selectedProperties.includes(prop.id)}
                               onClick={()=>{
                            //selectedProperties
                            let {selectedProperties} = this.state;
                            if(selectedProperties.includes(prop.id))
                            {
                                let index = selectedProperties.findIndex(x=>x === prop.id);
                                selectedProperties.splice(index,1);
                            }
                            else
                            {
                                selectedProperties.push(prop.id);
                            }
                            this.setState({selectedProperties},()=>{
                                this.props.applyChanges(this.getProductProperties());
                            })
                        }} /> {prop.name}
                    </div>
                </div>)
            }
        }

        /*<div className={'col-12 mt-3'}>
            {this.props.product.propertyTypes.filter(p =>
                p.id !== 30 && p.id !== 31 && p.id !== 62
            ).map( p => <div>
                <input type={'checkbox'} /> {p.name}
            </div>)}
        </div>*/

        return ht;
    }

    changeSize(length, height)
    {
        let li = parseFloat(length * 0.0393700787).toFixed(2);
        let hi = parseFloat(height * 0.0393700787).toFixed(2);
        this.setState({length, height, isCustomSize: false, heightInches: hi, lengthInches: li}, () => {
            this.props.applyChanges(this.getProductProperties());
        })
    }


    getMinimizeIcon() {
        if(this.state.isOpen)
        {
            return (<i className="fa fa-window-minimize" aria-hidden="true" />)
        }

        return (<i className="fa fa-plus" aria-hidden="true" />)
    }

    render() {
        let tirageFocus = 0;
        let heightFocus = 0;
        let lengthFocus = 0;
        return (
            <div className={'card mt-3'}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h3>
                                <div>
                                    <i className={'fa fa-book'}/> {this.props.t('form_properties')}
                                    <button onClick={()=>{
                                        this.setState({isOpen: !this.state.isOpen});
                                    }} className={'btn btn-sm btn-info float-right'}>
                                        {this.getMinimizeIcon()}
                                    </button>
                                </div>
                            </h3>
                        </div>
                    </div>
                    <div className={'collapse ' + (this.state.isOpen ? 'show' : '') }>
                        <div className="row">
                            <div className="col-12">
                                <label>{this.props.t('standard_size')}</label>
                            </div>
                            <div className="col-12">
                                <input
                                    type="radio"
                                    onClick={()=>{ this.changeSize(74, 105) }}
                                    checked={this.state.length === 74  && this.state.height === 105 && this.state.isCustomSize === false}
                                />  A7 &nbsp;

                                <input
                                    type="radio"
                                    onClick={()=>{ this.changeSize(105, 148) }}
                                    checked={this.state.length === 105  && this.state.height === 148 && this.state.isCustomSize === false}
                                /> A6 &nbsp;

                                <input
                                    type="radio"
                                    onClick={()=>{ this.changeSize(148, 210) }}
                                    checked={this.state.length === 148  && this.state.height === 210 && this.state.isCustomSize === false}
                                /> A5 &nbsp;

                                <input
                                    type="radio"
                                    onClick={()=>{ this.changeSize(210, 297) }}
                                    checked={this.state.length === 210  && this.state.height === 297 && this.state.isCustomSize === false}
                                /> A4 &nbsp;

                                <input
                                    type="radio"
                                    onClick={()=>{ this.changeSize(297, 420) }}
                                    checked={this.state.length === 297  && this.state.height === 420 && this.state.isCustomSize === false}
                                /> A3 &nbsp;

                                <input
                                    type="radio"
                                    onClick={()=>{ this.changeSize(420, 594) }}
                                    checked={this.state.length === 420  && this.state.height === 594 && this.state.isCustomSize === false}
                                /> A2 &nbsp;

                                <input
                                    type="radio"
                                    onClick={()=>{ this.changeSize(150, 150) }}
                                    checked={this.state.length === 150  && this.state.height === 150 && this.state.isCustomSize === false}
                                /> 150x150 &nbsp;

                                <input
                                    type="radio"
                                    onClick={()=>{ this.changeSize(210, 210) }}
                                    checked={this.state.length === 210  && this.state.height === 210 && this.state.isCustomSize === false}
                                /> 210x210 &nbsp;

                                <input
                                    type="radio"
                                    checked={this.state.isCustomSize === true}
                                    onClick={()=>{ this.setState({isCustomSize: true}) }}
                                /> {this.props.t('custom_size')}

                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-4">
                                <label>{this.props.t('length')} {this.props.measure}</label>
                                {/*Inches shown*/}
                                <input className={"form-control" + (this.props.measure === 'inches' ? '' : ' d-none')}
                                       type="number"
                                       placeholder={''}
                                       value={this.state.lengthInches}
                                       onChange={e => {
                                           let inches = e.target.value;
                                           let mm = Math.ceil(inches * 25.4);
                                           this.setState({lengthInches: e.target.value, length: mm})
                                       }}
                                       disabled={!this.state.isCustomSize}
                                />
                                <input
                                    className={"form-control" + (this.props.measure === 'mm' ? '' : ' d-none')}
                                    type="number"
                                    placeholder={this.props.t('length')}
                                    value={this.state.length}
                                    onChange={e => {
                                        let inches = parseFloat(e.target.value * 0.0393700787).toFixed(2);
                                        this.setState({length: e.target.value, lengthInches: inches}, () => {
                                            this.props.applyChanges(this.getProductProperties(false));
                                        })
                                    }}
                                    onFocus={(e)=>{
                                        heightFocus = e.target.value;
                                    }}
                                    onBlur={(e)=>{
                                        if(heightFocus !== e.target.value)
                                        {
                                            this.props.applyChanges(this.getProductProperties());
                                        }
                                    }}
                                    disabled={!this.state.isCustomSize}
                                />
                            </div>
                            <div className="col-4">
                                <label> {this.props.t('height')} {this.props.measure}</label>
                                <input className={"form-control" + (this.props.measure === 'inches' ? '' : ' d-none')}
                                       type="number"
                                       placeholder={''}
                                       value={this.state.heightInches}
                                       onChange={e=>{
                                           let inches = e.target.value;
                                           let mm = Math.ceil(inches * 25.4);
                                           this.setState({heightInches: e.target.value, height: mm})
                                       }}
                                       disabled={!this.state.isCustomSize}
                                />
                                <input
                                    className={"form-control" + (this.props.measure === 'mm' ? '' : ' d-none')}
                                    type="number"
                                    placeholder={this.props.t('height')}
                                    value={this.state.height}
                                    onChange={e => {
                                        let inches = parseFloat(e.target.value * 0.0393700787).toFixed(2);
                                        this.setState({height: e.target.value, heightInches: inches}, () => {
                                            this.props.applyChanges(this.getProductProperties(false));
                                        })
                                    }}
                                    onFocus={(e)=>{
                                        lengthFocus = e.target.value;
                                    }}
                                    onBlur={(e)=>{
                                        if(lengthFocus !== e.target.value)
                                        {
                                            this.props.applyChanges(this.getProductProperties());
                                        }
                                    }}
                                    disabled={!this.state.isCustomSize}
                                />
                            </div>
                            <div className="col-4">
                                <label>{this.props.t('measure')}</label>
                                <select
                                    onChange={e=>{
                                        this.setState({measure: e.target.value},()=>{
                                            //changeSize
                                            this.changeSize(this.state.length, this.state.height)
                                            this.props.applyChanges(this.getProductProperties(true));
                                        })
                                    }}
                                    className={'form-control'}>
                                    <option selected={this.state.measure === 'mm'} value="mm">mm</option>
                                    <option selected={this.state.measure === 'inches'} value="inches">inches</option>
                                </select>
                            </div>
                        </div>
                        <div className={"row" + (this.props.isHiddenBack ? ' d-none' : '') }>
                            {this.props.hasBackType &&
                            <div className={"col-4 justify-content-start  align-self-end d-flex mt-3 "}>
                                <label>{this.props.t('form_spine_type')}:</label>
                                &nbsp;&nbsp;
                                <label>{this.props.t('form_spine_type_flat')}</label>
                                &nbsp;
                                <input
                                    type="radio"
                                    checked={this.state.spine_type === 1}
                                    onClick={e => this.setState({spine_type: 1},()=>{
                                        this.props.applyChanges(this.getProductProperties(true));
                                    })}
                                />
                                &nbsp;
                                <label>{this.props.t('form_spine_type_round')}</label>
                                &nbsp;
                                <input
                                    type="radio"
                                    checked={this.state.spine_type === 2}
                                    onClick={e => this.setState({spine_type: 2},()=>{
                                        this.props.applyChanges(this.getProductProperties(true));
                                    })}
                                />
                            </div>
                            }
                            {this.props.measure == 'mm' &&
                            <div className={"col-"+(this.props.hasBackType ? '8' : '12')+" mt-3"}>
                                <label>
                                    {this.props.t('spine')}
                                </label>
                                <input
                                    className={"form-control"}
                                    type="number"
                                    placeholder={"Гръбче"}
                                    value={this.props.back}
                                    disabled={true}
                                />
                            </div>}

                            {this.props.measure == 'inches' &&
                            <div className={"col-"+(this.props.hasBackType ? '8' : '12')+" mt-3"}>
                                <label>
                                    {this.props.t('spine')}
                                </label>
                                <input
                                    className={"form-control"}
                                    type="number"
                                    placeholder={"Гръбче"}
                                    value={parseFloat(this.props.back * 0.0393700787).toFixed(2)}
                                    disabled={true}
                                />
                            </div>}
                        </div>
                        {this.props.bindingsSelected.length > 1 &&
                        <div className="row">
                            <div className="col-12 mt-3">
                                <label>
                                    {this.props.t('binding')}
                                    {/*value={this.state.bindings.find(c => c.id === this.state.binding)}*/}
                                </label>

                                <Select
                                    value={this.props.bindingsSelected.find(c=>c.id === this.state.binding)}
                                    onChange={(selected) => {
                                        this.setState({binding: selected.id}, () => {
                                            this.props.applyChanges(this.getProductProperties());
                                        });
                                    }}
                                    options={this.props.bindingsSelected}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                />
                            </div>
                        </div>
                        }

                        {this.props.foldingsSelected.length > 0 &&
                        <div className="row">
                            <div className="col-12 mt-3">
                                <label>
                                    {this.props.t('folding')}
                                    {/*value={this.state.bindings.find(c => c.id === this.state.binding)}*/}
                                </label>
                                <Select
                                    value={this.props.foldingsSelected.find(c=>c.id === this.state.folding)}
                                    onChange={(selected) => {
                                        this.setState({folding: selected.id}, () => {
                                            this.props.applyChanges(this.getProductProperties());
                                        });
                                    }}
                                    options={this.props.foldingsSelected}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                />
                            </div>
                        </div>
                        }

                        {this.props.product && this.renderProperties()}

                        <div className="row mt-3">
                            <div className="col-12">
                                <label>{this.props.t('form_comment')}</label>
                                <textarea
                                    className={'form-control'}
                                    value={this.state.comment}
                                    onChange={e => this.setState({comment: e.target.value},()=>{
                                        this.props.applyChanges(this.getProductProperties(false));
                                    })} rows="4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ProductPropertiesQForm);
