import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../api/NetworkClient';
import Loader from '../../partial/Loader';
import AuthHelper from  '../../helpers/AuthHelper';
import Cookies from 'js-cookie';
import './Login.css';

class Login extends Component {
    state = {
        isLoading: false,
        email: '',
        password: ''
    }

    componentDidMount() {
        document.addEventListener('keydown', (e)=>{
            if(e.code === 'Enter' || e.code === 'NumpadEnter')
            {
                if(this.state.isLoading === false)
                {
                    this.attempt();
                }
            }
        });
    }

    constructor(props) {
        super(props);
    }

    attempt() {
        this.setState({isLoading: true});
        NetworkClient.post('login',{email: this.state.email, password: this.state.password})
            .then( response => {
                AuthHelper.setUser(response);
                this.setState({isLoading: false});
                window.location.href = '/';
                //this.props.history.push('/admin/dashboard');
            }, error => {
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;

        return (
            <div>
                <Loader isLoading={this.state.isLoading} />
                <div className={"container-fluid"}>
                    <div className="row mt-5">
                        <div className="col-4 offset-4 mt-5 text-center" style={{background: 'white'}}>
                            <img src="/logo.png" className={"img-fluid"} alt=""/>
                            <div className="login-box-body">
                                <p className="login-box-msg">{t('login_msg')}</p>
                                <div className="form-group">
                                    <div className="input-group mb-3">
                                        <input type="email" className="form-control"
                                               value={this.state.email}
                                               onChange={(e)=>{
                                                   this.setState({email: e.target.value})
                                               }}
                                               placeholder={t('email')} />
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fa fa-envelope" aria-hidden="true" />
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control"
                                               value={this.state.password}
                                               onChange={(e)=>{
                                                   this.setState({password: e.target.value})
                                               }}
                                               placeholder={t('password')} />
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fa fa-key" aria-hidden="true" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pb-5">
                                    <div className="col-8 text-left">
                                    </div>
                                    <div className="col-4">
                                        <button onClick={this.attempt.bind(this)} className="btn btn-primary btn-block btn-sm">
                                            {t('sign_in')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Login);
