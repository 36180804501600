import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Constants from '../../../constants/Constants';
import Select from "react-select";
import './ClothModal.css';


class ClothModal extends Component {
    state = {
        isLoading: false,
        vendors: [],
        types: [],
        selectedVendor: -1,
        selectedType: -1,
        clothMinVal: '',
        clothMaxVal: '',
        selectedName: ''
    }

    constructor(props) {
        super(props);
    }

    renderTable() {
        if(this.props.cloths.length === 0)
        {
            return <div>Loading...</div>
        }

        if(this.state.vendors.length === 0)
        {
            let vendors = [{id: -1, name: 'all'}];
            for (let i = 0; i < this.props.cloths.length; i++)
            {
                if(!vendors.find(x=>x.name === this.props.cloths[i].vendor))
                {
                    vendors.push({id: i, name: this.props.cloths[i].vendor});
                }
            }

            this.setState({vendors});
        }

        if(this.state.types.length === 0)
        {
            let types = [{id: -1, name: 'all'}];
            for (let i = 0; i < this.props.cloths.length; i++)
            {
                if(!types.find(x=>x.name === this.props.cloths[i].type))
                {
                    types.push({id: i, name: this.props.cloths[i].type});
                }
            }

            this.setState({types});
        }

        return this.props.cloths
            .filter(c => {
                let vendor = true;
                let type = true;
                let minValue = true;
                let maxValue = true;
                let name = true;
                let price = parseFloat(c.price);

                if(this.state.selectedVendor > -1)
                {
                    let v = this.state.vendors.find(v => v.id === this.state.selectedVendor);
                    vendor = c.vendor === v.name;
                }

                if(this.state.selectedType > - 1)
                {
                    let v = this.state.types.find(t => t.id === this.state.selectedType);
                    type = c.type === v.name;
                }

                if(this.state.clothMinVal > 0)
                {
                    minValue = price >= this.state.clothMinVal;
                }

                if(this.state.clothMaxVal > 0)
                {
                    maxValue = price <= this.state.clothMaxVal;
                }

                if(this.state.selectedName.length > 0)
                {
                    name = c.series.toLowerCase().includes(this.state.selectedName.toLowerCase());
                }

                return vendor && type && minValue && maxValue && name;
            })
            .map( c => <>
            <div data-dismiss="modal" className="col-md-4 mt-1 cloth hover" onClick={()=>{
                this.props.selectCloth(c);
            }}>
                <img src={Constants.BASE_DOMAIN_NAME_WITHOUT + c.thumbnail}
                     className="cloth_thumbnail" />
                <div>
                    Vendor/Series: {c.vendor}/{c.series}
                </div>
                <div>
                    Model: {c.code}
                </div>
                <div>
                    Type: {c.type}
                </div>
                <div>
                    Price: {c.price} m<sup>2</sup>
                </div>
                <div>
                    Dimensions: {c.height}mm x {c.min_width}mm
                </div>
            </div>
        </>)
    }

    renderFilters() {
        return (<>
            <div className="col-2">
                <label>{this.props.t('vendor')}</label>
                <Select
                    value={this.state.vendors.find(x=>x.id === this.state.selectedVendor)}
                    options={this.state.vendors}
                    onChange={(selected) => {
                        this.setState({selectedVendor: selected.id});
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>

            <div className="col-2">
                <label>{this.props.t('type')}</label>
                <Select
                    value={this.state.types.find(x=>x.id === this.state.selectedType)}
                    options={this.state.types}
                    onChange={(selected) => {
                        this.setState({selectedType: selected.id});
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                />
            </div>
            <div className="col-2">
                <label>{this.props.t('cloth_price_min')}</label>
                <input
                    type="number" step={0.01}
                    min={0} className={'form-control'}
                    onChange={e => {
                        this.setState({clothMinVal: e.target.value})
                    }}
                />
            </div>
            <div className="col-2">
                <label>{this.props.t('cloth_price_max')}</label>
                <input
                    type="number" step={0.01}
                    min={0} className={'form-control'}
                    onChange={e => {
                        this.setState({clothMaxVal: e.target.value})
                    }}
                />
            </div>
            <div className="col-4">
                <label>{this.props.t('name')}</label>
                <input
                    type="text" className={'form-control'}
                    onChange={e => {
                        this.setState({selectedName: e.target.value})
                    }}
                />
            </div>
        </>)
    }

    render() {
        return (
            <div className="modal" id="myModal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className={'row'}>
                                {this.renderFilters()}
                            </div>
                            <div className="row" style={{height: 500, overflowY: 'scroll',overflowX:'hidden'}}>
                                {this.renderTable()}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ClothModal);
