import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Swal from 'sweetalert2'
import NetworkClient from '../../../api/NetworkClient';
import Loader from '../../../partial/Loader';
import Languages from "../../../constants/Languages";
import SimpleFieldsGenerator from "../../../helpers/SimpleFieldsGenerator";

class MaculatureKnigovezaneForm extends Component {
    state = {
        isLoading: false,
        languages: Languages,
        maculature: {
            id: null,
        }
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id !== undefined) {
            this.load(id);
        }
    }

    load(id) {
        this.setState({isLoading: true}, () => {
            NetworkClient
                .get(`maculature-knigovezane/${id}`)
                .then(response => {
                    const maculature = response.maculature;
                    this.setState({maculature});
                }).finally(c => {
                this.setState({isLoading: false});
            })
        })
    }

    store() {
        this.setState({isLoading: true});
        const {maculature} = this.state;
        let url = 'maculature-knigovezane';
        if (maculature.id !== null) {
            url += '/' + maculature.id;
        }

        NetworkClient.post(url, maculature)
            .then(response => {
                Swal.fire('Ok!', '', 'success')
                    .then(c => {
                        this.props.history.push('/maculatures-knigovezane');
                    })
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    render() {
        const {t} = this.props;

        const fields = [
            {name: 'tirage_from', type: 'number'},
            {name: 'tirage_to', type: 'number'},
            {name: 'lepena_bez_kapaci', type: 'number'},
            {name: 'lepena_s_kapaci', type: 'number'},
            {name: 'lepena_pur_bez_kapaci', type: 'number'},
            {name: 'lepena_pur_s_kapaci', type: 'number'},
            {name: 'shita_lepena_bez_kapaci', type: 'number'},
            {name: 'shita_lepena_s_kapaci', type: 'number'},
            {name: 'vshm_bez_kapaci', type: 'number'},
            {name: 'vshm_s_kapaci', type: 'number'},
            {name: 'tvurda_podvurziq_standart', type: 'number'},
            {name: 'tvurda_podvurziq_s_forzaci_ot_tqloto', type: 'number'},
            {name: 'punching', type: 'number'},
        ];

        return (
            <div>
                <Loader isLoading={this.state.isLoading}/>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('paper')} {t('color')}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={"content"}>
                    <div className={"container-fluid"}>
                        <div className={"col-md-6"}>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {SimpleFieldsGenerator.bind(this,t,fields, "maculature")()}
                                    <button onClick={this.store.bind(this)} className={"btn btn-info btn-sm"}>
                                        {this.state.maculature.id === null ? 'Create' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(MaculatureKnigovezaneForm);
