const us ={
    "regular_mitnica": "Такса митница (лв)",
    "regular_kg": "Тегло на палет (400)[X](кг)",
    "regular_first_kg_price": "Цена за първия палет до (400)[X]кг. (лв)",
    "regular_after_kg": "Цена за всеки следващ палет (400)[X]кг. (лв)",
    "regular_fix": "Фиксиран разход - сухопътен транспорт (лв)",
    "regular_price_kg_walk": "Цена за килограм - сухопътен транспорт (лв)",
    "regular_minimal_value_land": "Минимална цена (кг * цена за кг) - сухопътен транспорт (лв)",
    "express_first_kg_price": "Цена до (100)[X]кг тегло (лв)",
    "express_after_kg": "Цена за следващите (100)[Y] килограми (лв)",
    "express_kg": "Тегло X (кг)",
    "express_kg_each": "Тегло Y (кг)",
    
    "c_pro": "",
    "owner": "Owner",
    "show_mine" : "My orders",

    "maculatures-cover-print" : "Макулатури корица",
    "uv_lak": "УВ Лак",
    "selektiven_lak": "Селективен лак",
    "maculatures-knigovezane": "Макулатури книговезки",
    "lepena_bez_kapaci" : "Лепена без капаци",
    "lepena_s_kapaci" : "Лепена с капаци",
    "lepena_pur_s_kapaci" : "PUR с капаци",
    "lepena_pur_bez_kapaci" : "PUR без капаци",
    "shita_lepena_s_kapaci" : "Шита-лепена с капаци",
    "shita_lepena_bez_kapaci" : "Шита-лепена без капаци",
    "vshm_bez_kapaci" : "ВШМ без капаци",
    "vshm_s_kapaci" : "ВШМ с капаци",
    "tvurda_podvurziq_standart" : "Твърда стандартна",
    "tvurda_podvurziq_s_forzaci_ot_tqloto" : "Твърда с форзаци от тялото",
    "maculatures-print-body" : "Макулатури печат тяло",
    "tirage_from" : "Тираж от",
    "tirage_to" : "Тираж до",
    "print_one" : "1+0",
    "print_two" : "2+0",
    "print_three" : "3+0",
    "print_four" : "4+0",
    "pantone" : "Пантон",
    "nadpechaten_lak" : "Надпеч. лак 1+0",
    "laminate"  : "ламинат",
    "punching" : "Щанцоване",

    "paper_format_height" : "Формат х-я Височина",
    "paper_format_length" : "Формат х-я Дължина",
    "paper_max_format_height": "Мax Формат за печат Височина",
    "paper_max_format_length": "Мax Формат за печат Дължина",
    "paper_max_format_cut_height" : "Max обрязан формат Височина",
    "paper_max_format_cut_length" : "Max обрязан формат Дължина",
    "count_pages_in_print_list" : "Брой стр в печатен лист",
    "iso3_explained" : "Country Code 3 Symbols",
    "density_text_lb" : "Density text lb",
    "density_cover_lb" : "Density cover lb",
    "default_locale": "Default locale",
    "has_cover" : "Cover",
    "has_text" : "Text",
    "has_jacket" : "Jacket",
    "has_banderole" : "Banderole",
    "has_cloth" : "Cloth",
    "has_endpapers" : "Endpapers",
    'all_countries': "Countries",
    'pricePerTon': 'Price per Ton',
    "countries_delivery": "Countries - delivery",
    "complement":"Complement",
    "cur_val":"Current Value",
    "permissions":"Permissions",
    "list":"List",
    "id":"ID",
    "name":"Name",
    "display_name":"Display Name",
    "description":"Description",
    "create_new":"Create",
    "view":"View",
    "delete":"Delete",
    "submit":"Submit",
    "edit":"Edit",
    "permission":"Permission",
    "updated":"Updated",
    "added":"Added",
    "deleted":"Deleted",
    "all_permissions":"All Permissions",
    "users":"Users",
    "all_users":"All Users",
    "roles":"Roles",
    "role":"Role",
    "add":"Add",
    "all_roles":"All Roles",
    "assign_permissions":"Assign Permissions",
    "assign_roles":"Assign Roles",
    "create_user":"Create User",
    "assign_websites":"Assign Websites",
    "websites":"Websites",
    "domain":"Domain",
    "currency":"Currency",
    "email":"Email",
    "phone":"Phone",
    "team":"Team",
    "address_info":"Address Info",
    "orders_not_final":"List Not Final",
    "default_category":"Default Category",
    "is_special":"Is Special",
    "special_category":"Special Category",
    "website":"Website",
    "has_departments":"Has Departments",
    "weight":"Weight",
    "price":"Price",
    "type":"Type",
    "step":"Step",
    "update":"Update",
    "update_to_current_rate":"Update to current Rate",
    "value":"Value",
    "current_value":"Current Value",
    "current_rate":"Current Rate",
    "min_price":"Min Price",
    "max_price":"Max Price",
    "fixed_price":"Fixed Price",
    "per":"per",
    "for":"for",
    "from":"from",
    "to":"to",
    "surcharge":"surcharge",
    "economy":"Economy",
    "express":"Express",
    "courier":"Courier",
    "delivery":"Delivery",
    "color":"Color",
    "operations":"Operations",
    "orders_list":"Orders List",
    "status":"Status",
    "state":"State",
    "select_state":"Select State",
    "link":"Link",
    "linked_orders":"Linked Orders",
    "finish":"Finish",
    "back":"Back",
    "contacts":"Contacts",
    "cancel":"Cancel",
    "error":"Error",
    "preview":"Preview",
    "calculate":"Calculate",
    "custom_size":"Custom Size",
    "standard_size":"Standard Size",
    "final_size":"Final Size",
    "length":"Length",
    "height":"Height",
    "company_name":"Company Name",
    "first_name":"First Name",
    "last_name":"Last Name",
    "products":"Products",
    "properties":"Properties",
    "deliveries":"Deliveries",
    "papers":"Papers",
    "sizes":"Sizes",
    "densities":"Densities",
    "suppliers":"Suppliers",
    "colors":"Colors",
    "names":"Names",
    "types":"Types",
    "maculatures":"Maculatures",
    "transport":"Transport",
    "districts":"Districts",
    "overcharges":"Overcharges",
    "prices":"Prices",
    "settings":"Settings",
    "taxes":"Taxes",
    "tax_pulsio":"Tax Pulsio",
    "tax_security":"Tax Security",
    "tax_fuel":"Tax Fuel",
    "calculator_helper":"Calculator Helper",
    "currencies":"Currencies",
    "orders":"Quotes ",
    "current_states":"Current States",
    "states":"States",
    "clients":"Clients",
    "categories":"Categories",
    "contact_types":"Contact Types",
    "navigation":"Navigation",
    "create":"Create",
    "total_papers":"Total Papers",
    "total_clients":"Total Clients",
    "total_products":"Total Products",
    "total_orders":"Total Orders",
    "enabled":"Enabled",
    "enable":"Enable",
    "question_delete":"Are you sure you want to delete this?",
    "sign_in":"Sign In",
    "remember_me":"Remember me",
    "login_msg":"Start a new session",
    "password":"Password",
    "key":"Key",
    "info":"Info",
    "category":"Category",
    "mobile_phone":"Phone 2",
    "business":"Business",
    "bulstat":"Bulstat",
    "address":"Address",
    "invoice":"Invoice",
    "invoice_address":"Billing Address",
    "delivery_address":"Delivery Address",
    "number":"Number",
    "street_name":"Street Name",
    "city":"City",
    "post_code":"Post Code",
    "add_contact":"Add Contact",
    "title":"Title",
    "waste":"Waste",
    "percent":"Percent",
    "new_order":"New Order",
    "select_product_in_orders":"Choose a product for which you want to make a new order",
    "select_type_in_orders":"Select Type of Order",
    "select_website_in_orders":"Choose a Website for which you want to make a new order",
    "comment":"Comment",
    "client":"Client",
    "product":"Product",
    "company":"Company",
    "finished_size_length":"Finished size length",
    "finished_size_height":"Finished size height",
    "copies":"Copies",
    "bindings":"Bindings",
    "order":"Order",
    "foil":"Foil",
    "banderole":"Banderole",
    "jacket":"Jacket",
    "wrapped":"Wrapped",
    "total_price":"Total Price",
    "overcharge":"Overcharge",
    "discount":"Discount",
    "final_price":"Final Price",
    "budget":"Budget",
    "base_overcharges":"Base Overcharges",
    "additional_overcharges":"Additional Overcharges",
    "property":"Property",
    "percentage":"Percentage",
    "remove":"Remove",
    "paper":"Papier",
    "density":"Density",
    "text":"Text",
    "text2":"Text",
    "price_per_sheet":"Price per Sheet",
    "volume_ratio":"Volume Ratio",
    "volume_ratio_alt":"Volume Ratio (alt)",
    "coef1_sewed":"Coef 1 (sewed)",
    "coef2_sewed":"Coef 2 (sewed)",
    "coef3_sewed":"Coef 3 (sewed)",
    "coef1_stitched":"Coef 1 (stitched)",
    "coef2_stitched":"Coef 2 (stitched)",
    "coef3_stitched":"Coef 3 (stitched)",
    "cover":"Cover",
    "size":"Size",
    "price_per_ton_with_overcharge":"Price per Ton with Overcharge, VAT",
    "price_per_sheet_with_overcharge":"Price per Sheet with Overcharge, VAT",
    "vat":"VAT",
    "book":"Book",
    "paper_group":"paper Group",
    "special":"Special",
    "no_group":"No Group",
    "normal":"Normal",
    "profile":"Profile",
    "sign_out":"Sign Out",
    "folding":"Folding",
    "binding":"Binding",
    "print":"Print",
    "finishing":"Finishing",
    "insert":"Insert",
    "days":"Days",
    "new_password":"New Password",
    "repeat_password":"Repeat Password",
    "group":"Group",
    "factor":"Factor",
    "action_done":"Action Done",
    "success":"Success",
    "price_per_ton":"Price per Ton",
    "all":"All",
    "wrong_id":"Wrong Id",
    "formats":"Formats",
    "format":"Format",
    "thickness":"Thickness/Cardboard",
    "france":"France",
    "united_kingdom":"United Kingdom",
    "bulgaria":"Bulgaria",
    "belgium":"Belgium",
    "switzerland":"Switzerland",
    "belgium_trans_info":"The belgium transport is equal to 59 french department.",
    "mr_ms":"Mr./Ms.",
    "mr":"Mr.",
    "mrs":"Ms.",
    "country":"Country",
    "countries":"Countries",
    "default_currency":"Default Currency",
    "groups":"Groups",
    "code":"Code",
    "close":"Close",
    "delivery_days":"Delivery Days",
    "delivery_point":"Delivery Point",
    "shipments":"Shipments",
    "statuses":"Statuses",
    "priorities":"Priorities",
    "tir_groups":"Tir Groups",
    "tir_group":"Tir Group",
    "printings":"Printings",
    "priority":"Priority",
    "remarks":"Remarks",
    "remarks_trader":"Remarks Trader",
    "remarks_logistic":"Remarks Logistic",
    "zip_code":"Postal Code",
    "delivery_from_print":"Delivered from Printing day",
    "delivery_loading":"Expedition delivery day",
    "delivery_day":"Delivery arrival day",
    "copies_total":"Copies",
    "copies_sending":"Copies Sending",
    "copies_left":"Copies Left",
    "kg":"kg",
    "schedule":"Schedule",
    "schedules":"Schedules",
    "calendar":"Calendar",
    "search":"Search",
    "year":"Year",
    "week":"Week",
    "already_exists":"already exists",
    "has_items":"has items",
    "created_by":"Created by",
    "assign_delivery":"Assign Delivery",
    "must_be_less_than":"must be less than",
    "must_be_greater_than":"must be greater than",
    "trader":"Trader",
    "package_number":"Package Number",
    "not_set":"Not set yet",
    "active":"Active",
    "no_orders_assigned_yet":"No Orders assigned yet",
    "other":"Other",
    "action":"Action",
    "actions":"Actions",
    "need_update":"You need to update!",
    "total":"Total",
    "remaining":"Remaining",
    "to_clients_address":"to clients address on the territory of Bulgaria",
    "our_warehouse":"FRANCO our warehouse",
    "point":"point",
    "points":"points",
    "clients_delivery_addresses":"Clients delivery addresses",
    "street":"Street",
    "address_remarks":"Remarks Address",
    "enter_address_manually":"Enter address manually",
    "locked":"Locked",
    "unlocked":"Unlocked",
    "inactive":"Inactive",
    "shipped":"Shipped",
    "not_shipped":"Not shipped",
    "assigned":"Assigned",
    "selected":"Selected",
    "pallet":"Pallet",
    "paper_factor":"Paper Factor",
    "additional_costs":"Additional Costs",
    "reset":"Reset",
    "payer":"Payer",
    "assign_for_shipment":"Assign for Shipment",
    "yes":"Yes",
    "no":"No",
    "boxes":"Boxes",
    "mark_as_shipped":"Mark as Delivered",
    "mark_all_as_shipped":"Mark all as Delivered",
    "mark_as_not_shipped":"Mark as Not Delivered",
    "mark_all_as_not_shipped":"Mark all as Not Delivered",
    "send_to_transporter":"Send to Transporter",
    "transporter_has_locked_the_delivery":"The Transporter has locked the Delivery",
    "mark_as_assigned_to_tir_group":"Mark as assigned to Tir Group",
    "mark_as_is_shipping":"Mark as shipping",
    "mark_as_not_shipping":"Mark as Not shipping",
    "mark_all_as_assigned_to_tir_group":"Mark all as assigned to Tir Groups",
    "mark_all_as_not_assigned_to_tir_group":"Mark all as NOT assigned to any Tir Group",
    "dates":"Dates",
    "sending":"Sending",
    "transporter":"Transporter",
    "see_on_map":"See on Map",
    "delivery_express_day":"Express Delivery day",
    "date":"Date",
    "additional_options":"Additional Options",
    "barcode":"Barcode",
    "isbn":"ISBN",
    "generate":"Generate",
    "tickets":"Tickets",
    "ticket":"Ticket",
    "all_tickets":"All Tickets",
    "ex":"ct.",
    "cnt":"ct.",
    "documents":"Documents",
    "bdl":"BDL",
    "cmr":"CMR",
    "all_bd_ls":"All BDLs",
    "all_cm_rs":"All CMRs",
    "only_marked":"Only marked",
    "cloth":"Paper over board",
    "cardboard":"Cardboard",
    "endpapers":"Endpapers",
    "editor":"Editor",
    "consignment_number":"Consignment number",
    "number_of_copies_per_carton":"Number of copies per carton",
    "number_of_copies":"Number of copies",
    "title_label":"Title",
    "label_ex":"ex.",
    "nothing_selected":"Nothing selected!",
    "copies_in_a_box":"Copies in a box",
    "count_boxes":"Count boxes",
    "warehouse":"Warehouse",
    "damaged_papers":"Damaged Papers",
    "component":"Component",
    "component_is_required":"Component is required",
    "group_is_required_for_book":"Group is required for Book",
    "thickness_additional":"Thickness/Foam board",
    "difference":"Différence",
    "mark":"Mark",
    "un_mark":"Un-mark",
    "no_boxes_assigned":"There are no Boxes assigned",
    "shipments_order":"Order for Shipment",
    "all_shipment_order":"All Shipment Orders",
    "select_from_addresses":"Select from addresses",
    "enter_other_address":"Enter other address",
    "must_not_be_deleted":"Item must not be deleted!",
    "mm":"mm",
    "is_shipped":"Shipped",
    "system":"System",
    "width":"Width",
    "":"L",
    "":"H",
    "":"W",
    "created_at":"Created at",
    "optional":"optional",
    "production_number":"Production number",
    "distributions":"Distributions",
    "distribution":"Distribution",
    "contact_person":"Contact person",
    "client_has_orders":"Client has orders!",
    "suspended_by":"Suspended by",
    "suspended_at":"Suspended at",
    "suspend":"Suspend",
    "suspended":"Suspended",
    "deleted_by":"Deleted by",
    "deleted_at":"Deleted at",
    "organization":"Organization",
    "levs":"levs",
    "export_tn_ts":"Export TNTs",
    "bdl_date_label":"Document created at",
    "bdl_proof_of_delivery":"Proof of Delivery",
    "bdl_title":"Title",
    "bdl_number_of_copies_per_carton":"Number of copies per carton",
    "bdl_number_of_cartons":"Number of cartons",
    "bdl_total_quantity":"Total quantity",
    "bdl_number_of_pallets":"Number of pallets",
    "bdl_total":"Total",
    "bdl_consignment_number":"Consignment number",
    "bdl_delivered_to":"Delivered to",
    "bdl_on_behalf_of":"On behalf of",
    "bdl_date":"Date:",
    "bdl_signature":"Signature & stamp:",
    "assign_other_contact_in_documents":"Assign other contact in Documents",
    "production":"Production",
    "productivity":"Productivity",
    "circulation":"Circulation",
    "term":"Term",
    "machine":"Machine",
    "book_cover":"Book cover",
    "amount":"Amount",
    "schedule_status":"Status",
    "schedule_client":"Client",
    "schedule_title":"Title",
    "schedule_circulation":"Circulation",
    "schedule_format":"Format",
    "schedule_remarks":"Remarks",
    "schedule_color":"Color",
    "schedule_term":"Term",
    "schedule_machine":"Machine",
    "schedule_book_cover":"Book cover",
    "schedule_amount":"Amount",
    "schedule_body":"Body",
    "schedule_cover":"Cover",
    "schedule_cover_extras":"Cover extras",
    "schedule_cloth":"Cloth",
    "schedule_endpapers":"Endpapers",
    "filter":"Filter",
    "preparations":"Preparations",
    "schedule_preparations":"Preparations",
    "schedule_technologist":"Technologist",
    "due_date":"Due date",
    "schedule_fitter":"Fitter",
    "schedule_prepress":"Prepress",
    "copy":"Copy",
    "all_technologists":"All Technologists",
    "all_fitters":"All Fitters",
    "all_prepressers":"All Prepressers",
    "schedule_production_number":"Production Number",
    "show_only_mine":"Show only mine",
    "schedule_employees":"Employees",
    "schedule_type":"Type",
    "schedule_state":"State",
    "schedule_bodies":"Bodies",
    "schedule_sheets":"Sheets",
    "create_and_ship":"Create and ship",
    "update_and_ship":"Update and ship",
    "filter_by_trader":"Filter by Trader",
    "no_trader_selected":"No Trader is selected",
    "filter_by_printing":"Filter by Printing",
    "filter_by_tir_group":"Filter by Tir group",
    "filter_by_delivery_from_printing_day":"Filter by Delivery from Printing day",
    "filter_by_delivery_arrival_day":"Filter by Delivery arrival day",
    "no_printing_selected":"No Printing selected",
    "no_tir_group_selected":"No Tir group selected",
    "no_delivery_from_printing_day_selected":"No Delivery from Printing day selected",
    "no_delivery_arrival_day_selected":"No Delivery arrival day selected",
    "no_delivery_from_expedition_selected":"No Delivery from Expedition day selected",
    "filter_by_delivery_from_expedition_day":"Filter by Delivery from Expedition day",
    "filter_by_id":"Filter by ID",
    "filter_by_job":"Filter by Job",
    "filter_by_country":"Filter by Country",
    "no_country_selected":"No Country selected",
    "id_invalid":"ID is invalid",
    "job_invalid":"JOB is invalid",
    "filter_by_package_number":"Filter by Package number",
    "filter_by_isbn":"Filter by ISBN",
    "filter_by_order_name":"Filter by Order name",
    "filter_by_trader_name":"Filter by Trader name",
    "all_packages":"All Points",
    "all_books":"All Books",
    "dropped":"Dropped",
    "books_dropped":"Dropped",
    "books_title":"Title",
    "books_package_number":"Package number",
    "books_order_id":"Order ID",
    "books_address":"Address",
    "books_delivery_date":"Delivery date",
    "books_boxes":"Boxes",
    "books_printing":"Printing",
    "books_client":"Client",
    "books_trader":"Trader",
    "books_copies_ordered":"Copies ordered",
    "books_copies_expedited":"Copies expedited",
    "books_difference":"Difference",
    "books_weight_ordered":"Weight(kg) ordered",
    "books_weight_per_unit":"Weight(kg) per item",
    "points_all_point":"Shipments for transport",
    "tir_group":"Tir group",
    "client":"Client",
    "trader":"Trader",
    "shipment_n":"Shipment №",
    "boxes":"Boxes",
    "zip_code":"Postal Code",
    "address":"Address",
    "priority":"Priority",
    "delivery_date":"Delivery date",
    "remarks_trader":"Remarks Trader",
    "from_printing_date":"Date from Printing",
    "loading_date":"Date loading",
    "weight_ordered":"Ordered kg",
    "total_copies_ordered":"Total copies ordered",
    "total_weight_ordered":"Total weight kg",
    "total_copies_expedited":"Total copies expedited",
    "price_kg":"Price kg",
    "phone":"Phone",
    "email":"Email",
    "country":"Country",
    "remarks_logistic":"Remarks Logistics",
    "tickets_address":"Address",
    "tickets_editor":"Client",
    "tickets_consignment_number":"Ref",
    "tickets_order_number":"Order id",
    "tickets_title_label":"Title",
    "tickets_number_of_copies_per_carton":"Copies in carton",
    "tickets_number_of_copies":"Copies",
    "tickets_label_ex":"",
    "return_shipment":"Return the Shipment",
    "update_remarks":"Update remarks",
    "financial_ctrl":"Financial Ctrl",
    "fin_company_name":"Company Name",
    "fin_client_name":"Client Name",
    "fin_client_phone":"Client Phone",
    "fin_client_email":"Client Email",
    "fin_trader_name":"Trader Name",
    "fin_order_price":"Order Price",
    "fin_financial_ctrl_state":"Financial Ctrl State",
    "fin_financial_ctrl_remarks":"Financial Ctrl Remarks",
    "fin_super_am_state":"Super AM State",
    "fin_super_am_remarks":"Super AM Remarks",
    "fin_updated_by":"Last updated by",
    "fin_title":"Title",
    "restore_client":"Are you sure you want to restore this Client?",
    "client_exists":"A client with the same email address exists already.",
    "restore":"Restore",
    "all_reports":"All Reports",
    "sorted_by_id":"Sorted by ID",
    "sorted_by_country":"Sorted by Country",
    "sorted_by_post_code":"Sorted by Post Code",
    "export_documents":"Export Documents",
    "sorted_by_tir_group":"Sorted By Tir group",
    "sorted_by_city":"Sorted By City",
    "sorted_by_organization":"Sorted By Organization name",
    "sorted_by_client":"Sorted By Client name",
    "sorted_by_package_number":"Sorted By Package number",
    "reports_all_point":"Shipments for transport",
    "reports_tir_group":"Tir group",
    "reports_delivery_date":"Delivery date",
    "reports_country":"Country",
    "reports_post_code":"Post code",
    "reports_city":"City",
    "reports_address":"Address",
    "reports_number":"Number",
    "reports_organization":"Organization",
    "reports_client":"Client",
    "reports_phone":"Phone",
    "reports_email":"Email",
    "reports_phone_optional":"Phone (optional)",
    "reports_email_optional":"Email (optional)",
    "reports_order_name":"Title",
    "reports_package_number":"Package number",
    "reports_prints":"Copies",
    "reports_boxes":"Boxes count",
    "reports_weight":"Weight (kg)",
    "mark_as_arrived":"Mark as Arrived",
    "mark_as_departed":"Mark as Departed",
    "transport_ctrl":"Transport Ctrl",
    "not_expedited_yet":"Not expedited yet",
    "is_shipping":"Is shipping",
    "not_assigned":"Not assigned",
    "sending_copies":"Shipping copies",
    "order_id":"Order ID",
    "date_today":"Date Today",
    "date_marked_from_trader":"Date marked by Trader",
    "date_marked_from_warehouse":"Date marked by Warehouse",
    "filter_by_client":"Filter by Client",
    "auto_rescheduled_for_week":"Rescheduled for week",
    "rescheduled_prints":"Rescheduled Prints",
    "rescheduled_weight":"Rescheduled Weight",
    "quick_shipments_switch":"Quick Shipments switch",
    "quick_prices_switch":"Quick Prices switch",
    "sending_boxes":"Shipping Boxes",
    "deny_reschedule":"Deny Reschedule",
    "proceed_reschedule":"Proceed Reschedule",
    "proceed_reschedule_and_ship":"Proceed Reschedule and Ship",
    "reschedule":"Reschedule",
    "reschedule_history":"Reschedule History",
    "denied":"Denied",
    "rescheduled":"Rescheduled",
    "sent":"Sent",
    "copies_rescheduled":"Copies rescheduled",
    "weight_rescheduled":"Weight rescheduled",
    "unresolved":"Pending",
    "copy_to_clipboard":"Copy to clipboard",
    "no_boxes":"No boxes recorded",
    "agreement_day":"Agreement day",
    "filter_by_delivery_agreement_day":"Filter by Delivery Agreement day",
    "reports_delivery_date_trader":"Delivery date/Trader",
    "reports_delivery_date_agreement":"Delivery date/Agreement",
    "reports_delivery_date_final":"Delivery date/Final",
    "denied_prints":"Denied copies",
    "type_of_print":"Print type",
    "message_boxes":"The order is not marked as shipped",
    "language":"Language",
    "reception_company":"Reception company (included in the label)",
    "display_the_transport_price_in_the_offer":"Display the transport price in the offer",
    "global_search":"Global search",
    "client_val_email":"Email is already registered to another client",
    "client_val_name":"Client with same first and last name is already registered",
    "client_val_last":"Client with same first and last name is already registered",
    "client_val_org_name":"Organization with same name is already registered",
    "client_val_org_bulstat":"Organization with same BULSTAT is already registered",
    "sales_agent":"Sales agent",
    "disapproved":"Disapproved",
    "approved":"Approved",
    "pending":"Pending",
    "organizations":"Organizations",
    "show_only_disapproved":"Show only disapproved",
    "history":"History",
    "user":"User",
    "log":"Log",
    "updated_at":"Updated at",
    "system_generated":"System generated",
    "system_updated":"System updated",
    "client_email_registered":"Email is already registered in the database",
    "client_fln_ames_registered":"Name na last name are already registered in the database",
    "techno_card":"Tech. Card",
    "techno_card_upload":"Upload",
    "techno_card_filename":"Filename",
    "techno_card_date":"Date",
    "techno_card_status":"Status",
    "activate":"Activate",
    "techno_card_comment":"Comment",
    "download":"Download",
    "fin_sam_override":"Super AM overrides Financial Control state decision",
    "max_insurance":"Max insurance",
    "new_max_insurance":"Max insurance",
    "order_is_in_production":"Order is in production and its state can not be changed",
    "showing_results_after":"Showing results after",
    "generated_client_financial_excel_file":"Generated client financial control Excel file",
    "generated_excel_file_for_schedule":"Generated financial control Excel file for transport schedule",
    "all_shipments":"All Shipments",
    "all_prices":"All Prices",
    "printing":"Printing",
    "in_production":"In Production",
    "sent_to_fin_ctrl":"Sent to Financial Control",
    "statistics":"Statistics",
    "total_shipments":"Total Shipments",
    "sending_shipments":"Sending Shipments",
    "rescheduled_shipments":"Rescheduled Shipments",
    "locale":"Locale",
    "hunter":"Hunter",
    "quotes":"Quotes",
    "quote":"Quote",
    "offer":"Offer",
    "offers":"Offers",
    "order_now":"Order now",
    "copies_difference":"Copies difference",
    "tirage":"Copies",
    "redistribute":"Redistribute",
    "per_packs":"Per packs",
    "packaging":"PACKAGING: shrink-wrapped per packs, in boxes on pallets",
    "pdf_issue294":"Long grain print not guaranteed.",
    "print_run_from":"Print Run From",
    "printRunFrom":"Print Run From",
    "print_run_to":"Print Run To",
    "printRunTo":"Print Run To",

    "overcharge_52":"0+0 [%]",
    "discount_52":"Discount: 0+0",
    "overcharge_18":"1+0 [%]",
    "discount_18":"Discount: 1+0",
    "overcharge_15":"1+1 [%]",
    "discount_15":"Discount: 1+1",
    "overcharge_50":"2+0 [%]",
    "discount_50":"Discount: 2+0",
    "overcharge_16":"2+2 [%]",
    "discount_16":"Discount: 2+2",
    "overcharge_19":"4+0 [%]",
    "discount_19":"Discount: 4+0",
    "overcharge_51":"4+1 [%]",
    "discount_51":"Discount: 4+1",
    "overcharge_17":"4+4 [%]",
    "discount_17":"Discount: 4+4 [%]",

    "pdf_issue296":"Attention! At this size of the folds, the paper of the cover will not run with the grain.",
    "pdf_issue33":" varnish",
    "notifications":"Notifications",
    "notification":"Notification",
    "is_active":"Is Active",
    "off":"Off",
    "on":"On",
    "business_hours":"Business hours",
    "pallets_requirements":"Pallets requirements",
    "cartons_requirements":"Cartons requirements",
    "more_information":"More information",
    "none":"None",
    "neutral_delivery":"Neutral delivery",
    "preparers":"Prepress",
    "preparer":"Prepress",
    "duplicated_property":"Duplicated Property",
    "is_default":"Is Default",
    "is_final":"Is Final",
    "spine":"Spine",
    "real_spine":"Real spine",
    "sample":"Sample",
    "samples":"Samples",
    "bleed_inside":"Вътрешно",
    "bleed_outside":"Външно",
    "bleed_head":"Глава",
    "bleed_legs":"Крака",
    "bleeds":"Обрязване + мм",
    "technologist":"Technologist",
    "has_sample":"Has Sample",
    "address_delivery1":"Delivery Address 1",
    "address_delivery2":"Delivery Address 2",
    "address_delivery3":"Delivery Address 3",
    "contacts1":"Contacts 1",
    "contacts2":"Contacts 2",
    "contacts3":"Contacts 3",
    "contacts4":"Contacts 4",
    "contacts5":"Contacts 5",
    "foil":"Foil",
    "number_of_stamps":"Number of stamps",
    "all_sizes_in_mm":"Sizes",
    "embossing":"Embossing",
    "embossing_foil":"Embossing with foil",
    "load":"Load",
    "holidays":"Holidays",
    "short_name":"Short name",
    "working_days":"Working days",
    "capacity":"Capacity",
    "period":"Period",
    "load_lepena":"Лепена",
    "load_shita":"Шита",
    "load_tvarda":"Твърда",
    "load_s_kapaci":"С капаци",
    "load_vshm":"ВШМ",
    "load_drugi":"Друг вид поръчки",
    "free_capacity1":"Свободен капацитет %",
    "free_capacity2":"Свободен капацитет бр",
    "total40for_period":"Общо подготвки 4+0 за периода",
    "total40s_for_period":"Общо листа 4+0 за периода",
    "total11for_period":"Общо подготовки 1+1 за периода",
    "total11s_for_period":"Общо листа 1+1 за периода",
    "download_excel":"Download Excel",
    "download_detailed_excel":"Download detailed Excel",
    "dencity":"Density",
    "count_pages":"Pages",
    "supplier":"Supplier",
    "am":"AM",
    "paper_body":"Paper body",
    "paper_cover":"Paper cover",
    "calculated_transport_price":"Calculated transport price",
    "calculated_transport_copies":"Calculated transport copies",
    "calculated_transport_weight":"Calculated transport weight",
    "books_price_be":" Trnasport price BE",
    "recipient_email":"Recipient email",
    "recipient_phone":"Recipient phone",
    "recipient":"Recipient",
    "paper_type":"Type of paper (Text body)",
    "missing_paper":"is no longer available - to display this offer, it is replaced by paper",
    "disabled":"Disabled",
    "disabled":"Inactive",
    "enabled":"Active",
    "all":"All",
    "paper_validation":"Хартия с това име вече има зададен статус - Normal/Special за продукт",
    "pantone_number":"Pantone",
    "pantone_numbers":"Pantones",
    "pantone_type":"Type pantone",
    "pdf_paper_client":"Client paper ({PAPER_NAME} - {PAPER_DENSITY} g - {BIG_SHEETS} sheets {PAPER_SIZE})",
    "pdf_paper_client_special":"Special paper ({PAPER_NAME} - {PAPER_DENSITY} g - {BIG_SHEETS} sheets {PAPER_SIZE})",
    "pdf_paper_client_special2":"Special paper ({PAPER_NAME} - {PAPER_DENSITY} g)",
    "paper_client":"Client paper",
    "special_client":"Special paper",
    "users_excel_id":"ID",
    "users_excel_first_name":"First Name",
    "users_excel_last_name":"Last Name",
    "users_excel_email":"Email",
    "users_excel_phone":"Phone",
    "users_excel_role":"Role",
    "users_excel_websites":"Website/s",
    "price_per_sheet2":"Price per sheet",
    "fin_ctrl_currency":"Currency",
    "fin_ctrl_approval":"Approval",
    "fin_ctrl_payment_term":"Payment terms",
    "fin_intra_community_vat":"Intra-community VAT",
    "field_required":"This field is required",
    "format_error":"The format doesn’t comply with the technological limits (min {MIN_MM} mm / max {MAX_MM} mm). Please, change it or contact us for a tailored quote.",
    "format_error1":"The format doesn’t comply with the technological limits (",
    "format_error2":").",
    "format_error3":"Please, change it or contact us for a tailored quote.",
    "spine_error":"You have reached the minimum or the maximum spine width allowed for this product (min {MIN_MM} mm / max {MAX_MM} mm). Please,contact us for a tailored quote.",
    "spine_error1":"You have reached the minimum or the maximum spine width allowed for this product (",
    "spine_error2":"mm).",
    "spine_error3":"Please,contact us for a tailored quote.",
    "due_date_validation":"Please fill the field - due date",
    "initial_dead_line":"Initial deadline",
    "books_format":"Формат",
    "pantone_serial":"Pantone number",
    "pantone_amount":"Pantone quantity",
    "prep_remarks":"Забележка състояние",
    "prep_remarks_probe":"Забележка проба",
    "documentation":"Documentation",
    "remarks_accounting":"Accounting remarks",
    "billing_ntities":"Billing еntities",
    "volume_without":"Volume m3 (without cartons)",
    "ratio":"Ratio",
    "at_name":"At",
    "tirage_label_ex":"Copies",
    "shipment_price":"Delivery price lv",
    "failed":"These credentials do not match our records.",
    "throttle":"Too many login attempts. Please try again in :seconds seconds.",
    "default":"Default",
    "data_error":"Data error",
    "calc_error":"Calculation error",
    "db_error":"Database error",
    "paper_color_density_mismatch_text":"The combination paper/color/density for this offer (text) was not found in the database. Please choose other!",
    "paper_color_density_mismatch_cover":"The combination paper/color/density for this offer (cover) was not found in the database. Please choose other!",
    "paper_color_density_mismatch_insert":"The combination paper/color/density for this offer (insert) was not found in the database. Please choose other!",
    "zero_copies":"Number of copies must be greater than zero.",
    "no_hat_db_record":"Cannot find db record for head and tails band.",
    "no_lace_db_record":"Cannot find db record for lace.",
    "text_pages_change":"Text pages change: :FROM :TO",
    "insert_pages_change":"Insert pages change: :FROM :TO",
    "cover_pages_change":"Cover pages change: :FROM :TO",
    "titles":"Mr.",
    "titles":"Mrs.",
    "organization_type":"Legal entity",
    "trademark":"Trademark",
    "address_correspondence":"Mail address",
    "code_ebp":"Code EBP",
    "code_ajour":"Code AJOUR",
    "civilite":"Mr. / Mrs.",
    "prenom":"First name (invoice)",
    "nomenvoi":"Last name (invoice)",
    "email_organization":"E-mail (invoice)",
    "email_organization_copy":"E-mail copy (invoice)",
    "siege_social":"headquarters",
    "create":"Create New",
    "edit":"Edit",
    "copy":"Copy",
    "delete":"Delete",
    "submit":"Submit",
    "email":"Email",
    "order":"Order",
    "overcharges_main":"Overcharges",
    "overcharges_list":"List",
    "overcharges_create":"Create",
    "title":"Title",
    "client_email":"This email is already in use by another client!",
    "new_client":"Cannot create new client, aborting order",
    "client_id":"Client not found, aborting order",
    "order_save":"Cannot save the order",
    "headings_create":"New Order",
    "headings_edit":"Edit Order",
    "filter_id":"Id",
    "filter_client":"Client / Organization / Contact",
    "filter_status":"Status",
    "filter_name":"Title",
    "filter_product_type":"Product",
    "filter_apply":"Apply",
    "filter_clear":"Clear",
    "filter_owner":"Owner",
    "filter_show_mine":"My orders",
    "filter_allproducts":"All",
    "filter_allusers":"All",
    "filter_from":"Date from",
    "filter_to":"Date to",
    "form_pricing":"Pricing",
    "form_price_final":"Final Price",
    "form_price":"Price",
    "form_print_run":"Print run",
    "form_info":"Order Details",
    "form_cover":"Cover",
    "form_text":"Text Body",
    "form_insert":"Insert",
    "form_properties":"Product Properties",
    "form_order_name":"Project name",
    "form_budget":"Budget",
    "form_overcharge_default":"Default Overcharge",
    "form_overcharge_total":"Total Overcharge",
    "form_overcharge_change":"Change",
    "form_discount":"Discount",
    "form_category":"Select Category",
    "form_client_new":"New Client",
    "form_client_select":"Select Client",
    "form_client_name":"Customer Name",
    "form_client_email":"Customer Email",
    "form_client_phone":"Customer Phone",
    "form_client_company":"Company Name",
    "form_pages":"Pages",
    "form_print":"Print",
    "form_finishing":"Finishing",
    "form_paper":"Paper",
    "form_color":"Paper Colour",
    "form_density":"Paper Density",
    "form_weight":"Weight",
    "form_height":"Height",
    "form_length":"Length",
    "form_size":"Finished size",
    "form_folding":"Type of Folding",
    "form_binding":"Binding",
    "form_copies":"Number of copies",
    "form_address":"Delivery address",
    "form_delivery":"Additional point of delivery",
    "form_comment":"Order comment",
    "form_add_cover":"Cover",
    "form_add_insert":"Insert",
    "form_add_jacket":"Jacket",
    "form_add_banderole":"Belly Band",
    "form_history_id":"ID",
    "form_history_section_title":"Order history",
    "form_history_ids":"ID / Order Id",
    "form_history_action":"Event",
    "form_history_user_names":"User",
    "form_history_date":"Date",
    "form_history_additional_data":"Additional data",
    "form_banderole":"Belly Band",
    "form_jacket":"Jacket",
    "form_additional_languages":"Black colour mutations",
    "form_main_language_copies":"Number of copies - main language",
    "form_add_language_copies":"Number of copies - additional language",
    "form_add_language_mutation_count":"mutations of BLACK color",
    "form_cloth":"Paper over board",
    "form_cardboard":"Cardboard",
    "form_endpapers":"Endpapers",
    "form_add_endpapers":"Endpapers",
    "form_cloth_type":"Type",
    "form_cloth_type_paper":"Case - Paper",
    "form_cloth_type_mixed":"Case - Paper and Cloth",
    "form_cloth_type_cloth":"Case - Cloth",
    "form_cloth_type_without_clothing":"Without clothing",
    "form_cloth_width":"Cloth width",
    "form_cloth_price":"Cloth price (sq.m.)",
    "form_cloth_visible_part":"Cloth visible part",
    "form_cloth_model":"Name/Model",
    "form_cloth_supplier":"Supplier",
    "form_cloth_min_length":"Minimum length (m)",
    "form_cardboard_paper_name_suffix":"(Cardboard thickness mm. / Foamboard thickness mm.)",
    "form_bookmark_insert":"Individually placed bookmark",
    "form_bookmark_no_insert":"Bookmarks aside",
    "form_bookmarks":"Bookmarks",
    "form_spine_type":"Spine type",
    "form_spine_type_flat":"Square",
    "form_spine_type_round":"Round",
    "form_cloth_int_select":"Cloth catalogs",
    "form_cloth_int_vendor":"Vendor",
    "form_cloth_int_type":"Type",
    "form_cloth_int_price":"Price per sq.m",
    "form_cloth_int_price_min":"Price / sq.m (min)",
    "form_cloth_int_price_max":"Price / sq.m (max)",
    "form_cloth_int_search":"Filter by product name",
    "form_cloth_int_apply":"Apply filter",
    "form_cloth_int_clear":"Reset",
    "form_cloth_int_cancel":"Cancel",
    "form_cloth_int_insert":"Insert selected",
    "form_cloth_int_series":"Series",
    "form_cloth_int_model":"Model",
    "form_cloth_int_size":"Dimensions",
    "price_correction_type":"Type",
    "price_correction_title":"Price correction",
    "price_correction_visible":"Complimentary",
    "price_correction_sum":"Amount",
    "price_correction_description":"Description",
    "price_correction_option_included_invisible":"",
    "price_correction_option_included_included":"Included services: ",
    "price_correction_option_included_free":"Complimentary services: ",
    "price_correction_option_included_invisible_button":"Do not show in the offer",
    "price_correction_option_included_included_button":"Included",
    "price_correction_option_included_free_button":"Offered",
    "order_name":"Title",
    "organization":"Organization",
    "id":"Id",
    "client":"Client",
    "product":"Product",
    "price":"Price",
    "status":"Status",
    "date_created":"Creation date",
    "account_manager":"AM/Hunter",
    "website":"Website",
    "budget":"Budget",
    "order_status":"Personal price",
    "order_status":"Price Cat. client_category_id",
    "order_status":"Public price",
    "sum_thickness":"Spine width",
    "client_missing_category_alert":"The selected client does not belong to any price category!",
    "price_change_alert":"Caution - price difference between the old offer and the recalculation! ({price_change}).",
    "hide_discount":"Do not show the discount in the offer",
    "without_cover":"Without cover",
    "old_price":"Old price",
    "print_run_from":"Print Run From",
    "print_run_to":"Print Run To",
    "previous":"« Previous",
    "next":"Next »",
    "password":"Passwords must be at least six characters and match the confirmation.",
    "reset":"Your password has been reset!",
    "sent":"We have e-mailed your password reset link!",
    "token":"This password reset token is invalid.",
    "user":"We can't find a user with that e-mail address.",
    "clear_all_filters":"Reset",
    "filter_by_all_active_filters":"Filter",
    "filters":"Filters",
    "filter_by_op_in":"Operator",
    "filter_by_machine":"Filter by Machine",
    "filter_by_stage":"Filter by Stage",
    "filter_by_element":"Filter by Element",
    "filter_by_action":"Filter by Action",
    "filter_by_state":"Filter by State",
    "btn_edit":"Edit",
    "btn_create":"Create",
    "btn_submit":"Submit",
    "btn_delete":"Delete",
    "btn_update":"Update",
    "btn_toggle_drop_down":"Toggle Drop-down",
    "btn_back":"Back",
    "list":"List",
    "name":"Name",
    "color":"Color",
    "element":"Element",
    "state":"State",
    "stage":"Stage",
    "component":"Component",
    "action":"Action",
    "state_type":"State Type",
    "continue":"Continue",
    "operator_types":"Operator Types",
    "operator_type":"Operator Type",
    "operator":"Operator",
    "code":"Authentication Code",
    "settings_title":"Settings",
    "settings_elements_title":"Elements",
    "settings_stages_title":"Stages",
    "settings_components_title":"Components",
    "settings_actions_title":"Actions",
    "settings_states_title":"States",
    "settings_state_types_title":"State Types",
    "settings_machines_title":"Machines",
    "settings_product_builder_title":"Product Builder",
    "settings_operators_title":"Operators",
    "settings_operator_types_title":"Operator types",
    "messages_select_product":"Select product",
    "messages_select_elements":"Select elements for product",
    "messages_not_all_elements_have_stages":"Not all elements have stages",
    "messages_select_stages":"Select stages for products element",
    "messages_not_all_stages_have_actions":"Not all stages have actions",
    "messages_select_actions":"Select actions for stage of products element",
    "messages_not_all_actions_have_states":"Not all actions have states",
    "messages_select_states":"Select states for action in state of products element",
    "messages_select_default":"The select default state must be one of the checked states",
    "messages_select_final":"The select final state must be one of the checked states",
    "validation_required":"is required",
    "validation_date":"must be of date type",
    "validation_email":"must be valid email address",
    "validation_number":"must be only digits",
    "validation_rangelength":"must be 4 digits",
    "validation_rangelength_remarks":"must be between 3 to 1000 characters",
    "format":"Format",
    "ready_copies":"Copies done",
    "copies":"Copies",
    "production_number":"Production number",
    "personalize_operation":"Personalize operation",
    "find_yourself_in_the_list":"Find yourself in the list",
    "enter_your_personal_code":"Enter your personal code",
    "koli":"Koli",
    "machine":"Machine",
    "trader":"Trader",
    "technologist_in_charge":"Technologist",
    "final_date_to_be_produced":"Produced date final",
    "order_remark":"Order remarks",
    "stage_remark":"Stage remarks",
    "additional_date":"Due date 2",
    "additional_date_remarks":"Production notes",
    "machines":"Machines",
    "stages":"Stages",
    "elements":"Elements",
    "actions":"Actions",
    "states":"States",
    "title":"Title",
    "traders":"Traders",
    "order_history":"Order history",
    "updated_by":"Updated by-at",
    "date":"Date",
    "remarks":"Remarks",
    "clients":"Clients",
    "bindings":"Bindings",
    "products":"Products",
    "show_only_mine":"Show only mine",
    "auth":"Authentication",
    "on":"On",
    "off":"Off",
    "error":"Error",
    "failed_action":"Failed action",
    "close":"Close",
    "colors":"Colors",
    "required":"required",
    "saved":"Saved",
    "integer":"must be only digits",
    "exact_length":"must be 4 digits",
    "":"L.",
    "":"H.",
    "landscapes":"Landscape distributions",
    "portraits":"Portrait distributions",
    "sort_by_filter":"Sort by filter",
    "sort_order":"Sort order",
    "distribution":"Distribution",
    "distributions":"Distributions",
    "distribution_amount":"Distribution amount",
    "distribution_bending":"Сгъване",
    "asc":"ASC",
    "desc":"DESC",
    "remarks_date":"Remarks date",
    "slug":"Slug",
    "value":"Value",
    "new_record":"New record",
    "event_text":"Event text",
    "event_data":"Event data",
    "event_date":"Event date",
    "history":"History",
    "system":"System",
    "show_only_active":"Show only active orders",
    "active":"Active",
    "done":"Done",
    "pages":"Pages",
    "big_sheets":"Бр. л. за печат",
    "final":"Крайно",
    "operator_permissions":"Възможности",
    "operator_permission":"Възможност",
    "spine":"Гръбче",
    "real_spine":"Реално гръбче",
    "sample":"Проба",
    "samples":"Проби",
    "bleed_inside":"Вътрешно",
    "bleed_outside":"Външно",
    "bleed_head":"Глава",
    "bleed_legs":"Крака",
    "bleeds":"Обрязване + мм",
    "technologist":"Технолог",
    "number_of_sheets_text":"Бр. листа",
    "big_sheets_marked":"Бр. отбелязани л. за печат",
    "big_sheets_mark":"Бр. л. за печат отбележи",
    "number_of_sheets_text_marked":"Бр. отбелязани листа",
    "number_of_sheets_text_mark":"Бр. листа отбележи",
    "big_sheets_in_week":"Бр. л. за печат в седмицата",
    "number_of_sheets_in_week":"Бр. листа в седмицата",
    "with_flaps":"С капаци",
    "prepress":"Монтаж",
    "ctp":"CTP",
    "workshop":"Склад",
    "workshop_ready_production":"Склад готова продукция",
    "completion":"Довършителен цех",
    "fitter":"Печатен цех",
    "place":"Място",
    "due_date":"Клиентски срок",
    "with_bookmark":"С влагане",
    "without_bookmark":"Без влагане",
    "urgent":"Необратена",
    "in_edit":"В процес",
    "inactive":"Неактивна",
    "order1":"Необработена",
    "order2":"В процес",
    "order3":"Неактивна",
    "order4":"Готова",
    "production_second_production":"Повторна изработка",
    "amount":"Брой",
    "no_recs_found":"No records found.",
    "no_states":"Няма създадени състояния",
    "no_actions":"Няма създадени действия",
    "none":"Няма",
    "btn_send_to_production":"Send to Production",
    "btn_revert_production":"Revert from Production",
    "additional_date_validation":"Additional date is required for Production",
    "send_to_preparer":"Send to prepress",
    "revert_from_preparer":"Revert from prepress",
    "request_real_spine":"Измерване на гръбче",
    "request_width_measure":"Измерване на дебелина",
    "width":"Дебелина",
    "width_body":"Дебелина на тяло",
    "request_width_body_measure":"Измерване на дебелина",
    "revert":"Върни",
    "revert_to":"Върни към",
    "preparer_required":"Please fill the prepress",
    "sample_custom":"Цифрова проба",
    "paper_custom":"Paper peculiarity",
    "lace":"Ласе",
    "head_and_tails_band":"Капителбанд",
    "bookmark":"Разделител",
    "foil_wrapped":"Опаковане поединично",
    "foil_packed":"Фолирано в пакети",
    "total_orders":"Тотал всичко",
    "total_orders_without_vshm_and_others":"Тотал без ВШМ и Други",
    "count_orders":"Бр. поръчки",
    "big_sheets":"Бр. подготовки 4+0",
    "big_sheets":"Бр. подготовки 1+1",
    "big_sheets":"Бр. подготовки 2+2",
    "number_of_sheets":"Бр. листа 4+0",
    "number_of_sheets":"Бр. листа 1+1",
    "number_of_sheets":"Бр. листа 2+2",
    "dist_amount":"Бр. коли",
    "embossing_foil":"Топъл печат",
    "search_by_period":"Търси по Период",
    "no_copies":"Няма въведени Готови бройки",
    "done_completion":"Готова",
    "deactivated_paper":"The paper can not be disabled - because it is the only active paper for this element.",
    "period_from":"Period from",
    "period_to":"Period to",
    "week":"Week",
    "fit_sheet":"График за печат",
    "fit_heading":"График за печат на",
    "fit_hour":"ч.",
    "fit_gr":"гр.",
    "fit_order":"Пор.",
    "fit_client":"Клиент",
    "fit_product":"Продукт",
    "fit_due_date":"Срок",
    "fit_tirage":"Тираж",
    "fit_paper_format":"Формат на хартията",
    "fit_paper":"Хартия",
    "fit_color_embossing":"Цветност/ламинат",
    "fit_big_sheets":"Подготовки на машината",
    "fit_tirage_sheets":"Тиражни листи",
    "fit_paper_place":"Сектор с хартия",
    "fit_digital_real_proof":"Дигитална проба Реална проба Мостра Да/Не",
    "fit_printing_start_hour":"Начало за печат (Час)",
    "fit_printing_end_hour":"Край (Час)",
    "fit_tirage_time_in_hours":"Време на тираж (Часове)",
    "fit_comment_or_problems":"Коментар по тиража / Възникнали проблеми",
    "fit_format":"Формат",
    "fit_binding":"Книговезане",
    "remarks_workshop":"Забележка склад",
    "partially_done":"Частично изработени",
    "probe":"Проба",
    "partially_done_and_finish":"Частично изработени и завърши",
    "dones":"Готови",
    "text_pages":"Text pages",
    "text_print":"Text print",
    "text_paper":"Text paper",
    "text_paper_density":"Text paper density [gr]",
    "body_maculaturi":"Big sheets + waste (body)",
    "body_size":"Format paper body",
    "insert_pages":"Insert pages",
    "insert_print":"Insert print",
    "insert_paper":"Insert paper",
    "insert_paper_density":"Insert paper density [gr]",
    "vlojka_maculaturi":"Big sheets + waste (insert)",
    "size_vlojka":"Format paper insert",
    "spine_width":"Spine width",
    "dashboard_main":"Dashboard",
};

export default us;
