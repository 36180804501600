import React from 'react';
import ReactTooltip from "react-tooltip";

export default class TooltipLabel extends React.Component
{
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div style={{display:'inline-table', marginLeft: 5, fontWeight: 700}}>
                <div style={{background:'lightblue', width: 20,height:20, textAlign: 'center'}}>
                    <p data-tip={this.props.label}>?</p>
                    <ReactTooltip place="right" effect={"solid"}/>
                </div>

            </div>
        );
    }
}
