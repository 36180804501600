import React, { Component } from 'react'

export default class Loader extends Component {
    constructor(props) {
        super(props);
    }

    style = {
        ///backgroundColor:'black',
        opacity: 1,
        color: '#33b5e5',
        textAlign: 'center',
        height: '100%',
        width: '100%',
        position: 'fixed',
        zIndex: 9999,
        fontSize: 75,
        top:0,
        left:0,
        fontWeight: '700',
        textShadow: '1px 1px 1px black',
        fontStyle: 'italic'
    }

    render() {
    return (
      <div className={"container"}>
          {this.props.isLoading
            && <div style={this.style}>
                <i style={{marginTop: '17%'}} className={"fa fa-spinner fa-spin 3x"}></i>
                <br/>
                Loading...
                </div>}
      </div>
    )
  }
}
