import React, {Component} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

// fake data generator
const getItems = count =>
    Array.from({length: count}, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: `${grid}px ${grid}px 0 ${grid}px`,
    margin: `0 0 ${grid}px 0`,
    border: "1px solid #c5c5c5",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#f4f6f9",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: grid,
    width: "100%",
    overflowY: 'scroll',
    height: "520px"

});

class DragAndDropForPapers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: this.props.items,
            densityIds: [],
            massCheck: [],
            checkedClasses: []
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    handleOnChange(density)
    {
        let arr = this.state.massCheck;

        if (arr.some(c => c === density))
        {
            arr = arr.filter(p => p !== density)
            this.setState({
                massCheck: [...this.state.massCheck = arr]
            })
        }
        else {
            this.setState(state => {
                state.massCheck.push(density);
            })
        }

        this.props.selectedPaper(density, this.props.loopIndex)
    }

    addClassToArray(className)
    {

        this.setState(state => {
            state.checkedClasses.push(className);
        });
    }

    handleOnColorChange(e, index)
    {
        this.props.selectOrDeselectAll(index, e.target.checked);
    }

    printPapers(colors, name, paperIndex) {
        let colorsTmp = colors;
        let paperType = this.props.paperType;
        let test = this.props.component;
        let nameComponent = this.props.component.name;
        if(nameComponent === 'insert')
        {
            nameComponent = 'text';
        }

        let colorsLi = [];
        for (let color of Object.keys(colorsTmp) )
        {
            if(colorsTmp[color] === undefined){break;}

            let densitiesLi = [];
            for (let density of Object.keys(colorsTmp[color].densities))
            {

                if(colorsTmp[color].densities[density]['has_' + nameComponent] === 1)
                {
                    //let selector = paperType +  name;
                    densitiesLi.push(
                        <li className="list-inline-item" key={colorsTmp[color].densities[density].paper_id}>
                            <label style={{ fontWeight: 400}}>
                                <input type="checkbox"
                                       checked={this.props.component.papers.includes(colorsTmp[color].densities[density].paper_id)}
                                       onChange={(e) => {
                                           let paperId = colorsTmp[color].densities[density].paper_id;
                                           this.props.selectOrDeselectSingleDensitiy(paperId, density,color, e.target.checked, paperIndex);
                                       }}
                                       value={colorsTmp[color].densities[density].paper_id}/>
                                {colorsTmp[color].densities[density].name}{/*Paper Density*/}
                            </label>
                        </li>)
                }

            }

            colorsLi.push(
                <li className="list-unstyled ml-3" key={color}>
                    <label >
                        {/*<input
                            checked={colorsTmp[color].densities.find(x=>x.selected === true) !== undefined}
                            type="checkbox"/>*/}
                        {colorsTmp[color].name}
                    </label>
                    <ul className="list-unstyled">
                        <li>{densitiesLi}</li>
                    </ul>
                </li>
            )
        }
        return colorsLi;
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
    }

    hasSelectedCheckBox(colors)
    {
        let selectedPapers = this.props.component.papers;
        let paperIds = [];

        for (let color of Object.values(colors))
        {
            for(let j=0;j<color.densities.length;j++)
            {
                paperIds.push(color.densities[j].paper_id);
            }
        }

        for (let i = 0; i < paperIds.length; i++)
        {
            if(Array.isArray(selectedPapers))
            {
                if(selectedPapers.includes(paperIds[i]))
                {
                    return true;
                }
            }
        }

        return false;
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        return (
            <div>
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >

                            {this.state.items.map((item, index) => (
                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            <label>
                                                <input
                                                    checked={this.hasSelectedCheckBox(item.colors)}
                                                    type="checkbox"
                                                    onChange={(e) => this.handleOnColorChange(e,index)}
                                                />
                                                {item.name} {/* Paper Name*/}
                                            </label>
                                            {this.printPapers(item.colors, item.name, index)}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            </div>
        );
    }
}

export default DragAndDropForPapers;
